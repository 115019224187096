import { AccountType, InvitationInput } from '../../../../../graphql/types.generated';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastErrorMessage } from '../../../../../utils/helpers';
import {
  CreateInvitationMutation,
  useCreateInvitationMutation,
} from 'graphql/mutations/invitation/generated/CreateInvitation';
import constants from 'constants/constants';
import { UserManagementDocument, UserManagementQuery } from 'graphql/queries/user/generated/UserManagement';
import { AllInvitationsQuery } from 'graphql/queries/invitation/generated/AllInvitations';
import { useGetInvitationsFilter } from 'hooks/invitation/useGetInvitations';
import { useGetUsersFilter } from 'hooks/user/useGetUsers';

const { tableRowsCountPerPage } = constants;

const useSubmitInvitationForm = (hideModal: () => void, accountType: AccountType) => {
  const { tenantId } = useParams();
  const { t } = useTranslation();
  const invitationsFilter = useGetInvitationsFilter();
  const usersFilter = useGetUsersFilter();

  const [createInvitation, { client }] = useCreateInvitationMutation({
    onError: toastErrorMessage,
    onCompleted: (result: CreateInvitationMutation) => {
      const { isDraft, ...rest } = invitationsFilter;
      const invitationOrUser = result.createInvitation;
      const variables = {
        limit: tableRowsCountPerPage,
        skip: 0,
        tenantId,
        usersFilter: { ...usersFilter, accountType },
        invitationsFilter: { ...rest, accountType },
      };

      client.cache.updateQuery({ query: UserManagementDocument, variables }, (data: UserManagementQuery | null) => {
        if (!data) return data;
        const { allInvitations, allUsers } = data;
        if (invitationOrUser.__typename === 'User') {
          const { items, total } = allUsers;
          const updatedUsers = {
            total: total + 1,
            items: [invitationOrUser as First<UserManagementQuery['allUsers']['items']>, ...items],
          };
          return { ...data, allUsers: { ...allUsers, ...updatedUsers } };
        } else {
          const { items, plainTotal, total } = allInvitations;
          const newItems = [invitationOrUser as First<AllInvitationsQuery['allInvitations']['items']>, ...items];
          const updatedInvitations = { plainTotal: (plainTotal ?? 0) + 1, total: total + 1, items: newItems };
          return { ...data, allInvitations: { ...allInvitations, ...updatedInvitations } };
        }
      });
      hideModal();
      toast.success(t('userManagement.invitations.invitationSuccessfullySent'));
    },
  });

  return useCallback(
    async (formData: InvitationInput) => {
      const variables = { ...(tenantId ? { tenantId } : {}), formData };
      await createInvitation({ variables });
    },
    [createInvitation, tenantId],
  );
};

export default useSubmitInvitationForm;
