import React, { useCallback } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AllUsersQuery } from 'graphql/queries/user/generated/AllUsers';
import { Modal, useModal } from 'components/common/Modal';
import UpdateUserAccountForm from './UpdateUserAccountForm';
import { AccountType } from 'graphql/types.generated';
import useDeleteUserFromTenant from 'hooks/tenant/useDeleteUserFromTenant';
import { confirmAlert } from 'components/common/Alert';
import { useParams } from 'react-router-dom';
import { IconButton } from 'components/common/IconButton';
import useDeactivateUser from 'hooks/user/useDeactivateUser';

interface IDeleteUserFromTenantProps {
  userId: string;
  accountType: AccountType;
}

export const DeleteUserFromTenantBtn: FC<IDeleteUserFromTenantProps> = ({ userId, accountType }) => {
  const { t } = useTranslation();
  const { tenantId } = useParams();
  const { onDeleteUserFromTenant, loading } = useDeleteUserFromTenant({ userId });
  const { deactivateUser, loading: deactivateUserLoading } = useDeactivateUser({ userId, accountType });

  const handleDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      confirmAlert({
        onConfirm: async () => {
          if (tenantId) {
            await onDeleteUserFromTenant({ variables: { userId, tenantId } });
          } else {
            await deactivateUser({ variables: { userId } });
          }
        },
        title: t('userManagement.users.deleteUserFromTenant.title'),
        message: t('userManagement.users.deleteUserFromTenant.message'),
      });
    },
    [onDeleteUserFromTenant, t, tenantId, userId, deactivateUser],
  );

  return (
    <IconButton
      loading={loading || deactivateUserLoading}
      disabled={loading || deactivateUserLoading}
      onClick={handleDelete}
      icon={faTrash}
      variant="danger"
    />
  );
};

interface UpdateUserAccountParams {
  user: First<AllUsersQuery['allUsers']['items']>;
  accountType: AccountType;
}

export const UpdateUserAccountBtn: FC<UpdateUserAccountParams> = ({ user, accountType }) => {
  const { t } = useTranslation();
  const { showModal, hideModal, isVisible } = useModal();

  return (
    <>
      <IconButton onClick={() => showModal({ user })} icon={faPencil} />

      <Modal
        isVisible={isVisible}
        onHide={hideModal}
        headerCloseButton
        headerText={t('userManagement.users.updateUserAccount.modalHeader')}
        size="lg"
      >
        <UpdateUserAccountForm {...{ hideModal, user, accountType }} />
      </Modal>
    </>
  );
};

interface IActionButtons {
  user: First<AllUsersQuery['allUsers']['items']>;
  accountType: AccountType;
}

const ActionButtons: FC<IActionButtons> = ({ user, accountType }) => {
  return (
    <Stack gap={2} direction="horizontal" className="flex-nowrap">
      <UpdateUserAccountBtn {...{ user, accountType }} />
      {[AccountType.tenant, AccountType.meinbau].includes(accountType) ? (
        <DeleteUserFromTenantBtn accountType={accountType} userId={user._id} />
      ) : null}
    </Stack>
  );
};

export default ActionButtons;
