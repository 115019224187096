import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import SubmitButton from 'components/common/Button/SubmitButton';
import Select from 'components/inputs/Select/Select';
import TextInput from 'components/inputs/TextInput/TextInput';
import useGetSearchParams from 'hooks/useGetSearchParams';
import constants from 'constants/constants';
import { refetchAllInvitationsQuery } from 'graphql/queries/invitation/generated/AllInvitations';
import { AccountType } from 'graphql/types.generated';
import { useGetInvitationsFilter } from 'hooks/invitation/useGetInvitations';
import { toastErrorMessage } from 'utils/helpers';

const { tableRowsCountPerPage } = constants;

interface IInvitationsFilterForm {
  changePage: (page: number) => void;
  accountType: AccountType;
}

interface FormValues {
  invitationsSearchQuery: string;
  isDraft: boolean | undefined;
}

const InvitationsFilterForm: FC<IInvitationsFilterForm> = ({ changePage, accountType }) => {
  const { t } = useTranslation();
  const { tenantId } = useParams();
  const { setSearchParams } = useGetSearchParams();
  const filter = useGetInvitationsFilter();

  const initialValues = useMemo(() => {
    return {
      invitationsSearchQuery: filter.searchQuery || '',
      isDraft: filter.isDraft,
    };
  }, [filter.isDraft, filter.searchQuery]);

  const onSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { invitationsSearchQuery, isDraft } = values;
        changePage(1);
        setSearchParams({
          ...(typeof invitationsSearchQuery === 'string' ? { invitationsSearchQuery } : {}),
          ...(typeof isDraft === 'boolean' ? { isDraft: String(isDraft) } : {}),
        });
        refetchAllInvitationsQuery({
          filter: { searchQuery: invitationsSearchQuery, isDraft, accountType },
          tenantId,
          limit: tableRowsCountPerPage,
          skip: 0,
        });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [accountType, changePage, setSearchParams, tenantId],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <Field
              name="invitationsSearchQuery"
              type="search"
              label="userManagement.searchQuery"
              component={TextInput}
            />

            <Field
              name="isDraft"
              label="userManagement.invitations.draftOrSent"
              isClearable
              component={Select}
              options={[
                { label: t('userManagement.invitations.draft'), value: true },
                { label: t('userManagement.invitations.sent'), value: false },
              ]}
            />
          </div>
          <div className="filter-buttons">
            <SubmitButton label="buttons.search" icon={faMagnifyingGlass} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InvitationsFilterForm;
