import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { InvitationFragmentDoc } from '../../../fragments/invitation/generated/InvitationFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllInvitationsQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
  filter: SchemaTypes.InvitationsFilter;
}>;

export type AllInvitationsQuery = {
  __typename?: 'Query';
  allInvitations: {
    __typename?: 'PaginatedInvitationResponse';
    total: number;
    plainTotal?: number | null;
    items: Array<{
      __typename?: 'Invitation';
      _id: string;
      role: SchemaTypes.Roles;
      email: string;
      createdAt: any;
      isDraft?: boolean | null;
      profile: { __typename?: 'UserProfile'; lastName: string; firstName: string };
      inviter?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
    }>;
  };
};

export const AllInvitationsDocument = gql`
  query AllInvitations($limit: Int!, $skip: Int!, $tenantId: ID, $filter: InvitationsFilter!) {
    allInvitations(limit: $limit, skip: $skip, tenantId: $tenantId, filter: $filter) {
      total
      plainTotal
      items {
        ...Invitation
      }
    }
  }
  ${InvitationFragmentDoc}
`;

/**
 * __useAllInvitationsQuery__
 *
 * To run a query within a React component, call `useAllInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvitationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      tenantId: // value for 'tenantId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllInvitationsQuery(
  baseOptions: Apollo.QueryHookOptions<AllInvitationsQuery, AllInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllInvitationsQuery, AllInvitationsQueryVariables>(AllInvitationsDocument, options);
}
export function useAllInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllInvitationsQuery, AllInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllInvitationsQuery, AllInvitationsQueryVariables>(AllInvitationsDocument, options);
}
export type AllInvitationsQueryHookResult = ReturnType<typeof useAllInvitationsQuery>;
export type AllInvitationsLazyQueryHookResult = ReturnType<typeof useAllInvitationsLazyQuery>;
export type AllInvitationsQueryResult = Apollo.QueryResult<AllInvitationsQuery, AllInvitationsQueryVariables>;
export function refetchAllInvitationsQuery(variables: AllInvitationsQueryVariables) {
  return { query: AllInvitationsDocument, variables: variables };
}
