import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIframeFilesQuery } from 'graphql/queries/file/generated/IframeFiles';
import PlainSelect, { PlainSelectParams } from './PlainSelect';

type IFilesSelectParams = Pick<PlainSelectParams, 'name' | 'label' | 'onSelectOption' | 'initialValue'>;

const FilesSelect: FC<IFilesSelectParams> = ({ name, onSelectOption, label = '', ...rest }) => {
  const { iframeId } = useParams() as { iframeId: string };
  const { data } = useIframeFilesQuery({ variables: { iframeId }, skip: !iframeId });

  const options = useMemo(() => {
    if (!data?.iframeFiles) return [];
    return data?.iframeFiles.map(({ _id, name }) => ({ label: name, value: _id }));
  }, [data?.iframeFiles]);

  return <PlainSelect {...{ options, onSelectOption, name, label, isSearchable: true, ...rest }} />;
};

export default FilesSelect;
