import { QueryHookOptions } from '@apollo/client';
import { ApiLogQuery, ApiLogQueryVariables, useApiLogQuery } from 'graphql/queries/apiCallLog/generated/ApiLog';

interface IApiLogProps extends QueryHookOptions<ApiLogQuery, ApiLogQueryVariables> {
  apiLogId: string;
}

export default function useApiCallLog({ apiLogId }: IApiLogProps) {
  const { data, loading, error } = useApiLogQuery({ variables: { apiLogId }, skip: !apiLogId });
  return { apiLog: (data?.apiLog || null) as ApiLogQuery['apiLog'] | null, loading, error };
}
