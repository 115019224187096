import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type TranslationFragment = { __typename?: 'Translation'; en: string; de: string };

export type TranslationFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TranslationFragmentDoc = gql`
  fragment Translation on Translation {
    en
    de
  }
`;
