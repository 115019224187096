import React, { CSSProperties } from 'react';
import RButton from 'react-bootstrap/Button';
import { ButtonProps } from 'react-bootstrap';
import cn from 'classnames';
import s from './Button.module.sass';
import theme from 'styles/variables.sass';
import Loading from '../Loading/Loading';

interface IButtonProps extends ButtonProps {
  loading?: boolean;
  style?: CSSProperties;
}

const Button: FC<IButtonProps> = ({
  children,
  className,
  onClick,
  disabled = false,
  loading = false,
  size,
  type = 'button',
  variant = 'primary',
  style,
}) => {
  return (
    <RButton
      {...{
        variant,
        size,
        onClick,
        type,
        disabled: disabled || loading,
        style,
        className: cn(s.btn, className),
      }}
    >
      <div
        style={{ minHeight: '24px', ...(loading ? { visibility: 'hidden' } : { gap: '8px' }) }}
        className="d-flex align-items-center justify-content-center flex-nowrap"
      >
        {children}
      </div>
      {/* {endIcon ? <FontAwesomeIcon icon={endIcon} /> : null} */}
      {loading ? (
        <div className={s.btnLoading}>
          <Loading size={30} color={variant.includes('outline') ? theme.primaryColor : 'white'} />
        </div>
      ) : null}
    </RButton>
  );
};

export default Button;
