import React from 'react';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { Alert } from 'react-bootstrap';
import useProjects from 'hooks/project/useProjects';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import { grabErrorMessage } from 'utils/helpers';
import ProjectsFilter from 'components/project/ProjectsFilter';
import ProjectsTable from 'components/project/ProjectsTable';
import ExcelReportsButton from 'components/project/ExcelReportsButton';

const ProjectsPage = () => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'projectsPage' });
  const { projects, loading, total, error } = useProjects({ currentPage });

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.projects') }]} />
      <PageTitle>{t('pageTitles.projects')}</PageTitle>
      <ExcelReportsButton />
      <ProjectsFilter {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <ProjectsTable {...{ projects, currentPage, changePage, total, loading }} /> : null}
    </>
  );
};

export default ProjectsPage;
