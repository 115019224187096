import { clamp } from 'lodash';
import { useEffect, useMemo } from 'react';
import constants from '../constants/constants';

interface TableObserverParams {
  currentPage: number;
  loading: boolean;
  isError: boolean;
  total: number;
  changePage: (page: number) => void;
  limit?: number;
}

const useTableObserver = ({
  currentPage,
  loading,
  isError,
  total,
  limit = constants.tableRowsCountPerPage,
  changePage,
}: TableObserverParams) => {
  const maxPage = useMemo(() => Math.ceil(total / limit), [total, limit]);
  useEffect(() => {
    if (!loading && !isError && maxPage > 0 && currentPage > maxPage) {
      changePage(clamp(currentPage - 1, 1, maxPage));
    }
  }, [changePage, currentPage, isError, loading, maxPage, total]);
};

export default useTableObserver;
