import { FileInstanceType, ProjectType } from 'graphql/types.generated';
import concat from 'lodash/concat';

export const commonFileTypes = [
  FileInstanceType.EMAIL_LOGO,
  FileInstanceType.UI_LOGO,
  FileInstanceType.PDF_LOGO,
  FileInstanceType.FAVICON,
  FileInstanceType.APPLE_TOUCH_ICON,
  FileInstanceType.FAVICON_32,
  FileInstanceType.FAVICON_16,
  FileInstanceType.SAFARI_PINNED_TAB,
  FileInstanceType.ANDROID_CHROME_192,
  FileInstanceType.ANDROID_CHROME_512,
  FileInstanceType.IMG_HOME_PAGE,
];

export const fileByFileProjectType = {
  [ProjectType.renovatingHouse]: concat(commonFileTypes, [
    FileInstanceType.IMG_FIRST_STEP,
    FileInstanceType.IMG_FOURTH_STEP,
    FileInstanceType.IMG_FIFTH_STEP,
    FileInstanceType.PARTNERS_FIRST_SECTION_BG,
    FileInstanceType.PARTNERS_FIRST_SECTION_LOGO,
    FileInstanceType.PARTNERS_SECOND_SECTION_BG,
    FileInstanceType.PARTNERS_SECOND_SECTION_LOGO,
    FileInstanceType.PARTNERS_THIRD_SECTION_BG,
    FileInstanceType.PARTNERS_THIRD_SECTION_LOGO,
  ]),
  [ProjectType.newBuilding]: concat(commonFileTypes, [
    FileInstanceType.IMG_SECOND_STEP,
    FileInstanceType.IMG_THIRD_STEP,
    FileInstanceType.IMG_FOURTH_STEP,
    FileInstanceType.IMG_FIFTH_STEP,
  ]),
};
