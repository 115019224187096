import GeneralCO2ReportTable, { ICO2MappedReport, ICO2ReportItem } from 'components/co2Reports/GeneralCO2ReportTable';
import { useCo2SavingsReportQuery } from 'graphql/queries/co2Savings/generated/CO2SavingsReport';
import { CO2Category } from 'graphql/types.generated';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { grabErrorMessage } from 'utils/helpers';
import lodash from 'lodash';

interface IGeneralCO2Report {
  iframeId: string;
}

const GeneralCO2Report: React.FunctionComponent<IGeneralCO2Report> = ({ iframeId }) => {
  const { data, loading, error } = useCo2SavingsReportQuery({ variables: { iframeId } });

  const co2MappedReport = useMemo(() => {
    const mappedData: ICO2MappedReport = {
      co2SavingsArray: [],
      sumOfCO2Savings: 0,
    };
    if (data) {
      mappedData.co2SavingsArray.push(
        ...(lodash
          .map(data.co2SavingsReport, (co2CalculationValue, co2CalculationKey) => {
            if (co2CalculationKey === '__typename') return;
            return {
              name: co2CalculationKey,
              co2Savings: (co2CalculationValue as CO2Category).co2Savings,
              projectsCount: (co2CalculationValue as CO2Category).projectsCount,
            };
          })
          .filter((co2Calculation) => !!co2Calculation) as ICO2ReportItem[]),
      );

      mappedData.sumOfCO2Savings = lodash.reduce(
        mappedData.co2SavingsArray,
        (sum, current) => sum + (current as CO2Category).co2Savings,
        0,
      );
    }
    return mappedData;
  }, [data]);

  return (
    <>
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <GeneralCO2ReportTable {...{ co2MappedReport, loading }} /> : null}
    </>
  );
};

export default GeneralCO2Report;
