import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import useGetSearchParams from 'hooks/useGetSearchParams';
import {
  Co2SavingsReportByProjectQuery,
  Co2SavingsReportByProjectQueryVariables,
  useCo2SavingsReportByProjectQuery,
} from 'graphql/queries/co2Savings/generated/CO2SavingsReportByProject';

interface IGetCO2ReportsByProjectProps
  extends QueryHookOptions<Co2SavingsReportByProjectQuery, Co2SavingsReportByProjectQueryVariables> {
  iframeId: string;
  currentPage: number;
}

export const useGetCO2ReportsByProjectFilter = () => {
  const {
    searchParams: { co2ReportsByProjectSearchQuery = '' },
  } = useGetSearchParams(['co2ReportsByProjectSearchQuery']);

  return { searchQuery: co2ReportsByProjectSearchQuery };
};

export default function useCO2ReportByProject({ iframeId, currentPage, ...baseOptions }: IGetCO2ReportsByProjectProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const filter = useGetCO2ReportsByProjectFilter();

  const { data, loading, error } = useCo2SavingsReportByProjectQuery({
    variables: {
      iframeId,
      skip,
      limit: constants.tableRowsCountPerPage,
      searchString: filter.searchQuery,
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const co2SavingsReportByProject = data?.co2SavingsReportByProject?.items || [];
  const total = data?.co2SavingsReportByProject?.total || 0;

  return {
    co2SavingsReportByProject,
    total,
    loading,
    error,
  };
}
