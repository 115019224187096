import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUserFromTenantMutationVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['ID']['input'];
  tenantId: SchemaTypes.Scalars['ID']['input'];
}>;

export type DeleteUserFromTenantMutation = { __typename?: 'Mutation'; deleteUserFromTenant: boolean };

export const DeleteUserFromTenantDocument = gql`
  mutation DeleteUserFromTenant($userId: ID!, $tenantId: ID!) {
    deleteUserFromTenant(userId: $userId, tenantId: $tenantId)
  }
`;
export type DeleteUserFromTenantMutationFn = Apollo.MutationFunction<
  DeleteUserFromTenantMutation,
  DeleteUserFromTenantMutationVariables
>;

/**
 * __useDeleteUserFromTenantMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromTenantMutation, { data, loading, error }] = useDeleteUserFromTenantMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useDeleteUserFromTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserFromTenantMutation, DeleteUserFromTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserFromTenantMutation, DeleteUserFromTenantMutationVariables>(
    DeleteUserFromTenantDocument,
    options,
  );
}
export type DeleteUserFromTenantMutationHookResult = ReturnType<typeof useDeleteUserFromTenantMutation>;
export type DeleteUserFromTenantMutationResult = Apollo.MutationResult<DeleteUserFromTenantMutation>;
export type DeleteUserFromTenantMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserFromTenantMutation,
  DeleteUserFromTenantMutationVariables
>;
