import SubmitButton from 'components/common/Button/SubmitButton';
import { FastField, Form, Formik } from 'formik';
import { useUpdateUserAccountMutation } from 'graphql/mutations/user/generated/UpdateUserAccount';
import { AccountType, Roles, UpdateUserAccountInput } from 'graphql/types.generated';
import React, { useCallback } from 'react';
import { toastErrorMessage } from 'utils/helpers';
import * as yup from 'yup';
import { UserTableRow } from './User.types';
import { Col, Row } from 'react-bootstrap';
import TextInput from 'components/inputs/TextInput/TextInput';
import { MenuPortalTarget } from 'components/inputs/Select/useMenuPortalTarget';
import Select from 'components/inputs/Select/Select';
import useFilteredRoleOptions from 'hooks/user/useFilteredRoleOptions';

const validationShape = {
  firstName: yup.string().required('errors.requiredField'),
  lastName: yup.string().required('errors.requiredField'),
  role: yup.string().required('errors.requiredField'),
};

const validationSchema = yup.object().shape(validationShape);

interface IUpdateUserAccountForm {
  hideModal: () => void;
  user: UserTableRow['actionButtons']['user'];
  accountType: AccountType;
}

const UpdateUserAccountForm: FC<IUpdateUserAccountForm> = ({ hideModal, user, accountType }) => {
  const [updateUserAccount] = useUpdateUserAccountMutation({ onError: toastErrorMessage });
  const roles = useFilteredRoleOptions(accountType);

  const initialValues: UpdateUserAccountInput = {
    firstName: user.profile.firstName,
    lastName: user.profile.lastName,
    role: user?.role || ('' as Roles),
  };

  const onSubmit = useCallback(
    async (values: UpdateUserAccountInput) => {
      await updateUserAccount({ variables: { userId: user._id, input: values } });
      hideModal();
    },
    [hideModal, updateUserAccount, user._id],
  );

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {() => (
        <Form>
          <Row>
            <Col md={6}>
              <FastField name="firstName" component={TextInput} label="auth.firstName" />
            </Col>
            <Col md={6}>
              <FastField name="lastName" component={TextInput} label="auth.lastName" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FastField
                name="role"
                component={Select}
                label="auth.role"
                options={roles}
                menuPortalTarget={MenuPortalTarget.modal}
              />
            </Col>
          </Row>
          <div className="text-end">
            <SubmitButton />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateUserAccountForm;
