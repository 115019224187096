import React from 'react';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { Alert } from 'react-bootstrap';
import useAuditLogs from 'hooks/auditLog/useAuditLogs';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import { grabErrorMessage } from 'utils/helpers';
import AuditLogsTable from 'components/auditLogs/AuditLogsTable';
import { AuditLogEventsClass } from 'graphql/types.generated';
import AuditLogsFilterForm from 'components/auditLogs/AuditLogsFilterForm';

const AuditLogsPage = () => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'iframesPage' });
  const { auditLogs, loading, total, error } = useAuditLogs({ currentPage });

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.auditLogs') }]} />
      <PageTitle>{t('pageTitles.auditLogs')}</PageTitle>
      <AuditLogsFilterForm {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? (
        <AuditLogsTable
          {...{ auditLogs: auditLogs as AuditLogEventsClass[], currentPage, changePage, total, loading }}
        />
      ) : null}
    </>
  );
};

export default AuditLogsPage;
