import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FastField, Formik, useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Stack, Form as RForm } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/common/Button/Button';
import { IframeWebhookInput, WebhookHeaderInput } from 'graphql/types.generated';
import * as yup from 'yup';
import TextInput from 'components/inputs/TextInput';
import Table from 'components/common/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

const validationSchema = yup.object().shape({
  name: yup.string().required('errors.requiredField'),
  value: yup.string().required('errors.requiredField'),
});

interface HeadersTableParams {
  headers: IframeWebhookInput['headers'];
  onDeleteHeader: (headerName: string) => void;
}

export type TableRow = WebhookHeaderInput;

const HeadersTable: FC<HeadersTableParams> = ({ headers, onDeleteHeader }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'webhook.headers.table' });

  const data: TableRow[] = useMemo(() => {
    return headers.map((header: TableRow) => {
      const { name, value } = header;
      return { name, value, actions: { ...header } };
    });
  }, [headers]);

  const columns: ColumnDef<TableRow>[] = useMemo(
    () => [
      {
        header: t('name'),
        accessorKey: 'name',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('value'),
        accessorKey: 'value',
        cell: (info) => info.getValue() as string,
      },
      {
        header: '',
        accessorKey: 'actions',
        cell: (info) => {
          const { name } = info.getValue() as WebhookHeaderInput;
          return (
            <Button variant="outline-danger" className="ms-auto" onClick={() => onDeleteHeader(name)}>
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          );
        },
      },
    ],
    [onDeleteHeader, t],
  );
  return <Table columns={columns} data={data} emptyText={t('emptyDataMessage')} />;
};

const Headers: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<IframeWebhookInput>();
  const [headers, setHeaders] = useState<IframeWebhookInput['headers']>(values.headers || []);
  const [error, setError] = useState<string>('');

  const initialValues: WebhookHeaderInput = {
    name: '',
    value: '',
  };

  const onSubmit = useCallback(
    (values: WebhookHeaderInput) => {
      if (headers.find(({ name }) => name === values.name)) {
        setError(t('webhook.headers.headerAlreadyExists'));
      } else {
        const newHeaders = [...headers, values];
        setHeaders(newHeaders);
        setFieldValue('headers', newHeaders);
        if (error) setError('');
      }
    },
    [error, headers, setFieldValue, t],
  );

  const handleDeleteHeader = useCallback(
    (targetHeader: string) => {
      const filtered = headers.filter(({ name }) => name !== targetHeader);
      setHeaders(filtered);
      setFieldValue('headers', filtered);
    },
    [headers, setFieldValue],
  );

  return (
    <>
      <Formik {...{ onSubmit, validationSchema, initialValues }} as="div">
        {({ isSubmitting, values, isValid, resetForm }) => (
          <>
            <Stack direction="horizontal" gap={2} className="align-items-start">
              <FastField name="name" component={TextInput} label="webhook.headers.form.name" />
              <FastField name="value" component={TextInput} label="webhook.headers.form.value" />
              <Button
                style={{ marginTop: '2rem' }}
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={() => {
                  if (isValid) {
                    onSubmit(values);
                    resetForm();
                  }
                }}
              >
                {t('buttons.add')} <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Stack>
            <RForm.Control.Feedback type="invalid" className="d-block">
              {error}
            </RForm.Control.Feedback>
          </>
        )}
      </Formik>

      <HeadersTable headers={headers} onDeleteHeader={handleDeleteHeader} />
    </>
  );
};

export default Headers;
