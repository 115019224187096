import { omit, set } from 'lodash';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type SearchParams = Record<string, string | undefined>;
export const searchParams2String = ({
  searchParams,
  omitProperties = [],
}: {
  searchParams: SearchParams;
  omitProperties?: string[];
}) =>
  `?${Object.entries(omit(searchParams, omitProperties))
    .map(([k, v]) => `${k}=${v}`)
    .join('&')}`;

const useGetSearchParams = (params?: string | string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!params) return { searchParams: Object.fromEntries(searchParams), setSearchParams };

  const selectedParams = useMemo(() => {
    if (typeof params === 'string') {
      return { [params]: searchParams.get(params) || undefined };
    }

    return params.reduce((acc, key) => {
      set(acc, key, searchParams.get(key) || undefined);
      return acc;
    }, {} as SearchParams);
  }, [params, searchParams]);

  return { searchParams: selectedParams, setSearchParams };
};

export default useGetSearchParams;
