import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectPdfRequestsQueryVariables = SchemaTypes.Exact<{
  iframeId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
  limit: SchemaTypes.Scalars['Int']['input'];
  searchQuery: SchemaTypes.Scalars['String']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
}>;

export type ProjectPdfRequestsQuery = {
  __typename?: 'Query';
  projectPdfRequests: {
    __typename?: 'PaginatedProjectPDFRequestResponse';
    total: number;
    items: Array<{
      __typename?: 'ProjectPDFRequest';
      _id: string;
      createdAt: any;
      project: { __typename?: 'Project'; _id: string; name: string };
      user: {
        __typename?: 'ProjectPdfRequestUser';
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
      };
      meta: { __typename?: 'Meta'; clientIP: string; domain: string };
    }>;
  };
};

export const ProjectPdfRequestsDocument = gql`
  query ProjectPdfRequests($iframeId: ID, $limit: Int!, $searchQuery: String!, $skip: Int!) {
    projectPdfRequests(iframeId: $iframeId, limit: $limit, searchQuery: $searchQuery, skip: $skip) {
      total
      items {
        _id
        project {
          _id
          name
        }
        user {
          email
          firstName
          lastName
          phoneNumber
        }
        meta {
          clientIP
          domain
        }
        createdAt
      }
    }
  }
`;

/**
 * __useProjectPdfRequestsQuery__
 *
 * To run a query within a React component, call `useProjectPdfRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPdfRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPdfRequestsQuery({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *      limit: // value for 'limit'
 *      searchQuery: // value for 'searchQuery'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useProjectPdfRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectPdfRequestsQuery, ProjectPdfRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectPdfRequestsQuery, ProjectPdfRequestsQueryVariables>(
    ProjectPdfRequestsDocument,
    options,
  );
}
export function useProjectPdfRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectPdfRequestsQuery, ProjectPdfRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectPdfRequestsQuery, ProjectPdfRequestsQueryVariables>(
    ProjectPdfRequestsDocument,
    options,
  );
}
export type ProjectPdfRequestsQueryHookResult = ReturnType<typeof useProjectPdfRequestsQuery>;
export type ProjectPdfRequestsLazyQueryHookResult = ReturnType<typeof useProjectPdfRequestsLazyQuery>;
export type ProjectPdfRequestsQueryResult = Apollo.QueryResult<
  ProjectPdfRequestsQuery,
  ProjectPdfRequestsQueryVariables
>;
export function refetchProjectPdfRequestsQuery(variables: ProjectPdfRequestsQueryVariables) {
  return { query: ProjectPdfRequestsDocument, variables: variables };
}
