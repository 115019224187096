import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/common/Button/Button';
import { IframeInput } from 'graphql/types.generated';

const DomainWhitelist: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } = useFormikContext<IframeInput>();
  const [domainWhitelist, setDomainWhitelist] = useState<string[]>(values.domainWhiteListTypes || []);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      if (error) setError('');
    },
    [error],
  );

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleAddDomain();
    }
  };

  const handleAddDomain = useCallback(() => {
    const isValidDomain = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(value);
    if (!isValidDomain) {
      setError(t('errors.invalidDomain'));
      return;
    }

    if (domainWhitelist.find((a) => a === value)) {
      setError(t('iframes.domainAlreadyExists'));
    } else {
      const domains = [...domainWhitelist, value];
      setDomainWhitelist(domains);
      setValue('');
      setFieldValue('domainWhiteListTypes', domains);
    }
  }, [domainWhitelist, setFieldValue, t, value]);

  const handleDeleteDomain = useCallback(
    (v: string) => {
      const filtered = domainWhitelist.filter((c: string) => c !== v);
      setDomainWhitelist(filtered);
      setFieldValue('domainWhiteListTypes', filtered);
    },
    [domainWhitelist, setFieldValue],
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <Form.Group className="mb-3 w-100 flex-1">
          <Form.Label className="text-primary" htmlFor="domainWhiteListTypes">
            {t('iframe.form.domainWhiteList')}
          </Form.Label>
          <div className="d-flex w-100">
            <Form.Control
              value={value}
              name="domainWhiteListTypes"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              className="w-100"
            />
          </div>
          <Form.Control.Feedback type="invalid" className="d-block">
            {error}
          </Form.Control.Feedback>
        </Form.Group>

        <Button style={{ marginTop: '32px', marginLeft: '10px', alignSelf: 'baseline' }} onClick={handleAddDomain}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>

      <ListGroup variant="flush" as="ol" numbered>
        {domainWhitelist.map((domain, i) => (
          <ListGroup.Item key={`${domain}_${i}`} as="li" className="d-flex align-items-center px-0">
            <div className="ps-1">{domain}</div>
            <Button variant="outline-danger" className="ms-auto" onClick={() => handleDeleteDomain(domain)}>
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Form.Control.Feedback type="invalid" className="d-block">
        {errors.domainWhiteListTypes ? t(errors.domainWhiteListTypes) : ''}
      </Form.Control.Feedback>
    </>
  );
};

export default DomainWhitelist;
