import { QueryHookOptions } from '@apollo/client';
import { TenantQuery, TenantQueryVariables, useTenantQuery } from 'graphql/queries/tenant/generated/Tenant';

interface IGetTenantProps extends QueryHookOptions<TenantQuery, TenantQueryVariables> {
  tenantId: string;
}

export default function useTenant({ tenantId }: IGetTenantProps) {
  const { data, loading, error } = useTenantQuery({ variables: { tenantId }, skip: !tenantId });
  return { tenant: (data?.tenant || null) as TenantQuery['tenant'] | null, loading, error };
}
