import React from 'react';
import './App.css';
import { ApolloProvider } from '@apollo/client';
import { AppContextProvider } from './contexts/AppContext';
import RootRouter from './router/RootRouter';
import client from 'graphql/apollo';
import 'styles/index.sass';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FunctionComponent = () => {
  return (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <RootRouter />
        <ToastContainer />
      </AppContextProvider>
    </ApolloProvider>
  );
};

export default App;
