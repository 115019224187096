import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router-dom';
import { TenantsQuery } from '../../graphql/queries/tenant/generated/Tenants';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { routePaths } from 'router/routes';
import { BasicUserAttributes } from 'graphql/types.generated';
import UserActionBadge from 'components/common/UserActionBadge/UserActionBadge';

export type TenantListItem = First<TenantsQuery['tenants']['items']>;
interface iTenantsTableProps extends IPaginationProps {
  tenants: TenantListItem[];
  loading: boolean;
}

export type TenantTableRow = {
  companyName: string;
  createdBy: BasicUserAttributes;
  updatedBy?: BasicUserAttributes | null;
  changedStatusBy?: BasicUserAttributes | null;
  _id: string;
};

const TenantsTable: FC<iTenantsTableProps> = ({ tenants, currentPage, changePage, total, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data: TenantTableRow[] = useMemo(() => {
    return tenants.map((tenant: TenantListItem) => {
      const { _id, companyName, archived, createdBy, updatedBy, changedStatusBy } = tenant;
      return {
        companyName,
        _id,
        archived,
        createdBy,
        updatedBy,
        changedStatusBy,
      };
    });
  }, [tenants]);

  const columns: ColumnDef<TenantTableRow>[] = useMemo(
    () => [
      {
        header: t('tenants.tableHeaders.companyName'),
        accessorKey: 'companyName',
        cell: (info) => (
          <div>
            <FontAwesomeIcon icon={faUpRightFromSquare} /> {info.getValue() as string}
          </div>
        ),
      },
      {
        header: t('tenants.tableHeaders.status'),
        accessorKey: 'archived',
        cell: (info) => (
          <Badge bg={(info.getValue() as boolean) ? 'danger' : 'success'}>
            {(info.getValue() as boolean) ? t('tenants.archived') : t('tenants.active')}
          </Badge>
        ),
      },
      {
        header: t('tenants.tableHeaders.statusChangedBy'),
        accessorKey: 'changedStatusBy',
        cell: (info) => {
          if (!info.getValue()) return null;
          return (
            <>
              <UserActionBadge user={info.getValue() as BasicUserAttributes} />
            </>
          );
        },
      },
      {
        header: t('tenants.tableHeaders.createdBy'),
        accessorKey: 'createdBy',
        cell: (info) => (
          <>
            <UserActionBadge user={info.getValue() as BasicUserAttributes} />
          </>
        ),
      },
      {
        header: t('tenants.tableHeaders.updatedBy'),
        accessorKey: 'updatedBy',
        cell: (info) => {
          if (!info.getValue()) return null;
          return (
            <>
              <UserActionBadge user={info.getValue() as BasicUserAttributes} />
            </>
          );
        },
      },
    ],
    [t],
  );

  const onRowClick = useCallback(
    (row: TenantTableRow) => navigate(generatePath(routePaths.tenantManagement, { tenantId: row._id })),
    [navigate],
  );

  return (
    <>
      <Table
        getRowProps={(row) => ({
          className: 'clickableRow',
          onClick: () => onRowClick(row.original),
        })}
        columns={columns}
        data={data}
        loading={loading}
        emptyText="tenants.noTenantsFound.description"
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default TenantsTable;
