import Cookies from 'js-cookie';
import config from 'config/config';

class RefreshTokenServiceClass {
  __KEY__ = 'refreshToken';
  constructor(private graphqlServerUrl: string) {}

  getRefreshToken = () => {
    try {
      return localStorage.getItem(this.__KEY__);
    } catch {
      return null;
    }
  };

  setRefreshToken = (refreshToken: string | null) => {
    try {
      return localStorage.setItem(this.__KEY__, refreshToken || '');
    } catch {
      return null;
    }
  };

  clearRefreshToken = () => {
    this.setRefreshToken('');
  };

  isTokenValidOrUndefined = () => {
    const tokenExp = Cookies.get('accessTokenExp');
    const refreshToken = this.getRefreshToken();
    if (!tokenExp || !refreshToken) return true;
    const exp = parseInt(tokenExp, 10);
    const now = new Date().getTime();
    const isExpired = exp < now;
    return !isExpired;
  };

  fetchNewAccessToken = async () => {
    try {
      const fetchResult = await fetch(this.graphqlServerUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-client-type': config.xClientType },
        body: JSON.stringify({
          query: `
            mutation {
              token(refreshToken: "${this.getRefreshToken()}") {
                accessToken
                refreshToken
              }
            }
          `,
        }),
      });
      const refreshResponse = await fetchResult.json();
      if (!refreshResponse?.data?.token?.accessToken) {
        return undefined;
      }
      const { refreshToken } = refreshResponse.data.token;
      if (refreshToken) this.setRefreshToken(refreshToken);

      return refreshResponse.data.token;
    } catch (e) {
      throw new Error('Failed to fetch fresh access token');
    }
  };
}

export default new RefreshTokenServiceClass(config.graphqlServerUrl);
