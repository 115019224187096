import React, { MouseEventHandler } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Row, Table } from '@tanstack/react-table';
import cn from 'classnames';

interface iExpandedArrow extends Pick<FontAwesomeIconProps, 'onClick'> {
  isActive?: boolean;
}

const ExpandedArrow: FC<iExpandedArrow> = ({ isActive, ...props }) => {
  return (
    <FontAwesomeIcon
      {...props}
      className={cn('transition-transform', isActive ? 'rotate-180' : undefined)}
      icon={faAngleDown}
    />
  );
};

interface iExpandedHeader<T> {
  table: Table<T>;
  isExpanded?: boolean;
  children?: React.ReactNode;
  isCanSort?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ExpandedHeader = <T,>({ isExpanded, table, children, isCanSort, onClick }: iExpandedHeader<T>) => {
  if (isExpanded) {
    return (
      <div className="d-flex align-items-center gap-2">
        <ExpandedArrow isActive={table.getIsAllRowsExpanded()} onClick={table.getToggleAllRowsExpandedHandler()} />
        <div>{children}</div>
      </div>
    );
  }

  if (typeof onClick === 'function' && isCanSort) {
    return (
      <button
        onClick={onClick}
        style={{ all: 'unset' }}
        className={`d-flex align-items-center gap-2 ${isCanSort ? 'cursor-pointer select-none' : ''}`}
        role={isCanSort ? 'button' : ''}
      >
        {children}
      </button>
    );
  }
  return <div className={`d-flex align-items-center gap-2`}>{children}</div>;
};

interface iExpandedRow<T> {
  row: Row<T>;
  isExpanded?: boolean;
  children?: React.ReactNode;
}

export const ExpandedRow = <T,>({ isExpanded, row, children }: iExpandedRow<T>) => {
  if (!isExpanded || !row.getCanExpand()) return <>{children}</>;
  return (
    <div className="d-flex align-items-center gap-2">
      <ExpandedArrow
        isActive={row.getIsExpanded()}
        onClick={(e) => {
          e.stopPropagation();
          row.getToggleExpandedHandler()();
        }}
      />
      <div>{children}</div>
    </div>
  );
};

export interface ExpandedDepth<T> extends iExpandedRow<T> {
  expandedSpace?: number;
}

export const ExpandedDepth = <T,>({ row, isExpanded, children, expandedSpace = 1 }: ExpandedDepth<T>) => {
  if (!isExpanded) return <>{children}</>;
  return <div style={{ paddingLeft: `${row.depth * expandedSpace}rem` }}>{children}</div>;
};
