import { useCallback } from 'react';
import { useAppContext } from 'contexts/AppContext';
import { useApolloClient, ApolloClient, MutationHookOptions } from '@apollo/client';
import RefreshTokenService from 'services/RefreshTokenService';
import { MeDocument } from 'graphql/queries/user/generated/Me';
import { LogoutMutation, LogoutMutationVariables, useLogoutMutation } from 'graphql/mutations/auth/generated/Logout';

export const afterLogout = (client: ApolloClient<object>) => {
  RefreshTokenService.clearRefreshToken();
  client.writeQuery({ query: MeDocument, data: { me: null } });
  client.resetStore().catch(() => {
    return undefined;
  });
};

const useLogout = (baseOptions?: MutationHookOptions<LogoutMutation, LogoutMutationVariables>) => {
  const client = useApolloClient();
  const [logoutMutation] = useLogoutMutation();
  const { toggleSidebar } = useAppContext();

  const logout = useCallback(async () => {
    await logoutMutation(baseOptions);
    afterLogout(client);
    toggleSidebar(false);
  }, [baseOptions, client, logoutMutation, toggleSidebar]);

  return logout;
};

export default useLogout;
