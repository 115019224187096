import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type MeFragment = {
  __typename?: 'User';
  _id: string;
  email: string;
  role?: SchemaTypes.Roles | null;
  tenantId?: string | null;
  isTwoFAEnabled?: boolean | null;
  profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  activeRoles: Array<{ __typename?: 'UserRoleClass'; role: SchemaTypes.Roles; tenantId?: string | null }>;
};

export type MeFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const MeFragmentDoc = gql`
  fragment Me on User {
    _id
    email
    role
    tenantId
    isTwoFAEnabled
    profile {
      firstName
      lastName
    }
    activeRoles {
      role
      tenantId
    }
  }
`;
