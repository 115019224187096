import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FastField } from 'formik';
import {
  useIntegrateWithPriceHubbleIframeMutation,
  IntegrateWithPriceHubbleIframeMutationVariables,
} from 'graphql/mutations/priceHubble/generated/IntegrateWithPriceHubbleIframe';
import { toastErrorMessage } from 'utils/helpers';
import TextInput from 'components/inputs/TextInput/TextInput';
import Button from 'components/common/Button/Button';
import { faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubmitButton from 'components/common/Button/SubmitButton';

type PriceHubbleDataInput = IntegrateWithPriceHubbleIframeMutationVariables['priceHubbleData'];

type IframePriceHubbleFormParams = {
  iframeId: string;
  hideModal: () => void;
};

const validationSchema = yup.object().shape({
  username: yup.string().required('errors.requiredField'),
  password: yup.string().required('errors.requiredField'),
});

const IframePriceHubbleForm: FC<IframePriceHubbleFormParams> = ({ hideModal, iframeId }) => {
  const [integrateIframe] = useIntegrateWithPriceHubbleIframeMutation({
    onError: toastErrorMessage,
    onCompleted: async () => {
      hideModal();
    },
  });

  const { t } = useTranslation();
  const initialValues: PriceHubbleDataInput = useMemo((): PriceHubbleDataInput => ({ username: '', password: '' }), []);
  const onSubmit = useCallback(
    async (values: PriceHubbleDataInput) => {
      await integrateIframe({
        variables: {
          iframeId,
          priceHubbleData: {
            username: values.username,
            password: values.password,
          },
        },
      });
    },
    [iframeId, integrateIframe],
  );

  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
        validationSchema,
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FastField name="username" component={TextInput} label="iframe.priceHubbleApiUsername" />
          <FastField name="password" component={TextInput} label="iframe.priceHubbleApiPassword" />

          <Stack direction="horizontal" className="justify-content-end" gap={3}>
            <Button variant="secondary" disabled={isSubmitting} onClick={hideModal}>
              {t('iframe.close')} <FontAwesomeIcon icon={faXmark} />
            </Button>
            <SubmitButton label={'iframe.integrate'} icon={faKey} />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default IframePriceHubbleForm;
