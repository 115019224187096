import React from 'react';
import { Alert } from 'react-bootstrap';
import { grabErrorMessage } from 'utils/helpers';
import useIframe from 'hooks/iframe/useIframe';
import IframeFuelMatrixForm from './IframeFuelMatrixForm';

interface IIframeFuelMatrix {
  iframeId: string;
}

const IframeFuelMatrix: React.FunctionComponent<IIframeFuelMatrix> = ({ iframeId }) => {
  const { iframe, loading, error } = useIframe({ iframeId });

  return (
    <>
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error && iframe ? <IframeFuelMatrixForm iframe={iframe} /> : null}
    </>
  );
};

export default IframeFuelMatrix;
