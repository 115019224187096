import { faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import SubmitButton from 'components/common/Button/SubmitButton';
import TextInput from 'components/inputs/TextInput';
import { FastField, Form, Formik } from 'formik';
import { useIntegrateWithGTMMutation } from 'graphql/mutations/iframe/generated/IntegrateWithGTM';
import React, { useCallback, useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastErrorMessage } from 'utils/helpers';
import * as yup from 'yup';

interface GTMFormParams {
  iframeId: string;
  hideModal: () => void;
}

const validationSchema = yup.object().shape({
  gtmKey: yup.string().required('errors.requiredField'),
});

const GTMForm: FC<GTMFormParams> = ({ iframeId, hideModal }) => {
  const { t } = useTranslation();
  const [addGtmIntegration] = useIntegrateWithGTMMutation({ onError: toastErrorMessage });

  const onSubmit = useCallback(
    async (values: { gtmKey: string }) => {
      await addGtmIntegration({ variables: { iframeId, gtmKey: values.gtmKey } });

      hideModal();
    },
    [addGtmIntegration, hideModal, iframeId],
  );
  const initialValues: { gtmKey: string } = useMemo(() => {
    return {
      gtmKey: '',
    };
  }, []);

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema, enableReinitialize: true }}>
      {({ isSubmitting }) => (
        <Form>
          <FastField name="gtmKey" label="iframe.gtm.modal.gtmKey" component={TextInput} />

          <Stack direction="horizontal" className="justify-content-end" gap={3}>
            <Button variant="secondary" disabled={isSubmitting} onClick={hideModal}>
              {t('iframe.close')} <FontAwesomeIcon icon={faXmark} />
            </Button>
            <SubmitButton label="iframe.integrate" icon={faKey} />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default GTMForm;
