import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { FileFragmentDoc } from './FileFragment';
export type FileInstanceLinkFragment = {
  __typename?: 'FileInstanceLink';
  _id: string;
  instanceId: string;
  instanceType: SchemaTypes.FileInstanceType;
  file: {
    __typename?: 'File';
    _id: string;
    name: string;
    iframeId: string;
    tenantId: string;
    sizeBytes: number;
    createdAt: any;
  };
};

export type FileInstanceLinkFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const FileInstanceLinkFragmentDoc = gql`
  fragment FileInstanceLink on FileInstanceLink {
    _id
    instanceId
    instanceType
    file {
      ...File
    }
  }
  ${FileFragmentDoc}
`;
