import React, { useCallback, useEffect, useState } from 'react';
import RTabs from 'react-bootstrap/Tabs';
import RTab from 'react-bootstrap/Tab';
import s from './Tabs.module.sass';
import { get } from 'lodash';
import useGetSearchParams from 'hooks/useGetSearchParams';

interface ITab {
  eventKey: string;
  title: string | React.ReactElement;
  disabled?: boolean;
  content: React.ReactNode | React.ReactElement | null;
  hide?: boolean;
}

interface ITabsProps {
  tabs: ITab[];
  initialTabEventKey: string;
  restoreLastActiveTabKey: string;
  searchParamKey?: any;
}

const Tabs: FC<ITabsProps> = ({ tabs, initialTabEventKey, restoreLastActiveTabKey, searchParamKey = 'tab' }) => {
  const { searchParams, setSearchParams } = useGetSearchParams();
  const [activeEventKey, setActiveEventKey] = useState<string | number>(
    searchParams[searchParamKey] || localStorage.getItem(restoreLastActiveTabKey) || initialTabEventKey,
  );
  useEffect(() => {
    if (searchParams[searchParamKey] !== activeEventKey) {
      const param = get(searchParams, searchParamKey, '');
      if (param) setActiveEventKey(param);
    }
  }, [activeEventKey, searchParamKey, searchParams, tabs]);

  useEffect(() => {
    const tab = tabs.find(({ eventKey }) => eventKey === activeEventKey);
    if (!tab || tab.hide) {
      const fallbackEventKey = tabs[0]?.eventKey;
      setSearchParams({ ...searchParams, [searchParamKey]: fallbackEventKey });
      setActiveEventKey(fallbackEventKey);
    }
  }, [activeEventKey, searchParamKey, searchParams, setSearchParams, tabs]);

  const onSelectTab = useCallback(
    (key: string | null) => {
      if (key) {
        setActiveEventKey(key);
        setSearchParams({ [searchParamKey]: key });
        localStorage.setItem(restoreLastActiveTabKey, key);
      }
    },
    [restoreLastActiveTabKey, searchParamKey, setSearchParams],
  );

  return (
    <RTabs unmountOnExit activeKey={activeEventKey} onSelect={onSelectTab} className={s.tabs}>
      {tabs
        .filter(({ hide }) => !hide)
        .map(({ eventKey, title, disabled, content }) => (
          <RTab key={eventKey} {...{ eventKey, title, disabled }}>
            {activeEventKey === eventKey ? content : null}
          </RTab>
        ))}
    </RTabs>
  );
};

export default Tabs;
