import React, { useMemo } from 'react';
import s from './Avatar.module.sass';
import { User } from '../../../graphql/types.generated';

export const stringToHslColor = ({
  str,
  saturation = 40,
  lightness = 60,
}: {
  str: string;
  saturation?: number;
  lightness?: number;
}) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 4) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${saturation}%, ${lightness}%)`;
};

export const getUserInitials = (user: Pick<User, 'profile'>) => {
  const { profile } = user;
  const { firstName, lastName } = profile;
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

const Avatar = ({ user, size = 40 }: { user?: Pick<User, 'profile' | 'email'> | null; size?: number }) => {
  const initials = useMemo(() => {
    if (!user) return '';
    return getUserInitials(user);
  }, [user]);

  const username = useMemo(() => {
    if (!user) return '';
    const { profile, email } = user;
    return `${profile.firstName} ${profile.lastName} ${email}`;
  }, [user]);

  return (
    <div
      className={s.avatar}
      style={{
        backgroundColor: stringToHslColor({ str: username }),
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        minHeight: `${size}px`,
      }}
    >
      {initials}
    </div>
  );
};

export default Avatar;
