import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import RModal, { ModalProps } from 'react-bootstrap/Modal';

export const useModal = <T,>() => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalProps, setModalProps] = useState({} as T);
  const showModal = useCallback((data: T = {} as T) => {
    setModalProps(data);
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
    setModalProps({} as T);
  }, []);

  return { showModal, hideModal, modalProps, isVisible };
};

export interface iModalProps extends ModalProps {
  isVisible: boolean;
  headerText: string;
  onHide: () => void;
  children: React.ReactNode;
  headerCloseButton?: boolean;
}

const Modal: FC<iModalProps> = ({
  children,
  isVisible,
  onHide,
  size,
  headerText = '',
  headerCloseButton,
  centered = true,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <RModal {...{ show: isVisible, onHide, size, centered, ...rest }}>
      <RModal.Header closeButton={headerCloseButton}>
        <RModal.Title id="contained-modal-title-vcenter">{t(headerText)}</RModal.Title>
      </RModal.Header>
      <RModal.Body>{children}</RModal.Body>
    </RModal>
  );
};

export default Modal;
