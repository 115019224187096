import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { FieldInputProps, FormikProps } from 'formik';
import { debounce, get, isNull } from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface ITextArea {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  type?: string;
  label: string;
  isDisabled?: boolean;
  rows?: number;
  helpText?: string;
  max?: number;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  autoSubmitDebounce?: number;
}

const TextArea: FC<ITextArea> = ({
  field,
  form,
  label,
  isDisabled = false,
  rows = 5,
  max,
  helpText,
  placeholder,
  autoFocus,
  className = 'mb-3',
  autoSubmitDebounce,
}) => {
  const { name, onBlur, value } = field;
  const { errors, touched } = form;
  const { t } = useTranslation();
  const debounceSubmitForm = useMemo(
    () => debounce(form.submitForm, autoSubmitDebounce),
    [autoSubmitDebounce, form.submitForm],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(name, e.target.value);
      if (Number.isFinite(autoSubmitDebounce)) debounceSubmitForm();
    },
    [autoSubmitDebounce, debounceSubmitForm, form, name],
  );

  useEffect(() => {
    if (isNull(value)) form.setFieldValue(name, '');
  }, [form, name, value]);

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group className={className}>
      {label ? (
        <Form.Label className="text-primary" htmlFor={name}>
          {t(label)}
        </Form.Label>
      ) : null}
      <Form.Control
        autoFocus={autoFocus}
        {...{ name, onBlur, rows }}
        value={value ?? ''}
        disabled={isDisabled}
        readOnly={isDisabled}
        onChange={handleChange}
        className={cn(error ? 'is-invalid' : '')}
        as="textarea"
        placeholder={placeholder && t(placeholder)}
        maxLength={max}
      />

      {helpText ? <Form.Text className="text-muted">{t(helpText)}</Form.Text> : null}

      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default TextArea;
