import React, { HTMLProps, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import s from './RowSelectionColumn.module.sass';

function IndeterminateCheckbox({
  indeterminate,
  checked,
  disabled,
  onChange,
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate, checked]);

  return (
    <Form.Check
      ref={ref}
      type="checkbox"
      className={s.checkbox}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
    />
  );
}

export default IndeterminateCheckbox;
