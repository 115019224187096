import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Stack, Badge, ListGroup, Row, Col } from 'react-bootstrap';
import Button from 'components/common/Button/Button';
import IframeButton, { IframeButtonType } from 'components/iframe/IframeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'components/common/Alert';
import UserActionBadge from 'components/common/UserActionBadge/UserActionBadge';
import { useArchiveIframeMutation } from 'graphql/mutations/iframe/generated/ArchiveIframe';
import { useReleaseIframeMutation } from 'graphql/mutations/iframe/generated/ReleaseIframe';
import { copyToClipBoard, toastErrorMessage } from 'utils/helpers';
import s from './IframeBaseData.module.sass';
import { Iframe, Roles } from 'graphql/types.generated';
import useUser from 'hooks/user/useUser';
import useTenant from 'hooks/tenant/useTenant';
import config from 'config/config';

interface IColorBoxParams {
  color: string;
}

const ColorBox: FC<IColorBoxParams> = ({ color }) => {
  return (
    <div
      className={s.colorBox}
      style={{
        backgroundColor: color,
      }}
    />
  );
};

interface IIframeBaseDataParams {
  iframe: Iframe;
}

const IframeBaseData: FC<IIframeBaseDataParams> = ({ iframe }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'iframe' });
  const { t: globalT } = useTranslation('translation');
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const [archiveIframe] = useArchiveIframeMutation({ onError: toastErrorMessage });
  const [releaseIframe] = useReleaseIframeMutation({ onError: toastErrorMessage });
  const { tenant } = useTenant({ tenantId: iframe.tenantId });

  const onChangeArchivingStatus = useCallback(async () => {
    confirmAlert({
      onConfirm: async () => {
        if (iframe?.archived) {
          await releaseIframe({
            variables: { iframeId: iframe._id },
          });
        } else {
          await archiveIframe({
            variables: { iframeId: iframe._id },
          });
        }
      },
      title: iframe.archived ? t('alert.releaseHeader') : t('alert.suspendHeader'),
      message: iframe.archived ? t('alert.releaseIframeAlert') : t('alert.suspendIframeAlert'),
    });
  }, [archiveIframe, iframe._id, iframe.archived, releaseIframe, t]);

  return (
    <>
      <Card>
        <Card.Header>
          <Stack direction="horizontal" gap={3}>
            <Card.Title className="mb-0">
              {iframe.name}
              <Button
                size="sm"
                className="mx-1 h-75"
                onClick={() =>
                  copyToClipBoard(new URL(iframe._id, config.iframeBaseUrl).toString(), t('copiedToClipboard'))
                }
              >
                {t('copyLink')}
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Card.Title>
            <Badge className="ms-auto" bg={iframe.priceHubbleConfig?.isEnabled ? 'primary' : 'danger'}>
              {iframe.priceHubbleConfig?.isEnabled ? t('integratedWithPriceHubble') : t('notIntegratedWithPriceHubble')}
            </Badge>
            <Badge bg={iframe.archived ? 'danger' : 'success'}>{iframe.archived ? t('archived') : t('active')}</Badge>
          </Stack>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('baseData.projectTypes')}</Card.Text>
                  {iframe.projectType
                    ? globalT(`projectTypes.${iframe.projectType}`)
                    : t('baseData.projectTypeNotSpecified')}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col md={3}>
                      <Card.Text className="fw-bold mb-1">{t('baseData.primaryColor')}</Card.Text>
                      {iframe.theme.primaryColor}
                      <ColorBox color={iframe.theme.primaryColor} />
                    </Col>
                    <Col md={3}>
                      <Card.Text className="fw-bold mb-1">{t('baseData.textColor')}</Card.Text>
                      {iframe.theme.textColor}
                      <ColorBox color={iframe.theme.textColor} />
                    </Col>
                    <Col md={3}>
                      <Card.Text className="fw-bold mb-1">{t('baseData.pdfButtonColor')}</Card.Text>
                      {iframe.theme.pdfButtonColor || iframe.theme.primaryColor}
                      <ColorBox color={iframe.theme.pdfButtonColor || iframe.theme.primaryColor} />
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('baseData.textColor')}</Card.Text>
                  {iframe.theme.textColor}
                  <ColorBox color={iframe.theme.textColor} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <>
                    <Card.Text className="fw-bold mb-1">{t('baseData.iframeHtmlTitle')}</Card.Text>
                    {iframe.title}
                  </>
                </ListGroup.Item>
                <ListGroup.Item>
                  <>
                    <Card.Text className="fw-bold mb-1">{t('baseData.domainWhiteListTypes')}</Card.Text>
                    {iframe.domainWhiteListTypes.length
                      ? iframe.domainWhiteListTypes.map((allowedDomain, index) => (
                          <Badge key={index} className="mx-1" bg="primary">
                            {allowedDomain}
                          </Badge>
                        ))
                      : t('baseData.domainWhiteListTypesNotSpecified')}
                  </>
                </ListGroup.Item>
                <ListGroup.Item>
                  <>
                    <Card.Text className="fw-bold mb-1">{t('baseData.headInjectionTags')}</Card.Text>
                    {iframe.headInjectionTags?.length
                      ? iframe.headInjectionTags
                      : t('baseData.headInjectionTagsNotSpecified')}
                  </>
                </ListGroup.Item>
                {iframe.changedStatusBy && (
                  <ListGroup.Item>
                    <>
                      <Card.Text className="fw-bold mb-1">
                        {iframe.archived ? t('baseData.archivedBy') : t('baseData.releasedBy')}
                      </Card.Text>
                      <UserActionBadge user={iframe.changedStatusBy} date={iframe.changedStatusAt} />
                    </>
                  </ListGroup.Item>
                )}
                {iframe.privacyPolicyUrl && (
                  <ListGroup.Item>
                    <>
                      <Card.Text className="fw-bold mb-1">{t('baseData.privacyPolicyUrl')} </Card.Text>
                      {iframe.privacyPolicyUrl}
                    </>
                  </ListGroup.Item>
                )}
                {iframe.termsOfUseUrl && (
                  <ListGroup.Item>
                    <>
                      <Card.Text className="fw-bold mb-1">{t('baseData.termsOfUseUrl')}</Card.Text>
                      {iframe.termsOfUseUrl}
                    </>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
            <Col>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <>
                    <Card.Text className="fw-bold mb-1">{t('baseData.requestProjectPdfConfig.emailFrom')}</Card.Text>
                    {iframe.requestProjectPdfConfig.emailFrom}
                  </>
                </ListGroup.Item>
                <ListGroup.Item>
                  <>
                    <Card.Text className="fw-bold mb-1">{t('baseData.requestProjectPdfConfig.subject')}</Card.Text>
                    {iframe.requestProjectPdfConfig.subject}
                  </>
                </ListGroup.Item>
                <ListGroup.Item>
                  <>
                    <Card.Text className="fw-bold mb-1">{t('baseData.requestProjectPdfConfig.message')}</Card.Text>
                    {iframe.requestProjectPdfConfig.message}
                  </>
                </ListGroup.Item>
                <ListGroup.Item>
                  <>
                    <Card.Text className="fw-bold mb-1">{t('baseData.projectPdfConfig.pdfWebsiteUrl')}</Card.Text>
                    {iframe.projectPdfConfig.pdfWebsiteUrl}
                  </>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
        {me?.role === Roles.MEINBAU_ADMIN && tenant && !tenant.archived && (
          <Card.Footer>
            <Stack direction="horizontal" gap={3}>
              <IframeButton
                variant="outline-primary"
                iframeId={iframe._id}
                tenantId={iframe.tenantId}
                type={IframeButtonType.update}
              >
                {t('updateIframeBtn')} <FontAwesomeIcon icon={faEdit} />
              </IframeButton>

              <Button
                className="ms-auto"
                variant={iframe.archived ? 'success' : 'danger'}
                onClick={onChangeArchivingStatus}
              >
                {iframe.archived ? (
                  <>
                    {t('releaseIframeBtn')} <FontAwesomeIcon icon={faUnlock} />
                  </>
                ) : (
                  <>
                    {t('suspendIframeBtn')} <FontAwesomeIcon icon={faLock} />
                  </>
                )}
              </Button>
            </Stack>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};

export default IframeBaseData;
