import React from 'react';
import usePagination from 'hooks/usePagination';
import { Alert } from 'react-bootstrap';
import useProjects from 'hooks/project/useProjects';
import { grabErrorMessage } from 'utils/helpers';
import ProjectsFilter from 'components/project/ProjectsFilter';
import ProjectsTable from 'components/project/ProjectsTable';
import ExcelReportsButton from 'components/project/ExcelReportsButton';
import { Modal, useModal } from 'components/common/Modal';
import IframePreview from './IframePreview';
import { useTranslation } from 'react-i18next';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';

interface IIframeProjectsProps {
  iframe: IframeQuery['iframe'];
}

const IframeProjects: FC<IIframeProjectsProps> = ({ iframe }) => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'iframeProjectsPage' });
  const { projects, loading, total, error } = useProjects({ currentPage, iframeId: iframe._id });
  const { showModal, isVisible, hideModal, modalProps } = useModal<{ projectId: string; token: string }>();

  return (
    <>
      <ExcelReportsButton iframeId={iframe._id}/>
      <ProjectsFilter {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <ProjectsTable {...{ projects, showModal, currentPage, changePage, total, loading }} /> : null}
      <Modal
        fullscreen={true}
        isVisible={isVisible}
        onHide={hideModal}
        headerCloseButton
        headerText={t('projects.projectEditModal')}
      >
        <IframePreview iframe={iframe} projectId={modalProps.projectId} token={modalProps.token} />
      </Modal>
    </>
  );
};

export default IframeProjects;
