import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { InvitationFragmentDoc } from '../../../fragments/invitation/generated/InvitationFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInvitationMutationVariables = SchemaTypes.Exact<{
  formData: SchemaTypes.InvitationInput;
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
}>;

export type CreateInvitationMutation = {
  __typename?: 'Mutation';
  createInvitation:
    | {
        __typename?: 'Invitation';
        _id: string;
        role: SchemaTypes.Roles;
        email: string;
        createdAt: any;
        isDraft?: boolean | null;
        profile: { __typename?: 'UserProfile'; lastName: string; firstName: string };
        inviter?: {
          __typename?: 'BasicUserAttributes';
          _id: string;
          email: string;
          profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
        } | null;
      }
    | { __typename?: 'User' };
};

export const CreateInvitationDocument = gql`
  mutation CreateInvitation($formData: InvitationInput!, $tenantId: ID) {
    createInvitation(formData: $formData, tenantId: $tenantId) {
      ...Invitation
    }
  }
  ${InvitationFragmentDoc}
`;
export type CreateInvitationMutationFn = Apollo.MutationFunction<
  CreateInvitationMutation,
  CreateInvitationMutationVariables
>;

/**
 * __useCreateInvitationMutation__
 *
 * To run a mutation, you first call `useCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationMutation, { data, loading, error }] = useCreateInvitationMutation({
 *   variables: {
 *      formData: // value for 'formData'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useCreateInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInvitationMutation, CreateInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvitationMutation, CreateInvitationMutationVariables>(
    CreateInvitationDocument,
    options,
  );
}
export type CreateInvitationMutationHookResult = ReturnType<typeof useCreateInvitationMutation>;
export type CreateInvitationMutationResult = Apollo.MutationResult<CreateInvitationMutation>;
export type CreateInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateInvitationMutation,
  CreateInvitationMutationVariables
>;
