import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from '../../utils/helpers';
import SubmitButton from 'components/common/Button/SubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import useGetSearchParams from 'hooks/useGetSearchParams';
import constants from 'constants/constants';
import { refetchAllProjectsQuery } from 'graphql/queries/project/generated/AllProjects';
import { useGetProjectsFilter } from 'hooks/project/useProjects';
import { useTranslation } from 'react-i18next';
import Select from 'components/inputs/Select/Select';
import { ProjectTypeFilter } from 'graphql/types.generated';

const { tableRowsCountPerPage } = constants;

interface IProjectsFilter {
  changePage: (page: number) => void;
}

type FormValues = {
  projectsSearchQuery: string;
  projectType: ProjectTypeFilter;
};

const ProjectsFilter: FC<IProjectsFilter> = ({ changePage }) => {
  const { t } = useTranslation();

  const { searchParams, setSearchParams } = useGetSearchParams();
  const filter = useGetProjectsFilter();

  const initialValues = useMemo(() => {
    return { projectsSearchQuery: filter.searchQuery, projectType: filter.projectType as ProjectTypeFilter };
  }, [filter.searchQuery, filter.projectType]);

  const onSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { projectsSearchQuery, projectType } = values;
        changePage(1);
        setSearchParams({
          ...searchParams,
          ...(typeof projectsSearchQuery === 'string' ? { projectsSearchQuery } : {}),
          ...(typeof projectType === 'string' ? { projectType } : { projectType: ProjectTypeFilter.all }),
        });

        refetchAllProjectsQuery({
          skip: 0,
          limit: tableRowsCountPerPage,
          searchQuery: projectsSearchQuery,
          ...(typeof projectType === 'string' ? { projectType } : { projectType: ProjectTypeFilter.all }),
        });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [changePage, searchParams, setSearchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <Field name="projectsSearchQuery" type="search" label="projects.searchQuery" component={TextInput} />
            <Field
              name="projectType"
              label="projects.projectType.label"
              isClearable
              component={Select}
              options={[
                { label: t(`projectTypes.${ProjectTypeFilter.newBuilding}`), value: ProjectTypeFilter.newBuilding },
                {
                  label: t(`projectTypes.${ProjectTypeFilter.renovatingHouse}`),
                  value: ProjectTypeFilter.renovatingHouse,
                },
                { label: t('projectTypes.all'), value: ProjectTypeFilter.all },
              ]}
            />
          </div>
          <div className="filter-buttons">
            <SubmitButton label="buttons.search" icon={faMagnifyingGlass} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectsFilter;
