import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TenantFragmentDoc } from '../../../fragments/tenant/generated/tenantFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTenantMutationVariables = SchemaTypes.Exact<{
  tenantId: SchemaTypes.Scalars['ID']['input'];
  formData: SchemaTypes.TenantInput;
}>;

export type UpdateTenantMutation = {
  __typename?: 'Mutation';
  updateTenant: {
    __typename?: 'Tenant';
    _id: string;
    companyName: string;
    archived?: boolean | null;
    createdBy: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    };
    updatedBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
    changedStatusBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  };
};

export const UpdateTenantDocument = gql`
  mutation UpdateTenant($tenantId: ID!, $formData: TenantInput!) {
    updateTenant(tenantId: $tenantId, formData: $formData) {
      ...Tenant
    }
  }
  ${TenantFragmentDoc}
`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      formData: // value for 'formData'
 *   },
 * });
 */
export function useUpdateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
}
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>;
