import CardWithHeader from 'components/common/CardWithHeader';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';
import React from 'react';
import { Card, Col, ListGroup, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PriceHubbleButton from './priceHubble/PriceHubbleButton';
import AddWebhookButton from './webhook/WebhookButton';
import GTMButton from './gtm/GTMButton';
import WebhookCard from './webhook/WebhookCard';
import { WebhookFormType } from './webhook/WebhookForm';
import { ProjectType } from 'graphql/types.generated';
import AddApiIntegrations from './apiIntegrations/AddApiIntegrations';
import ApiIntegrationCard from './apiIntegrations/ApiIntegrationCard';

interface IframeIntegrationsParams {
  iframe: IframeQuery['iframe'];
}

const IframeIntegrations: FC<IframeIntegrationsParams> = ({ iframe }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={12}>
        <div className="integration-actions">
          <div className="text-end mb-3">
            <AddApiIntegrations apiIntegrations={iframe.apiIntegrations} iframeId={iframe._id} />
          </div>
          <div className="text-end mb-3">
            <AddWebhookButton iframeId={iframe._id} formType={WebhookFormType.create} />
          </div>
        </div>
      </Col>
      {iframe.webhooks.map((webhook) => {
        return (
          <Col md={6} key={webhook._id}>
            <WebhookCard {...{ webhook, iframeId: iframe._id }} />
          </Col>
        );
      })}
      {iframe.projectType === ProjectType.renovatingHouse ? (
        <Col md={6}>
          <CardWithHeader
            className="mb-3"
            title={
              <Stack direction="horizontal" gap={3}>
                {t('iframe.integrations.priceHubbleConfiguration')}
                <PriceHubbleButton iframe={iframe} />
              </Stack>
            }
          >
            {iframe.priceHubbleConfig ? (
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('iframe.integrations.priceHubbleUsername')}</Card.Text>
                  {iframe.priceHubbleConfig?.username}
                </ListGroup.Item>
              </ListGroup>
            ) : (
              t('iframe.integrations.priceHubbleNotConnected')
            )}
          </CardWithHeader>
        </Col>
      ) : null}
      <Col md={6}>
        <CardWithHeader
          className="mb-3"
          title={
            <Stack direction="horizontal" gap={3}>
              {t('iframe.integrations.gtmConfiguration')}
              <GTMButton iframeId={iframe._id} isEnabled={!!iframe.gtmKey} />
            </Stack>
          }
        >
          {iframe.gtmKey ? (
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Card.Text className="fw-bold mb-1">{t('iframe.integrations.gtmKey')}</Card.Text>
                {iframe.gtmKey}
              </ListGroup.Item>
            </ListGroup>
          ) : (
            t('iframe.integrations.gtmNotConnected')
          )}
        </CardWithHeader>
      </Col>

      {iframe?.apiIntegrations?.map((integration) => (
        <ApiIntegrationCard
          key={`${integration.name}-${integration.clientId}`}
          {...integration}
          iframeId={iframe?._id}
        />
      ))}
    </Row>
  );
};

export default IframeIntegrations;
