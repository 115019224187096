import * as Apollo from '@apollo/client';
import {
  TenantUsersQuery,
  TenantUsersQueryVariables,
  useTenantUsersQuery,
} from 'graphql/queries/user/generated/TenantUsers';
import { useMemo } from 'react';

export default function useGetTenantUsers(
  baseOptions: Apollo.QueryHookOptions<TenantUsersQuery, TenantUsersQueryVariables>,
) {
  const { loading, error, data } = useTenantUsersQuery(baseOptions);

  const tenantUserOptions = useMemo(
    () =>
      data?.tenantUsers.map(({ _id, email, profile }) => ({
        value: _id,
        label: `${profile.firstName} ${profile.lastName} ${email}`,
      })),
    [data],
  );

  return { tenantUserOptions: tenantUserOptions ?? [], loading, error };
}
