import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik, Form, FastField, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import s from './RedeemInvitationForm.module.sass';
import * as yup from 'yup';
import PasswordInput from 'components/inputs/PasswordInput';
import config from 'config/config';
import TextInput from 'components/inputs/TextInput/TextInput';
import ScrollToError from 'components/common/ScrollToError';
import { passwordValidation } from 'utils/helpers';
import Checkbox from 'components/inputs/Checkbox';
import SubmitButton from 'components/common/Button/SubmitButton';

const validationSchema = yup.object().shape({
  email: yup.string().email('errors.invalidEmail').required('errors.requiredField'),
  password: yup
    .string()
    .required('errors.requiredField')
    .min(8, 'errors.passwordIsTooShort')
    .test('isValidPassword', 'errors.passwordIsInvalid', passwordValidation),
  passwordRepeat: yup.string().oneOf([yup.ref('password'), undefined], 'errors.passwordsMustMatch'),
  termsOfUseConsent: yup.bool().oneOf([true], 'errors.requiredField'),
});

export interface IRedeemInvitationFormValues {
  email: string;
  password: string;
  passwordRepeat: string;
  termsOfUseConsent: boolean;
}

interface IRedeemInvitationForm {
  initialValues: IRedeemInvitationFormValues;
  onSubmit: (values: IRedeemInvitationFormValues, helpers: FormikHelpers<IRedeemInvitationFormValues>) => void;
}

const RedeemInvitationForm: FC<IRedeemInvitationForm> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {() => (
        <Form>
          <ScrollToError />
          <FastField
            disabled
            name="email"
            component={TextInput}
            label="auth.email"
            type="email"
            startIcon={<FontAwesomeIcon icon={faEnvelope} />}
          />
          <FastField
            name="password"
            type="password"
            component={PasswordInput}
            label="auth.password"
            startIcon={<FontAwesomeIcon icon={faKey} />}
            helpText="auth.passwordRules"
          />
          <FastField
            name="passwordRepeat"
            type="password"
            component={PasswordInput}
            label="auth.repeatNewPassword"
            startIcon={<FontAwesomeIcon icon={faKey} />}
          />
          <FastField
            name="termsOfUseConsent"
            component={Checkbox}
            label={
              <Trans
                i18nKey="auth.termsOfUseLabel"
                components={{
                  termsOfUseUrl: (
                    <a href={config.termsOfUseUrl} rel="noopener noreferrer" target="_blank">
                      {t('auth.termsOfUseLinkText')}
                    </a>
                  ),
                }}
              />
            }
          />
          <p>
            <Trans
              i18nKey="auth.privacyPolicyLabel"
              components={{
                privacyPolicyUrl: (
                  <a href={config.privacyPolicyUrl} rel="noopener noreferrer" target="_blank">
                    {t('auth.privacyPolicyLinkText')}
                  </a>
                ),
              }}
            />
          </p>

          <div className={s.submitBtnWrapper}>
            <SubmitButton />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RedeemInvitationForm;
