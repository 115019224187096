import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateReportMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ReportFileInput;
}>;

export type GenerateReportMutation = {
  __typename?: 'Mutation';
  generateReport: {
    __typename?: 'ReportFile';
    _id: string;
    name: string;
    iframeName?: string | null;
    rangeStartDate?: any | null;
    rangeEndDate?: any | null;
    status: string;
    createdAt: any;
  };
};

export const GenerateReportDocument = gql`
  mutation GenerateReport($input: ReportFileInput!) {
    generateReport(input: $input) {
      _id
      name
      iframeName
      rangeStartDate
      rangeEndDate
      status
      createdAt
    }
  }
`;
export type GenerateReportMutationFn = Apollo.MutationFunction<GenerateReportMutation, GenerateReportMutationVariables>;

/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateReportMutation, GenerateReportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateReportMutation, GenerateReportMutationVariables>(GenerateReportDocument, options);
}
export type GenerateReportMutationHookResult = ReturnType<typeof useGenerateReportMutation>;
export type GenerateReportMutationResult = Apollo.MutationResult<GenerateReportMutation>;
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<
  GenerateReportMutation,
  GenerateReportMutationVariables
>;
