import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BasicUserAttributesFragmentDoc } from '../../../fragments/user/generated/BasicUserAttribuesFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantUsersQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  searchQuery: SchemaTypes.Scalars['String']['input'];
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
}>;

export type TenantUsersQuery = {
  __typename?: 'Query';
  tenantUsers: Array<{
    __typename?: 'BasicUserAttributes';
    _id: string;
    email: string;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  }>;
};

export const TenantUsersDocument = gql`
  query TenantUsers($limit: Int!, $searchQuery: String!, $tenantId: ID) {
    tenantUsers(limit: $limit, searchQuery: $searchQuery, tenantId: $tenantId) {
      ...BasicUserAttributes
    }
  }
  ${BasicUserAttributesFragmentDoc}
`;

/**
 * __useTenantUsersQuery__
 *
 * To run a query within a React component, call `useTenantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchQuery: // value for 'searchQuery'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useTenantUsersQuery(baseOptions: Apollo.QueryHookOptions<TenantUsersQuery, TenantUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantUsersQuery, TenantUsersQueryVariables>(TenantUsersDocument, options);
}
export function useTenantUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantUsersQuery, TenantUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantUsersQuery, TenantUsersQueryVariables>(TenantUsersDocument, options);
}
export type TenantUsersQueryHookResult = ReturnType<typeof useTenantUsersQuery>;
export type TenantUsersLazyQueryHookResult = ReturnType<typeof useTenantUsersLazyQuery>;
export type TenantUsersQueryResult = Apollo.QueryResult<TenantUsersQuery, TenantUsersQueryVariables>;
export function refetchTenantUsersQuery(variables: TenantUsersQueryVariables) {
  return { query: TenantUsersDocument, variables: variables };
}
