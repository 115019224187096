import React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { Iframe } from 'graphql/types.generated';

export type IframeListItem = Iframe;

export interface ICO2ReportItem {
  name: string;
  co2Savings: number;
  projectsCount: number;
}

export interface ICO2MappedReport {
  co2SavingsArray: ICO2ReportItem[];
  sumOfCO2Savings: number;
}

interface iGeneralCO2ReportsTableProps {
  co2MappedReport: ICO2MappedReport;
  loading: boolean;
}

const GeneralCO2ReportTable: FC<iGeneralCO2ReportsTableProps> = ({ co2MappedReport, loading }) => {
  const { t } = useTranslation();

  const data: ICO2ReportItem[] = useMemo(() => {
    return co2MappedReport.co2SavingsArray.map((co2Report: ICO2ReportItem) => {
      const { name, co2Savings, projectsCount } = co2Report;
      return {
        name,
        co2Savings,
        projectsCount,
      };
    });
  }, [co2MappedReport.co2SavingsArray]);

  const columns: ColumnDef<ICO2ReportItem>[] = useMemo(
    () => [
      {
        header: t('co2Reports.tableHeaders.renovationName'),
        accessorKey: 'name',
        cell: (info) => t(`co2Reports.renovations.${info.getValue() as string}`),
      },
      {
        header: t('co2Reports.tableHeaders.projectsCount'),
        accessorKey: 'projectsCount',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.tableHeaders.co2Saving'),
        accessorKey: 'co2Savings',
        cell: (info) => info.getValue() as string,
        footer: co2MappedReport.sumOfCO2Savings.toString(),
      },
    ],
    [co2MappedReport.sumOfCO2Savings, t],
  );

  return (
    <Table
      hideFooter={false}
      columns={columns}
      data={data}
      loading={loading}
      emptyText="co2Reports.noReportsFound.description"
    />
  );
};

export default GeneralCO2ReportTable;
