import constants from 'constants/constants';
import {
  AddUsersToTenantMutationVariables,
  useAddUsersToTenantMutation,
} from 'graphql/mutations/tenant/generated/AddUsersToTenant';
import { UserManagementDocument, UserManagementQuery } from 'graphql/queries/user/generated/UserManagement';
import { AccountType } from 'graphql/types.generated';
import { useCallback } from 'react';
import { toastErrorMessage } from 'utils/helpers';

interface IAddUsersToTenantParams {
  hideModal: () => void;
  tenantId: string;
}

export type AddUsersToTenantFormValues = Omit<AddUsersToTenantMutationVariables, 'tenantId'>;

const useAddUsersToTenant = ({ hideModal, tenantId }: IAddUsersToTenantParams) => {
  const [addUsersToTenant, { client }] = useAddUsersToTenantMutation({
    onError: toastErrorMessage,
    onCompleted: hideModal,
  });

  const handleAddUsers = useCallback(
    async (values: AddUsersToTenantFormValues) => {
      const { userIds, role } = values;
      const { data } = await addUsersToTenant({
        variables: { userIds, role, tenantId },
        refetchQueries: ['TenantUsers'],
      });
      if (!data || !data?.addUsersToTenant) return;
      const users = data.addUsersToTenant;
      const variables = {
        tenantId,
        limit: constants.tableRowsCountPerPage,
        skip: 0,
        invitationsFilter: { accountType: AccountType.tenant },
        usersFilter: { accountType: AccountType.tenant },
      };
      client.cache.updateQuery({ query: UserManagementDocument, variables }, (data: UserManagementQuery | null) => {
        if (!data) return data;
        const { allUsers } = data;
        const { items } = allUsers;
        return { ...data, allUsers: { ...allUsers, items: [...users, ...items] } };
      });
      hideModal();
    },
    [addUsersToTenant, client.cache, hideModal, tenantId],
  );

  return handleAddUsers;
};

export default useAddUsersToTenant;
