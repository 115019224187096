import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ListGroup } from 'react-bootstrap';

import { ApiLog } from 'graphql/types.generated';

interface IMeinBauApiLogDataParams {
  apiLog: ApiLog;
}

const MeinBauApiLogsData: FC<IMeinBauApiLogDataParams> = ({ apiLog }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'apiLog' });

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Card.Title className="mb-0">{t('titles.baseData')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.apiName')}</Card.Text>
              {apiLog.apiName}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.eventType')}</Card.Text>
              {apiLog.eventType ? apiLog.eventType : t('baseData.eventTypeNotSpecified')}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.method')}</Card.Text>
              {apiLog.method}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.statusCode')}</Card.Text>
              {apiLog.statusCode ? apiLog.statusCode : t('baseData.statusCodeNotSpecified')}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.url')}</Card.Text>
              {apiLog.url ? apiLog.url : t('baseData.urlNotSpecified')}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.data')}</Card.Text>
              {apiLog.data ? apiLog.data : t('baseData.dataNotSpecified')}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.params')}</Card.Text>
              {apiLog.params ? apiLog.params : t('baseData.paramsNotSpecified')}
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default MeinBauApiLogsData;
