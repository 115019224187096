import { first, get } from 'lodash';
import { useCallback, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const useLoadingData = ({
  length,
  fetchMore,
  variables,
  limit,
  queryItemsPath = '',
  rootMargin = '0px 0px 50px 0px',
}: {
  length: number;
  limit: number;
  queryItemsPath?: string;
  variables?: any;
  fetchMore: any;
  rootMargin?: string;
}) => {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const fetchMoreData = useCallback(async () => {
    try {
      const { data } = await fetchMore({ variables: { skip: length, limit, ...variables } });
      const newData = first(Object.values(data));
      const items = Array.isArray(newData) ? newData : get(newData, queryItemsPath, []);
      if (!items.length) setFullyLoaded(true);
    } catch (error) {
      setDisabled(true);
      setFullyLoaded(true);
    }
  }, [fetchMore, length, limit, queryItemsPath, variables]);

  const handleLoadMore = useCallback(async () => {
    if (disabled) {
      setIsFetching(false);
      return;
    }
    setIsFetching(true);
    await fetchMoreData();
    setIsFetching(false);
  }, [disabled, fetchMoreData]);

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: !fullyLoaded && !disabled,
    onLoadMore: handleLoadMore,
    rootMargin,
  });

  return { sentryRef, isFetching: !fullyLoaded || isFetching, setFullyLoaded, fetchMoreData, disabled, fullyLoaded };
};

export default useLoadingData;
