import { Roles } from '../../../graphql/types.generated';
import { debounce, get, isEmpty, uniqBy } from 'lodash';
import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GroupBase, MultiValue, OnChangeValue, OptionProps, OptionsOrGroups } from 'react-select';
import RAsyncSelect from 'react-select/async';
import { multiSelectStyles } from './Select.styles';
import { CMultiValue, IMultiSelectOption, IMultiSelectProps } from './MultiSelect';
import { MenuPortalTarget, useMenuPortalTarget } from './useMenuPortalTarget';

export interface IAsyncMultiSelectProps extends IMultiSelectProps {
  loadOptionsCallback: (inputValue: string) => Promise<IMultiSelectOption[]>;
  defaultOptions?: IMultiSelectProps['options'];
  customOption?: ComponentType<OptionProps<unknown, boolean, GroupBase<unknown>>>;
  customOptionProps?: { activePopoverId: string; setActivePopoverId: (id: string) => void; ctxUserRole?: Roles | null };
  onMenuClose?: () => void;
}

const AsyncMultiSelect: FC<IAsyncMultiSelectProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  disabled = false,
  defaultOptions = [],
  loadOptionsCallback,
  customOption: CustomOption,
  customOptionProps,
  onMenuClose,
  helpText,
  menuPortalTarget: _menuPortalTarget = MenuPortalTarget.body,
}) => {
  const { name } = field;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<IMultiSelectOption[]>([]);

  useEffect(() => {
    if (defaultOptions.length) {
      setOptions([...defaultOptions]);
    }
  }, [defaultOptions, options]);

  const loadOptions = useCallback(
    async (
      inputValue: string,
      callback: (options: IMultiSelectOption[]) => void,
    ): Promise<OptionsOrGroups<MultiValue<IMultiSelectOption>, any>> => {
      if (!inputValue) return [];
      const newOptions = await loadOptionsCallback(inputValue);

      const selected = options.filter(({ value }: IMultiSelectOption) => field.value.includes(value));

      const allOptions = uniqBy([...newOptions, ...selected], 'value');

      setOptions(allOptions);
      callback(allOptions);
      return allOptions;
    },
    [field.value, loadOptionsCallback, options],
  );

  const handleInputChange = useCallback((v: string) => {
    setInputValue(v);
  }, []);

  const handleChange = useCallback(
    (_options: OnChangeValue<MultiValue<IMultiSelectOption>, boolean>) => {
      const selectedOptions = _options as MultiValue<IMultiSelectOption>;
      const values = selectedOptions ? selectedOptions.map((option: IMultiSelectOption) => option.value) : [];
      setFieldValue(field.name, values);
    },
    [field.name, setFieldValue],
  );

  const optionValue = useMemo(
    () => field.value && options.filter(({ value }: IMultiSelectOption) => field.value.includes(value)),
    [field.value, options],
  );

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  const menuPortalTarget = useMenuPortalTarget(_menuPortalTarget);

  return (
    <Form.Group className="mb-3 w-100">
      <Form.Label htmlFor={name} className="text-primary lh-1-and-5">
        {t(label)}
      </Form.Label>
      <div onClick={(e) => e.stopPropagation()}>
        <RAsyncSelect
          cacheOptions
          defaultOptions={isEmpty(options) ? defaultOptions : options}
          isMulti
          value={optionValue}
          options={options}
          styles={multiSelectStyles}
          onChange={handleChange}
          inputId={field.name}
          name={field.name}
          placeholder=""
          components={{
            IndicatorSeparator: () => null,
            MultiValue: (props: any) => <CMultiValue {...props} label={t(label)} name={name} />,
            ...(CustomOption
              ? { Option: (props: any) => <CustomOption {...{ ...props, ...customOptionProps }} /> }
              : {}),
          }}
          onMenuClose={onMenuClose}
          menuPortalTarget={menuPortalTarget}
          isDisabled={disabled}
          closeMenuOnSelect={false}
          inputValue={inputValue}
          hideSelectedOptions={false}
          isClearable
          onInputChange={handleInputChange}
          isSearchable
          loadOptions={debounce(loadOptions, 1000)}
          noOptionsMessage={() => <div>{t('forms.noOptions')}</div>}
        />
      </div>
      {helpText ? <Form.Text className="text-muted">{t(helpText)}</Form.Text> : null}
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default AsyncMultiSelect;
