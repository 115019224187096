import PageTitle from 'components/common/PageTitle/PageTitle';
import AnalyticRangePicker from 'components/dashboard/AnalyticRangePicker';
import CountPerEntityChart from 'components/dashboard/CountPerEntityChart';
import useGeneralAnalytic from 'hooks/analytics/useGeneralAnalytic';
import React, { useCallback } from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { grabErrorMessage, toastErrorMessage } from 'utils/helpers';

const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { generalAnalytic, loading, error, refetchGeneralAnalytic } = useGeneralAnalytic();

  const onDateRangeChanged = useCallback(
    async (rangeStart?: Date, rangeEnd?: Date) => {
      try {
        await refetchGeneralAnalytic({ rangeStart, rangeEnd });
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [refetchGeneralAnalytic],
  );

  return (
    <>
      <PageTitle>{t('pageTitles.dashboard')}</PageTitle>
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!loading && !error && generalAnalytic && (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row className="justify-content-center">
                <Col xs={10} xl={2}>
                  <AnalyticRangePicker callbackQuery={onDateRangeChanged} />
                </Col>
                <Col xs={12} xl={5}>
                  <CountPerEntityChart
                    name={t('dashboardInfo.projectPerTenant')}
                    data={generalAnalytic.projectsPerTenants}
                    noDataText={{
                      title: t('dashboardInfo.noDataTextProjects.title'),
                      description: t('dashboardInfo.noDataTextProjects.description'),
                    }}
                  />
                </Col>
                <Col xs={12} xl={5}>
                  <CountPerEntityChart
                    name={t('dashboardInfo.pdfRequestPerTenant')}
                    data={generalAnalytic.pdfRequestsPerTenants}
                    noDataText={{
                      title: t('dashboardInfo.noDataTextPdfRequests.title'),
                      description: t('dashboardInfo.noDataTextPdfRequests.description'),
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col md={3} className="mb-3">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>{generalAnalytic.allTenantsCount}</Card.Title>
                  <Card.Text>{t('dashboardInfo.tenantsCount')}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-3">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>{generalAnalytic.allUsersCount}</Card.Title>
                  <Card.Text>{t('dashboardInfo.usersCount')}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-3">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>{generalAnalytic.allProjectsCount}</Card.Title>
                  <Card.Text>{t('dashboardInfo.projectsCount')}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-3">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>{generalAnalytic.allPdfRequestsCount}</Card.Title>
                  <Card.Text>{t('dashboardInfo.pdfRequestsCount')}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Dashboard;
