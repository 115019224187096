import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BasicUserAttributesFragmentDoc } from '../../user/generated/BasicUserAttribuesFragment';
export type TenantFragment = {
  __typename?: 'Tenant';
  _id: string;
  companyName: string;
  archived?: boolean | null;
  createdBy: {
    __typename?: 'BasicUserAttributes';
    _id: string;
    email: string;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  };
  updatedBy?: {
    __typename?: 'BasicUserAttributes';
    _id: string;
    email: string;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  } | null;
  changedStatusBy?: {
    __typename?: 'BasicUserAttributes';
    _id: string;
    email: string;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  } | null;
};

export type TenantFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TenantFragmentDoc = gql`
  fragment Tenant on Tenant {
    _id
    companyName
    createdBy {
      ...BasicUserAttributes
    }
    updatedBy {
      ...BasicUserAttributes
    }
    archived
    changedStatusBy {
      ...BasicUserAttributes
    }
  }
  ${BasicUserAttributesFragmentDoc}
`;
