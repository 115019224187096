import React, { CSSProperties } from 'react';
import RButton from 'react-bootstrap/Button';
import { ButtonProps } from 'react-bootstrap';
import cn from 'classnames';
import s from './IconButton.module.sass';
import theme from 'styles/variables.sass';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading/Loading';

export interface iIconButton extends ButtonProps {
  loading?: boolean;
  style?: CSSProperties;
  icon: IconDefinition;
  iconSize?: FontAwesomeIconProps['size'];
  iconColor?: ButtonProps['color'];
}

const IconButton: FC<iIconButton> = ({
  icon,
  className,
  onClick,
  disabled = false,
  loading = false,
  iconSize = 'lg',
  type = 'button',
  style,
  variant = 'primary',
  ...props
}) => {
  return (
    <RButton
      {...{
        ...props,
        variant,
        onClick,
        type,
        disabled: disabled || loading,
        style,
        className: cn(s.iconButton, className),
      }}
    >
      <FontAwesomeIcon
        size={iconSize}
        icon={icon}
        style={{ ...(loading ? { visibility: 'hidden' } : {}), maxWidth: '100%' }}
      />
      {loading ? (
        <div className={s.btnLoading}>
          <Loading size={30} color={theme.primaryColor} />
        </div>
      ) : null}
    </RButton>
  );
};

export default IconButton;
