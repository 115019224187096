import ApiLogsFilter from 'components/apiCallLogs/ApiLogsFilter';
import ApiLogsTable from 'components/apiCallLogs/ApiLogsTable';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import PageTitle from 'components/common/PageTitle';
import useApiCallLogs from 'hooks/apiCallLogs/useApiCallLogs';
import usePagination from 'hooks/usePagination';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { grabErrorMessage } from 'utils/helpers';

const ApiCallLogsPage = () => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'apiCallLogsPage' });
  const { apiLogs, loading, total, error } = useApiCallLogs({ currentPage });

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.apiCallLogs') }]} />
      <PageTitle>{t('pageTitles.apiCallLogs')}</PageTitle>
      <ApiLogsFilter {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <ApiLogsTable {...{ apiLogs, currentPage, changePage, total, loading }} /> : null}
    </>
  );
};

export default ApiCallLogsPage;
