import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { FieldInputProps, FormikProps } from 'formik';
import { get, isNull, trimStart } from 'lodash';
import InputGroup from 'react-bootstrap/InputGroup';
import cn from 'classnames';
import s from './TextInput.module.sass';
import { useTranslation } from 'react-i18next';

interface ITextInput {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  type?: string;
  // helpText?: string | React.ReactElement;
  // label: string | React.ReactElement;
  helpText?: string;
  label: string;
  startIcon?: React.ReactElement;
  append?: React.ReactElement;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  formGroupClassName?: string;
  sideEffect?: (value: string) => void;
}

const TextInput: FC<ITextInput> = ({
  field,
  form,
  type = 'text',
  label,
  helpText,
  startIcon,
  append,
  disabled = false,
  placeholder,
  autoComplete,
  formGroupClassName = '',
  sideEffect,
}) => {
  const { name, onBlur, value } = field;
  const { errors, touched } = form;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(name, trimStart(e.target.value));
      if (typeof sideEffect === 'function') sideEffect(e.target.value);
    },
    [form, name, sideEffect],
  );

  useEffect(() => {
    if (isNull(value)) form.setFieldValue(name, '');
  }, [form, name, value]);

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group className={formGroupClassName || 'mb-3 w-100'}>
      {label ? (
        <Form.Label className="text-primary" htmlFor={name}>
          {t(label)}
        </Form.Label>
      ) : null}
      <div className="d-flex">
        {startIcon ? (
          <InputGroup.Text id={name} className={s.startBoxRightAngles}>
            {startIcon}
          </InputGroup.Text>
        ) : null}

        <Form.Control
          {...{ type, name, onBlur, disabled, placeholder, autoComplete }}
          value={value ?? ''}
          readOnly={disabled}
          maxLength={100}
          onChange={handleChange}
          className={cn(error ? 'is-invalid' : '', startIcon ? s.inputLeftAngles : '', disabled ? s.disabledInput : '')}
        />

        {append}
      </div>
      {helpText ? <Form.Text className="text-muted">{t(helpText)}</Form.Text> : null}
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default TextInput;
