import React from 'react';
import { Webhook } from 'graphql/types.generated';
import { Card, ListGroup, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardWithHeader from 'components/common/CardWithHeader';
import UpdateWebhookButton from './WebhookButton';
import { WebhookFormType } from './WebhookForm';
import DeleteWebhookButton from './DeleteWebhookButton';

interface WebhookCardParams {
  webhook: Webhook;
  iframeId: string;
}

const WebhookCard: FC<WebhookCardParams> = ({ webhook, iframeId }) => {
  const { t } = useTranslation();

  return (
    <CardWithHeader
      className="mb-3"
      title={
        <Stack direction="horizontal" className="justify-content-between">
          <p className="m-0">{t(`webhook.form.triggerOptions.${webhook.trigger}`)}</p>
          <Stack gap={2} direction="horizontal">
            <UpdateWebhookButton {...{ webhook, iframeId, formType: WebhookFormType.update }} />
            <DeleteWebhookButton webhookId={webhook._id} iframeId={iframeId} />
          </Stack>
        </Stack>
      }
    >
      <Card.Body className="p-0">
        <h5>{t('webhook.form.events')}</h5>
        <ListGroup as="ul" numbered>
          {webhook.events.map((event) => (
            <ListGroup.Item key={event} as="li">
              {t(`webhook.form.eventOptions.${event}`)}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </CardWithHeader>
  );
};

export default WebhookCard;
