import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiLogsQueryVariables = SchemaTypes.Exact<{
  apiName?: SchemaTypes.InputMaybe<SchemaTypes.ApiNameFilter>;
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
}>;

export type ApiLogsQuery = {
  __typename?: 'Query';
  apiLogs: {
    __typename?: 'PaginatedApiLogResponse';
    total: number;
    items: Array<{
      __typename?: 'ApiLog';
      _id: string;
      apiName: SchemaTypes.ApiName;
      data?: string | null;
      details?: string | null;
      eventType: SchemaTypes.EventType;
      method: string;
      params?: string | null;
      statusCode?: string | null;
      url?: string | null;
    }>;
  };
};

export const ApiLogsDocument = gql`
  query ApiLogs($apiName: ApiNameFilter, $limit: Int!, $skip: Int!) {
    apiLogs(apiName: $apiName, limit: $limit, skip: $skip) {
      items {
        _id
        apiName
        data
        details
        eventType
        method
        params
        statusCode
        url
      }
      total
    }
  }
`;

/**
 * __useApiLogsQuery__
 *
 * To run a query within a React component, call `useApiLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiLogsQuery({
 *   variables: {
 *      apiName: // value for 'apiName'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useApiLogsQuery(baseOptions: Apollo.QueryHookOptions<ApiLogsQuery, ApiLogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiLogsQuery, ApiLogsQueryVariables>(ApiLogsDocument, options);
}
export function useApiLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiLogsQuery, ApiLogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiLogsQuery, ApiLogsQueryVariables>(ApiLogsDocument, options);
}
export type ApiLogsQueryHookResult = ReturnType<typeof useApiLogsQuery>;
export type ApiLogsLazyQueryHookResult = ReturnType<typeof useApiLogsLazyQuery>;
export type ApiLogsQueryResult = Apollo.QueryResult<ApiLogsQuery, ApiLogsQueryVariables>;
export function refetchApiLogsQuery(variables: ApiLogsQueryVariables) {
  return { query: ApiLogsDocument, variables: variables };
}
