import { FormikHelpers } from 'formik';
import { BaseMutationOptions } from '@apollo/client';
import { iErrorFormValues } from './ErrorMessage';
import { grabErrorMessage } from 'utils/helpers';

interface SetFormErrorParams {
  error: unknown;
  setFieldError: FormikHelpers<iErrorFormValues>['setFieldError'];
}

export const setFormError = ({ error, setFieldError }: SetFormErrorParams) => {
  const message = grabErrorMessage(error);
  setFieldError('form', message);
};

export const setApolloFormikError: BaseMutationOptions['onError'] = (error, clientOptions) => {
  if (typeof clientOptions?.context?.setFieldError === 'function') {
    const { setFieldError } = clientOptions.context;
    setFormError({ error, setFieldError });
  }

  // throw error;
};
