import Loading from 'components/common/Loading/Loading';
import PageTitle from 'components/common/PageTitle';
import IframeForm from 'components/iframe/form/IframeForm';
import useIframe from 'hooks/iframe/useIframe';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { grabErrorMessage } from 'utils/helpers';

const CreateIframePage = () => {
  const { iframeId } = useParams();
  const { t } = useTranslation();
  const { iframe, loading, error } = useIframe({ iframeId: iframeId as string });
  return (
    <>
      {loading ? <Loading /> : null}
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!loading && !error && iframe ? (
        <>
          <PageTitle>{t('iframe.form.headerUpdate')}</PageTitle>
          <IframeForm iframe={iframe} tenantId={iframe.tenantId} />
        </>
      ) : null}
    </>
  );
};

export default CreateIframePage;
