import React, { useCallback, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { subTimezoneOffset, formatDate } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import SimpleDatePicker from 'components/inputs/DatePicker/SimpleDatePicker';
import { endOfDay } from 'date-fns';

export enum TimePeriod {
  allTime = 'allTime',
  lastMonth = 'lastMonth',
  thisMonth = 'thisMonth',
  custom = 'custom',
}

interface IAnalyticRangePickerProp {
  callbackQuery: (rangeStart?: Date, rangeEnd?: Date) => Promise<void>;
  tenantId?: string;
}

export const calculateDateRange = (timePeriod: TimePeriod) => {
  const currentDate = new Date();
  switch (timePeriod) {
    case TimePeriod.thisMonth:
      return {
        startDate: subTimezoneOffset(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
        endDate: subTimezoneOffset(endOfDay(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))),
      };
    case TimePeriod.lastMonth:
      return {
        startDate: subTimezoneOffset(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)),
        endDate: subTimezoneOffset(endOfDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), 0))),
      };
    default:
      return null;
  }
};

const AnalyticRangePicker: FC<IAnalyticRangePickerProp> = ({ callbackQuery }) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [datePickerStartDate, setDatePickerStartDate] = useState<Date | undefined>();
  const [datePickerEndDate, setDatePickerEndDate] = useState<Date | undefined>();
  const [timePeriod, setTimePeriod] = useState(TimePeriod.allTime);

  const isValidDate = (d?: Date | Date[]) => {
    return d instanceof Date && !isNaN(d.getSeconds());
  };

  const setDate = (setStateCallback: any, date?: Date | Date[]) => {
    if (isValidDate(date as Date)) {
      setStateCallback(new Date(date as Date));
    } else {
      setStateCallback(undefined);
    }
  };

  const onTimePeriodChange = useCallback(
    async (timePeriod: TimePeriod) => {
      let startDate: Date | undefined;
      let endDate: Date | undefined;

      if (timePeriod === TimePeriod.custom) {
        startDate = datePickerStartDate;
        endDate = datePickerEndDate;
      } else {
        const calculatedDates = calculateDateRange(timePeriod);
        startDate = calculatedDates?.startDate;
        endDate = calculatedDates?.endDate;
      }

      setStartDate(startDate);
      setEndDate(endDate);
      setTimePeriod(timePeriod);

      if (typeof callbackQuery === 'function') await callbackQuery(startDate, endDate);
    },
    [callbackQuery, datePickerEndDate, datePickerStartDate],
  );

  return (
    <Row className="justify-content-center h-90">
      <Row className="pb-3">
        <span className="justify-content-center text-primary">
          {startDate
            ? formatDate({ date: startDate, dateFormat: 'dd MMMM yyyy' })
            : t('dashboardInfo.rangePicker.startDate')}
          -
          {endDate ? formatDate({ date: endDate, dateFormat: 'dd MMMM yyyy' }) : t('dashboardInfo.rangePicker.endDate')}
        </span>
      </Row>
      <Row className="pb-3 px-4">
        <Button onClick={() => onTimePeriodChange(TimePeriod.lastMonth)} active={timePeriod === TimePeriod.lastMonth}>
          {t('dashboardInfo.rangePicker.lastMonthDateRange')}
        </Button>
      </Row>
      <Row className="pb-3 px-4">
        <Button onClick={() => onTimePeriodChange(TimePeriod.thisMonth)} active={timePeriod === TimePeriod.thisMonth}>
          {t('dashboardInfo.rangePicker.thisMonthDateRange')}
        </Button>
      </Row>
      <Row className="pb-3 px-4">
        <Button onClick={() => onTimePeriodChange(TimePeriod.allTime)} active={timePeriod === TimePeriod.allTime}>
          {t('dashboardInfo.rangePicker.allTimeDateRange')}
        </Button>
      </Row>

      <Row className="pb-3">
        <span className="text-primary pb-2">{t('dashboardInfo.rangePicker.startTimeDateRange')}</span>
        <SimpleDatePicker selected={datePickerStartDate} onChange={(date) => setDate(setDatePickerStartDate, date)} />
      </Row>
      <Row className="pb-3">
        <span className="text-primary  pb-2">{t('dashboardInfo.rangePicker.endTimeDateRange')}</span>
        <SimpleDatePicker selected={datePickerEndDate} onChange={(date) => setDate(setDatePickerEndDate, date)} />
      </Row>
      <Row className="pb-3 px-4">
        <Button onClick={() => onTimePeriodChange(TimePeriod.custom)} active={timePeriod === TimePeriod.custom}>
          {t('dashboardInfo.rangePicker.applyRange')}
        </Button>
      </Row>
    </Row>
  );
};

export default AnalyticRangePicker;
