import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from '../../../utils/helpers';
import { useParams } from 'react-router-dom';
import SubmitButton from 'components/common/Button/SubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import useGetSearchParams from 'hooks/useGetSearchParams';
import constants from 'constants/constants';
import { refetchAllUsersQuery } from 'graphql/queries/user/generated/AllUsers';
import { useGetUsersFilter } from 'hooks/user/useGetUsers';
import { AccountType } from 'graphql/types.generated';

const { tableRowsCountPerPage } = constants;

interface IUsersFilterForm {
  changePage: (page: number) => void;
  accountType: AccountType;
}

type FormValues = {
  usersSearchQuery: string;
};

const UsersFilterForm: FC<IUsersFilterForm> = ({ changePage, accountType }) => {
  const { tenantId } = useParams();
  const { searchParams, setSearchParams } = useGetSearchParams();
  const filter = useGetUsersFilter();

  const initialValues = useMemo(() => {
    return { usersSearchQuery: filter.searchQuery || '' };
  }, [filter.searchQuery]);

  const onSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { usersSearchQuery } = values;
        changePage(1);
        setSearchParams({
          ...searchParams,
          ...(typeof usersSearchQuery === 'string' ? { usersSearchQuery } : {}),
        });

        refetchAllUsersQuery({
          filter: { searchQuery: usersSearchQuery, accountType },
          skip: 0,
          tenantId,
          limit: tableRowsCountPerPage,
        });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [accountType, changePage, searchParams, setSearchParams, tenantId],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <Field name="usersSearchQuery" type="search" label="userManagement.searchQuery" component={TextInput} />
          </div>
          <div className="filter-buttons">
            <SubmitButton label="buttons.search" icon={faMagnifyingGlass} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UsersFilterForm;
