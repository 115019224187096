import { ProjectFeature, SolePlateOrBasement } from 'graphql/types.generated';

const planningCategories = {
  [ProjectFeature.planningAndConstructionManagement]: 'Planung & Bauleitung',
  [ProjectFeature.propertyCosts]: 'Grundstückskosten',
  [ProjectFeature.chargesAndTaxes]: 'Gebühren & Steuern',
  [ProjectFeature.developmentCosts]: 'Erschließungskosten',
  [ProjectFeature.demolitionCosts]: 'Abrisskosten',
  [ProjectFeature.reservesForUnexpectedCosts]: 'Reserve ungeplante Zusatzkosten',
  [`${ProjectFeature.reservesForUnexpectedCosts}Description`]:
    'Insbesondere bei Sanierungen empfehlen wir eine Finanzreserve von mindestens 10% für unvorhergesehene Zusatzaufwände.',
  [ProjectFeature.shellConstruction]: 'Rohbau',
  [ProjectFeature.windowsAndDoors]: 'Fenster & Türen',
  [ProjectFeature.sanitary]: 'Sanitär',
  [ProjectFeature.electricalInstallation]: 'Elektro',
  // [ProjectFeature.heatingSystem]: 'Heizung',
  [ProjectFeature.heatingSystem]: 'Heizung erneuern (vorzugsweise Wärmepumpe oder Fernwärme)',
  [`${ProjectFeature.heatingSystem}Description`]:
    'Sollte ein Anschluss an ein Fernwärmenetz nicht möglich sein, ist in fast allen Fällen eine Wärmepumpe im Hinblick auf Kosteneinsparungen und CO2-Reduktion die beste Lösung. In vielen Fällen ist dies auch im Altbestand ohne weitere Dämmmaßnahmen möglich - mit einer Energieberatung VorOrt lässt sich das rasch klären!',
  [`${ProjectFeature.heatingSystem}EquipmentTypeDescription`]:
    'Bei der <b>Standardvariante</b> wird nur die Heizung inklusive aller notwendigen Pumpen getauscht. Die <b>gehobene Variante</b> beinhaltet zusätzlich zur Heizung auch neue Heizkörper und eine moderne Steuerung. Bei der <b>Premiumvariante</b> sind die Kosten für den (teilweisen) Umstieg auf eine Fußbodenheizung ebenfalls berücksichtigt, zusätzlich ist die Anlage via App (Smartphone, etc.) steuerbar.',
  [ProjectFeature.interiorConstruction]: 'Innenausbau',
  [ProjectFeature.interiorEquipment]: 'Inneneinrichtung',
  [`${ProjectFeature.interiorEquipment}Description`]:
    'Inkludiert Küche, Möblierung aller Räume, Beleuchtung, Elektrogeräte und Alarmanlage inklusive Montage',
  [`${ProjectFeature.interiorEquipment}TypeDescription`]:
    'Die Kategorie <b>Standard</b> berücksichtigt Einrichtung in durchschnittlicher Qualität von Einrichtungshäusern. In den höheren Kategorien werden regional produzierte, hochwertigere und oft auch langlebigere und damit nachhaltigere Lösungen kalkuliert',
  [ProjectFeature.terrace]: 'Terrasse',
  [ProjectFeature.gardenDesign]: 'Gartengestaltung',
  [ProjectFeature.winterGarden]: 'Wintergarten',
  [`${ProjectFeature.winterGarden}Description`]:
    'Inkludiert Wintergarten, Bodenbelag, je nach Kategorie beheizt und mit Zusatzausstattung ',
  [`${ProjectFeature.winterGarden}EquipmentTypeDescription`]:
    'Unterschiede ergeben sich in der Art der Verglasung, Beschattung und Heizung/Lüftung. Ein <b>"Standard-Wintergarten"</b> ist mehr eine geschlossene Terrrasse, ein Wintergarten der Kategorie "Exklusiv" ein vollwertiger, ganzjährig nutzbarer Wohnraum.',
  [ProjectFeature.pool]: 'Pool (massiv, eingegraben)',
  [`${ProjectFeature.pool}Description`]:
    'Massiver Pool inklusive Erdarbeiten, je nach Kategorie zusätzlich mit Überdachung/Zusatzausstattung',
  [ProjectFeature.fence]: 'Gartenzaun/ -mauer',
  [`${ProjectFeature.fence}EquipmentTypeDescription`]:
    'In der Version <b>Standard</b> wird eine Zaun mit Fundament und einfachem Sichtschutz kalkuliert. Hochwertigere Asuführung sowie Mauern sind in den höheren Kategorien berücksichtigt.',
  [`${ProjectFeature.fence}Description`]: 'Inkludiert Sockel, Zaun, Türe und Tor',
  [ProjectFeature.carport]: 'Carport',
  [`${ProjectFeature.carport}Description`]: 'Inkludiert massive Überdachung',
  [ProjectFeature.garage]: 'Garage',
  [`${ProjectFeature.garage}Description`]: 'Inkludiert massive Garage mit elektrischem Torantrieb.',
  [ProjectFeature.gardenLandscaping]: 'Gartengestaltung',
  [`${ProjectFeature.gardenLandscaping}Description`]: 'Inkludiert Rasen, Pflanzen und Wege',
  [`${ProjectFeature.gardenLandscaping}EquipmentTypeDescription`]:
    'Die Kategorie <b>"Standard"</b> kalkuliert großteils Rasenflächen und günstige Bepflanzung auf Basis einer einfachen Planung wie sie in Fachmärkten angeboten wird.',
  [ProjectFeature.hillsideLocation]: 'Hanglage',
  [ProjectFeature.limitedAccess]: 'Eingeschränkte Zufahrt',
  [ProjectFeature.specialUnderground]: 'Schwieriger Untergrund',
  [ProjectFeature.solePlateOrBasement]: 'Bodenplatte oder Keller',
  [SolePlateOrBasement.solePlate]: 'Bodenplatte',
  [SolePlateOrBasement.basement]: 'Keller',
  [ProjectFeature.domesticVentilation]: 'Aktive Wohnraumlüftung',
  [`${ProjectFeature.domesticVentilation}Description`]:
    'Eine aktive Wohnraumlüftung sorgt durch Luftein- und -auslässe für einen automatischen und energieeffizienten Luftaustausch.',
  [`${ProjectFeature.domesticVentilation}EquipmentTypeDescription`]:
    'Unterschiede ergeben sich in der Steuerungsmöglichkeit, den Filtern und der Qualität der Komponenten.',
  [ProjectFeature.busSystem]: 'Bussystem, Smart-Home',
  [`${ProjectFeature.busSystem}EquipmentTypeDescription`]:
    'Grundlage ist ein durchgängies Bussystem mit entsprechender Verkabelung und Steuerung für Beleuchtung und Strom. Unterschiede bestehen im Bussystem selbst (wichtig hier auf einen <b>Standard</b> wie KNX zu setzten), und in der Art der Steuerung.',
  [`${ProjectFeature.busSystem}Description`]:
    'Ein Bussystem statt der herkömmlichen Elektroinstallation erlaubt die zentrale Programmierung und Steuerung aller Schalter und Steckdosen.',
  [ProjectFeature.solarHeating]: 'Solarthermieanlage',
  [`${ProjectFeature.solarHeating}Description`]:
    'Die Solarthermie dient der Warmwasseraufbereitung und speichert dieses in einem Boiler.',
  [`${ProjectFeature.solarHeating}EquipmentTypeDescription`]:
    'Die <b>Standardvariante</b> dient für Warmwasser, in den teureren Kategorien dient die Anlage auch für Heizunterstützung und verfügt über hochwertigere Komponenten.',
  [ProjectFeature.tileStove]: 'Kachelofen',
  [`${ProjectFeature.tileStove}EquipmentTypeDescription`]:
    'Grundlage ist ein vom Hafner gesetzter Kachelofen. Unterschiede bestehen in Größe und Ausführung, die bis hin zur Wasserführung und Integration in die Heizanlage geht.',
  [`${ProjectFeature.tileStove}Description`]: 'Gemauerter Kachelofen',
  [ProjectFeature.solarPowerSystem]: 'Photovoltaik',
  [`${ProjectFeature.solarPowerSystem}Description`]:
    'Eine PV-Anlage macht ein Stück weit unabhängig. Oft gibt es dafür auch öffentliche Förderungen - informiere Dich! ',
  // [ProjectFeature.wallboxEMobility]: 'Ladestation E-Auto - Wallbox',
  [ProjectFeature.wallboxEMobility]: 'Ladestation für E-Auto - Wallbox',
  [`${ProjectFeature.wallboxEMobility}Description`]:
    'Um den Neubau zukunftssicher zu gestalten, sollte eine Wallbox immer in der Planung Berücksichtigung finden.',
  [`${ProjectFeature.wallboxEMobility}EquipmentTypeDescription`]:
    'In der Kategorie <>Standard</> wird eine hochwertige Wallbox mit Installation durch einen Fachelektriker geplant. Die Grundlage ist hier die Verwendung der bestehenden Verkabelung, was bedeutet dass oft nur eine Ladeleistung von 3,7kW möglich ist. In den höheren Kategorien wird eine zusätzliche Verkabelung, und eine Wallbox mit Integrationsmöglichkeit in eine PV-Anlage kalkuliert, damit ist eine Ladeleistung von bis zu 11kW möglich.',
  [ProjectFeature.otherInvestments]: 'Sonstige Investitionen',
  [`${ProjectFeature.otherInvestments}Description`]:
    'Sonstige Ausgaben im Zusammenhang mit Ihrem Sanierungsvorhaben können Sie an dieser Stelle individuell erfassen.',
  additionalCategories: 'Zusätzliche Optionen',
  [ProjectFeature.masonry]: 'Maurer-, Beton-, bzw. Stahlbetonarbeiten',
  [ProjectFeature.chimney]: 'Kamin',
  [ProjectFeature.carpentry]: 'Zimmererarbeiten',
  [ProjectFeature.roofing]: 'Dachdecker- und Spenglerarbeiten',
  [ProjectFeature.facade]: 'Fassade inklusive Wärmeschutz',
  [ProjectFeature.metal]: 'Schlosserarbeiten',
  [ProjectFeature.windowsPatioBalcony]: 'Fenster, Terrassen- und Eingangstüre',
  [ProjectFeature.interiorDoorsDoorStays]: 'Innentüren komplett',
  [ProjectFeature.plumbing]: 'Sanitärinstallation',
  [ProjectFeature.sanitaryEquipment]: 'Sanitärgeräte',
  [ProjectFeature.interiorPlastering]: 'Innenverputz',
  [ProjectFeature.floorConstruction]: 'Estrich, Fußbodenaufbau',
  [ProjectFeature.tiling]: 'Fliesenlegerarbeiten',
  [ProjectFeature.flooring]: 'Bodenbeläge',
  [ProjectFeature.paintingWork]: 'Maler- und Tapeziererarbeiten',
  [ProjectFeature.drywallConstruction]: 'Trockenbau',
  [ProjectFeature.heatingInstallation]: 'Heizungsverrohrung',
  [ProjectFeature.radiator]: 'Heizkörper bzw. Fußbodenheizung',
  [ProjectFeature.boiler]: 'Heizkessel bzw. Wärmepumpe',
  [ProjectFeature.costEstimation]: 'Entwurfsplanung & Kostenschätzung',
  [ProjectFeature.permitPlanning]: 'Einreichplan erstellen',
  [ProjectFeature.executionPlanning]: 'Ausführungsplanung',
  [ProjectFeature.billOfQuantity]: 'Leistungsverzeichnis & Angebote',
  [ProjectFeature.constructionManagement]: 'Bauleitung',
  [ProjectFeature.electroCableDucts]: 'Leerverrohrung Elektrik',
  [ProjectFeature.electroWiring]: 'Verdrahtung',
  [ProjectFeature.electroFinishing]: 'Fertigstellung (Schalter, Steckdosen etc.)',
  [ProjectFeature.priceOfProperty]: 'Grundstückspreis',
  [ProjectFeature.taxesAndNotaryExpenses]: 'Gebühren & Steuern',
  [ProjectFeature.earthworks]: 'Erdarbeiten',
  [ProjectFeature.channel]: 'Kanalisierungsarbeiten',

  [ProjectFeature.batteryStorage]: 'Batteriespeicher',
  [`${ProjectFeature.batteryStorage}Description`]:
    'Batteriespeicher haben in Verbindung mit einer PV-Anlage den Zweck, den selbsterzeugten Strom zwischenspeichern zu können. Mit einer intelligenten Speicherlösungen kann man seinen eigenen Sonnenstrom auch dann verbrauchen, wenn die Sonne nicht scheint, sonst muss der erzeugte Strom entweder sofort verbraucht oder ins Netz eingespeist werden. ',
  batteryStorageSize: 'Größe des Batteriespeichers (kWh)',
  batteryStorageSizeDescription:
    'Als Faustregel gilt, dass die Kapazität des Batteriespeichers in kWh in etwa der Peakleistung (kWp) der PV-Anlage entspricht.',
  batteryStorageBlackoutOption: 'Willst Du auch dass Deine Anlage notstromfähig ist?',
  batteryStorageBlackoutOptionDescription:
    'Die Versorgung mit Strom aus dem Stromspeicher ohne das öffentliche Netz ist aus Sicherheitsgründen nicht möglich, da bei Netzausfall der Wechselrichter sofort ausgeschaltet wird. Nur durch einen zusätzlichen Notstromschalter kann der Stromspeicher weiterhin Strom an die Verbraucher im Haus liefern.',

  [ProjectFeature.ceilingTopInsulation]: 'Dämmung der obersten Geschossdecke',
  [`${ProjectFeature.ceilingTopInsulation}Description`]:
    'Eine kostengünstigere Variante anstelle der Dachstuhlsanierung berücksichtigt lediglich das Anbringen eines Dämmstoffs auf die oberste Geschoßdecke.',
  [`${ProjectFeature.ceilingTopInsulation}EquipmentTypeDescription`]:
    '<b>Standardausführung</b> sind lose verlegte Dämmplatten mit beschränkter Begehbarkeit. In den höheren Kategorien kommen trittfeste und nachhaltig produzierte Dämmplatten zum Einsatz.',
  [ProjectFeature.ceilingBasementInsulation]: 'Dämmung der Kellerdecke',
  [`${ProjectFeature.ceilingBasementInsulation}Description`]:
    'Bei einem unbeheizten Keller sollte die Decke gut gedämmt werden um Wärmeverluste zu minimieren.',
  [`${ProjectFeature.ceilingBasementInsulation}EquipmentTypeDescription`]:
    'Unterschiede bestehen in der Qualität und (nachhaltigen) Ausführung der Dämmung.',
  [ProjectFeature.frontDoor]: 'Eingangstüre erneuern',
  [`${ProjectFeature.frontDoor}Description`]: 'Die Grundlage ist eine moderne Sicherheitstüre inklusive Einbau.',
  [`${ProjectFeature.frontDoor}EquipmentTypeDescription`]:
    'Unterschiede ergeben sich in der Größe und Ausführung der Türe (Material und Technik wie Fingerprint-Sensor).',
  [ProjectFeature.newWindows]: 'Fenstertausch',
  [`${ProjectFeature.newWindows}Description`]:
    'Preistreiber sind hier vor allem die Größe der Fenster, die Ausführung (Kunstoff, Holz oder Holz/Alu) sowie Zusatzausstattungen wie z.B. Sonnenschutz, Absturzsicherung, etc.',
  [`${ProjectFeature.newWindows}EquipmentTypeDescription`]:
    'Die <b>Standardvariante</b> beinhaltet die Entsorgung der alten Fenster und den Einbau günstiger Kunststofffenster. Bei der <b>gehobenen Variante</b> handelt es sich um höherwertige Kunststoff-Aluminiumfenster und teilweisen Sonnenschutz. Die <b>Premiumvariante</b> enthält hochwertige Holz-Alufenster mit durchgängiger Beschattung, welche zusätzlich per App (Smartphone, etc.) gesteuert werden kann.',
  [ProjectFeature.roof]: 'Dach erneuern und dämmen',
  [`${ProjectFeature.roof}Description`]:
    'Die Grundlage ist die Entfernung der alten Dacheindeckung und eine neue Dacheindeckung inklusive Dämmung auf Basis des bestehenden Dachstuhls.',
  [`${ProjectFeature.roof}EquipmentTypeDescription`]:
    'Die <b>Standardvariante</b> beinhaltet die Erneuerung der Dacheindeckung inklusive einer Dämmung auf Basis des bestehenden Dachstuhls. Bei der <b>gehobenen Variante</b> ist eine neue Dachunterschicht sowie die Erneuerung aller Spenglerarbeiten zusätzlich berücksichtigt. Die <b>Premiumvariante</b> beinhaltet einen kompletten Tausch des bestehenden Dachstuhls inklusive Dämmung und wohnlicher Innenausführung.',
  [ProjectFeature.facadeInsulation]: 'Fassade dämmen',
  [`${ProjectFeature.facadeInsulation}Description`]:
    'Die Grundlage ist die Dämmung der Außenwände, die Fassade erhält einen neuen Außenputz. Bitte beachte, dass ein allfälliger Tausch der Fenster ebenfalls eingeplant werden sollte.',
  [`${ProjectFeature.facadeInsulation}EquipmentTypeDescription`]:
    'Bei der <b>Standardvariante</b> werden kostengünstige Dämm- und Putzmaterialien sowie einfache Fensterbänke verwendet. Die <b>gehobene Variante</b> inkludiert höherwertige Materialien (Dämmung, Außenputz, Fensterbänke). Bei der <b>Premiumvariante</b> werden ökologische Dämmstoffe (z.B. Hanf) mit hochwertigem Außenputz und Fensterbänken kombiniert. Die Dicke der Dämmung wird wie folgt kalkuliert: Standard mit 10cm/ Gehoben 16cm/ Premium 20cm - Dein Baumeister/Architekt/Energieberater informiert Dich gerne über die notwendige Dämmstärke.',
};

export default planningCategories;
