import React from 'react';
import { Alert } from 'react-bootstrap';
import { grabErrorMessage } from 'utils/helpers';
import CO2ReportsByProjectTable from './CO2ReportsByProjectTable';
import usePagination from 'hooks/usePagination';
import useCO2ReportByProject from 'hooks/co2Reports/useCO2ReportByProject';
import CO2ReportsByProjectFilter from './CO2ReportsByProjectFilter';

interface ICO2ReportByProject {
  iframeId: string;
}

const CO2ReportByProject: React.FunctionComponent<ICO2ReportByProject> = ({ iframeId }) => {
  const { currentPage, changePage } = usePagination({ searchParamKey: 'iframesPage' });

  const { co2SavingsReportByProject, loading, error, total } = useCO2ReportByProject({ iframeId, currentPage });

  return (
    <>
      <CO2ReportsByProjectFilter iframeId={iframeId} changePage={changePage} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? (
        <CO2ReportsByProjectTable
          {...{
            co2Reports: co2SavingsReportByProject,
            loading,
            currentPage,
            changePage,
            total: total,
          }}
        />
      ) : null}
    </>
  );
};

export default CO2ReportByProject;
