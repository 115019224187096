import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BasicUserAttributesFragmentDoc } from '../../../fragments/user/generated/BasicUserAttribuesFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuditLogsQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  searchString: SchemaTypes.Scalars['String']['input'];
  tenant?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
  startDate?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Float']['input']>;
  endDate?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Float']['input']>;
}>;

export type AuditLogsQuery = {
  __typename?: 'Query';
  auditLogs: {
    __typename?: 'PaginatedAuditLogEventsClassResponse';
    total: number;
    items: Array<{
      __typename?: 'AuditLogEventsClass';
      _id: string;
      name: SchemaTypes.AuditLogEventName;
      createdAt: any;
      createdBy?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
    }>;
  };
};

export const AuditLogsDocument = gql`
  query AuditLogs($limit: Int!, $skip: Int!, $searchString: String!, $tenant: ID, $startDate: Float, $endDate: Float) {
    auditLogs(
      limit: $limit
      skip: $skip
      searchString: $searchString
      tenant: $tenant
      startDate: $startDate
      endDate: $endDate
    ) {
      total
      items {
        _id
        name
        createdAt
        createdBy {
          ...BasicUserAttributes
        }
      }
    }
  }
  ${BasicUserAttributesFragmentDoc}
`;

/**
 * __useAuditLogsQuery__
 *
 * To run a query within a React component, call `useAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      searchString: // value for 'searchString'
 *      tenant: // value for 'tenant'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAuditLogsQuery(baseOptions: Apollo.QueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditLogsQuery, AuditLogsQueryVariables>(AuditLogsDocument, options);
}
export function useAuditLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditLogsQuery, AuditLogsQueryVariables>(AuditLogsDocument, options);
}
export type AuditLogsQueryHookResult = ReturnType<typeof useAuditLogsQuery>;
export type AuditLogsLazyQueryHookResult = ReturnType<typeof useAuditLogsLazyQuery>;
export type AuditLogsQueryResult = Apollo.QueryResult<AuditLogsQuery, AuditLogsQueryVariables>;
export function refetchAuditLogsQuery(variables: AuditLogsQueryVariables) {
  return { query: AuditLogsDocument, variables: variables };
}
