import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/helpers';
import { AllInvitationsQuery } from '../../../graphql/queries/invitation/generated/AllInvitations';
import UserActionBadge from 'components/common/UserActionBadge/UserActionBadge';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { BasicUserAttributes, Roles } from 'graphql/types.generated';
import { Badge } from 'react-bootstrap';
import { isNil } from 'lodash';
import ActionButtons from './ActionButtons';

export type LocalInvitation = First<AllInvitationsQuery['allInvitations']['items']>;
interface iInvitationsTableProps extends IPaginationProps {
  invitations: LocalInvitation[];
  loading: boolean;
}

type ActionButtonProps = {
  invitationId: string;
  email: string;
  isDraft?: boolean | null;
};

export type InvitationTableRow = {
  email: string;
  firstName: string;
  lastName: string;
  role: Roles;
  inviter?: BasicUserAttributes | null;
  invitationDate: Date;
  isDraft?: boolean | null;
  actionButtons: ActionButtonProps;
};

const InvitationsTable: FC<iInvitationsTableProps> = ({ invitations, total, currentPage, changePage, loading }) => {
  const { t, i18n } = useTranslation();

  const data: InvitationTableRow[] = useMemo(() => {
    return invitations.map((invitation: LocalInvitation) => {
      const { _id, email, profile, inviter, role, createdAt, isDraft } = invitation;
      const { firstName, lastName } = profile;
      return {
        email,
        firstName,
        lastName,
        role,
        inviter,
        invitationDate: createdAt,
        isDraft,
        actionButtons: { invitationId: _id, email, isDraft },
      };
    });
  }, [invitations]);

  const columns: ColumnDef<InvitationTableRow>[] = useMemo(
    () => [
      {
        header: t('auth.email'),
        accessorKey: 'email',
        cell: (info) => info.getValue(),
      },
      {
        header: t('auth.firstName'),
        accessorKey: 'firstName',
        cell: (info) => info.getValue(),
      },
      {
        header: t('auth.lastName'),
        accessorKey: 'lastName',
        cell: (info) => info.getValue(),
      },
      {
        header: t('auth.role'),
        accessorKey: 'role',
        cell: (info) => t(`roles.${info.getValue()}`),
      },
      {
        header: t('auth.inviter'),
        accessorKey: 'inviter',
        cell: (info) => {
          const inviter = info.getValue();
          if (!inviter) return null;
          return <UserActionBadge user={inviter as BasicUserAttributes} />;
        },
      },
      {
        header: t('userManagement.invitations.invitationDate'),
        accessorKey: 'invitationDate',
        cell: (info) => formatDate({ date: new Date(info.getValue() as Date), locale: i18n.language }),
      },
      {
        header: t('userManagement.invitations.draftOrSent'),
        accessorKey: 'isDraft',
        cell: (info) => {
          const isDraft = info.getValue();
          if (isNil(isDraft)) return null;
          return (
            <Badge bg={isDraft ? 'warning' : 'success'}>
              {t(`userManagement.invitations.${isDraft ? 'draft' : 'sent'}`)}
            </Badge>
          );
        },
      },
      {
        header: '',
        accessorKey: 'actionButtons',
        cell: (info) => {
          const { email, invitationId, isDraft } = info.getValue() as ActionButtonProps;
          if (isNil(isDraft)) return null;
          return <ActionButtons {...{ invitationId, email, isDraft }} />;
        },
      },
    ],
    [i18n.language, t],
  );

  return (
    <>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        emptyText="userManagement.invitations.noInvitationsFound.description"
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default InvitationsTable;
