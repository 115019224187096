import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { Stack } from 'react-bootstrap';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FastField } from 'formik';
import { TenantModalType } from './TenantButton';
import { CreateTenantMutation, useCreateTenantMutation } from 'graphql/mutations/tenant/generated/CreateTenant';
import { useUpdateTenantMutation } from 'graphql/mutations/tenant/generated/UpdateTenant';
import { toastErrorMessage } from 'utils/helpers';
import { CreateTenantMutationVariables } from 'graphql/mutations/tenant/generated/CreateTenant';
import TextInput from 'components/inputs/TextInput/TextInput';
import { TenantQuery } from 'graphql/queries/tenant/generated/Tenant';
import { routePaths } from 'router/routes';
import Button from 'components/common/Button/Button';
import { faEdit, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubmitButton from 'components/common/Button/SubmitButton';

type TenantInput = CreateTenantMutationVariables['formData'];

type TenantFormParams = {
  tenantModalType: TenantModalType;
  tenant?: TenantQuery['tenant'] | null;
  hideModal: () => void;
};

const validationSchema = yup.object().shape({
  companyName: yup.string().required('errors.requiredField'),
});

const TenantForm: FC<TenantFormParams> = ({ hideModal, tenant, tenantModalType }) => {
  const navigate = useNavigate();
  const [createTenantMutation] = useCreateTenantMutation({
    onError: toastErrorMessage,
    onCompleted: async ({ createTenant }) => {
      const { _id } = createTenant as CreateTenantMutation['createTenant'];
      hideModal();
      navigate(
        generatePath(routePaths.tenantManagement, {
          tenantId: _id,
        }),
      );
    },
  });
  const [updateTenantMutation] = useUpdateTenantMutation({
    onError: toastErrorMessage,
    onCompleted: () => hideModal(),
  });
  const { t } = useTranslation();
  const initialValues: TenantInput = useMemo((): TenantInput => ({ companyName: tenant?.companyName || '' }), [tenant]);
  const onSubmit = useCallback(
    async (values: TenantInput) => {
      if (tenant) {
        await updateTenantMutation({
          variables: { tenantId: tenant._id, formData: { companyName: values.companyName } },
        });
      } else {
        await createTenantMutation({ variables: { formData: { companyName: values.companyName } } });
      }
    },
    [createTenantMutation, tenant, updateTenantMutation],
  );

  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
        validationSchema,
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FastField name="companyName" component={TextInput} label="tenantManagement.modal.companyNameLabel" />
          <Stack direction="horizontal" className="justify-content-end" gap={3}>
            <Button variant="secondary" disabled={isSubmitting} onClick={hideModal}>
              {t('tenantManagement.modal.close')} <FontAwesomeIcon icon={faXmark} />
            </Button>
            <SubmitButton
              label={
                tenantModalType === TenantModalType.CREATE_TENANT
                  ? 'tenantManagement.modal.create'
                  : 'tenantManagement.modal.update'
              }
              icon={tenantModalType === TenantModalType.CREATE_TENANT ? faPlus : faEdit}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default TenantForm;
