import { Field } from 'formik';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import constants from 'constants/constants';
import AsyncMultiSelect from './AsyncMultiSelect';
import { useTenantUsersLazyQuery } from 'graphql/queries/user/generated/TenantUsers';
import useGetTenantUsers from 'hooks/user/useGetTenantUsers';
import { toastErrorMessage } from 'utils/helpers';
import { IMultiSelectProps } from './MultiSelect';

const { selectOptionsCount } = constants;

interface ITenantUsersSelectParams extends Pick<IMultiSelectProps, 'menuPortalTarget'> {
  name: string;
  label: string;
}

const TenantUsersSelect: FC<ITenantUsersSelectParams> = ({ name, label, ...rest }) => {
  const [loadTenantUserOptions] = useTenantUsersLazyQuery();
  const { tenantId } = useParams() as { tenantId: string };

  const { tenantUserOptions } = useGetTenantUsers({
    variables: {
      limit: selectOptionsCount,
      searchQuery: '',
      tenantId,
    },
    skip: !tenantId,
  });

  const handleLoadOptions = useCallback(
    async (inputValue: string) => {
      try {
        const { data } = await loadTenantUserOptions({
          variables: {
            searchQuery: inputValue,
            tenantId,
            limit: selectOptionsCount,
          },
        });

        if (!data?.tenantUsers) {
          return [];
        }

        const tenantUserOptions = data?.tenantUsers.map(({ _id, email, profile }) => ({
          value: _id,
          label: `${profile.firstName} ${profile.lastName} ${email}`,
        }));

        return tenantUserOptions;
      } catch (error: any) {
        toastErrorMessage(error);
        return [];
      }
    },
    [loadTenantUserOptions, tenantId],
  );

  return (
    <Field
      {...rest}
      name={name}
      label={label}
      component={AsyncMultiSelect}
      defaultOptions={tenantUserOptions}
      loadOptionsCallback={handleLoadOptions}
    />
  );
};

export default TenantUsersSelect;
