import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { routePaths } from 'router/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faFileLines } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import useIframe from 'hooks/iframe/useIframe';
import IframeBaseData from 'components/iframe/baseData/IframeBaseData';
import { Alert, Placeholder } from 'react-bootstrap';
import { grabErrorMessage } from 'utils/helpers';
import Loading from 'components/common/Loading/Loading';
import { Iframe, ProjectType, Roles, Tenant } from 'graphql/types.generated';
import useTenant from 'hooks/tenant/useTenant';
import Tabs from 'components/common/Tabs/Tabs';
import IframeProjectPdfRequests from 'components/iframe/IframeProjectPdfRequests';
import IframeProjects from 'components/iframe/IframeProjects';
import IframeAssetsTab from 'components/iframe/assets/IframeAssetsTab';
import useUser from 'hooks/user/useUser';
import IframeIntegrations from 'components/iframe/IframeIntegrations';
import GeneralCO2Report from 'components/co2Reports/GeneralCO2Report';
import CO2ReportByProject from 'components/co2Reports/CO2ReportsByProject';
import IframeTranslations from 'components/iframe/translations/IframeTranslations';
import IframePreview from 'components/iframe/IframePreview';
import IframeFuelMatrix from 'components/iframe/fuelMatrix/IframeFuelMatrix';
import IframePlanningItemsTab from 'components/iframe/planningItemsTable/PlanningItemsTab';

interface IBreadCrumbBuilderProps {
  iframe: Iframe;
  iframeLoading: boolean;
  tenant: Tenant;
  tenantLoading: boolean;
}

const BreadCrumbBuilder: FC<IBreadCrumbBuilderProps> = ({ iframe, iframeLoading, tenant, tenantLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { me } = useUser({ fetchPolicy: 'cache-only' });

  return (
    <BreadcrumbBuilder
      pathLinks={[
        {
          text: t('pageTitles.tenantsManagement'),
          onClick: () => navigate(routePaths.tenantsManagement),
          isHide: me?.role !== Roles.MEINBAU_ADMIN,
        },
        {
          style: tenant ? { minWidth: '0' } : { minWidth: '250px' },
          onClick: () =>
            navigate(
              generatePath(routePaths.tenantManagement, {
                tenantId: tenant._id,
              }),
            ),
          text: (
            <>
              {tenantLoading && !tenant ? (
                <Placeholder animation="glow">
                  <Placeholder xs={11} />
                </Placeholder>
              ) : (
                <>
                  <FontAwesomeIcon icon={faBuilding} /> {tenant.companyName}
                </>
              )}
            </>
          ),
        },
        {
          style: { minWidth: '250px' },
          text: (
            <>
              {iframeLoading && !iframe ? (
                <Placeholder animation="glow">
                  <Placeholder xs={11} />
                </Placeholder>
              ) : (
                <>
                  {iframe ? (
                    <>
                      <FontAwesomeIcon icon={faFileLines} /> {(iframe as Iframe).name}
                    </>
                  ) : null}
                </>
              )}
            </>
          ),
        },
      ]}
    />
  );
};

enum TabKeys {
  baseData = 'baseData',
  integrations = 'integrations',
  assets = 'assets',
  projectPdfRequests = 'projectPdfRequests',
  projects = 'projects',
  generalCo2Report = 'generalCo2Report',
  co2ReportByProject = 'co2ReportByProject',
  translations = 'translations',
  preview = 'preview',
  fuelMatrixConfiguration = 'fuelMatrixConfiguration',
  categoryConfiguration = 'categoryConfiguration',
}

const IframePage = () => {
  const { t } = useTranslation();
  const { iframeId } = useParams();
  const { tenantId } = useParams();
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const { iframe, error, loading } = useIframe({ iframeId: iframeId as string });
  const tenantResult = useTenant({ tenantId: tenantId as string });

  return (
    <>
      <BreadCrumbBuilder
        iframe={iframe as Iframe}
        iframeLoading={loading}
        tenant={tenantResult.tenant as Tenant}
        tenantLoading={tenantResult.loading}
      />
      {loading ? <Loading /> : null}
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!loading && !error && iframe ? (
        <>
          <PageTitle>{(iframe as Iframe).name}</PageTitle>
          <Tabs
            initialTabEventKey={TabKeys.baseData}
            restoreLastActiveTabKey="__last_active_iframe_management_tab__"
            tabs={[
              {
                eventKey: TabKeys.baseData,
                title: t('iframe.tabs.baseData'),
                content: <IframeBaseData iframe={iframe as Iframe} />,
              },
              {
                eventKey: TabKeys.preview,
                title: t('iframe.tabs.preview'),
                content: <IframePreview iframe={iframe} />,
              },
              {
                eventKey: TabKeys.integrations,
                title: t('iframe.tabs.integrations'),
                content: <IframeIntegrations iframe={iframe} />,
                hide: me?.role !== Roles.MEINBAU_ADMIN,
              },
              {
                eventKey: TabKeys.assets,
                title: t('iframe.tabs.assets'),
                content: <IframeAssetsTab {...{ iframe }} />,
                hide: me?.role !== Roles.MEINBAU_ADMIN,
              },
              {
                eventKey: TabKeys.projectPdfRequests,
                title: t('iframe.tabs.projectPdfRequests'),
                content: <IframeProjectPdfRequests iframeId={iframe._id} />,
              },
              {
                eventKey: TabKeys.projects,
                title: t('iframe.tabs.projects'),
                content: <IframeProjects iframe={iframe} />,
              },
              {
                eventKey: TabKeys.translations,
                title: t('iframe.tabs.translations'),
                content: <IframeTranslations iframe={iframe} />,
                hide: iframe.projectType !== ProjectType.renovatingHouse,
              },
              {
                eventKey: TabKeys.generalCo2Report,
                title: t('iframe.tabs.generalCo2Report'),
                content: <GeneralCO2Report iframeId={iframe._id} />,
                hide: iframe.projectType !== ProjectType.renovatingHouse,
              },
              {
                eventKey: TabKeys.co2ReportByProject,
                title: t('iframe.tabs.co2ReportByProject'),
                content: <CO2ReportByProject iframeId={iframe._id} />,
                hide: iframe.projectType !== ProjectType.renovatingHouse,
              },
              {
                eventKey: TabKeys.fuelMatrixConfiguration,
                title: t('iframe.tabs.fuelMatrixConfiguration'),
                content: <IframeFuelMatrix iframeId={iframe._id} />,
                hide: iframe.projectType !== ProjectType.renovatingHouse,
              },
              {
                eventKey: TabKeys.categoryConfiguration,
                title: t('iframe.tabs.categoryConfiguration'),
                content: <IframePlanningItemsTab iframeId={iframe._id} />,
                hide: iframe.projectType !== ProjectType.newBuilding,
              },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export default IframePage;
