import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type UserFragment = {
  __typename?: 'User';
  _id: string;
  email: string;
  role?: SchemaTypes.Roles | null;
  isTwoFAEnabled?: boolean | null;
  createdAt: any;
  profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
};

export type UserFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const UserFragmentDoc = gql`
  fragment User on User {
    _id
    email
    role
    isTwoFAEnabled
    profile {
      firstName
      lastName
    }
    createdAt
  }
`;
