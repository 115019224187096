import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantNameQueryVariables = SchemaTypes.Exact<{
  tenantId: SchemaTypes.Scalars['ID']['input'];
}>;

export type TenantNameQuery = {
  __typename?: 'Query';
  tenant: { __typename?: 'Tenant'; _id: string; companyName: string };
};

export const TenantNameDocument = gql`
  query TenantName($tenantId: ID!) {
    tenant(tenantId: $tenantId) {
      _id
      companyName
    }
  }
`;

/**
 * __useTenantNameQuery__
 *
 * To run a query within a React component, call `useTenantNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantNameQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useTenantNameQuery(baseOptions: Apollo.QueryHookOptions<TenantNameQuery, TenantNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantNameQuery, TenantNameQueryVariables>(TenantNameDocument, options);
}
export function useTenantNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantNameQuery, TenantNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantNameQuery, TenantNameQueryVariables>(TenantNameDocument, options);
}
export type TenantNameQueryHookResult = ReturnType<typeof useTenantNameQuery>;
export type TenantNameLazyQueryHookResult = ReturnType<typeof useTenantNameLazyQuery>;
export type TenantNameQueryResult = Apollo.QueryResult<TenantNameQuery, TenantNameQueryVariables>;
export function refetchTenantNameQuery(variables: TenantNameQueryVariables) {
  return { query: TenantNameDocument, variables: variables };
}
