import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import SubmitButton from 'components/common/Button/SubmitButton';
import Select from 'components/inputs/Select/Select';
import useGetSearchParams from 'hooks/useGetSearchParams';
import constants from 'constants/constants';
import { refetchApiLogsQuery } from 'graphql/queries/apiCallLog/generated/ApiLogs';
import { ApiNameFilter } from 'graphql/types.generated';
import { useGetApiCallLogsFilter } from 'hooks/apiCallLogs/useApiCallLogs';
import { toastErrorMessage } from 'utils/helpers';

const { tableRowsCountPerPage } = constants;

interface IApiLogsFilterForm {
  changePage: (page: number) => void;
}

interface FormValues {
  apiName: ApiNameFilter;
}

const ApiLogsFilter: FC<IApiLogsFilterForm> = ({ changePage }) => {
  const { t } = useTranslation();
  const { setSearchParams } = useGetSearchParams();
  const filter = useGetApiCallLogsFilter();

  const initialValues = useMemo(() => {
    return {
      apiName: (filter.apiName as ApiNameFilter) || ApiNameFilter.all,
    };
  }, [filter.apiName]);

  const onSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { apiName } = values;
        changePage(1);
        setSearchParams({
          ...(typeof apiName === 'string' ? { apiName } : { apiName: ApiNameFilter.all }),
        });
        refetchApiLogsQuery({
          ...(typeof apiName === 'string' ? { apiName } : { apiName: ApiNameFilter.all }),
          limit: tableRowsCountPerPage,
          skip: 0,
        });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [changePage, setSearchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <Field
              name="apiName"
              label="apiLogs.filter.apiName.label"
              isClearable
              component={Select}
              options={[
                { label: t('apiLogs.filter.apiName.priceHubble'), value: ApiNameFilter.priceHubble },
                { label: t('apiLogs.filter.apiName.effi'), value: ApiNameFilter.effi },
                { label: t('apiLogs.filter.apiName.all'), value: ApiNameFilter.all },
              ]}
            />
          </div>
          <div className="filter-buttons">
            <SubmitButton label="buttons.search" icon={faMagnifyingGlass} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApiLogsFilter;
