import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDeleteWebhookMutation } from 'graphql/mutations/iframe/generated/DeleteWebhook';
import { toastErrorMessage } from 'utils/helpers';
import Button from 'components/common/Button';
import { confirmAlert } from 'components/common/Alert';

interface IDeleteWebhookButtonParams {
  iframeId: string;
  webhookId: string;
}

const DeleteWebhookButton: FC<IDeleteWebhookButtonParams> = ({ iframeId, webhookId }) => {
  const { t } = useTranslation('translation');
  const [deleteWebhook, { loading }] = useDeleteWebhookMutation({ onError: toastErrorMessage });

  const onDeleteClick = useCallback(async () => {
    confirmAlert({
      onConfirm: async () => {
        await deleteWebhook({ variables: { webhookId, iframeId } });
      },
      title: t('webhook.deleteWebhookAlert.title'),
      message: t('webhook.deleteWebhookAlert.message'),
    });
  }, [deleteWebhook, iframeId, t, webhookId]);

  return (
    <Button variant="danger" onClick={onDeleteClick} disabled={loading} loading={loading}>
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  );
};

export default DeleteWebhookButton;
