import React from 'react';
import cn from 'classnames';
import s from './PageTitle.module.sass';

interface iPageTitleProps {
  children: React.ReactNode | string;
  className?: string;
}

const PageTitle: FC<iPageTitleProps> = ({ children, className }) => {
  return <h1 className={cn(s.title, className)}>{children}</h1>;
};

export default PageTitle;
