import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { IframePlanningItemFragmentDoc } from '../../../fragments/iframe/generated/IframePlanningItem';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetPlanningItemsMutationVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
}>;

export type ResetPlanningItemsMutation = {
  __typename?: 'Mutation';
  resetPlanningItems: {
    __typename?: 'Iframe';
    _id: string;
    planningItems?: Array<{
      __typename?: 'IframePlanningItem';
      parentId: string;
      name: SchemaTypes.ProjectFeature;
      parent: SchemaTypes.ProjectFeature;
      pricePerUnit?: number | null;
      equipmentTypes?: { __typename?: 'EquipmentTypes'; standard: number; premium: number; superior: number } | null;
      pricesPerUnit?: {
        __typename?: 'SolePlateOrBasementPricesPerUnit';
        solePlate?: number | null;
        basement?: number | null;
      } | null;
    }> | null;
  };
};

export const ResetPlanningItemsDocument = gql`
  mutation ResetPlanningItems($iframeId: ID!) {
    resetPlanningItems(iframeId: $iframeId) {
      _id
      planningItems {
        ...IframePlanningItem
      }
    }
  }
  ${IframePlanningItemFragmentDoc}
`;
export type ResetPlanningItemsMutationFn = Apollo.MutationFunction<
  ResetPlanningItemsMutation,
  ResetPlanningItemsMutationVariables
>;

/**
 * __useResetPlanningItemsMutation__
 *
 * To run a mutation, you first call `useResetPlanningItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPlanningItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPlanningItemsMutation, { data, loading, error }] = useResetPlanningItemsMutation({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useResetPlanningItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPlanningItemsMutation, ResetPlanningItemsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPlanningItemsMutation, ResetPlanningItemsMutationVariables>(
    ResetPlanningItemsDocument,
    options,
  );
}
export type ResetPlanningItemsMutationHookResult = ReturnType<typeof useResetPlanningItemsMutation>;
export type ResetPlanningItemsMutationResult = Apollo.MutationResult<ResetPlanningItemsMutation>;
export type ResetPlanningItemsMutationOptions = Apollo.BaseMutationOptions<
  ResetPlanningItemsMutation,
  ResetPlanningItemsMutationVariables
>;
