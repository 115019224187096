import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IframeFilesQueryVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
}>;

export type IframeFilesQuery = {
  __typename?: 'Query';
  iframeFiles: Array<{ __typename?: 'BriefFileInfo'; _id: string; name: string }>;
};

export const IframeFilesDocument = gql`
  query IframeFiles($iframeId: ID!) {
    iframeFiles(iframeId: $iframeId) {
      _id
      name
    }
  }
`;

/**
 * __useIframeFilesQuery__
 *
 * To run a query within a React component, call `useIframeFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIframeFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIframeFilesQuery({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useIframeFilesQuery(baseOptions: Apollo.QueryHookOptions<IframeFilesQuery, IframeFilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IframeFilesQuery, IframeFilesQueryVariables>(IframeFilesDocument, options);
}
export function useIframeFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IframeFilesQuery, IframeFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IframeFilesQuery, IframeFilesQueryVariables>(IframeFilesDocument, options);
}
export type IframeFilesQueryHookResult = ReturnType<typeof useIframeFilesQuery>;
export type IframeFilesLazyQueryHookResult = ReturnType<typeof useIframeFilesLazyQuery>;
export type IframeFilesQueryResult = Apollo.QueryResult<IframeFilesQuery, IframeFilesQueryVariables>;
export function refetchIframeFilesQuery(variables: IframeFilesQueryVariables) {
  return { query: IframeFilesDocument, variables: variables };
}
