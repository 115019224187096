import React, { useCallback, useMemo, useState } from 'react';
import ReactSelect, { OnChangeValue, StylesConfig } from 'react-select';
import get from 'lodash/get';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { MenuPortalTarget, useMenuPortalTarget } from './useMenuPortalTarget';
import { selectStyles } from './Select.styles';

export interface ISelectOption {
  label: string;
  value: string | number | null;
  isDisabled?: boolean;
}
export type SideEffectOnSelectFunc = (params: {
  selectedValue: ISelectOption['value'] | null;
  name: string;
  prevValue?: string;
  form: FormikProps<string>;
}) => void;

export interface ISelectProps {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  label: string;
  options: ISelectOption[];
  isSearchable?: boolean;
  isClearable?: boolean;
  sideEffectOnSelect?: SideEffectOnSelectFunc;
  noOptionsMessage?: string;
  disabled: boolean;
  menuPortalTarget?: MenuPortalTarget;
}

const Select: FC<ISelectProps> = ({
  field,
  form,
  label,
  options = [],
  isSearchable = false,
  isClearable = false,
  sideEffectOnSelect,
  disabled = false,
  menuPortalTarget: _menuPortalTarget = MenuPortalTarget.body,
  ...rest
}) => {
  const { touched, errors, setFieldValue } = form;
  const { name, value } = field;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const localizedOptions = useMemo(
    () => options.map((option) => ({ ...option, label: t(option.label) })),
    [options, t],
  );

  const handleInputChange = useCallback((v: string) => {
    setInputValue(v);
  }, []);

  const optionValue = useMemo(() => {
    const option = localizedOptions?.find((o) => o.value === value);
    return option || null;
  }, [localizedOptions, value]);

  const handleChange = useCallback(
    (option: OnChangeValue<ISelectOption, boolean>) => {
      const selectedOption = option as ISelectOption;
      const selectedValue = selectedOption?.value;
      setFieldValue(field.name, selectedValue);
      if (typeof sideEffectOnSelect === 'function') sideEffectOnSelect({ selectedValue, name, prevValue: value, form });
    },
    [setFieldValue, field.name, sideEffectOnSelect, name, value, form],
  );

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  const menuPortalTarget = useMenuPortalTarget(_menuPortalTarget);

  return (
    <Form.Group className="mb-3 w-100">
      {label ? (
        <Form.Label htmlFor={name} className="text-primary">
          {t(label)}
        </Form.Label>
      ) : null}
      <div onClick={(e) => e.stopPropagation()}>
        <ReactSelect
          {...rest}
          inputValue={inputValue}
          isClearable={isClearable}
          onInputChange={handleInputChange}
          inputId={field.name}
          value={optionValue}
          onChange={handleChange}
          name={field.name}
          options={localizedOptions}
          styles={selectStyles as StylesConfig<ISelectOption>}
          placeholder=""
          isDisabled={disabled}
          components={{ IndicatorSeparator: () => null }}
          isSearchable={isSearchable}
          blurInputOnSelect
          menuPortalTarget={menuPortalTarget}
          noOptionsMessage={() => <div>{t('forms.noOptions')}</div>}
        />
      </div>
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default Select;
