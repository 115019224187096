import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import s from './UserManagement.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePagination from 'hooks/usePagination';
import constants from 'constants/constants';
import Button from 'components/common/Button/Button';
import { Modal, useModal } from 'components/common/Modal';
import { useUserManagementQuery } from 'graphql/queries/user/generated/UserManagement';
import { InviteUserForm } from './invitations/InviteUserForm';
import InvitationsSection from './invitations/InvitationsSection';
import { Alert } from 'react-bootstrap';
import { grabErrorMessage } from 'utils/helpers';
import Loading from 'components/common/Loading/Loading';
import UsersSection from './users/UsersSection';
import { AccountType } from 'graphql/types.generated';
import AddUserToTenantForm from './users/AddUserToTenantForm/AddUserToTenantForm';

const { tableRowsCountPerPage } = constants;

interface IUserManagement {
  accountType: AccountType;
}

enum UserManagementModalType {
  INVITE_USER = 'INVITE_USER',
  ADD_EXISTING_USER = 'ADD_EXISTING_USER',
}

const UserManagement: FC<IUserManagement> = ({ accountType }) => {
  const { currentPage } = usePagination();
  const { tenantId } = useParams();
  const { t } = useTranslation();
  const { showModal, isVisible, hideModal } = useModal();
  const [modalType, setModalType] = useState<UserManagementModalType>(UserManagementModalType.INVITE_USER);

  const { data, loading, error } = useUserManagementQuery({
    variables: {
      limit: tableRowsCountPerPage,
      skip: tableRowsCountPerPage * (currentPage - 1),
      tenantId,
      invitationsFilter: { accountType },
      usersFilter: { accountType },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const showInvitations = useMemo(() => !!data?.allInvitations.plainTotal, [data?.allInvitations.plainTotal]);

  const onBtnClick = useCallback(
    (modalContentType: UserManagementModalType) => {
      setModalType(modalContentType);
      showModal();
    },
    [showModal],
  );

  if (loading) return <Loading />;
  if (error) return <Alert variant="danger">{grabErrorMessage(error)}</Alert>;

  return (
    <>
      {/* <TrackPageVisit {...{ event: AuditLogEventName.viewUserManagement, entity: AuditLogEntity.view, tenantId }} /> */}
      <div className={s.header}>
        <div className={s.buttons}>
          {accountType === AccountType.meinbau || tenantId ? (
            <Button onClick={() => onBtnClick(UserManagementModalType.INVITE_USER)}>
              {t('userManagement.invitations.inviteUserBtn')} <FontAwesomeIcon icon={faUserPlus} />
            </Button>
          ) : null}
          {tenantId ? (
            <Button onClick={() => onBtnClick(UserManagementModalType.ADD_EXISTING_USER)}>
              {t('userManagement.users.addTenantUserBtn')} <FontAwesomeIcon icon={faUserPlus} />
            </Button>
          ) : null}
        </div>
      </div>
      {showInvitations ? <InvitationsSection {...{ accountType }} /> : null}

      <UsersSection {...{ accountType }} />
      <Modal
        isVisible={isVisible}
        onHide={hideModal}
        headerCloseButton
        headerText={t(
          modalType === UserManagementModalType.INVITE_USER
            ? 'userManagement.invitations.modalHeader'
            : 'userManagement.users.addTenantUserModal.header',
        )}
        size="lg"
      >
        <>
          {modalType === UserManagementModalType.INVITE_USER ? (
            <InviteUserForm {...{ hideModal, accountType }} />
          ) : null}
          {modalType === UserManagementModalType.ADD_EXISTING_USER ? (
            <AddUserToTenantForm {...{ hideModal, accountType }} />
          ) : null}
        </>
      </Modal>
    </>
  );
};

export default UserManagement;
