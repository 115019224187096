import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { FileFragmentDoc } from '../../../fragments/file/generated/FileFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PersistFileMutationVariables = SchemaTypes.Exact<{
  requestId: SchemaTypes.Scalars['ID']['input'];
}>;

export type PersistFileMutation = {
  __typename?: 'Mutation';
  persistFile?: {
    __typename?: 'File';
    _id: string;
    name: string;
    iframeId: string;
    tenantId: string;
    sizeBytes: number;
    createdAt: any;
  } | null;
};

export const PersistFileDocument = gql`
  mutation PersistFile($requestId: ID!) {
    persistFile(requestId: $requestId) {
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export type PersistFileMutationFn = Apollo.MutationFunction<PersistFileMutation, PersistFileMutationVariables>;

/**
 * __usePersistFileMutation__
 *
 * To run a mutation, you first call `usePersistFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersistFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [persistFileMutation, { data, loading, error }] = usePersistFileMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function usePersistFileMutation(
  baseOptions?: Apollo.MutationHookOptions<PersistFileMutation, PersistFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PersistFileMutation, PersistFileMutationVariables>(PersistFileDocument, options);
}
export type PersistFileMutationHookResult = ReturnType<typeof usePersistFileMutation>;
export type PersistFileMutationResult = Apollo.MutationResult<PersistFileMutation>;
export type PersistFileMutationOptions = Apollo.BaseMutationOptions<PersistFileMutation, PersistFileMutationVariables>;
