import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BasicUserAttributesFragmentDoc } from '../../user/generated/BasicUserAttribuesFragment';
export type InvitationFragment = {
  __typename?: 'Invitation';
  _id: string;
  role: SchemaTypes.Roles;
  email: string;
  createdAt: any;
  isDraft?: boolean | null;
  profile: { __typename?: 'UserProfile'; lastName: string; firstName: string };
  inviter?: {
    __typename?: 'BasicUserAttributes';
    _id: string;
    email: string;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  } | null;
};

export type InvitationFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const InvitationFragmentDoc = gql`
  fragment Invitation on Invitation {
    _id
    role
    email
    profile {
      lastName
      firstName
    }
    createdAt
    inviter {
      ...BasicUserAttributes
    }
    isDraft
  }
  ${BasicUserAttributesFragmentDoc}
`;
