import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, useModal } from 'components/common/Modal';
import WebhookForm, { WebhookFormType } from './WebhookForm';
import { faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Webhook } from 'graphql/types.generated';
import Button from 'components/common/Button';

interface IWebhookButtonParams {
  iframeId: string;
  formType: WebhookFormType;
  webhook?: Webhook;
}

const WebhookButton: FC<IWebhookButtonParams> = ({ iframeId, formType, webhook }) => {
  const { t } = useTranslation('translation');
  const { showModal, isVisible, hideModal } = useModal();

  const onButtonClick = useCallback(() => {
    showModal();
  }, [showModal]);

  return (
    <>
      {formType === WebhookFormType.create ? (
        <Button onClick={onButtonClick}>
          {t('webhook.addWebhookButton')} <FontAwesomeIcon icon={faPlus} />
        </Button>
      ) : null}
      {formType === WebhookFormType.update ? (
        <Button onClick={onButtonClick}>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
      ) : null}
      <Modal
        isVisible={isVisible}
        onHide={hideModal}
        headerCloseButton
        headerText={t(
          `webhook.${formType === WebhookFormType.create ? 'addWebhookModal' : 'updateWebhookModal'}.header`,
        )}
        size="lg"
      >
        <WebhookForm {...{ webhook, iframeId, formType }} afterSubmitFunc={hideModal} />
      </Modal>
    </>
  );
};

export default WebhookButton;
