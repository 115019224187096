import RefreshTokenService from 'services/RefreshTokenService';
import { ApolloClient, ApolloLink, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { InMemoryCache, makeVar } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import config from 'config/config';
import { GraphQLError } from 'graphql';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { difference, get, includes } from 'lodash';
import { LogoutDocument } from './mutations/auth/generated/Logout';
import { afterLogout } from 'hooks/auth/useLogout';
import { LOCAL_STORAGE_KEY } from 'i18n';
import { backendErrors as deBackendErrors } from '../i18n/translations/de';
import { backendErrors as enBackendErrors } from '../i18n/translations/en';

export const ctxTenantIdVar = makeVar<string>(window.location.pathname.split('/')[2]);

const httpLink = new HttpLink({
  uri: config.graphqlServerUrl,
  credentials: 'same-origin',
  headers: {
    'x-client-type': config.xClientType,
    tenant: ctxTenantIdVar(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

const link = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: async () => {
      return RefreshTokenService.isTokenValidOrUndefined();
    },
    fetchAccessToken: async () => {
      return RefreshTokenService.fetchNewAccessToken();
    },
    handleFetch: () => console.log('refreshed a token with apollo TokenRefreshLink'),
    handleResponse: () => (response: GraphQLError) => {
      return response;
    },
    handleError: () => {
      window.setTimeout(() => {
        window.setTimeout(async () => {
          try {
            await client.mutate({
              mutation: LogoutDocument,
            });
          } catch (e) {
            console.log('Error while logout', e);
          }
          afterLogout(client);
        });
      });
    },
  }),
  setContext((_, { headers }) => {
    return { headers: { ...headers, tenant: ctxTenantIdVar() } };
  }),
  onError(({ graphQLErrors, operation }) => {
    const logError = (error: GraphQLError, errorType: string) => {
      const { message, locations, path, extensions } = error;

      if (message.includes('ACCESS_DENIED')) {
        client.mutate({ mutation: LogoutDocument });
        afterLogout(client);
      }
      if (message.includes('NOT_AUTHORIZED') && !includes(path, 'me')) {
        client.mutate({ mutation: LogoutDocument });
        return afterLogout(client);
      }

      const doNotLog = ['login', 'changePassword', 'resetPassword', 'forgotPassword'];
      if (path && typeof path[0] === 'string' && doNotLog.includes(path[0])) return;

      const lVariables = JSON.stringify(operation.variables);
      const lPath = JSON.stringify(path);
      const lLocations = JSON.stringify(locations);
      const lMessage = JSON.stringify(message);
      console.error(
        [
          `[${errorType}]:`,
          lMessage && `Message: ${lMessage}`,
          lLocations && `Location: ${lLocations}`,
          lPath && `Path: ${lPath}`,
          lVariables && `Variables: ${lVariables}`,
        ]
          .filter(Boolean)
          .join('\n'),
        extensions,
      );
    };

    if (graphQLErrors) graphQLErrors.forEach((error: GraphQLError) => logError(error, 'GraphQL error'));
  }),
  onError(({ graphQLErrors }) => {
    const locale = window?.localStorage?.getItem(LOCAL_STORAGE_KEY) || config.defaultLocale;
    const backendErrorsTranslations = { de: deBackendErrors, en: enBackendErrors };

    const translateMessage = (error: GraphQLError) => {
      const messageKey = `${locale}.${error.message}`;
      const translated = get(backendErrorsTranslations, messageKey, error.message);
      if (translated !== messageKey) {
        error.message = translated;
      }
    };
    if (graphQLErrors) {
      graphQLErrors.forEach(translateMessage);
    }
  }),
  httpLink,
]);

export function mergeApolloCache(existing = [], incoming = []) {
  const incomingIds = incoming.map(({ __ref }: { __ref: string }) => __ref);
  const existingIds = existing.map(({ __ref }: { __ref: string }) => __ref);
  const newIncomingIds = difference(incomingIds, existingIds);
  const newIncoming = incoming.filter(({ __ref }: { __ref: string }) => newIncomingIds.includes(__ref));
  if (newIncomingIds.length) return [...existing, ...newIncoming];
  return existing;
}

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {},
    },
  }),
  assumeImmutableResults: true,
});

export default client;
