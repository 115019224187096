import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { UserFragmentDoc } from '../../../fragments/user/generated/UserFragment';
import { BasicUserAttributesFragmentDoc } from '../../../fragments/user/generated/BasicUserAttribuesFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllUsersQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
  filter: SchemaTypes.UsersFilter;
}>;

export type AllUsersQuery = {
  __typename?: 'Query';
  allUsers: {
    __typename?: 'PaginatedUserResponse';
    total: number;
    items: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      role?: SchemaTypes.Roles | null;
      isTwoFAEnabled?: boolean | null;
      createdAt: any;
      inviter?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    }>;
  };
};

export const AllUsersDocument = gql`
  query AllUsers($limit: Int!, $skip: Int!, $tenantId: ID, $filter: UsersFilter!) {
    allUsers(limit: $limit, skip: $skip, tenantId: $tenantId, filter: $filter) {
      total
      items {
        ...User
        inviter {
          ...BasicUserAttributes
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${BasicUserAttributesFragmentDoc}
`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      tenantId: // value for 'tenantId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
}
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export function refetchAllUsersQuery(variables: AllUsersQueryVariables) {
  return { query: AllUsersDocument, variables: variables };
}
