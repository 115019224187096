import { FieldInputProps, FormikProps } from 'formik';
import { castArray, get, isUndefined } from 'lodash';
import React, { CSSProperties, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import SimpleDatePicker, { iSimpleDatePicker } from './SimpleDatePicker';

export interface iDatePickerProps extends Omit<iSimpleDatePicker, 'selected'> {
  field: FieldInputProps<Date | Date[]>;
  form: FormikProps<Date | Date[]>;
  label: string;
  style?: CSSProperties;
  formGroupClassName?: string;
}

const DatePicker: FC<iDatePickerProps> = ({ field, form, label, style = {}, formGroupClassName = '', ...props }) => {
  const { name } = field;
  const { touched, errors, setFieldValue } = form;
  const { t } = useTranslation();
  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  const handleChange = React.useCallback(
    (date?: Date | Date[]) => {
      form.setFieldValue(name, date);
    },
    [form, name],
  );

  const selected = useMemo(() => castArray(field.value)[0], [field.value]);

  useEffect(() => {
    if (isUndefined(field.value)) {
      setFieldValue(field.name, null);
    }
  }, [field.name, field.value, setFieldValue]);

  return (
    <Form.Group className={formGroupClassName || 'mb-3'} style={style}>
      {label ? (
        <Form.Label htmlFor={name} className="text-primary">
          {t(label)}
        </Form.Label>
      ) : null}
      <SimpleDatePicker selected={selected} onChange={handleChange} {...props} />
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default DatePicker;
