import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type MeinBauApiLogFragment = {
  __typename?: 'MeinBauApiLog';
  _id: string;
  createdAt: any;
  status?: number | null;
  ip?: string | null;
  appName?: string | null;
  useCase: string;
  method: SchemaTypes.MethodType;
  input: string;
  output?: string | null;
  errorMessage?: string | null;
};

export type MeinBauApiLogFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const MeinBauApiLogFragmentDoc = gql`
  fragment MeinBauApiLog on MeinBauApiLog {
    _id
    createdAt
    status
    ip
    appName
    useCase
    method
    input
    output
    errorMessage
  }
`;
