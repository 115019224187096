import React, { useRef, useState, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format';
import isNil from 'lodash/isNil';
import clamp from 'lodash/clamp';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InputGroup } from 'react-bootstrap';
import s from './NumberInput.module.sass';
import { isNumber } from 'lodash';

const config = {
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  de: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};

export interface INumberInputProps {
  field: FieldInputProps<number>;
  form: FormikProps<number>;
  type?: string;
  label: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  skipMaxRestriction?: boolean;
  separators?: boolean;
  formGroupClassName?: string;
  inputClassName?: string;
  maxDigits?: number;
  fractionDigits?: number;
  getInputRef?: (el: HTMLInputElement) => void;
  endIcon?: React.ReactNode;
  helpText?: string;
}

const NumberInput: FC<INumberInputProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  disabled,
  min = 0,
  max = 500000000,
  skipMaxRestriction,
  separators = true,
  formGroupClassName = 'mb-3',
  inputClassName = '',
  maxDigits,
  fractionDigits,
  getInputRef,
  endIcon,
  helpText,
}) => {
  const ref: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [, forceRerender] = useState(false);
  const { value, name, onBlur } = field;
  const { t, i18n } = useTranslation();

  const handleChange = useCallback(
    (values: NumberFormatValues) => {
      let v: NumberFormatValues['floatValue'] | null | string = values.floatValue;
      if (typeof fractionDigits === 'number' && typeof v === 'number') v = v.toFixed(fractionDigits);
      if (maxDigits && values.value.length >= maxDigits) {
        setFieldValue(name, Number(values.value.slice(0, maxDigits)));
        forceRerender((b) => !b);
        return;
      }
      if (isNil(v)) {
        v = null;
      }
      if (!isNil(v) && isNumber(values.floatValue) && !skipMaxRestriction) {
        v = clamp(values.floatValue, min, max);
      }
      setFieldValue(name, v);
      forceRerender((b) => !b);
    },
    [fractionDigits, max, maxDigits, min, name, setFieldValue, skipMaxRestriction],
  );

  const handleInputRef = (el: HTMLInputElement) => {
    ref.current = el;
    if (typeof getInputRef === 'function') getInputRef(el);
  };

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, name, errors]);

  return (
    <Form.Group className={formGroupClassName}>
      {label ? (
        <Form.Label htmlFor={name} className="text-primary">
          {t(label)}
        </Form.Label>
      ) : null}
      <div className="d-flex">
        <NumberFormat
          allowNegative={false}
          allowLeadingZeros={false}
          allowEmptyFormatting={false}
          {...(separators ? config[i18n.language as 'en' | 'de'] : { thousandSeparator: false, decimalScale: 0 })}
          onValueChange={handleChange}
          name={name}
          value={value}
          onBlur={onBlur}
          autoComplete="off"
          id={name}
          disabled={disabled}
          className={cn('form-control', inputClassName, endIcon ? s.inputRightAngles : '')}
          getInputRef={handleInputRef}
          type="text"
        />
        {endIcon ? (
          <InputGroup.Text id={name} className={s.endBoxLeftAngles}>
            {endIcon}
          </InputGroup.Text>
        ) : null}
      </div>

      {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}

      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default NumberInput;
