import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { filterTypeAcceptableFiles, validateFilesSize, validateMimeTypes } from '../DndContainer.helpers';
import { OnAddNewFiles } from '../DndContainer';

export const useHandleChangeFileInput = ({
  extensions,
  onAddNewFiles,
  maxFileSize,
  cb,
}: {
  extensions: string[];
  maxFileSize: number;
  onAddNewFiles?: OnAddNewFiles;
  cb?: () => void;
}) => {
  const { t } = useTranslation();
  const onSelectFiles = useCallback(
    async ({ files }: { files: File[] }) => {
      let validFiles = filterTypeAcceptableFiles({ files, extensions, t });

      validFiles = validateFilesSize(validFiles, maxFileSize, t);

      const isAllValid = await validateMimeTypes(validFiles);
      if (!isAllValid) {
        toast.error(t('dmsTenant.invalidOriginalFileType'));
        return;
      }
      if (typeof onAddNewFiles === 'function') {
        await onAddNewFiles({ files: validFiles });
      }
      if (typeof cb === 'function') cb();
    },
    [cb, extensions, maxFileSize, onAddNewFiles, t],
  );

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || isEmpty(e.target.files)) return;
      const files = Array.from(e.target.files);
      return onSelectFiles({ files });
    },
    [onSelectFiles],
  );
  return handleChange;
};
