import { ColumnDef } from '@tanstack/react-table';
import ReactTable from 'components/common/Table/Table';
import { NumberInput } from 'components/inputs/NumberInput';
import { FastField } from 'formik';
import { IframePlanningItemsQuery } from 'graphql/queries/iframe/generated/IframePlanningItems';
import { EquipmentType } from 'graphql/types.generated';
import { groupBy, isNumber, map } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import s from './PlanningItemsTable.module.sass';
import { PlanningItemsRow } from './PlanningItemsTab.types';

interface PlanningItemsTableProps {
  loading: boolean;
  planningItems: IframePlanningItemsQuery['iframe']['planningItems'];
}

const PlanningItemsTable: FC<PlanningItemsTableProps> = ({ loading, planningItems }) => {
  const { t: headers } = useTranslation('translation', { keyPrefix: 'iframeCategoryConfiguration.table.headers' });
  const { t } = useTranslation();

  const parents = useMemo(() => {
    return groupBy(planningItems, 'parent');
  }, [planningItems]);

  const columns = useMemo<ColumnDef<PlanningItemsRow>[]>(() => {
    return [
      {
        id: 'parent',
        header: headers('parent'),
        accessorKey: 'parent',
        cell: ({ row: { original, index } }) => {
          const hideCell = index !== 0 && planningItems?.[index].parent === planningItems?.[index - 1].parent;
          if (hideCell) return null;
          const rowSpan = parents[original.parent].length;
          return <td rowSpan={rowSpan}>{t(`planningCategories.${original.parent}`)}</td>;
        },
        meta: { customTd: true },
      },
      {
        id: 'category',
        header: headers('category'),
        accessorKey: 'name',
        cell: ({ row: { original } }) => t(`planningCategories.${original.name}`),
      },
      {
        id: 'pricePerUnit',
        header: headers('pricePerUnit'),
        cell: ({ row: { original } }) => {
          const { name, pricePerUnit, pricesPerUnit } = original;
          if (original.pricesPerUnit) {
            return (
              <>
                {map(pricesPerUnit, (_, key) => (
                  <FastField
                    key={key}
                    label={t(`iframeCategoryConfiguration.table.body.${key}`)}
                    name={`${name}.pricesPerUnit.${key}`}
                    component={NumberInput}
                    formGroupClassName={s.numberInputFormGroup}
                  />
                ))}
              </>
            );
          }
          if (!isNumber(pricePerUnit)) return null;
          return (
            <FastField
              name={`${name}.pricePerUnit`}
              component={NumberInput}
              formGroupClassName={s.numberInputFormGroup}
            />
          );
        },
      },
      {
        id: 'equipmentTypes',
        header: headers('equipmentTypes'),
        accessorKey: 'equipmentTypes',
        columns: [EquipmentType.standard, EquipmentType.premium, EquipmentType.superior].map((equipmentType) => ({
          id: equipmentType,
          header: t(`equipmentTypes.${equipmentType}`),
          cell: ({ row: { original } }) => {
            if (!original.equipmentTypes) return null;
            const { name } = original;
            return (
              <FastField
                name={`${name}.equipmentTypes.${equipmentType}`}
                component={NumberInput}
                formGroupClassName={s.numberInputFormGroup}
              />
            );
          },
        })),
      },
    ];
  }, [headers, parents, planningItems, t]);

  const data: PlanningItemsRow[] = useMemo(() => {
    if (!planningItems) return [];
    return planningItems;
  }, [planningItems]);

  return <ReactTable initialState={{ expanded: true }} loading={loading} columns={columns} data={data} />;
};

export default PlanningItemsTable;
