import React from 'react';
import { FastField, Formik, Form } from 'formik';
import * as yup from 'yup';
import { AccountType, InvitationInput, Roles } from '../../../../graphql/types.generated';
import useSubmitInvitationForm from './hooks/useSubmitInvitationForm';
import { Col, Row } from 'react-bootstrap';
import SubmitButtons from './SubmitButtons';
import ScrollToError from 'components/common/ScrollToError';
import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'components/inputs/Select/Select';
import TextArea from 'components/inputs/TextArea/TextArea';
import useFilteredRoleOptions from 'hooks/user/useFilteredRoleOptions';
import { MenuPortalTarget } from 'components/inputs/Select/useMenuPortalTarget';

const validationShape = {
  email: yup.string().required('errors.requiredField').email('errors.invalidEmail'),
  firstName: yup.string().required('errors.requiredField'),
  lastName: yup.string().required('errors.requiredField'),
  role: yup.string().required('errors.requiredField'),
};

const validationSchema = yup.object().shape(validationShape);

interface InviteUserFormProps {
  hideModal: () => void;
  accountType: AccountType;
}

const InviteUserForm: FC<InviteUserFormProps> = ({ hideModal, accountType }) => {
  const onSubmit = useSubmitInvitationForm(hideModal, accountType);
  const roles = useFilteredRoleOptions(accountType);

  const initialValues: InvitationInput = {
    email: '',
    firstName: '',
    lastName: '',
    role: '' as Roles,
    invitationMessage: '',
    isDraft: false,
  };

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {() => (
        <Form>
          <ScrollToError inModal />
          <Row>
            <Col md={6}>
              <FastField name="firstName" component={TextInput} label="auth.firstName" />
            </Col>
            <Col md={6}>
              <FastField name="lastName" component={TextInput} label="auth.lastName" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FastField
                name="role"
                component={Select}
                label="auth.role"
                options={roles}
                menuPortalTarget={MenuPortalTarget.modal}
              />
            </Col>
            <Col md={6}>
              <FastField name="email" type="email" component={TextInput} label="auth.email" />
            </Col>
          </Row>

          <FastField
            name="invitationMessage"
            component={TextArea}
            label="userManagement.invitations.additionalMessage"
          />

          <SubmitButtons />
        </Form>
      )}
    </Formik>
  );
};

export default InviteUserForm;
