import { range } from 'lodash';
import React from 'react';
import { Placeholder } from 'react-bootstrap';
import s from './RowPlaceholder.module.sass';

const RowPlaceholder = ({ rows = 5, columns = 5 }: { rows?: number; columns?: number }) => (
  <>
    {range(0, rows).map((el) => (
      <Placeholder as="tr" animation="glow" className={s.row} key={el}>
        <td className={s.mdCol}>
          <Placeholder bg="primary" />
        </td>
        <td className={s.mdCol}>
          <Placeholder bg="primary" />
        </td>
        {range(0, columns - 2).map((el) => (
          <td className={s.smCol} key={el}>
            <Placeholder bg="primary" />
          </td>
        ))}
      </Placeholder>
    ))}
  </>
);

export default RowPlaceholder;
