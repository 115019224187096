import { useEffect } from 'react';
import constants from '../constants/constants';

const usePolling = ({
  startPolling,
  stopPolling,
  interval = constants.pollInterval,
}: {
  startPolling: (interval: number) => void;
  stopPolling: () => void;
  interval?: number;
}) => {
  useEffect(() => {
    startPolling(interval);
    return stopPolling;
  }, [interval, startPolling, stopPolling]);
};

export default usePolling;
