import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, FastField, Field } from 'formik';
import { ProjectType, TemplateName } from 'graphql/types.generated';
import TextInput from 'components/inputs/TextInput/TextInput';
import TextArea from 'components/inputs/TextArea/TextArea';
import Select from 'components/inputs/Select';
import DomainWhitelist from './DomainWhitelist';
import { IframeFormProps, TInitialValues } from './IframeForm.types';
import {
  getFormOptions,
  getInitialValues,
  getTemplatesByProjectType,
  useSubmitIframeForm,
  validationSchema,
} from './IframeForm.helpers';
import MultiSelect from 'components/inputs/Select/MultiSelect';
import PdfConfigSection from './PdfConfig';
import IframeFormButtons from './IframeFormButtons';
import { ErrorMessage } from 'components/common/Form';
import { SideEffectOnSelectFunc } from 'components/inputs/Select/Select';
import { isString } from 'lodash';

const IframeForm: FC<IframeFormProps> = ({ iframe, tenantId }) => {
  const initialValues = useMemo<TInitialValues>(() => getInitialValues(iframe), [iframe]);
  const onSubmit = useSubmitIframeForm({ iframeId: iframe?._id, tenantId });

  const [templateOptions, setTemplateOptions] = useState<TemplateName[]>(
    getTemplatesByProjectType(iframe?.projectType),
  );
  const formOptions = useMemo(() => getFormOptions({ templateOptions }), [templateOptions]);

  const sideEffectOnSelectProjectType: SideEffectOnSelectFunc = useCallback(({ selectedValue, form }) => {
    if (!isString(selectedValue)) return;
    const newOptions = getTemplatesByProjectType(selectedValue as ProjectType);
    setTemplateOptions(newOptions);
    form.setFieldValue('template', null);
  }, []);

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue }) => (
          <Form>
            <Row>
              <Col xl={6}>
                <Row>
                  <Col xl={6}>
                    <FastField name="name" component={TextInput} label="iframe.form.nameLabel" />
                  </Col>
                  <Col xl={6}>
                    <FastField name="title" component={TextInput} label="iframe.form.htmlTitle" />
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <FastField
                      label="iframe.form.projectTypeLabel"
                      name="projectType"
                      component={Select}
                      options={formOptions.projectTypes}
                      sideEffectOnSelect={sideEffectOnSelectProjectType}
                    />
                  </Col>
                  <Col xl={6}>
                    {values.projectType ? (
                      <Field
                        label="iframe.form.projectTemplate"
                        name="template"
                        component={Select}
                        options={formOptions.templates}
                      />
                    ) : null}
                  </Col>
                </Row>
                <FastField name="privacyPolicyUrl" component={TextInput} label="iframe.form.privacyPolicyUrl" />
                <FastField name="termsOfUseUrl" component={TextInput} label="iframe.form.termsOfUseUrl" />
                <Row>
                  <Col xl={3}>
                    <FastField
                      label="iframe.form.country"
                      name="country"
                      component={Select}
                      options={formOptions.countries}
                    />
                  </Col>
                  <Col xl={3}>
                    <FastField
                      name="theme.primaryColor"
                      component={TextInput}
                      sideEffect={(value: string) => setFieldValue('theme.pdfButtonColor', value)}
                      type={'color'}
                      label="iframe.form.primaryColorLabel"
                    />
                  </Col>
                  <Col xl={3}>
                    <FastField
                      name="theme.textColor"
                      component={TextInput}
                      type={'color'}
                      label="iframe.form.textColorLabel"
                    />
                  </Col>
                  <Col xl={3}>
                    <FastField
                      name="theme.pdfButtonColor"
                      component={TextInput}
                      type={'color'}
                      label="iframe.form.pdfButtonColorLabel"
                    />
                  </Col>
                </Row>
                {values.projectType === ProjectType.renovatingHouse ? (
                  <Row>
                    <FastField
                      label="iframe.form.integrationServices"
                      name="integrationServices"
                      component={MultiSelect}
                      options={formOptions.integrationServices}
                    />
                  </Row>
                ) : null}
                <FastField
                  label="iframe.form.domainWhiteListTypes"
                  name="domainWhiteListTypes"
                  component={DomainWhitelist}
                />
                <FastField label="iframe.form.headInjectionTags" name="headInjectionTags" component={TextArea} />
              </Col>
              <Col xl={6}>
                <PdfConfigSection />
              </Col>
            </Row>

            <ErrorMessage />
            <IframeFormButtons iframeId={iframe?._id} tenantId={tenantId} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default IframeForm;
