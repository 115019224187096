import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useMemo } from 'react';
import PlanningItemsTable from './PlanningItemsTable';
import SubmitButton from 'components/common/Button/SubmitButton';
import { omitDeep, toastSuccessMessage } from 'utils/helpers';
import { isArray, isEmpty, isFunction, isNil, isObject, map, mapValues, omitBy, sortBy } from 'lodash';
import {
  UpdateNewBuildingPlanningItemsMutationVariables,
  useUpdateNewBuildingPlanningItemsMutation,
} from 'graphql/mutations/iframe/generated/UpdateNewBuildingPlanningItems';
import { ErrorMessage, setApolloFormikError } from 'components/common/Form';
import { useTranslation } from 'react-i18next';
import { ProjectFeature } from 'graphql/types.generated';
import { PlanningItem } from './PlanningItemsTab.types';
import {
  IframePlanningItemsDocument,
  IframePlanningItemsQuery,
} from 'graphql/queries/iframe/generated/IframePlanningItems';
import * as yup from 'yup';
import dot from 'dot-object';
import Loading from 'components/common/Loading/Loading';

interface NestedObject {
  [key: string]: any;
}

const createYupSchema = (obj: NestedObject): yup.Schema<any> => {
  const schemaShape = mapValues(obj, (value) => {
    if (isObject(value) && !isArray(value) && !isFunction(value)) {
      return createYupSchema(value);
    } else {
      return yup.number().required('errors.requiredField');
    }
  });

  return yup.object().shape(schemaShape);
};

interface PlanningItemsFormProps {
  iframeId: string;
  loading: boolean;
  items: IframePlanningItemsQuery['iframe']['planningItems'];
}

const PlanningItemsForm: FC<PlanningItemsFormProps> = ({ iframeId, items, loading }) => {
  const { t } = useTranslation();

  const [updatePlanningItems] = useUpdateNewBuildingPlanningItemsMutation({
    onError: setApolloFormikError,
    onCompleted: () => toastSuccessMessage(t),
  });

  const planningItems = useMemo(() => {
    if (!items?.length) return [];
    return sortBy(items, 'parent').map(({ parentId, name, parent, pricePerUnit, pricesPerUnit, equipmentTypes }) => ({
      parentId,
      name,
      parent,
      pricePerUnit,
      pricesPerUnit: omitDeep(pricesPerUnit, ['__typename']),
      equipmentTypes: omitDeep(equipmentTypes, ['__typename']),
    }));
  }, [items]);

  const initialValues = useMemo(() => {
    return planningItems.reduce((acc, { parentId, name, pricePerUnit, pricesPerUnit, equipmentTypes }) => {
      return {
        ...acc,
        [name]: { parentId, pricePerUnit, pricesPerUnit, equipmentTypes },
      };
    }, {} as Record<ProjectFeature, Pick<PlanningItem, 'parentId' | 'pricePerUnit' | 'pricesPerUnit' | 'equipmentTypes'>>);
  }, [planningItems]);

  const validationSchema = useMemo(() => {
    if (isEmpty(initialValues)) return {};
    const omitted = omitDeep(initialValues, ['parentId']);
    const dotDot = omitBy(dot.dot(omitted), isNil);
    const dotObject = dot.object(dotDot);
    const shape = createYupSchema(dotObject);
    return shape;
  }, [initialValues]);

  const onSubmit = useCallback(
    async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {
      const planningItems: UpdateNewBuildingPlanningItemsMutationVariables['planningItems'] = map(
        values,
        ({ parentId, pricePerUnit, pricesPerUnit, equipmentTypes }) => ({
          parentId,
          pricePerUnit,
          pricesPerUnit,
          equipmentTypes,
        }),
      );
      await updatePlanningItems({
        variables: { iframeId, planningItems },
        refetchQueries: [{ query: IframePlanningItemsDocument, variables: { iframeId } }],
        awaitRefetchQueries: true,
        context: { setFieldError },
      });
    },
    [iframeId, updatePlanningItems],
  );

  if (isEmpty(initialValues)) return <Loading />;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
      {() => (
        <Form>
          <PlanningItemsTable loading={loading} planningItems={planningItems} />
          <ErrorMessage />
          <div className="mt-3 text-end">
            <SubmitButton />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PlanningItemsForm;
