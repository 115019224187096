import React, { useState, useCallback, createContext, useContext } from 'react';
import { isDesktop } from 'react-device-detect';

interface SidebarState {
  sidebarOpen: boolean;
  toggleSidebar: (v: boolean) => void;
}

const defaultSidebarState: SidebarState = {
  sidebarOpen: isDesktop ? localStorage.getItem('sidebarOpen') !== 'false' : false,
  toggleSidebar: (v) => ({ sidebarOpen: v }),
};

const useSidebarState = () => {
  const [sidebarOpen, setSidebar] = useState(defaultSidebarState.sidebarOpen);

  const toggleSidebar = useCallback((state: boolean) => {
    setSidebar(state);
    localStorage.setItem('sidebarOpen', `${state}`);
  }, []);

  return { sidebarOpen, toggleSidebar };
};

export const AppContext = createContext({ ...defaultSidebarState });

interface iAppContextProviderProps {
  children: React.ReactNode;
}

export const AppContextProvider: FC<iAppContextProviderProps> = ({ children }) => {
  const sidebarState = useSidebarState();
  return <AppContext.Provider value={{ ...sidebarState }}>{children}</AppContext.Provider>;
};

export const AppContextConsumer = AppContext.Consumer;

export const useAppContext = () => useContext(AppContext);
