import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BasicUserAttributesFragmentDoc } from '../../../fragments/user/generated/BasicUserAttribuesFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllApplicationsQueryVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.ApplicationFilter;
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
}>;

export type AllApplicationsQuery = {
  __typename?: 'Query';
  allApplications: {
    __typename?: 'PaginatedApplicationResponse';
    total: number;
    items: Array<{
      __typename?: 'Application';
      _id: string;
      tenantName: string;
      createdAt: any;
      archived?: boolean | null;
      profile: { __typename?: 'ApplicationProfile'; appName: string };
      createdBy: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      };
      archivedBy?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
    }>;
  };
};

export const AllApplicationsDocument = gql`
  query AllApplications($filter: ApplicationFilter!, $limit: Int!, $skip: Int!) {
    allApplications(filter: $filter, skip: $skip, limit: $limit) {
      total
      items {
        _id
        tenantName
        profile {
          appName
        }
        createdBy {
          ...BasicUserAttributes
        }
        createdAt
        archivedBy {
          ...BasicUserAttributes
        }
        archived
      }
    }
  }
  ${BasicUserAttributesFragmentDoc}
`;

/**
 * __useAllApplicationsQuery__
 *
 * To run a query within a React component, call `useAllApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllApplicationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAllApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<AllApplicationsQuery, AllApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllApplicationsQuery, AllApplicationsQueryVariables>(AllApplicationsDocument, options);
}
export function useAllApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllApplicationsQuery, AllApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllApplicationsQuery, AllApplicationsQueryVariables>(AllApplicationsDocument, options);
}
export type AllApplicationsQueryHookResult = ReturnType<typeof useAllApplicationsQuery>;
export type AllApplicationsLazyQueryHookResult = ReturnType<typeof useAllApplicationsLazyQuery>;
export type AllApplicationsQueryResult = Apollo.QueryResult<AllApplicationsQuery, AllApplicationsQueryVariables>;
export function refetchAllApplicationsQuery(variables: AllApplicationsQueryVariables) {
  return { query: AllApplicationsDocument, variables: variables };
}
