import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantsIdsQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type TenantsIdsQuery = {
  __typename?: 'Query';
  tenantsIds: Array<{ __typename?: 'TenantIds'; _id: string; companyName: string }>;
};

export const TenantsIdsDocument = gql`
  query TenantsIds {
    tenantsIds {
      _id
      companyName
    }
  }
`;

/**
 * __useTenantsIdsQuery__
 *
 * To run a query within a React component, call `useTenantsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantsIdsQuery(baseOptions?: Apollo.QueryHookOptions<TenantsIdsQuery, TenantsIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantsIdsQuery, TenantsIdsQueryVariables>(TenantsIdsDocument, options);
}
export function useTenantsIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantsIdsQuery, TenantsIdsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantsIdsQuery, TenantsIdsQueryVariables>(TenantsIdsDocument, options);
}
export type TenantsIdsQueryHookResult = ReturnType<typeof useTenantsIdsQuery>;
export type TenantsIdsLazyQueryHookResult = ReturnType<typeof useTenantsIdsLazyQuery>;
export type TenantsIdsQueryResult = Apollo.QueryResult<TenantsIdsQuery, TenantsIdsQueryVariables>;
export function refetchTenantsIdsQuery(variables?: TenantsIdsQueryVariables) {
  return { query: TenantsIdsDocument, variables: variables };
}
