import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import useGetSearchParams from 'hooks/useGetSearchParams';
import {
  AuditLogsQuery,
  AuditLogsQueryVariables,
  useAuditLogsQuery,
} from 'graphql/queries/auditLogs/generated/AuditLogs';

interface IGetAuditLogsProps extends QueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables> {
  currentPage: number;
}

export const useGetAuditLogsFilter = () => {
  const {
    searchParams: { auditLogsSearchQuery = '', tenant = '', startDate = '', endDate = '' },
  } = useGetSearchParams(['auditLogsSearchQuery', 'tenant', 'startDate', 'endDate']);

  return { searchQuery: auditLogsSearchQuery, tenant, startDate, endDate };
};

export default function useAuditLogs({ currentPage, ...baseOptions }: IGetAuditLogsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const filter = useGetAuditLogsFilter();

  const { data, loading, error } = useAuditLogsQuery({
    variables: {
      skip,
      limit: constants.tableRowsCountPerPage,
      searchString: filter.searchQuery,
      ...(filter.tenant ? { tenant: filter.tenant } : {}),
      ...(filter.startDate ? { startDate: Number(filter.startDate) } : {}),
      ...(filter.endDate ? { endDate: Number(filter.endDate) } : {}),
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const auditLogs = data?.auditLogs?.items || [];
  const total = data?.auditLogs?.total || 0;

  return {
    auditLogs,
    total,
    loading,
    error,
  };
}
