import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import useTenant from 'hooks/tenant/useTenant';
import TenantBaseData from 'components/tenants/TenantBaseData';
import { Alert, Card, Col, Placeholder, Row } from 'react-bootstrap';
import { grabErrorMessage, toastErrorMessage } from 'utils/helpers';
import { TenantQuery } from 'graphql/queries/tenant/generated/Tenant';
import Loading from 'components/common/Loading/Loading';
import useTenantAnalytic from 'hooks/analytics/useTenantAnalytic';
import CountPerEntityChart from 'components/dashboard/CountPerEntityChart';
import AnalyticRangePicker from 'components/dashboard/AnalyticRangePicker';
import { useTranslation } from 'react-i18next';

const DashboardTenantPage = () => {
  const { tenantId } = useParams();
  const { t } = useTranslation();

  const { tenant, error, loading } = useTenant({ tenantId: tenantId as string });
  const { tenantAnalytic, refetchTenantAnalytic } = useTenantAnalytic({
    tenantId: tenantId as string,
  });

  const onDateRangeChanged = useCallback(
    async (rangeStart?: Date, rangeEnd?: Date) => {
      try {
        refetchTenantAnalytic({
          tenantId,
          rangeStart,
          rangeEnd,
        });
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [refetchTenantAnalytic, tenantId],
  );

  return (
    <>
      <BreadcrumbBuilder
        pathLinks={[
          {
            style: { minWidth: '250px' },
            text: (
              <>
                {loading && !tenant ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={11} />
                  </Placeholder>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faBuilding} /> {(tenant as TenantQuery['tenant']).companyName}
                  </>
                )}
              </>
            ),
          },
        ]}
      />
      <Card className="mb-3">
        <Card.Body>
          <Row className="justify-content-center">
            <Col xs={10} xl={2}>
              <AnalyticRangePicker callbackQuery={onDateRangeChanged} tenantId={tenantId as string} />
            </Col>
            {tenantAnalytic?.projectsPerIframes && tenantAnalytic?.pdfRequestsPerIframes && (
              <>
                <Col xs={12} xl={5}>
                  <CountPerEntityChart
                    data={tenantAnalytic.projectsPerIframes}
                    name={t('tenantDashboardInfo.projectPerIframe')}
                    noDataText={{
                      title: t('tenantDashboardInfo.noDataTextProjects.title'),
                      description: t('tenantDashboardInfo.noDataTextProjects.description'),
                    }}
                  />
                </Col>

                <Col xs={12} xl={5}>
                  <CountPerEntityChart
                    data={tenantAnalytic.pdfRequestsPerIframes}
                    name={t('tenantDashboardInfo.pdfRequestPerIframe')}
                    noDataText={{
                      title: t('tenantDashboardInfo.noDataTextPdfRequests.title'),
                      description: t('tenantDashboardInfo.noDataTextPdfRequests.description'),
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
      {loading ? <Loading /> : null}
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!loading && !error && tenant ? <TenantBaseData tenant={tenant as TenantQuery['tenant']} /> : null}
    </>
  );
};

export default DashboardTenantPage;
