import Sidebar from 'components/layout/Sidebar';
import React from 'react';
import Footer from '../layout/Footer';
import Navbar from '../layout/Navbar';
import s from './Layout.module.sass';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAppContext } from 'contexts/AppContext';
import { Stack } from 'react-bootstrap';
import cn from 'classnames';

type iAppLayoutProps = {
  children: React.ReactNode;
  hideSidebar?: boolean;
};

const AppLayout: FC<iAppLayoutProps> = ({ children, hideSidebar }) => {
  const { sidebarOpen } = useAppContext();

  return (
    <Container fluid>
      <Row className="p-0">
        <Col xs={12} className={s.col}>
          {!hideSidebar ? <Sidebar /> : null}
          <Stack
            as="main"
            direction="vertical"
            className={cn(s.content, { [s.sidebarOpen]: sidebarOpen })}
            style={hideSidebar ? { padding: 0 } : {}}
          >
            <Navbar />
            <div className={s.children}>{children}</div>
            <Footer />
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default AppLayout;
