import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from 'components/common/IconButton';
import { useArchiveApplicationMutation } from 'graphql/mutations/application/generated/ArchiveApplication';
import { toastErrorMessage } from 'utils/helpers';
import { toast } from 'react-toastify';
import { confirmAlert } from 'components/common/Alert';

interface IApplicationArchiveProps {
  applicationId: string;
}

export const ApplicationArchiveButton: FC<IApplicationArchiveProps> = ({ applicationId }) => {
  const { t } = useTranslation();
  const [archiveApplication, { loading }] = useArchiveApplicationMutation({
    onError: toastErrorMessage,
    onCompleted: async ({ archiveApplication }) => {
      toast.success(
        t('applicationManagement.toast.applicationArchived', { appName: archiveApplication.profile.appName }),
      );
    },
  });

  const handleDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      confirmAlert({
        onConfirm: async () => {
          await archiveApplication({ variables: { applicationId } });
        },
        title: t('applicationManagement.alert.title'),
        message: t('applicationManagement.alert.message'),
      });
    },
    [t, archiveApplication, applicationId],
  );

  return <IconButton loading={loading} disabled={loading} onClick={handleDelete} icon={faTrash} variant="danger" />;
};

export default ApplicationArchiveButton;
