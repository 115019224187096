import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import { faFloppyDisk, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Modal, useModal } from 'components/common/Modal';
import { FastField, Form, Formik } from 'formik';
import { ApiIntegrationNames, ApiIntegrationsInput } from 'graphql/types.generated';
import SubmitButton from 'components/common/Button/SubmitButton';
import * as yup from 'yup';
import { Nullable } from 'constants/types';
import Select from 'components/inputs/Select';
import map from 'lodash/map';
import { MenuPortalTarget } from 'components/inputs/Select/useMenuPortalTarget';
import TextInput from 'components/inputs/TextInput';
import { useAddApiIntegrationMutation } from 'graphql/mutations/iframe/generated/AddApiIntegration';
import { setApolloFormikError } from 'components/common/Form';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';

interface IFormValues {
  name: ApiIntegrationNames;
  clientId: string;
  clientSecret: string;
}

const initialValues: Nullable<IFormValues> = {
  name: null,
  clientId: null,
  clientSecret: null,
};

const validationSchema = yup.object().shape({
  name: yup.mixed<ApiIntegrationNames>().oneOf(Object.values(ApiIntegrationNames)).required('errors.requiredField'),
  clientId: yup.string().nullable().required('errors.requiredField'),
  clientSecret: yup.string().nullable().required('errors.requiredField'),
});

const AddApiIntegrations = ({
  iframeId,
  apiIntegrations,
}: {
  iframeId?: string;
  apiIntegrations: IframeQuery['iframe']['apiIntegrations'];
}) => {
  const { t } = useTranslation('translation');
  const [addApiIntegration] = useAddApiIntegrationMutation();

  const { showModal, isVisible, hideModal } = useModal();

  const onSubmit = async (values: Nullable<IFormValues>) => {
    if (iframeId)
      await addApiIntegration({
        variables: { iframeId, formData: values as ApiIntegrationsInput },
        onError: setApolloFormikError,
        onCompleted: hideModal,
      });
  };

  const addedApis = apiIntegrations?.map(({ name }) => name);

  const nameOptions = map(ApiIntegrationNames, (value, key: ApiIntegrationNames) => {
    if (addedApis?.includes(key)) return null;
    const option: { value: ApiIntegrationNames; label: string } = {
      value: key as ApiIntegrationNames,
      label: `iframe.integrations.${key}`,
    };
    return option;
  }).filter((option) => option);

  return (
    <>
      <Button disabled={!nameOptions.length} onClick={showModal}>
        {t('iframe.integrations.apiIntegrations')}
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Modal headerText={t('iframe.integrations.apiIntegrations')} onHide={hideModal} isVisible={isVisible}>
        <Formik validationSchema={validationSchema} onSubmit={onSubmit} initialValues={initialValues}>
          {() => (
            <Form>
              <FastField
                menuPortalTarget={MenuPortalTarget.modal}
                name="name"
                label="iframe.integrations.serviceName"
                component={Select}
                options={nameOptions}
              />
              <FastField name="clientId" label="iframe.integrations.clientId" component={TextInput} />
              <FastField name="clientSecret" label="iframe.integrations.secret" component={TextInput} />
              <div className="text-center">
                <SubmitButton label="buttons.submit" icon={faFloppyDisk} />
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddApiIntegrations;
