import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeLocaleMutationVariables = SchemaTypes.Exact<{
  locale: SchemaTypes.Locale;
}>;

export type ChangeLocaleMutation = { __typename?: 'Mutation'; changeLocale: boolean };

export const ChangeLocaleDocument = gql`
  mutation ChangeLocale($locale: Locale!) {
    changeLocale(locale: $locale)
  }
`;
export type ChangeLocaleMutationFn = Apollo.MutationFunction<ChangeLocaleMutation, ChangeLocaleMutationVariables>;

/**
 * __useChangeLocaleMutation__
 *
 * To run a mutation, you first call `useChangeLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLocaleMutation, { data, loading, error }] = useChangeLocaleMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useChangeLocaleMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeLocaleMutation, ChangeLocaleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeLocaleMutation, ChangeLocaleMutationVariables>(ChangeLocaleDocument, options);
}
export type ChangeLocaleMutationHookResult = ReturnType<typeof useChangeLocaleMutation>;
export type ChangeLocaleMutationResult = Apollo.MutationResult<ChangeLocaleMutation>;
export type ChangeLocaleMutationOptions = Apollo.BaseMutationOptions<
  ChangeLocaleMutation,
  ChangeLocaleMutationVariables
>;
