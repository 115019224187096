import React, { useEffect, useMemo } from 'react';
import { faBug, faDownload } from '@fortawesome/free-solid-svg-icons';
import { formatDate, toastErrorMessage } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import Table from 'components/common/Table/Table';
import DateRangeExcelFilter from './DateRangeExcelFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from 'components/common/Pagination';
import useReports from 'hooks/reports/useReports';
import { useGenerateReportMutation } from 'graphql/mutations/reports/generated/GenerateReport';
import { toast } from 'react-toastify';
import usePagination from 'hooks/usePagination';
import { Modal, useModal } from 'components/common/Modal';
import { ColumnDef } from '@tanstack/react-table';

interface IExcelReportsModalContentProps {
  iframeId?: string;
}

interface FileReportRow {
  createdAt: Date;
  rangeStartDate?: Date;
  rangeEndDate?: Date;
  status: string;
  action: string;
  signedUrl?: string | null;
  errorMessage?: string | null;
  iframeName?: string | null;
}

const ExcelReportsModalContent: FC<IExcelReportsModalContentProps> = ({ iframeId }) => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'reportsModal' });
  const { reports, total, loading, error, stopPolling, startPolling } = useReports({ iframeId, currentPage });

  const [generateReportMutation] = useGenerateReportMutation({
    onError: toastErrorMessage,
    onCompleted: () => toast.success(t('projectReports.generationHasBegun')),
  });

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const data: FileReportRow[] = useMemo(() => {
    if (!reports) return [];
    return reports.map((report) => {
      return {
        createdAt: report.createdAt,
        rangeStartDate: report.rangeStartDate,
        rangeEndDate: report.rangeEndDate,
        status: report.status,
        action: '',
        signedUrl: report.signedUrl,
        errorMessage: report.errorMessage,
        iframeName: report.iframeName,
      };
    });
  }, [reports]);

  const columns: ColumnDef<FileReportRow>[] = useMemo(() => {
    return [
      {
        header: t('projectReports.tableHeaders.iframeName'),
        accessorKey: 'iframeName',
        cell: (info) => info.getValue(),
      },
      {
        header: t('projectReports.tableHeaders.createdAt'),
        accessorKey: 'createdAt',
        cell: (info) => formatDate({ date: info.getValue() as Date }),
      },
      {
        header: t('projectReports.tableHeaders.rangeStartDate'),
        accessorKey: 'rangeStartDate',
        cell: (info) => info.getValue() && formatDate({ date: info.getValue() as Date }),
      },
      {
        header: t('projectReports.tableHeaders.rangeEndDate'),
        accessorKey: 'rangeEndDate',
        cell: (info) => info.getValue() && formatDate({ date: info.getValue() as Date }),
      },
      {
        header: t('projectReports.tableHeaders.status'),
        accessorKey: 'status',
        cell: (info) => {
          let color;
          switch (info.getValue()) {
            case 'completed':
              color = 'green';
              break;
            case 'error':
              color = 'red';
              break;
            default:
              color = 'blue';
              break;
          }
          return <span style={{ color }}>{info.getValue() as string}</span>;
        },
      },
      {
        header: '',
        accessorKey: 'signedUrl',
        cell: (info) => {
          return (
            <Button
              disabled={!info.getValue() || !!info.row.original.errorMessage}
              onClick={() => window.open(info.getValue() as string)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          );
        },
      },
      {
        header: '',
        accessorKey: 'errorMessage',
        cell: (info) => {
          const { showModal, isVisible, hideModal } = useModal();
          return (
            <>
              <Button disabled={!info.getValue()} onClick={showModal}>
                <FontAwesomeIcon icon={faBug} />
              </Button>
              <Modal
                isVisible={isVisible}
                onHide={hideModal}
                headerCloseButton
                headerText={t('projectReports.errorModalHeader')}
                fullscreen
              >
                {JSON.stringify(info.getValue())}
              </Modal>
            </>
          );
        },
      },
    ];
  }, [t]);

  return (
    <>
      <DateRangeExcelFilter iframeId={iframeId} onClick={generateReportMutation} />
      {error ? (
        <>{t('projectReports.error.failedToLoad')}</>
      ) : (
        <>
          <Table columns={columns} data={data} loading={loading} />
          <Pagination {...{ changePage, currentPage, total }} />
        </>
      )}
    </>
  );
};

export default ExcelReportsModalContent;
