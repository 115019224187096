import SubmitButton from 'components/common/Button/SubmitButton';
import ScrollToError from 'components/common/ScrollToError';
import PasswordInput from 'components/inputs/PasswordInput';
import { FastField, Form, Formik } from 'formik';
import React from 'react';
import { passwordValidation } from 'utils/helpers';
import * as yup from 'yup';

export type ResetPasswordFormValues = {
  password: string;
  repeatedPassword: string;
};

interface ResetPasswordFormParams {
  initialValues: ResetPasswordFormValues;
  onSubmit: (values: ResetPasswordFormValues) => Promise<void>;
}

const resetPasswordShape = {
  password: yup
    .string()
    .required('errors.requiredField')
    .min(8, 'errors.passwordIsTooShort')
    .test('isValidPassword', 'errors.passwordIsInvalid', passwordValidation),
  repeatedPassword: yup
    .string()
    .required('errors.requiredField')
    .oneOf([yup.ref('password'), ''], 'errors.passwordsMustMatch'),
};

const validationSchema = yup.object().shape(resetPasswordShape);

const ResetPasswordForm: FC<ResetPasswordFormParams> = ({ initialValues, onSubmit }) => {
  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {() => (
        <>
          <Form>
            <ScrollToError />
            <FastField
              label="auth.resetPassword.newPassword"
              type="password"
              name="password"
              component={PasswordInput}
              helpText="auth.passwordRules"
            />
            <FastField
              label="auth.resetPassword.repeatNewPassword"
              type="password"
              name="repeatedPassword"
              component={PasswordInput}
            />
            <div className="text-center">
              <SubmitButton />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
