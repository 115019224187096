import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FieldInputProps, FormikProps } from 'formik';
import { get, isNull } from 'lodash';
import InputGroup from 'react-bootstrap/InputGroup';
import cn from 'classnames';
import s from './TextInput/TextInput.module.sass';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faKey } from '@fortawesome/free-solid-svg-icons';

interface IPasswordInput {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  helpText?: string;
}

const PasswordInput: FC<IPasswordInput> = ({
  field,
  form,
  label,
  disabled = false,
  placeholder,
  autoComplete,
  helpText,
}) => {
  const { name, onBlur, value } = field;
  const { errors, touched } = form;
  const [showPassword, changePasswordVisibility] = useState(false);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(name, e.target.value);
    },
    [form, name],
  );

  useEffect(() => {
    if (isNull(value)) form.setFieldValue(name, '');
  }, [form, name, value]);

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  const handleToggle = useCallback(() => {
    changePasswordVisibility(!showPassword);
  }, [showPassword]);

  const icon = useMemo(() => (showPassword ? faEye : faEyeSlash), [showPassword]);

  return (
    <Form.Group className="mb-3 w-100">
      <Form.Label className="text-primary" htmlFor={name}>
        {t(label)}
      </Form.Label>
      <div className="d-flex position-relative">
        <InputGroup.Text id={name} className={s.startBoxRightAngles}>
          <FontAwesomeIcon icon={faKey} />
        </InputGroup.Text>

        <Form.Control
          {...{ name, onBlur, disabled, placeholder, autoComplete }}
          type={showPassword ? 'text' : 'password'}
          value={value ?? ''}
          readOnly={disabled}
          onChange={handleChange}
          style={{ background: 'none' }}
          className={cn(error ? 'is-invalid' : '', s.inputLeftAngles, disabled ? s.disabledInput : '')}
        />
        <FontAwesomeIcon
          className="position-absolute end-0 top-50 translate-middle text-secondary"
          style={{ cursor: 'pointer' }}
          onClick={handleToggle}
          icon={icon}
        />
      </div>
      {helpText ? <Form.Text className="text-muted">{t(helpText)}</Form.Text> : null}
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default PasswordInput;
