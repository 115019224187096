import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from '../../utils/helpers';
import SubmitButton from 'components/common/Button/SubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import useGetSearchParams from 'hooks/useGetSearchParams';
import constants from 'constants/constants';
import { refetchProjectPdfRequestsQuery } from 'graphql/queries/projectPdfRequests/generated/ProjectPdfRequests';
import { useGetProjectPdfRequestsFilter } from 'hooks/projectPdfRequest/useProjectPdfRequests';

const { tableRowsCountPerPage } = constants;

interface IProjectPdfRequestsFilter {
  changePage: (page: number) => void;
}

type FormValues = {
  projectPdfRequestsSearchQuery: string;
};

const ProjectPdfRequestsFilter: FC<IProjectPdfRequestsFilter> = ({ changePage }) => {
  const { searchParams, setSearchParams } = useGetSearchParams();
  const filter = useGetProjectPdfRequestsFilter();

  const initialValues = useMemo(() => {
    return { projectPdfRequestsSearchQuery: filter.searchQuery };
  }, [filter.searchQuery]);

  const onSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { projectPdfRequestsSearchQuery } = values;
        changePage(1);
        setSearchParams({
          ...searchParams,
          ...(typeof projectPdfRequestsSearchQuery === 'string' ? { projectPdfRequestsSearchQuery } : {}),
        });

        refetchProjectPdfRequestsQuery({
          skip: 0,
          limit: tableRowsCountPerPage,
          searchQuery: projectPdfRequestsSearchQuery,
        });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [changePage, searchParams, setSearchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <Field
              name="projectPdfRequestsSearchQuery"
              type="search"
              label="projectPdfRequests.searchQuery"
              component={TextInput}
            />
          </div>
          <div className="filter-buttons">
            <SubmitButton label="buttons.search" icon={faMagnifyingGlass} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectPdfRequestsFilter;
