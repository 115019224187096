import React from 'react';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { Alert } from 'react-bootstrap';
import useIframes from 'hooks/iframe/useIframes';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import { grabErrorMessage } from 'utils/helpers';
import IframesFilterForm from 'components/iframe/IframesFilter';
import IframesTable from 'components/iframe/IframesTable';
import { Iframe } from 'graphql/types.generated';

const IframeManagementPage = () => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'iframesPage' });
  const { iframes, loading, total, error } = useIframes({ currentPage });

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.iframesManagement') }]} />
      <PageTitle>{t('pageTitles.iframesManagement')}</PageTitle>
      <IframesFilterForm {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <IframesTable {...{ iframes: iframes as Iframe[], currentPage, changePage, total, loading }} /> : null}
    </>
  );
};

export default IframeManagementPage;
