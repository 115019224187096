import TenantsList from 'components/tenants/changeTenant/TenantsList';
import SearchTenants from 'components/tenants/searchTenants/SearchTenants';
import constants from 'constants/constants';
import { useAllUserTenantsQuery } from 'graphql/queries/user/generated/AllUserTenants';
import useGetSearchParams from 'hooks/useGetSearchParams';
import useLoadingData from 'hooks/useLoadingData';
import useUser from 'hooks/user/useUser';
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { grabErrorMessage } from 'utils/helpers';

const { selectOptionsCount } = constants;

const ChangeTenantPage = () => {
  const { t } = useTranslation();
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const {
    searchParams: { searchQuery = '' },
  } = useGetSearchParams('searchQuery');

  const { data, loading, error, fetchMore } = useAllUserTenantsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { limit: selectOptionsCount, skip: 0, searchQuery },
  });

  const { isFetching, sentryRef } = useLoadingData({
    fetchMore,
    limit: selectOptionsCount,
    length: data?.allUserTenants?.items.length || 0,
    variables: { searchQuery },
    queryItemsPath: 'items',
  });

  return (
    <Row className="justify-content-center mt-2">
      <Col lg={10} xl={8}>
        <h5 className="text-center">{t('changeTenant.welcomeBack', { firstName: me?.profile?.firstName })}</h5>
        <h4 className="text-center mb-3">{t('changeTenant.selectTenant')}</h4>
        <SearchTenants />
        {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
        {!loading && !error && !data?.allUserTenants?.items.length ? (
          <Alert variant="secondary">
            <Alert.Heading>{t('tenants.noTenantsFound.title')}</Alert.Heading>
            <p>{t('tenants.noTenantsFound.description')}</p>
          </Alert>
        ) : null}
        {data?.allUserTenants ? <TenantsList {...{ isFetching, sentryRef, data, searchQuery }} /> : null}
      </Col>
    </Row>
  );
};

export default ChangeTenantPage;
