import PageTitle from 'components/common/PageTitle/PageTitle';
import { useTwoFaQrCodeQuery } from 'graphql/queries/auth/generated/TwoFaQrCode';
import React, { useCallback, useMemo } from 'react';
import { Alert, Col, Row, Stack } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import { grabErrorMessage, toastErrorMessage } from 'utils/helpers';
import { FastField, Form, Formik } from 'formik';
import OtpInput from 'components/inputs/OtpInput/OtpInput';
import Button from 'components/common/Button/Button';
import { useEnableTwoFAMutation } from 'graphql/mutations/auth/generated/EnableTwoFA';
import Loading from 'components/common/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'router/routes';

type InitTwoFAFormValues = {
  code: string;
};

const InitTwoFAPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [enableTwoFA] = useEnableTwoFAMutation({ onError: toastErrorMessage });
  const { data, loading, error } = useTwoFaQrCodeQuery();

  const initialValues: InitTwoFAFormValues = { code: '' };

  const onSubmit = useCallback(
    async (values: InitTwoFAFormValues) => {
      await enableTwoFA({ variables: { code: values.code } });
    },
    [enableTwoFA],
  );

  const qrCodeUrl = useMemo(() => {
    let url = '';
    if (!data?.twoFaQrCode) return url;
    QRCode.toDataURL(data.twoFaQrCode, (err, imageData) => {
      url = imageData;
    });
    return url;
  }, [data?.twoFaQrCode]);

  if (error) {
    return <Alert>{grabErrorMessage(error)}</Alert>;
  }

  return (
    <Row className="justify-content-center h-100">
      <Col md={6} lg={5} xxl={4} className="my-auto">
        <PageTitle className="text-center">{t('pageTitles.initTwoFA')}</PageTitle>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minWidth: '228px', minHeight: '228px' }}
        >
          {loading ? <Loading position="center" /> : <img src={qrCodeUrl} alt="QR code" />}
        </div>
        <Formik {...{ initialValues, onSubmit }}>
          {({ isSubmitting }) => (
            <Form>
              <FastField component={OtpInput} label="auth.otp.code" name="code" />
              <Stack gap={3} direction="horizontal" className="justify-content-between">
                <Button disabled={isSubmitting} variant="outline-primary" onClick={() => navigate(routePaths.logout)}>
                  <FontAwesomeIcon icon={faAngleLeft} /> {t('buttons.cancel')}
                </Button>
                <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  {t('buttons.submit')} <FontAwesomeIcon icon={faAngleRight} />
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={6} lg={5} xxl={4} className="my-auto">
        <Trans
          components={{
            strong: <strong className="mb-3 d-block text-center" />,
            ol: <ol className="p-0 ms-4" />,
            li: <li />,
            div: <div className="flex-1" />,
          }}
        >
          {t('auth.otp.qrCodeInstructions')}
        </Trans>
      </Col>
    </Row>
  );
};

export default InitTwoFAPage;
