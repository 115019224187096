import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import s from './SearchTenants.module.sass';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { toastErrorMessage } from 'utils/helpers';
import TextInput from 'components/inputs/TextInput';
import Button from 'components/common/Button';

interface IFormValues {
  searchQuery: string;
}

const SearchTenants: FC = () => {
  const { t } = useTranslation();
  const { searchParams, setSearchParams } = useGetSearchParams('searchQuery');

  const initialValues: IFormValues = { searchQuery: searchParams.searchQuery || '' };

  const onSubmit = useCallback(
    async (values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
      try {
        const { searchQuery } = values;
        setSearchParams({ searchQuery });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [setSearchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit }}>
      {({ isSubmitting }) => (
        <Form className={s.form}>
          <FastField name="searchQuery" label="forms.searchByName" component={TextInput} />
          <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="outline-primary">
            {t('buttons.search')} <FontAwesomeIcon icon={faMagnifyingGlass} />
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SearchTenants;
