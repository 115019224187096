import React from 'react';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { Alert } from 'react-bootstrap';
import useProjectPdfRequests from 'hooks/projectPdfRequest/useProjectPdfRequests';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import { grabErrorMessage } from 'utils/helpers';
import ProjectPdfRequestsFilter from 'components/projectPdfRequsts/ProjectPdfRequestsFilter';
import ProjectPdfRequestsTable from 'components/projectPdfRequsts/ProjectPdfRequestsTable';

const ProjectPdfRequestsPage = () => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'projectPdfRequestsPage' });
  const { projectPdfRequests, loading, total, error } = useProjectPdfRequests({ currentPage });

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.projectPdfRequests') }]} />
      <PageTitle>{t('pageTitles.projectPdfRequests')}</PageTitle>
      <ProjectPdfRequestsFilter {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <ProjectPdfRequestsTable {...{ projectPdfRequests, currentPage, changePage, total, loading }} /> : null}
    </>
  );
};

export default ProjectPdfRequestsPage;
