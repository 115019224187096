import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateNewBuildingPlanningItemsMutationVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
  planningItems: Array<SchemaTypes.IframePlanningItemInput>;
}>;

export type UpdateNewBuildingPlanningItemsMutation = {
  __typename?: 'Mutation';
  updateNewBuildingPlanningItems: boolean;
};

export const UpdateNewBuildingPlanningItemsDocument = gql`
  mutation UpdateNewBuildingPlanningItems($iframeId: ID!, $planningItems: [IframePlanningItemInput!]!) {
    updateNewBuildingPlanningItems(iframeId: $iframeId, planningItems: $planningItems)
  }
`;
export type UpdateNewBuildingPlanningItemsMutationFn = Apollo.MutationFunction<
  UpdateNewBuildingPlanningItemsMutation,
  UpdateNewBuildingPlanningItemsMutationVariables
>;

/**
 * __useUpdateNewBuildingPlanningItemsMutation__
 *
 * To run a mutation, you first call `useUpdateNewBuildingPlanningItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewBuildingPlanningItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewBuildingPlanningItemsMutation, { data, loading, error }] = useUpdateNewBuildingPlanningItemsMutation({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *      planningItems: // value for 'planningItems'
 *   },
 * });
 */
export function useUpdateNewBuildingPlanningItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNewBuildingPlanningItemsMutation,
    UpdateNewBuildingPlanningItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNewBuildingPlanningItemsMutation, UpdateNewBuildingPlanningItemsMutationVariables>(
    UpdateNewBuildingPlanningItemsDocument,
    options,
  );
}
export type UpdateNewBuildingPlanningItemsMutationHookResult = ReturnType<
  typeof useUpdateNewBuildingPlanningItemsMutation
>;
export type UpdateNewBuildingPlanningItemsMutationResult =
  Apollo.MutationResult<UpdateNewBuildingPlanningItemsMutation>;
export type UpdateNewBuildingPlanningItemsMutationOptions = Apollo.BaseMutationOptions<
  UpdateNewBuildingPlanningItemsMutation,
  UpdateNewBuildingPlanningItemsMutationVariables
>;
