import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvitationRedemptionBriefQueryVariables = SchemaTypes.Exact<{
  invitationId: SchemaTypes.Scalars['ID']['input'];
}>;

export type InvitationRedemptionBriefQuery = {
  __typename?: 'Query';
  invitationRedemptionBrief: { __typename?: 'InvitationRedemptionBrief'; _id: string; email: string };
};

export const InvitationRedemptionBriefDocument = gql`
  query InvitationRedemptionBrief($invitationId: ID!) {
    invitationRedemptionBrief(invitationId: $invitationId) {
      _id
      email
    }
  }
`;

/**
 * __useInvitationRedemptionBriefQuery__
 *
 * To run a query within a React component, call `useInvitationRedemptionBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationRedemptionBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationRedemptionBriefQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useInvitationRedemptionBriefQuery(
  baseOptions: Apollo.QueryHookOptions<InvitationRedemptionBriefQuery, InvitationRedemptionBriefQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvitationRedemptionBriefQuery, InvitationRedemptionBriefQueryVariables>(
    InvitationRedemptionBriefDocument,
    options,
  );
}
export function useInvitationRedemptionBriefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvitationRedemptionBriefQuery, InvitationRedemptionBriefQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvitationRedemptionBriefQuery, InvitationRedemptionBriefQueryVariables>(
    InvitationRedemptionBriefDocument,
    options,
  );
}
export type InvitationRedemptionBriefQueryHookResult = ReturnType<typeof useInvitationRedemptionBriefQuery>;
export type InvitationRedemptionBriefLazyQueryHookResult = ReturnType<typeof useInvitationRedemptionBriefLazyQuery>;
export type InvitationRedemptionBriefQueryResult = Apollo.QueryResult<
  InvitationRedemptionBriefQuery,
  InvitationRedemptionBriefQueryVariables
>;
export function refetchInvitationRedemptionBriefQuery(variables: InvitationRedemptionBriefQueryVariables) {
  return { query: InvitationRedemptionBriefDocument, variables: variables };
}
