import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { EnergyRenovationItem } from 'graphql/types.generated';
import { Co2SavingsReportByProjectQuery } from 'graphql/queries/co2Savings/generated/CO2SavingsReportByProject';

type Co2SavingsReportByProjectItem = First<Co2SavingsReportByProjectQuery['co2SavingsReportByProject']['items']>;
interface iCO2ReportsByProjectTableProps extends IPaginationProps {
  co2Reports: Co2SavingsReportByProjectItem[];
  loading: boolean;
}

interface Co2SavingsReportByProjectRow {
  name: string;
  newWindows?: EnergyRenovationItem;
  ceilingTopInsulation?: EnergyRenovationItem;
  ceilingBasementInsulation?: EnergyRenovationItem;
  facadeInsulation?: EnergyRenovationItem;
  domesticVentilation?: EnergyRenovationItem;
  solarPowerSystem?: EnergyRenovationItem;
  heatingSystem?: EnergyRenovationItem;
}

const CO2ReportsByProjectTable: FC<iCO2ReportsByProjectTableProps> = ({
  co2Reports,
  currentPage,
  changePage,
  total,
  loading,
}) => {
  const { t } = useTranslation();

  const data: Co2SavingsReportByProjectRow[] = useMemo(() => {
    return co2Reports.map((co2Report: Co2SavingsReportByProjectItem) => {
      const {
        name,
        energyRenovations: {
          newWindows,
          ceilingTopInsulation,
          ceilingBasementInsulation,
          facadeInsulation,
          domesticVentilation,
          solarPowerSystem,
          heatingSystem,
        },
      } = co2Report;

      const defaultRenovationValues = {
        co2Savings: 0,
        energyCostSavings: 0,
      };

      return {
        name,
        newWindows: newWindows || defaultRenovationValues,
        ceilingTopInsulation: ceilingTopInsulation || defaultRenovationValues,
        ceilingBasementInsulation: ceilingBasementInsulation || defaultRenovationValues,
        facadeInsulation: facadeInsulation || defaultRenovationValues,
        domesticVentilation: domesticVentilation || defaultRenovationValues,
        solarPowerSystem: solarPowerSystem || defaultRenovationValues,
        heatingSystem: heatingSystem || defaultRenovationValues,
      };
    });
  }, [co2Reports]);

  const columns: ColumnDef<Co2SavingsReportByProjectRow>[] = useMemo(
    () => [
      {
        header: t('co2Reports.tableHeaders.name'),
        accessorKey: 'name',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.newWindows'),
        accessorKey: 'newWindows.co2Savings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.newWindowsCostSavings'),
        accessorKey: 'newWindows.energyCostSavings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.ceilingTopInsulation'),
        accessorKey: 'ceilingTopInsulation.co2Savings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.ceilingTopInsulationCostSavings'),
        accessorKey: 'ceilingTopInsulation.energyCostSavings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.ceilingBasementInsulation'),
        accessorKey: 'ceilingBasementInsulation.co2Savings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.ceilingBasementInsulationCostSavings'),
        accessorKey: 'ceilingBasementInsulation.energyCostSavings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.facadeInsulation'),
        accessorKey: 'facadeInsulation.co2Savings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.facadeInsulationCostSavings'),
        accessorKey: 'facadeInsulation.energyCostSavings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.domesticVentilation'),
        accessorKey: 'domesticVentilation.co2Savings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.domesticVentilationCostSavings'),
        accessorKey: 'domesticVentilation.energyCostSavings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.solarPowerSystem'),
        accessorKey: 'solarPowerSystem.co2Savings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.solarPowerSystemCostSavings'),
        accessorKey: 'solarPowerSystem.energyCostSavings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.heatingSystem'),
        accessorKey: 'heatingSystem.co2Savings',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('co2Reports.renovations.heatingSystemCostSavings'),
        accessorKey: 'heatingSystem.energyCostSavings',
        cell: (info) => info.getValue() as string,
      },
    ],
    [t],
  );

  return (
    <>
      <Table columns={columns} data={data} loading={loading} emptyText="co2Reports.noReportsFound.description" />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default CO2ReportsByProjectTable;
