import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { Badge } from 'react-bootstrap';
import { routePaths } from 'router/routes';
import { generatePath, useNavigate } from 'react-router-dom';
import { ApiLogsQuery } from 'graphql/queries/apiCallLog/generated/ApiLogs';

export type ApiLogsListItem = First<ApiLogsQuery['apiLogs']['items']>;
interface iMeinBauApiLogsTableProps extends IPaginationProps {
  apiLogs: ApiLogsListItem[];
  loading: boolean;
}

export type ApiLogsTableTableRow = ApiLogsListItem;

const MeinBauApiLogsTable: FC<iMeinBauApiLogsTableProps> = ({ apiLogs, currentPage, changePage, total, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data: ApiLogsTableTableRow[] = useMemo(() => {
    return apiLogs.map((apiLogs: ApiLogsListItem) => {
      const { _id, apiName, data, details, eventType, method, params, statusCode, url } = apiLogs;
      return {
        _id,
        apiName,
        data,
        details,
        eventType,
        method,
        params,
        statusCode,
        url,
      };
    });
  }, [apiLogs]);

  const columns: ColumnDef<ApiLogsTableTableRow>[] = useMemo(
    () => [
      {
        header: t('apiLogs.tableHeaders.apiName'),
        accessorKey: 'apiName',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('apiLogs.tableHeaders.url'),
        accessorKey: 'url',
        cell: (info) => <Badge>{info.getValue() as string}</Badge>,
      },
      {
        header: t('apiLogs.tableHeaders.data'),
        accessorKey: 'data',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('apiLogs.tableHeaders.statusCode'),
        accessorKey: 'statusCode',
        cell: (info) => <Badge>{info.getValue() as string}</Badge>,
      },
      {
        header: t('apiLogs.tableHeaders.eventType'),
        accessorKey: 'eventType',
        cell: (info) => <Badge>{info.getValue() as string}</Badge>,
      },
      {
        header: t('apiLogs.tableHeaders.method'),
        accessorKey: 'method',
        cell: (info) => <Badge>{info.getValue() as string}</Badge>,
      },
    ],
    [t],
  );

  const onRowClick = useCallback(
    (row: ApiLogsTableTableRow) => {
      navigate(generatePath(routePaths.apiCallLog, { apiLogId: row._id }));
    },
    [navigate],
  );

  return (
    <>
      <Table
        getRowProps={(row) => ({
          className: 'clickableRow',
          onClick: () => onRowClick(row.original),
        })}
        columns={columns}
        data={data}
        loading={loading}
        emptyText="auditLog.noLogsFound.description"
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default MeinBauApiLogsTable;
