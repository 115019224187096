import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { MeFragmentDoc } from '../../../fragments/user/generated/MeFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllUserTenantsQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  searchQuery: SchemaTypes.Scalars['String']['input'];
}>;

export type AllUserTenantsQuery = {
  __typename?: 'Query';
  allUserTenants: {
    __typename?: 'PaginatedTenantResponse';
    total: number;
    items: Array<{ __typename?: 'Tenant'; _id: string; companyName: string }>;
  };
  me: {
    __typename?: 'User';
    _id: string;
    email: string;
    role?: SchemaTypes.Roles | null;
    tenantId?: string | null;
    isTwoFAEnabled?: boolean | null;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    activeRoles: Array<{ __typename?: 'UserRoleClass'; role: SchemaTypes.Roles; tenantId?: string | null }>;
  };
};

export const AllUserTenantsDocument = gql`
  query AllUserTenants($limit: Int!, $skip: Int!, $searchQuery: String!) {
    allUserTenants(limit: $limit, skip: $skip, searchQuery: $searchQuery) {
      total
      items {
        _id
        companyName
      }
    }
    me {
      ...Me
    }
  }
  ${MeFragmentDoc}
`;

/**
 * __useAllUserTenantsQuery__
 *
 * To run a query within a React component, call `useAllUserTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserTenantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useAllUserTenantsQuery(
  baseOptions: Apollo.QueryHookOptions<AllUserTenantsQuery, AllUserTenantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllUserTenantsQuery, AllUserTenantsQueryVariables>(AllUserTenantsDocument, options);
}
export function useAllUserTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllUserTenantsQuery, AllUserTenantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllUserTenantsQuery, AllUserTenantsQueryVariables>(AllUserTenantsDocument, options);
}
export type AllUserTenantsQueryHookResult = ReturnType<typeof useAllUserTenantsQuery>;
export type AllUserTenantsLazyQueryHookResult = ReturnType<typeof useAllUserTenantsLazyQuery>;
export type AllUserTenantsQueryResult = Apollo.QueryResult<AllUserTenantsQuery, AllUserTenantsQueryVariables>;
export function refetchAllUserTenantsQuery(variables: AllUserTenantsQueryVariables) {
  return { query: AllUserTenantsDocument, variables: variables };
}
