import React from 'react';
import LoginPage from '../pages/auth/LoginPage';
import { routePaths } from './routes';
import AuthLayout from '../components/layouts/AuthLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage';
import ResetPasswordPage from 'pages/auth/ResetPasswordPage';
import RedeemInvitationPage from 'pages/auth/RedeemInvitation';

export const authRoutes = [
  {
    path: routePaths.login,
    component: LoginPage,
  },
  {
    path: routePaths.forgotPassword,
    component: ForgotPasswordPage,
  },
  {
    path: routePaths.resetPassword,
    component: ResetPasswordPage,
  },
  {
    path: routePaths.redeemInvitation,
    component: RedeemInvitationPage,
  },
];

const AuthRouter = () => {
  return (
    <AuthLayout>
      <Routes>
        {authRoutes.map(({ path, component: C }) => (
          <Route key={path} path={path} element={<C />} />
        ))}
        <Route path="*" element={<Navigate to={routePaths.login} />} />
      </Routes>
    </AuthLayout>
  );
};

export default AuthRouter;
