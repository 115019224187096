import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { UserFragmentDoc } from '../../../fragments/user/generated/UserFragment';
import { BasicUserAttributesFragmentDoc } from '../../../fragments/user/generated/BasicUserAttribuesFragment';
import { InvitationFragmentDoc } from '../../../fragments/invitation/generated/InvitationFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserManagementQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
  invitationsFilter: SchemaTypes.InvitationsFilter;
  usersFilter: SchemaTypes.UsersFilter;
}>;

export type UserManagementQuery = {
  __typename?: 'Query';
  allUsers: {
    __typename?: 'PaginatedUserResponse';
    total: number;
    items: Array<{
      __typename?: 'User';
      _id: string;
      email: string;
      role?: SchemaTypes.Roles | null;
      isTwoFAEnabled?: boolean | null;
      createdAt: any;
      inviter?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    }>;
  };
  allInvitations: {
    __typename?: 'PaginatedInvitationResponse';
    total: number;
    plainTotal?: number | null;
    items: Array<{
      __typename?: 'Invitation';
      _id: string;
      role: SchemaTypes.Roles;
      email: string;
      createdAt: any;
      isDraft?: boolean | null;
      profile: { __typename?: 'UserProfile'; lastName: string; firstName: string };
      inviter?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
    }>;
  };
};

export const UserManagementDocument = gql`
  query UserManagement(
    $limit: Int!
    $skip: Int!
    $tenantId: ID
    $invitationsFilter: InvitationsFilter!
    $usersFilter: UsersFilter!
  ) {
    allUsers(limit: $limit, skip: $skip, tenantId: $tenantId, filter: $usersFilter) {
      total
      items {
        ...User
        inviter {
          ...BasicUserAttributes
        }
      }
    }
    allInvitations(limit: $limit, skip: $skip, tenantId: $tenantId, filter: $invitationsFilter) {
      total
      plainTotal
      items {
        ...Invitation
      }
    }
  }
  ${UserFragmentDoc}
  ${BasicUserAttributesFragmentDoc}
  ${InvitationFragmentDoc}
`;

/**
 * __useUserManagementQuery__
 *
 * To run a query within a React component, call `useUserManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserManagementQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      tenantId: // value for 'tenantId'
 *      invitationsFilter: // value for 'invitationsFilter'
 *      usersFilter: // value for 'usersFilter'
 *   },
 * });
 */
export function useUserManagementQuery(
  baseOptions: Apollo.QueryHookOptions<UserManagementQuery, UserManagementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserManagementQuery, UserManagementQueryVariables>(UserManagementDocument, options);
}
export function useUserManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserManagementQuery, UserManagementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserManagementQuery, UserManagementQueryVariables>(UserManagementDocument, options);
}
export type UserManagementQueryHookResult = ReturnType<typeof useUserManagementQuery>;
export type UserManagementLazyQueryHookResult = ReturnType<typeof useUserManagementLazyQuery>;
export type UserManagementQueryResult = Apollo.QueryResult<UserManagementQuery, UserManagementQueryVariables>;
export function refetchUserManagementQuery(variables: UserManagementQueryVariables) {
  return { query: UserManagementDocument, variables: variables };
}
