import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Modal, useModal } from 'components/common/Modal';
import { useDisablePriceHubbleIntegrationIframeMutation } from 'graphql/mutations/priceHubble/generated/DisablePriceHubbleIntegrationIframe';
import { toastErrorMessage } from 'utils/helpers';
import { confirmAlert } from 'components/common/Alert';
import IframePriceHubbleForm from './IframePriceHubbleForm';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';

interface IPriceHubbleButtonParams {
  iframe: IframeQuery['iframe'];
}

const PriceHubbleButton: FC<IPriceHubbleButtonParams> = ({ iframe }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'iframe' });
  const { showModal, isVisible, hideModal } = useModal();
  const [disablePriceHubbleIntegration] = useDisablePriceHubbleIntegrationIframeMutation({
    onError: toastErrorMessage,
  });
  const onPriceHubbleIntegrateClick = useCallback(() => {
    showModal();
  }, [showModal]);

  const onDisablePriceHubbleIntegration = useCallback(async () => {
    confirmAlert({
      onConfirm: async () => {
        await disablePriceHubbleIntegration({
          variables: { iframeId: iframe._id },
        });
      },
      title: t('alert.disableIntegrationHeader'),
      message: t('alert.disableIntegrationAlert'),
    });
  }, [disablePriceHubbleIntegration, iframe._id, t]);

  return (
    <>
      <Button
        className="me-1 ms-auto"
        variant={iframe.priceHubbleConfig?.isEnabled ? 'danger' : 'primary'}
        onClick={iframe.priceHubbleConfig?.isEnabled ? onDisablePriceHubbleIntegration : onPriceHubbleIntegrateClick}
      >
        {iframe.priceHubbleConfig?.isEnabled ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faKey} />}
      </Button>
      <Modal
        isVisible={isVisible}
        onHide={hideModal}
        headerCloseButton
        headerText={t('integrationWithPriceHubbleModal')}
        size="lg"
      >
        <>
          <IframePriceHubbleForm iframeId={iframe._id} hideModal={hideModal} />
        </>
      </Modal>
    </>
  );
};

export default PriceHubbleButton;
