import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserAccountMutationVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['ID']['input'];
  input: SchemaTypes.UpdateUserAccountInput;
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
}>;

export type UpdateUserAccountMutation = {
  __typename?: 'Mutation';
  updateUserAccount: {
    __typename?: 'User';
    _id: string;
    role?: SchemaTypes.Roles | null;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  };
};

export const UpdateUserAccountDocument = gql`
  mutation UpdateUserAccount($userId: ID!, $input: UpdateUserAccountInput!, $tenantId: ID) {
    updateUserAccount(userId: $userId, input: $input, tenantId: $tenantId) {
      _id
      role
      profile {
        firstName
        lastName
      }
    }
  }
`;
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(
    UpdateUserAccountDocument,
    options,
  );
}
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = Apollo.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>;
