import React, { useCallback, useMemo, useState } from 'react';
import { FastField, Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import ScrollToError from 'components/common/ScrollToError';
import { MenuPortalTarget } from 'components/inputs/Select/useMenuPortalTarget';
import TextInput from 'components/inputs/TextInput';
import {
  CreateApplicationAccountMutationVariables,
  useCreateApplicationAccountMutation,
} from 'graphql/mutations/application/generated/CreateApplicationAccount';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Card, ListGroup, Stack } from 'react-bootstrap';
import useGetSearchParams from 'hooks/useGetSearchParams';
import Select from 'components/inputs/Select';
import { useTenantsIdsQuery } from 'graphql/queries/tenant/generated/TenantIds';
import { copyToClipBoard } from 'utils/helpers';
import SubmitButton from 'components/common/Button/SubmitButton';
import { useTenantsQuery } from 'graphql/queries/tenant/generated/Tenants';
import { TenantStatusFilter } from 'graphql/types.generated';

const validationSchema = yup.object().shape({
  appName: yup.string().required('errors.requiredField'),
});

interface CreateApplicationAccountFormProps {
  hideModal: () => void;
  onCreateRefetch: (searchQuery: string) => Promise<void>;
}

const CreateApplicationAccountForm: FC<CreateApplicationAccountFormProps> = ({ hideModal, onCreateRefetch }) => {
  const { t } = useTranslation();
  const { setSearchParams } = useGetSearchParams();
  const [createApplicationAccount] = useCreateApplicationAccountMutation();

  const { data } = useTenantsQuery({
    variables: { limit: 20, skip: 0, status: TenantStatusFilter.ACTIVE, searchString: '' },
    fetchPolicy: 'cache-and-network',
  });

  const tenantSelectData = useMemo(() => {
    return data?.tenants.items?.map((tenant) => {
      return {
        label: tenant.companyName,
        value: tenant._id,
      };
    });
  }, [data?.tenants.items]);

  const [applicationData, setApplicationData] = useState<{
    applicationId: string | undefined;
    secret: string | undefined;
  }>();

  const initialValues: CreateApplicationAccountMutationVariables = {
    appName: '',
  };

  const onCloseBtn = useCallback(() => {
    hideModal();
  }, [hideModal]);

  const onSubmit = useCallback(
    async (values: CreateApplicationAccountMutationVariables) => {
      const data = await createApplicationAccount({
        variables: {
          appName: values.appName,
          tenantId: values.tenantId || null,
        },
      });
      setSearchParams({
        applicationsSearchQuery: '',
      });
      onCreateRefetch('');
      setApplicationData({
        applicationId: data.data?.createApplicationAccount._id,
        secret: data.data?.createApplicationAccount.secret,
      });
    },
    [createApplicationAccount, onCreateRefetch, setSearchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {() => (
        <Form>
          <ScrollToError inModal />
          <FastField
            name="appName"
            component={TextInput}
            label="applicationManagement.modal.appName"
            menuPortalTarget={MenuPortalTarget.modal}
          />
          {tenantSelectData?.length && (
            <Field
              name="tenantId"
              label="applicationManagement.modal.tenant.label"
              isClearable
              component={Select}
              menuPortalTarget={MenuPortalTarget.modal}
              options={tenantSelectData}
            />
          )}
          {applicationData && (
            <ListGroup variant="flush" className="mb-3">
              <ListGroup.Item>
                <Card.Text className="fw-bold mb-1">{t('applicationManagement.modal.applicationId')}</Card.Text>
                <Stack direction="horizontal" gap={3}>
                  {applicationData.applicationId}
                  <Button
                    className="ms-auto"
                    onClick={() =>
                      copyToClipBoard(
                        applicationData.applicationId as string,
                        t('applicationManagement.modal.copiedToClipboard'),
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Stack>
              </ListGroup.Item>
              <ListGroup.Item>
                <Card.Text className="fw-bold mb-1">{t('applicationManagement.modal.password')}</Card.Text>
                <Stack direction="horizontal" gap={3}>
                  {applicationData.secret}
                  <Button
                    className="ms-auto"
                    onClick={() =>
                      copyToClipBoard(
                        applicationData.secret as string,
                        t('applicationManagement.modal.copiedToClipboard'),
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Stack>
              </ListGroup.Item>
            </ListGroup>
          )}
          <Stack direction="horizontal" className="justify-content-end" gap={3}>
            <Button variant="secondary" onClick={onCloseBtn}>
              {t('applicationManagement.modal.close')} <FontAwesomeIcon icon={faXmark} />
            </Button>
            {!applicationData && <SubmitButton label={'applicationManagement.modal.create'} icon={faPlus} />}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default CreateApplicationAccountForm;
