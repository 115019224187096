import React from 'react';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import Loading from 'components/common/Loading/Loading';
import PageTitle from 'components/common/PageTitle';
import MeinBauApiLogsData from 'components/meinBauApiLogs/MeinBauApiLogsData';
import { MeinBauApiLog, Roles } from 'graphql/types.generated';
import useMeinBauApiLog from 'hooks/meinBauApiLogs/useMeinBauApiLog';
import { Alert, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { formatDate, grabErrorMessage } from 'utils/helpers';
import { routePaths } from 'router/routes';
import i18n from 'i18n';
import useUser from 'hooks/user/useUser';

const MeinBauApiLogPage = () => {
  const { t } = useTranslation();
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const navigate = useNavigate();
  const { meinBauApiLogId } = useParams();
  const { meinBauApiLog, error, loading } = useMeinBauApiLog({ meinBauApiLogId: meinBauApiLogId as string });

  return (
    <>
      <BreadcrumbBuilder
        pathLinks={[
          {
            text: t('pageTitles.meinBauApiLogs'),
            onClick: () => {
              if (me?.role === Roles.TENANT_ADMIN) {
                navigate(
                  generatePath(routePaths.tenantMeinBauApiLogs, {
                    tenantId: (me?.tenantId || me.activeRoles[0].tenantId) as string | undefined,
                  }),
                );
              } else {
                navigate(routePaths.meinBauApiLogs);
              }
            },
          },
          {
            style: { minWidth: '500px' },
            text: (
              <>
                {!loading && meinBauApiLog ? (
                  <div className="fw-bold">
                    {(meinBauApiLog as MeinBauApiLog).method}-{(meinBauApiLog as MeinBauApiLog).useCase}-
                    {formatDate({ date: (meinBauApiLog as MeinBauApiLog).createdAt, locale: i18n.language })}
                  </div>
                ) : (
                  <Placeholder animation="glow">
                    <Placeholder xs={11} />
                  </Placeholder>
                )}
              </>
            ),
          },
        ]}
      />
      <PageTitle>{t('meinBauApiLog.meinBauApiLogPageTitle')}</PageTitle>
      {loading ? <Loading /> : null}
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!loading && !error && meinBauApiLog ? (
        <MeinBauApiLogsData meinBauApiLog={meinBauApiLog as MeinBauApiLog} />
      ) : null}
    </>
  );
};

export default MeinBauApiLogPage;
