import React from 'react';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import Loading from 'components/common/Loading/Loading';
import PageTitle from 'components/common/PageTitle';
import { ApiLog } from 'graphql/types.generated';
import { Alert, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { grabErrorMessage } from 'utils/helpers';
import { routePaths } from 'router/routes';
import useApiCallLog from 'hooks/apiCallLogs/useApiCallLog';
import ApiLogsData from 'components/apiCallLogs/ApiLogsData';

const ApiCallLogPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { apiLogId } = useParams();
  const { apiLog, error, loading } = useApiCallLog({ apiLogId: apiLogId as string });

  return (
    <>
      <BreadcrumbBuilder
        pathLinks={[
          { text: t('pageTitles.apiCallLogs'), onClick: () => navigate(routePaths.apiCallLogs) },
          {
            style: { minWidth: '500px' },
            text: (
              <>
                {!loading && apiLog ? (
                  <div className="fw-bold">
                    {(apiLog as ApiLog).method}-{(apiLog as ApiLog).apiName}
                  </div>
                ) : (
                  <Placeholder animation="glow">
                    <Placeholder xs={11} />
                  </Placeholder>
                )}
              </>
            ),
          },
        ]}
      />
      <PageTitle>{t('apiLog.apiLogPageTitle')}</PageTitle>
      {loading ? <Loading /> : null}
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!loading && !error && apiLog ? <ApiLogsData apiLog={apiLog as ApiLog} /> : null}
    </>
  );
};

export default ApiCallLogPage;
