import React, { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageTitle from 'components/common/PageTitle/PageTitle';
import ResetPasswordForm, { ResetPasswordFormValues } from 'components/auth/ResetPasswordForm';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { routePaths } from 'router/routes';
import { useResetPasswordMutation } from 'graphql/mutations/auth/generated/ResetPassword';
import { toastErrorMessage } from 'utils/helpers';
import { toast } from 'react-toastify';

interface IResetPasswordPage {
  token: string;
}

const ResetPasswordPage: FC<IResetPasswordPage> = ({ token }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation({ onError: toastErrorMessage });

  const initialValues: ResetPasswordFormValues = { password: '', repeatedPassword: '' };

  const onSubmit = useCallback(
    async ({ password }: ResetPasswordFormValues) => {
      const res = await resetPassword({ variables: { token, password } });
      if (res.data?.resetPassword) {
        toast.success(t('auth.resetPassword.passwordChangedSuccessfully'));
        navigate(routePaths.login);
      }
    },
    [navigate, resetPassword, t, token],
  );

  return (
    <Row className="h-100">
      <Col md={6} xl={5} className="m-auto">
        <PageTitle className="text-center">{t('pageTitles.resetPassword')}</PageTitle>
        <ResetPasswordForm {...{ initialValues, onSubmit }} />
      </Col>
    </Row>
  );
};

const ResetPasswordContainer = () => {
  const {
    searchParams: { token },
  } = useGetSearchParams('token');

  if (!token) return <Navigate to={routePaths.login} />;

  return <ResetPasswordPage {...{ token }} />;
};

export default ResetPasswordContainer;
