import React, { useCallback, useMemo } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import s from './NavbarDropdownMenu.module.sass';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routePaths } from 'router/routes';
import useUser from 'hooks/user/useUser';
import { faBuildingUser, faEnvelope, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from 'utils/helpers';
import { useChangeLocaleMutation } from 'graphql/mutations/user/generated/ChangeLocale';
import { Locale, User } from 'graphql/types.generated';
import { Avatar } from 'components/common/Avatar';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [changeLocale] = useChangeLocaleMutation({ onError: toastErrorMessage });

  const handleChangeLocale = useCallback(
    async (locale: Locale) => {
      i18n.changeLanguage(locale);
      await changeLocale({ variables: { locale } });
    },
    [changeLocale, i18n],
  );

  return (
    <>
      {isMobile ? (
        <div className="d-flex">
          {Object.keys(Locale).map((locale) => (
            <button
              key={locale}
              className={`btn ${locale === i18n.language ? 'text-primary' : ''}`}
              onClick={() => handleChangeLocale(locale as Locale)}
            >
              {t(`locales.${locale}`)}
            </button>
          ))}
        </div>
      ) : (
        <DropdownButton
          drop="start"
          variant="light"
          className={s.languageDropdownMenu}
          title={<>{t(`locales.${i18n.language}`)}</>}
        >
          {Object.keys(Locale).map((locale) => (
            <Dropdown.Item
              key={locale}
              onClick={() => handleChangeLocale(locale as Locale)}
              disabled={locale === i18n.language}
            >
              {t(`locales.${locale}`)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
    </>
  );
};

const NavbarDropdownMenu: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const displayName = useMemo(
    () => `${me?.profile.firstName || ''} ${me?.profile.lastName || ''}`,
    [me?.profile.firstName, me?.profile.lastName],
  );

  return (
    <DropdownButton
      align={isMobile ? 'start' : 'end'}
      variant="light"
      className={s.dropdownButton}
      title={
        <div style={{ display: 'inline-flex' }}>
          <Avatar user={me as User} size={35} /> <span className="my-auto ms-2">{displayName}</span>
        </div>
      }
    >
      {me?.email ? (
        <Dropdown.ItemText className="text-nowrap">
          <FontAwesomeIcon icon={faEnvelope} className="me-1 text-warning" />
          {me?.email}
        </Dropdown.ItemText>
      ) : null}

      <Dropdown.Divider />
      <LanguageSwitcher />
      <Dropdown.Divider />

      {(me?.activeRoles?.length || 1) > 1 ? (
        <>
          <Dropdown.Item as={Link} to={routePaths.changeTenant} className="text-nowrap">
            <FontAwesomeIcon icon={faBuildingUser} className="me-1 text-secondary" />
            {t('pageTitles.changeTenant')}
          </Dropdown.Item>
          <Dropdown.Divider />
        </>
      ) : null}

      <Dropdown.Item as={Link} to={routePaths.logout} className="text-danger text-nowrap">
        <FontAwesomeIcon icon={faRightFromBracket} className="me-1" />
        {t('pageTitles.logout')}
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default NavbarDropdownMenu;
