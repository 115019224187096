import React from 'react';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import Loading from 'components/common/Loading/Loading';
import PageTitle from 'components/common/PageTitle';
import { AuditLogEventsClass } from 'graphql/types.generated';
import { Alert, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { grabErrorMessage } from 'utils/helpers';
import { routePaths } from 'router/routes';
import useAuditLog from 'hooks/auditLog/useAuditLog';
import AuditLogData from 'components/auditLogs/AuditLogData';

const AuditLogPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auditLogId } = useParams();
  const { auditLog, error, loading } = useAuditLog({ auditLogId: auditLogId as string });

  return (
    <>
      <BreadcrumbBuilder
        pathLinks={[
          {
            text: t('pageTitles.auditLogs'),
            onClick: () => {
              navigate(routePaths.auditLogs);
            },
          },
          {
            style: { minWidth: '500px' },
            text: (
              <>
                {!loading && auditLog ? (
                  <div className="fw-bold">{(auditLog as AuditLogEventsClass).name}</div>
                ) : (
                  <Placeholder animation="glow">
                    <Placeholder xs={11} />
                  </Placeholder>
                )}
              </>
            ),
          },
        ]}
      />
      <PageTitle>{t('auditLog.auditLogPageTitle')}</PageTitle>
      {loading ? <Loading /> : null}
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!loading && !error && auditLog ? <AuditLogData auditLog={auditLog as AuditLogEventsClass} /> : null}
    </>
  );
};

export default AuditLogPage;
