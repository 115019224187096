import React, { useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'components/common/Loading/LoadingOverlay';
import { useInvitationRedemptionBriefQuery } from 'graphql/queries/invitation/generated/InvitationRedemptionBrief';
import { routePaths } from 'router/routes';
import PageTitle from 'components/common/PageTitle/PageTitle';
import Button from 'components/common/Button/Button';
import RedeemInvitationForm, {
  IRedeemInvitationFormValues,
} from 'components/userManagement/invitations/RedeemInvitationForm/RedeemInvitationForm';
import useRedeemInvitation from 'hooks/invitation/useRedeemInvitation';

const RedeemInvitationPage: FC<{ invitationId: string }> = ({ invitationId }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useInvitationRedemptionBriefQuery({ variables: { invitationId } });
  const redeemInvitation = useRedeemInvitation();

  const onSubmit = useCallback(
    async (values: IRedeemInvitationFormValues) => {
      await redeemInvitation({ variables: { password: values.password, invitationId } });
    },
    [invitationId, redeemInvitation],
  );

  if (loading) return <LoadingOverlay />;
  if (!data?.invitationRedemptionBrief) return <Navigate to={routePaths.login} />;

  const { invitationRedemptionBrief: invitation } = data;

  const initialValues: IRedeemInvitationFormValues = {
    email: invitation.email ?? '',
    password: '',
    passwordRepeat: '',
    termsOfUseConsent: false,
  };

  return (
    <Row className="h-100">
      <Col md={8} lg={6} xl={5} xxl={4} className="m-auto">
        <PageTitle className="text-center">{t('pageTitles.redeemInvitation')}</PageTitle>
        {!loading && error ? (
          <Alert variant="danger" className="text-center mt-4">
            <p>{error.message}</p>
            <Link to={routePaths.login}>
              <Button variant="outline-danger">{t('auth.goToLogin')}</Button>
            </Link>
          </Alert>
        ) : null}
        {!loading && invitation ? <RedeemInvitationForm {...{ onSubmit, initialValues }} /> : null}
      </Col>
    </Row>
  );
};

const RedeemInvitationPageContainer = () => {
  const { invitationId } = useParams();
  if (!invitationId) return <Navigate to={routePaths.login} />;
  return <RedeemInvitationPage {...{ invitationId }} />;
};

export default RedeemInvitationPageContainer;
