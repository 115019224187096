import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { copyToClipBoard } from 'utils/helpers';

export interface IAuditLogPayloadItem {
  label: string;
  value: string;
}

interface IAuditPayloadTableProps {
  auditPayload: IAuditLogPayloadItem[];
}

export type AuditPayloadTableTableRow = IAuditLogPayloadItem;

const AuditLogPayloadTable: FC<IAuditPayloadTableProps> = ({ auditPayload }) => {
  const { t } = useTranslation();

  const data: AuditPayloadTableTableRow[] = useMemo(() => {
    return auditPayload.map((auditLog: IAuditLogPayloadItem) => {
      const { label, value } = auditLog;
      return {
        label,
        value,
      };
    });
  }, [auditPayload]);

  const columns: ColumnDef<AuditPayloadTableTableRow>[] = useMemo(
    () => [
      {
        header: t('auditLog.payload.tableHeaders.label'),
        accessorKey: 'label',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('auditLog.payload.tableHeaders.value'),
        accessorKey: 'value',
        cell: (info) => info.getValue(),
      },
    ],
    [t],
  );

  const onRowClick = useCallback(
    (row: AuditPayloadTableTableRow) => {
      copyToClipBoard(row.value, t('auditLog.valueCopiedToClipBoard', { label: row.label }));
    },
    [t],
  );

  return (
    <>
      <Table
        getRowProps={(row) => ({
          className: 'clickableRow',
          onClick: () => onRowClick(row.original),
        })}
        columns={columns}
        data={data}
      />
    </>
  );
};

export default AuditLogPayloadTable;
