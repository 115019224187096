import React, { useMemo } from 'react';
import { Cell as CellType, flexRender, Row } from '@tanstack/react-table';
import { ExpandedDepth, ExpandedRow } from './components/ExpandedWrapper';
import { getCommonPinningStyles } from './helpers';

interface iSideEffect<T> {
  sideEffect?: (params: Row<T>) => React.ReactNode;
}

export interface iReactTableRow<T extends object> extends Pick<ExpandedDepth<T>, 'expandedSpace'> {
  row: Row<T>;
  expandedFieldId?: string;
  getRowProps?: (
    row: Row<T>,
  ) =>
    | (iSideEffect<T> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>)
    | undefined;
}

interface CellProps<T> extends Pick<ExpandedDepth<T>, 'expandedSpace'> {
  isExpanded: boolean;
  row: Row<T>;
  cell: CellType<T, unknown>;
}

const Cell = <T,>({ isExpanded, row, cell, expandedSpace }: CellProps<T>) => {
  return (
    <ExpandedDepth expandedSpace={expandedSpace} row={row} isExpanded={isExpanded}>
      <ExpandedRow row={row} isExpanded={isExpanded}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </ExpandedRow>
    </ExpandedDepth>
  );
};

const ReactTableRow = <T extends object>({ row, expandedFieldId, getRowProps, expandedSpace }: iReactTableRow<T>) => {
  const { sideEffect, ...props } = useMemo<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> & iSideEffect<T>
  >(() => {
    if (!getRowProps) return {};
    const { className, ...props } = getRowProps(row) ?? {};
    return { className: className, ...props };
  }, [getRowProps, row]);

  return (
    <tr key={row.id} role="row" {...props}>
      {sideEffect && sideEffect(row)}
      {row.getVisibleCells().map((cell) => {
        const isExpanded = expandedFieldId === cell.column.id;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (cell.column.columnDef.meta?.isHeader) {
          return (
            <th
              scope="col"
              key={cell.id}
              style={{ ...getCommonPinningStyles(cell.column) }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              className={cell.column.columnDef.meta?.th?.className}
            >
              <Cell isExpanded={isExpanded} row={row} cell={cell} expandedSpace={expandedSpace} />
            </th>
          );
        }

        if (cell.column.columnDef.meta?.customTd) {
          return <Cell key={cell.id} isExpanded={isExpanded} row={row} cell={cell} expandedSpace={expandedSpace} />;
        }

        return (
          <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
            <Cell isExpanded={isExpanded} row={row} cell={cell} expandedSpace={expandedSpace} />
          </td>
        );
      })}
    </tr>
  );
};

export default ReactTableRow;
