import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import config from 'config/config';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';

interface IIframePreviewParams {
  iframe: IframeQuery['iframe'];
  projectId?: string;
  token?: string;
}

const IframePreview: FC<IIframePreviewParams> = ({ iframe, projectId, token }) => {
  const src = useMemo(() => {
    if (projectId && token) return `${config.iframeBaseUrl}/${iframe._id}?adminToken=${token}&projectId=${projectId}`;
    return `${config.iframeBaseUrl}/${iframe._id}`;
  }, [iframe._id, projectId, token]);

  return (
    <>
      <Row>
        <Col>
          <iframe
            style={{ width: '100%', height: '710px', border: '1px solid #dee2e6', borderRadius: '0.375rem' }}
            src={src}
          />
        </Col>
      </Row>
    </>
  );
};

export default IframePreview;
