import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type IframePlanningItemFragment = {
  __typename?: 'IframePlanningItem';
  parentId: string;
  name: SchemaTypes.ProjectFeature;
  parent: SchemaTypes.ProjectFeature;
  pricePerUnit?: number | null;
  equipmentTypes?: { __typename?: 'EquipmentTypes'; standard: number; premium: number; superior: number } | null;
  pricesPerUnit?: {
    __typename?: 'SolePlateOrBasementPricesPerUnit';
    solePlate?: number | null;
    basement?: number | null;
  } | null;
};

export type IframePlanningItemFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const IframePlanningItemFragmentDoc = gql`
  fragment IframePlanningItem on IframePlanningItem {
    parentId
    name
    parent
    equipmentTypes {
      standard
      premium
      superior
    }
    pricesPerUnit {
      ... on SolePlateOrBasementPricesPerUnit {
        solePlate
        basement
      }
    }
    pricePerUnit
  }
`;
