// eslint-disable react/jsx-key
import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

interface BreadcrumbBuilderParams {
  pathLinks?: Array<{
    text: string | React.ReactElement;
    style?: object;
    onClick?: () => void;
    isHide?: boolean;
  }>;
}

const BreadcrumbBuilder: FC<BreadcrumbBuilderParams> = ({ pathLinks }) => {
  const pathBuildedComponents = pathLinks?.map(({ text, onClick, style, isHide }, index) => {
    if (isHide) return null;
    return (
      <Breadcrumb.Item key={index} onClick={onClick} active={pathLinks.length === index + 1} style={style}>
        {text}
      </Breadcrumb.Item>
    );
  });
  return <Breadcrumb>{pathBuildedComponents}</Breadcrumb>;
};

export default BreadcrumbBuilder;
