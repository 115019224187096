import React from 'react';
import { FastField, Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import s from './LoginForm.module.sass';
import { useTranslation } from 'react-i18next';
import OtpInput from 'components/inputs/OtpInput/OtpInput';
import Button from 'components/common/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const validationSchema = yup.object().shape({
  code: yup.string().required('errors.requiredField'),
});

export interface IOTPFormValues {
  code: string;
}

interface iOTPFormProps {
  initialValues: IOTPFormValues;
  onSubmit: (values: IOTPFormValues, helpers: FormikHelpers<IOTPFormValues>) => void;
  onCancel?: () => void;
}

const OTPForm: FC<iOTPFormProps> = ({ initialValues, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {({ isSubmitting }) => (
        <Form>
          <FastField name="code" label="auth.otp.code" component={OtpInput} isDisabled={isSubmitting} />

          <div className={s.submitBtnWrapper}>
            {typeof onCancel === 'function' ? (
              <Button
                disabled={isSubmitting}
                type="button"
                onClick={onCancel}
                className="mx-1"
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faAngleLeft} /> {t('buttons.cancel')}
              </Button>
            ) : null}
            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" className="mx-1" id="otpFormSubmitBtn">
              {t('buttons.submit')} <FontAwesomeIcon icon={faAngleRight} />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OTPForm;
