import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TenantFragmentDoc } from '../../../fragments/tenant/generated/tenantFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTenantMutationVariables = SchemaTypes.Exact<{
  formData: SchemaTypes.TenantInput;
}>;

export type CreateTenantMutation = {
  __typename?: 'Mutation';
  createTenant: {
    __typename?: 'Tenant';
    _id: string;
    companyName: string;
    archived?: boolean | null;
    createdBy: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    };
    updatedBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
    changedStatusBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  };
};

export const CreateTenantDocument = gql`
  mutation CreateTenant($formData: TenantInput!) {
    createTenant(formData: $formData) {
      ...Tenant
    }
  }
  ${TenantFragmentDoc}
`;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      formData: // value for 'formData'
 *   },
 * });
 */
export function useCreateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
}
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<
  CreateTenantMutation,
  CreateTenantMutationVariables
>;
