import React, { CSSProperties } from 'react';
import { Card } from 'react-bootstrap';

const CardWithHeader: FC<{
  title?: string | React.ReactNode;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}> = ({ children, title, className, style = {} }) => {
  return (
    <Card className={className} style={style}>
      {title ? (
        <Card.Header>
          <h5 className="m-0">{title}</h5>
        </Card.Header>
      ) : null}

      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default CardWithHeader;
