import { QueryHookOptions } from '@apollo/client';
import { AuditLogQuery, AuditLogQueryVariables, useAuditLogQuery } from 'graphql/queries/auditLogs/generated/AuditLog';

interface IGetAuditLogProps extends QueryHookOptions<AuditLogQuery, AuditLogQueryVariables> {
  auditLogId: string;
}

export default function useAuditLog({ auditLogId }: IGetAuditLogProps) {
  const { data, loading, error } = useAuditLogQuery({ variables: { auditLogId }, skip: !auditLogId });
  return { auditLog: (data?.auditLog || null) as AuditLogQuery['auditLog'] | null, loading, error };
}
