import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Co2SavingsReportByProjectQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  searchString: SchemaTypes.Scalars['String']['input'];
  iframeId: SchemaTypes.Scalars['String']['input'];
}>;

export type Co2SavingsReportByProjectQuery = {
  __typename?: 'Query';
  co2SavingsReportByProject: {
    __typename?: 'PaginatedGetCO2SavingsByProjectsItemResponse';
    total: number;
    items: Array<{
      __typename?: 'GetCO2SavingsByProjectsItem';
      projectId: string;
      name: string;
      energyRenovations: {
        __typename?: 'EnergyRenovations';
        newWindows?: { __typename?: 'EnergyRenovationItem'; co2Savings: number; energyCostSavings: number } | null;
        ceilingTopInsulation?: {
          __typename?: 'EnergyRenovationItem';
          co2Savings: number;
          energyCostSavings: number;
        } | null;
        ceilingBasementInsulation?: {
          __typename?: 'EnergyRenovationItem';
          co2Savings: number;
          energyCostSavings: number;
        } | null;
        facadeInsulation?: {
          __typename?: 'EnergyRenovationItem';
          co2Savings: number;
          energyCostSavings: number;
        } | null;
        domesticVentilation?: {
          __typename?: 'EnergyRenovationItem';
          co2Savings: number;
          energyCostSavings: number;
        } | null;
        solarPowerSystem?: {
          __typename?: 'EnergyRenovationItem';
          co2Savings: number;
          energyCostSavings: number;
        } | null;
        heatingSystem?: { __typename?: 'EnergyRenovationItem'; co2Savings: number; energyCostSavings: number } | null;
      };
    }>;
  };
};

export const Co2SavingsReportByProjectDocument = gql`
  query Co2SavingsReportByProject($limit: Int!, $skip: Int!, $searchString: String!, $iframeId: String!) {
    co2SavingsReportByProject(limit: $limit, skip: $skip, searchString: $searchString, iframeId: $iframeId) {
      total
      items {
        projectId
        energyRenovations {
          newWindows {
            co2Savings
            energyCostSavings
          }
          ceilingTopInsulation {
            co2Savings
            energyCostSavings
          }
          ceilingBasementInsulation {
            co2Savings
            energyCostSavings
          }
          facadeInsulation {
            co2Savings
            energyCostSavings
          }
          domesticVentilation {
            co2Savings
            energyCostSavings
          }
          solarPowerSystem {
            co2Savings
            energyCostSavings
          }
          heatingSystem {
            co2Savings
            energyCostSavings
          }
        }
        name
      }
    }
  }
`;

/**
 * __useCo2SavingsReportByProjectQuery__
 *
 * To run a query within a React component, call `useCo2SavingsReportByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCo2SavingsReportByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCo2SavingsReportByProjectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      searchString: // value for 'searchString'
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useCo2SavingsReportByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<Co2SavingsReportByProjectQuery, Co2SavingsReportByProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Co2SavingsReportByProjectQuery, Co2SavingsReportByProjectQueryVariables>(
    Co2SavingsReportByProjectDocument,
    options,
  );
}
export function useCo2SavingsReportByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Co2SavingsReportByProjectQuery, Co2SavingsReportByProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Co2SavingsReportByProjectQuery, Co2SavingsReportByProjectQueryVariables>(
    Co2SavingsReportByProjectDocument,
    options,
  );
}
export type Co2SavingsReportByProjectQueryHookResult = ReturnType<typeof useCo2SavingsReportByProjectQuery>;
export type Co2SavingsReportByProjectLazyQueryHookResult = ReturnType<typeof useCo2SavingsReportByProjectLazyQuery>;
export type Co2SavingsReportByProjectQueryResult = Apollo.QueryResult<
  Co2SavingsReportByProjectQuery,
  Co2SavingsReportByProjectQueryVariables
>;
export function refetchCo2SavingsReportByProjectQuery(variables: Co2SavingsReportByProjectQueryVariables) {
  return { query: Co2SavingsReportByProjectDocument, variables: variables };
}
