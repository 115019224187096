import { ColumnDef } from '@tanstack/react-table';
import { CSSProperties } from 'react';
import { Column } from '@tanstack/react-table';

export const getColumnVisibility = <T extends object>(columns: ColumnDef<T>[]) => {
  return columns
    .filter(({ id }) => id)
    .reduce((acc, { id, enableHiding }) => ({ ...acc, [id as string]: !enableHiding }), {});
};

export const getColumnSize = <T>(column: Column<T>): CSSProperties => {
  const width = column.getSize();
  const minWidth = column.columnDef.minSize ? `${column.columnDef.minSize}px` : undefined;

  return {
    minWidth: minWidth || width,
    ...(column.columnDef.size ? { size: `${column.columnDef.size}px` } : {}),
    maxWidth: column.columnDef.maxSize ? `${column.columnDef.maxSize}px` : undefined,
  };
};

export const getCommonPinningStyles = <T>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  let boxShadow: string | undefined = undefined;
  if (isLastLeftPinnedColumn) boxShadow = '-3px 0 3px -3px gray inset';
  if (isFirstRightPinnedColumn) boxShadow = '3px 0 3px -3px gray inset';
  return {
    boxShadow,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? 1 : undefined,
  };
};
