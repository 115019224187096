import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import s from './RowSelectionColumn.module.sass';
import IndeterminateCheckbox from './IndeterminateCheckbox';

const getRowSelectionColumn = <T,>(): ColumnDef<T> => {
  const rowSelectionColumn: ColumnDef<T> = {
    id: 'rowSelection',
    minSize: 40,
    maxSize: 40,
    header: ({ table }) => {
      return (
        <div className={s.checkboxWrapper}>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      if (!row.getCanSelect()) return null;
      return (
        <div className={s.checkboxWrapper}>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  };
  return rowSelectionColumn;
};

export default getRowSelectionColumn;
