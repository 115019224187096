import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadFileRequestsMutationVariables = SchemaTypes.Exact<{
  files: Array<SchemaTypes.FileInput>;
  iframeId: SchemaTypes.Scalars['ID']['input'];
  tenantId: SchemaTypes.Scalars['ID']['input'];
}>;

export type UploadFileRequestsMutation = {
  __typename?: 'Mutation';
  uploadFileRequests: Array<{
    __typename?: 'UploadFileRequestResponseItem';
    _id: string;
    signedURL: string;
    webClientFileId: string;
  }>;
};

export const UploadFileRequestsDocument = gql`
  mutation UploadFileRequests($files: [FileInput!]!, $iframeId: ID!, $tenantId: ID!) {
    uploadFileRequests(files: $files, iframeId: $iframeId, tenantId: $tenantId) {
      _id
      signedURL
      webClientFileId
    }
  }
`;
export type UploadFileRequestsMutationFn = Apollo.MutationFunction<
  UploadFileRequestsMutation,
  UploadFileRequestsMutationVariables
>;

/**
 * __useUploadFileRequestsMutation__
 *
 * To run a mutation, you first call `useUploadFileRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileRequestsMutation, { data, loading, error }] = useUploadFileRequestsMutation({
 *   variables: {
 *      files: // value for 'files'
 *      iframeId: // value for 'iframeId'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useUploadFileRequestsMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadFileRequestsMutation, UploadFileRequestsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileRequestsMutation, UploadFileRequestsMutationVariables>(
    UploadFileRequestsDocument,
    options,
  );
}
export type UploadFileRequestsMutationHookResult = ReturnType<typeof useUploadFileRequestsMutation>;
export type UploadFileRequestsMutationResult = Apollo.MutationResult<UploadFileRequestsMutation>;
export type UploadFileRequestsMutationOptions = Apollo.BaseMutationOptions<
  UploadFileRequestsMutation,
  UploadFileRequestsMutationVariables
>;
