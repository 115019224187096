import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiLogQueryVariables = SchemaTypes.Exact<{
  apiLogId: SchemaTypes.Scalars['ID']['input'];
}>;

export type ApiLogQuery = {
  __typename?: 'Query';
  apiLog: {
    __typename?: 'ApiLog';
    _id: string;
    apiName: SchemaTypes.ApiName;
    data?: string | null;
    details?: string | null;
    eventType: SchemaTypes.EventType;
    method: string;
    params?: string | null;
    statusCode?: string | null;
    url?: string | null;
  };
};

export const ApiLogDocument = gql`
  query ApiLog($apiLogId: ID!) {
    apiLog(apiLogId: $apiLogId) {
      _id
      apiName
      data
      details
      eventType
      method
      params
      statusCode
      url
    }
  }
`;

/**
 * __useApiLogQuery__
 *
 * To run a query within a React component, call `useApiLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiLogQuery({
 *   variables: {
 *      apiLogId: // value for 'apiLogId'
 *   },
 * });
 */
export function useApiLogQuery(baseOptions: Apollo.QueryHookOptions<ApiLogQuery, ApiLogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiLogQuery, ApiLogQueryVariables>(ApiLogDocument, options);
}
export function useApiLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiLogQuery, ApiLogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiLogQuery, ApiLogQueryVariables>(ApiLogDocument, options);
}
export type ApiLogQueryHookResult = ReturnType<typeof useApiLogQuery>;
export type ApiLogLazyQueryHookResult = ReturnType<typeof useApiLogLazyQuery>;
export type ApiLogQueryResult = Apollo.QueryResult<ApiLogQuery, ApiLogQueryVariables>;
export function refetchApiLogQuery(variables: ApiLogQueryVariables) {
  return { query: ApiLogDocument, variables: variables };
}
