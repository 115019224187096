import { confirmAlert } from 'components/common/Alert';
import { useDeleteFileInstanceLinkMutation } from 'graphql/mutations/file/generated/DeleteFileInstanceLink';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toastErrorMessage } from 'utils/helpers';

const useDeleteFileInstanceLink = () => {
  const { t } = useTranslation();
  const [deleteFileInstanceLink, { client }] = useDeleteFileInstanceLinkMutation({ onError: toastErrorMessage });

  const onDeleteFileInstanceLink = useCallback(
    async (fileInstanceLinkId: string) => {
      confirmAlert({
        onConfirm: async () => {
          await deleteFileInstanceLink({
            variables: { fileInstanceLinkId },
            onCompleted: () => {
              client.cache.evict({ id: `FileInstanceLink:${fileInstanceLinkId}` });
            },
          });
        },
        title: t('files.deleteFileAlert.title'),
        message: t('files.deleteFileAlert.message'),
      });
    },
    [client.cache, deleteFileInstanceLink, t],
  );

  return onDeleteFileInstanceLink;
};

export default useDeleteFileInstanceLink;
