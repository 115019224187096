import React from 'react';
import CardWithHeader from 'components/common/CardWithHeader';
import { Card, Col, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ApiIntegrationNames } from 'graphql/types.generated';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useRemoveApiIntegrationMutation } from 'graphql/mutations/iframe/generated/RemoveApiIntegration';
import { toastErrorMessage } from 'utils/error';
import { confirmAlert } from 'components/common/Alert';

interface IApiIntegrationCard {
  name: ApiIntegrationNames;
  clientId: string;
  iframeId?: string;
}

const CardHeader = ({ iframeId, name, clientId }: IApiIntegrationCard) => {
  const { t } = useTranslation('translation', { keyPrefix: 'iframe' });

  const [remove] = useRemoveApiIntegrationMutation();

  const handleOnClick = async () => {
    confirmAlert({
      onConfirm: async () => {
        if (iframeId) await remove({ variables: { iframeId, input: { name, clientId } }, onError: toastErrorMessage });
      },
      title: t('alert.removeApiIntegrationHeader', { name: t(`integrations.${name}`) }),
      message: t('alert.disableApiIntegrationAlert'),
    });
  };
  return (
    <Stack direction="horizontal" gap={3}>
      {t(`integrations.${name}`)}
      <Button onClick={handleOnClick} variant="danger" className="me-1 ms-auto">
        <FontAwesomeIcon icon={faXmark} />
      </Button>
    </Stack>
  );
};

const ApiIntegrationCard = ({ iframeId, name, clientId }: IApiIntegrationCard) => {
  const { t } = useTranslation();

  return (
    <Col md={6}>
      <CardWithHeader className="mb-3" title={<CardHeader {...{ iframeId, name, clientId }} />}>
        <Card.Text className="fw-bold mb-1">{t('iframe.integrations.clientId')}</Card.Text>
        {clientId}
      </CardWithHeader>
    </Col>
  );
};

export default ApiIntegrationCard;
