import React, { CSSProperties } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import Button from './Button';

interface SubmitButtonParams {
  className?: string;
  style?: CSSProperties;
  icon?: IconDefinition | null;
  label?: string;
  onClick?: () => void;
  variant?: ButtonVariant;
  disabled?: boolean;
}

const SubmitButton = ({
  icon = faAngleRight,
  label = 'buttons.submit',
  className,
  style,
  variant,
  onClick,
  disabled,
}: SubmitButtonParams) => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      className={className}
      style={style}
      disabled={isSubmitting || disabled}
      loading={isSubmitting}
      variant={variant}
      type="submit"
      onClick={onClick}
    >
      {t(label)} {icon && <FontAwesomeIcon icon={icon} />}
    </Button>
  );
};

export default SubmitButton;
