import React, { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { OnAddNewFiles, useGetExtensionsByRole } from 'components/dnd/DndContainer';
import { useHandleChangeFileInput } from 'components/dnd/hooks/useHandleChangeFileInput';
import { EnhancedFile } from 'components/dnd/DroppedFiles.helpers';

interface IFileInputParams {
  name?: string;
  onAddNewFiles: OnAddNewFiles;
  extensions?: string[];
  icon?: IconProp;
  label?: string;
  maxFileSize: number;
  droppedFiles?: EnhancedFile[];
  multiple?: boolean;
  variant?: ButtonVariant;
}

const FileInput: FC<IFileInputParams> = ({
  name = 'specificFileInputId',
  multiple = true,
  onAddNewFiles,
  icon = faPaperclip,
  label = '',
  maxFileSize,
  extensions,
  variant = 'outline-primary',
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const availableExtensions = useGetExtensionsByRole({ extensions });
  const accept = useMemo(
    () => availableExtensions.map((extension) => `.${extension}`).join(', '),
    [availableExtensions],
  );

  const handleChange = useHandleChangeFileInput({ onAddNewFiles, extensions: availableExtensions, maxFileSize });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleChange(e);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [handleChange],
  );

  return (
    <Form.Group>
      <Form.Label htmlFor={name} className="mb-0">
        <div className={`btn btn-${variant}`}>
          {label} <FontAwesomeIcon icon={icon} />
        </div>
      </Form.Label>
      <Form.Control
        id={name}
        name={name}
        multiple={multiple}
        className="d-none"
        type="file"
        size="sm"
        onChange={onChange}
        accept={accept}
        ref={fileInputRef}
      />
    </Form.Group>
  );
};

export default FileInput;
