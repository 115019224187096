import { useCallback } from 'react';

const useDownloadFile = () => {
  const onDownloadFileClick = useCallback((url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noreferrer';
    link.click();
  }, []);
  return onDownloadFileClick;
};

export default useDownloadFile;
