import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { IframesQuery, IframesQueryVariables, useIframesQuery } from 'graphql/queries/iframe/generated/Iframes';

interface IGetIframesProps extends QueryHookOptions<IframesQuery, IframesQueryVariables> {
  tenantId?: string;
  currentPage: number;
}

export const useGetIframesFilter = () => {
  const {
    searchParams: { iframesSearchQuery = '' },
  } = useGetSearchParams(['iframesSearchQuery']);

  return { searchQuery: iframesSearchQuery };
};

export default function useIframes({ tenantId, currentPage, ...baseOptions }: IGetIframesProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const filter = useGetIframesFilter();

  const { data, loading, error } = useIframesQuery({
    variables: {
      skip,
      limit: constants.tableRowsCountPerPage,
      searchString: filter.searchQuery,
      ...(tenantId ? { tenantId } : {}),
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const iframes = data?.iframes?.items || [];
  const total = data?.iframes?.total || 0;

  return {
    iframes,
    total,
    loading,
    error,
  };
}
