import React from 'react';
import { FastField, Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import SubmitButton from 'components/common/Button/SubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import { routePaths } from 'router/routes';

const validationSchema = yup.object().shape({
  email: yup.string().required('errors.requiredField').email('errors.invalidEmail'),
});

export interface IForgotPasswordFormValues {
  email: string;
}

interface IForgotPasswordFormProps {
  initialValues: IForgotPasswordFormValues;
  onSubmit: (values: IForgotPasswordFormValues, helpers: FormikHelpers<IForgotPasswordFormValues>) => void;
}

const ForgotPasswordForm: FC<IForgotPasswordFormProps> = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation();

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {() => (
        <Form>
          <FastField
            name="email"
            type="email"
            component={TextInput}
            label="auth.email"
            startIcon={<FontAwesomeIcon icon={faUser} />}
          />
          <p className="my-4 text-end" style={{ fontSize: '14px' }}>
            {t('auth.forgotPassword.goToLogin')} <Link to={routePaths.login}>{t('pageTitles.login')}</Link>
          </p>

          <div className="text-center">
            <SubmitButton />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
