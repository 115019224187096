import React, { useCallback, useMemo } from 'react';
import ReactOtpInput, { OTPInputProps } from 'react-otp-input';
import get from 'lodash/get';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import s from './OtpInput.module.sass';
import { useTranslation } from 'react-i18next';

interface IOtpInput extends OTPInputProps {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  label: string;
}

const OtpInput: FC<IOtpInput> = ({ field, form, label, numInputs = 6, ...restOtpInputProps }) => {
  const { name, value } = field;
  const { touched, errors, setFieldValue } = form;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (v: string) => {
      setFieldValue(name, v);
    },
    [name, setFieldValue],
  );

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group className="mb-3">
      <div className="text-center">
        <Form.Label htmlFor={name} className="text-primary">
          {t(label)}
        </Form.Label>
      </div>
      <ReactOtpInput
        shouldAutoFocus
        {...restOtpInputProps}
        value={value}
        onChange={handleChange}
        containerStyle={s.container}
        inputStyle={s.input}
        numInputs={numInputs}
        renderInput={(props) => <input {...props} />}
      />
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default OtpInput;
