import React from 'react';
import CPagination from 'rc-pagination';
import constants from '../../../constants/constants';
import cn from 'classnames';
import 'rc-pagination/assets/index.css';
import s from './Pagination.module.sass';

export interface IPaginationProps {
  changePage: (page: number) => void;
  currentPage: number;
  total: number;
  className?: string;
  pageSize?: number;
}

const Pagination: FC<IPaginationProps> = ({
  changePage,
  currentPage,
  total,
  className,
  pageSize = constants.tableRowsCountPerPage,
}) => {
  return (
    <CPagination
      className={cn('mt-3', s.rcPagination, className)}
      prevIcon={<div className={s.arrowBack} />}
      nextIcon={<div className={s.arrowForward} />}
      onChange={changePage}
      pageSize={pageSize}
      current={currentPage}
      total={total}
    />
  );
};

export default Pagination;
