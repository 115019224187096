import React from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { User } from '../../../graphql/types.generated';
import { Avatar } from '../Avatar';
import { formatDate, getBasicUserAccountInfo } from 'utils/helpers';

interface iUserActionBadge {
  user?: Pick<User, 'email' | 'profile'> | null;
  date?: Date | null;
  size?: number;
  type?: 'light' | 'transparent';
}

const UserActionBadge: FC<iUserActionBadge> = ({ user, date, size, type = 'transparent' }) => {
  const { i18n } = useTranslation();

  if (!user) return null;
  return (
    <Stack
      direction="horizontal"
      gap={2}
      className={cn('text-start d-inline-flex', type === 'light' ? 'bg-light p-2 rounded border' : undefined)}
      style={{ minWidth: 'fit-content' }}
    >
      <Avatar user={user} size={size} />
      <div style={{ fontSize: '0.9em' }}>
        <div className="text-primary" style={{ whiteSpace: 'pre-wrap' }}>
          {typeof user === 'string' ? user : getBasicUserAccountInfo(user)}
        </div>
        {date ? (
          <div className="text-secondary text-wrap">{formatDate({ date: new Date(date), locale: i18n.language })}</div>
        ) : null}
      </div>
    </Stack>
  );
};

export default UserActionBadge;
