import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TenantFragmentDoc } from '../../../fragments/tenant/generated/tenantFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantQueryVariables = SchemaTypes.Exact<{
  tenantId: SchemaTypes.Scalars['ID']['input'];
}>;

export type TenantQuery = {
  __typename?: 'Query';
  tenant: {
    __typename?: 'Tenant';
    _id: string;
    companyName: string;
    archived?: boolean | null;
    createdBy: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    };
    updatedBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
    changedStatusBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  };
};

export const TenantDocument = gql`
  query Tenant($tenantId: ID!) {
    tenant(tenantId: $tenantId) {
      ...Tenant
    }
  }
  ${TenantFragmentDoc}
`;

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useTenantQuery(baseOptions: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
}
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
}
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>;
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>;
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>;
export function refetchTenantQuery(variables: TenantQueryVariables) {
  return { query: TenantDocument, variables: variables };
}
