import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TenantFragmentDoc } from '../../../fragments/tenant/generated/tenantFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveTenantMutationVariables = SchemaTypes.Exact<{
  tenantId: SchemaTypes.Scalars['ID']['input'];
}>;

export type ArchiveTenantMutation = {
  __typename?: 'Mutation';
  archiveTenant: {
    __typename?: 'Tenant';
    _id: string;
    companyName: string;
    archived?: boolean | null;
    createdBy: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    };
    updatedBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
    changedStatusBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  };
};

export const ArchiveTenantDocument = gql`
  mutation ArchiveTenant($tenantId: ID!) {
    archiveTenant(tenantId: $tenantId) {
      ...Tenant
    }
  }
  ${TenantFragmentDoc}
`;
export type ArchiveTenantMutationFn = Apollo.MutationFunction<ArchiveTenantMutation, ArchiveTenantMutationVariables>;

/**
 * __useArchiveTenantMutation__
 *
 * To run a mutation, you first call `useArchiveTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTenantMutation, { data, loading, error }] = useArchiveTenantMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useArchiveTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveTenantMutation, ArchiveTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveTenantMutation, ArchiveTenantMutationVariables>(ArchiveTenantDocument, options);
}
export type ArchiveTenantMutationHookResult = ReturnType<typeof useArchiveTenantMutation>;
export type ArchiveTenantMutationResult = Apollo.MutationResult<ArchiveTenantMutation>;
export type ArchiveTenantMutationOptions = Apollo.BaseMutationOptions<
  ArchiveTenantMutation,
  ArchiveTenantMutationVariables
>;
