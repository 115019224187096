import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TenantFragmentDoc } from '../../../fragments/tenant/generated/tenantFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReleaseTenantMutationVariables = SchemaTypes.Exact<{
  tenantId: SchemaTypes.Scalars['ID']['input'];
}>;

export type ReleaseTenantMutation = {
  __typename?: 'Mutation';
  releaseTenant: {
    __typename?: 'Tenant';
    _id: string;
    companyName: string;
    archived?: boolean | null;
    createdBy: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    };
    updatedBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
    changedStatusBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  };
};

export const ReleaseTenantDocument = gql`
  mutation ReleaseTenant($tenantId: ID!) {
    releaseTenant(tenantId: $tenantId) {
      ...Tenant
    }
  }
  ${TenantFragmentDoc}
`;
export type ReleaseTenantMutationFn = Apollo.MutationFunction<ReleaseTenantMutation, ReleaseTenantMutationVariables>;

/**
 * __useReleaseTenantMutation__
 *
 * To run a mutation, you first call `useReleaseTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseTenantMutation, { data, loading, error }] = useReleaseTenantMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useReleaseTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<ReleaseTenantMutation, ReleaseTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReleaseTenantMutation, ReleaseTenantMutationVariables>(ReleaseTenantDocument, options);
}
export type ReleaseTenantMutationHookResult = ReturnType<typeof useReleaseTenantMutation>;
export type ReleaseTenantMutationResult = Apollo.MutationResult<ReleaseTenantMutation>;
export type ReleaseTenantMutationOptions = Apollo.BaseMutationOptions<
  ReleaseTenantMutation,
  ReleaseTenantMutationVariables
>;
