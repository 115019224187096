import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFileInstanceLinkMutationVariables = SchemaTypes.Exact<{
  fileInstanceLinkId: SchemaTypes.Scalars['ID']['input'];
}>;

export type DeleteFileInstanceLinkMutation = { __typename?: 'Mutation'; deleteFileInstanceLink: boolean };

export const DeleteFileInstanceLinkDocument = gql`
  mutation DeleteFileInstanceLink($fileInstanceLinkId: ID!) {
    deleteFileInstanceLink(fileInstanceLinkId: $fileInstanceLinkId)
  }
`;
export type DeleteFileInstanceLinkMutationFn = Apollo.MutationFunction<
  DeleteFileInstanceLinkMutation,
  DeleteFileInstanceLinkMutationVariables
>;

/**
 * __useDeleteFileInstanceLinkMutation__
 *
 * To run a mutation, you first call `useDeleteFileInstanceLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileInstanceLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileInstanceLinkMutation, { data, loading, error }] = useDeleteFileInstanceLinkMutation({
 *   variables: {
 *      fileInstanceLinkId: // value for 'fileInstanceLinkId'
 *   },
 * });
 */
export function useDeleteFileInstanceLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFileInstanceLinkMutation, DeleteFileInstanceLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileInstanceLinkMutation, DeleteFileInstanceLinkMutationVariables>(
    DeleteFileInstanceLinkDocument,
    options,
  );
}
export type DeleteFileInstanceLinkMutationHookResult = ReturnType<typeof useDeleteFileInstanceLinkMutation>;
export type DeleteFileInstanceLinkMutationResult = Apollo.MutationResult<DeleteFileInstanceLinkMutation>;
export type DeleteFileInstanceLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileInstanceLinkMutation,
  DeleteFileInstanceLinkMutationVariables
>;
