import { QueryHookOptions } from '@apollo/client';
import constants from 'constants/constants';
import { FilesQuery, FilesQueryVariables, useFilesQuery } from 'graphql/queries/file/generated/Files';
import { useParams } from 'react-router-dom';

const { tableRowsCountPerPage } = constants;

interface IGetFilesProps extends QueryHookOptions<FilesQuery, FilesQueryVariables> {
  currentPage: number;
}

export default function useFiles({ currentPage, ...baseOptions }: IGetFilesProps) {
  const { iframeId } = useParams() as { iframeId: string };
  const skip = tableRowsCountPerPage * (currentPage - 1);

  const { data, loading, error, fetchMore } = useFilesQuery({
    variables: { iframeId, limit: tableRowsCountPerPage, skip },
    fetchPolicy: 'cache-and-network',
    skip: !iframeId,
    ...baseOptions,
  });

  const files = data?.files?.items || [];
  const total = data?.files?.total || 0;

  return { files, total, loading, error, fetchMore };
}
