export const iframeProjectsReportUrl = (iframeId: string, startDate?: Date, endDate?: Date) => {
  const startDateInTime = startDate instanceof Date && !isNaN(startDate.getTime()) ? startDate.getTime() : undefined;
  const endDateInTime = endDate instanceof Date && !isNaN(endDate.getTime()) ? endDate.getTime() : undefined;
  return `/api/admin/projects-report/?iframeId=${iframeId}${startDateInTime || endDateInTime ? '&' : ''}${
    startDateInTime ? `startDate=${startDateInTime}` : ''
  }${startDateInTime && endDateInTime ? '&' : ''}${endDateInTime ? `endDate=${endDateInTime}` : ''}`;
};

export const projectsReportUrl = (startDate?: Date, endDate?: Date) => {
  const startDateInTime = startDate instanceof Date && !isNaN(startDate.getTime()) ? startDate.getTime() : undefined;
  const endDateInTime = endDate instanceof Date && !isNaN(endDate.getTime()) ? endDate.getTime() : undefined;
  return `/api/admin/projects-report/${startDateInTime || endDateInTime ? '?' : ''}${
    startDateInTime ? `startDate=${startDateInTime}` : ''
  }${startDateInTime && endDateInTime ? '&' : ''}${endDateInTime ? `endDate=${endDateInTime}` : ''}`;
};
