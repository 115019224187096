import { Locale, User } from 'graphql/types.generated';
import { toast } from 'react-toastify';
import { cloneDeep, cloneDeepWith, get } from 'lodash';
import { format, sub } from 'date-fns';
import { de, enUS as en } from 'date-fns/locale';

export * from './error';

const locales = { de, en };

export const getBasicUserAccountInfo = (user?: Pick<User, 'email' | 'profile'> | null) => {
  if (!user) return '';
  const { email, profile } = user;
  return `${profile.firstName} ${profile.lastName} \n${email}`;
};

export const passwordValidation = (value: any) => {
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasSymbol = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
  const conditions = [hasUpperCase, hasLowerCase, hasSymbol];
  const isValid = conditions.every((condition) => condition);
  return isValid;
};

export const copyToClipBoard = (data: string, label: string) => {
  navigator.clipboard.writeText(data);
  toast.success(label);
};

type FormatDateParams = {
  date: Date;
  dateFormat?: string;
  locale?: string;
};
export const yearFormat = 'yyyy';
export const dateFormat = 'do MMMM yyyy';
export const dateFormatInput = 'dd.MM.yyyy';
export const dayFormat = 'do MMMM';
export const dayFormatInput = 'dd.MM';
export const timeFormatOnly = 'HH:mm';
export const timeFormat = 'do MMMM yyyy HH:mm';
export const timeFormatInput = 'dd.MM.yyyy HH:mm';
export const secondFormat = 'do MMMM yyyy HH:mm:ss';
export const dayOfWeekFormat = 'ccc';
export const formatDate = ({ date, dateFormat = timeFormat, locale = Locale.de }: FormatDateParams) => {
  return format(new Date(date), dateFormat, { locale: get(locales, locale) });
};
export const subTimezoneOffset = (date: Date) => sub(date, { minutes: new Date(date).getTimezoneOffset() });

export function omitDeep<T extends Record<string, any>>(collection: T | undefined | null, excludeKeys: string[]): T {
  const obj = cloneDeep(collection);
  function omitFn(value: any) {
    if (value && typeof value === 'object') {
      excludeKeys.forEach((key) => {
        delete value[key];
      });
    }
  }

  return cloneDeepWith(obj, omitFn);
}

export function simpleDecrypt(value: string): string {
  return value
    .split('')
    .map((char) => {
      const code = char.charCodeAt(0);

      if (code >= 65 && code <= 90) {
        // A-Z
        return String.fromCharCode(((code - 65 - 3 + 26) % 26) + 65);
      } else if (code >= 97 && code <= 122) {
        // a-z
        return String.fromCharCode(((code - 97 - 3 + 26) % 26) + 97);
      } else if (code >= 48 && code <= 57) {
        // 0-9
        return String.fromCharCode(((code - 48 - 3 + 10) % 10) + 48);
      } else {
        return char;
      }
    })
    .join('');
}
