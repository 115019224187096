import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, useModal } from 'components/common/Modal';
import { faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/common/Button';
import GTMForm from './GTMForm';
import { useTranslation } from 'react-i18next';
import { toastErrorMessage } from 'utils/helpers';
import { useDisableGTMIntegrationMutation } from 'graphql/mutations/iframe/generated/DisableGTMIntegration';
import { confirmAlert } from 'components/common/Alert';

interface IGTMButtonParams {
  iframeId: string;
  isEnabled: boolean;
}

const GTMButton: FC<IGTMButtonParams> = ({ iframeId, isEnabled }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'iframe' });
  const { showModal, isVisible, hideModal } = useModal();

  const [disableGTMIntegration] = useDisableGTMIntegrationMutation({
    onError: toastErrorMessage,
  });
  const onGTMIntegrateClick = useCallback(() => {
    showModal();
  }, [showModal]);

  const onDisableGTMIntegration = useCallback(async () => {
    confirmAlert({
      onConfirm: async () => {
        await disableGTMIntegration({
          variables: { iframeId },
        });
      },
      title: t('alert.disableGTMIntegrationHeader'),
      message: t('alert.disableGTMIntegrationAlert'),
    });
  }, [disableGTMIntegration, iframeId, t]);

  return (
    <>
      <Button
        className="me-1 ms-auto"
        variant={isEnabled ? 'danger' : 'primary'}
        onClick={isEnabled ? onDisableGTMIntegration : onGTMIntegrateClick}
      >
        {isEnabled ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faKey} />}
      </Button>
      <Modal isVisible={isVisible} onHide={hideModal} headerCloseButton headerText={t('gtm.modal.header')} size="lg">
        <GTMForm {...{ iframeId, hideModal }} />
      </Modal>
    </>
  );
};

export default GTMButton;
