import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import { useResetPlanningItemsMutation } from 'graphql/mutations/iframe/generated/ResetPlanningItems';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toastErrorMessage, toastSuccessMessage } from 'utils/error';

interface ResetPlanningItemsButtonProps {
  iframeId: string;
}

const ResetPlanningItemsButton: FC<ResetPlanningItemsButtonProps> = ({ iframeId }) => {
  const { t } = useTranslation();
  const [resetConfiguration, { loading }] = useResetPlanningItemsMutation({
    onError: toastErrorMessage,
    onCompleted: () => toastSuccessMessage(t),
  });

  const onClick = useCallback(async () => {
    await resetConfiguration({ variables: { iframeId } });
  }, [iframeId, resetConfiguration]);

  return (
    <Button onClick={onClick} variant="danger" loading={loading}>
      {t('iframeCategoryConfiguration.resetConfiguration')} <FontAwesomeIcon icon={faArrowRotateLeft} />
    </Button>
  );
};

export default ResetPlanningItemsButton;
