import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TranslationFragmentDoc } from '../../translation/generated/Translation';
export type PartnerSectionFragment = {
  __typename?: 'PartnerSection';
  position?: number | null;
  isEnabled?: boolean | null;
  header?: { __typename?: 'Translation'; en: string; de: string } | null;
  content?: { __typename?: 'Translation'; en: string; de: string } | null;
  pdfButton?: { __typename?: 'Translation'; en: string; de: string } | null;
};

export type PartnerSectionFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const PartnerSectionFragmentDoc = gql`
  fragment PartnerSection on PartnerSection {
    header {
      ...Translation
    }
    position
    isEnabled
    content {
      ...Translation
    }
    pdfButton {
      ...Translation
    }
  }
  ${TranslationFragmentDoc}
`;
