import { MutationHookOptions } from '@apollo/client';
import RefreshTokenService from 'services/RefreshTokenService';
import { MeDocument } from 'graphql/queries/user/generated/Me';
import {
  RedeemInvitationMutation,
  RedeemInvitationMutationVariables,
  useRedeemInvitationMutation,
} from 'graphql/mutations/invitation/generated/RedeemInvitation';
import { toastErrorMessage } from 'utils/helpers';

const useRedeemInvitation = (
  baseOptions?: MutationHookOptions<RedeemInvitationMutation, RedeemInvitationMutationVariables>,
) => {
  const [redeemInvitationMutation, { client }] = useRedeemInvitationMutation({
    onCompleted: async ({ redeemInvitation: data }) => {
      const { refreshToken, user } = data as RedeemInvitationMutation['redeemInvitation'];

      if (refreshToken) {
        RefreshTokenService.setRefreshToken(refreshToken);
      }

      client.writeQuery({ query: MeDocument, data: { me: user } });
    },
    onError: toastErrorMessage,
    ...baseOptions,
  });

  return redeemInvitationMutation;
};

export default useRedeemInvitation;
