import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field, FastField } from 'formik';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from '../../utils/helpers';
import SubmitButton from 'components/common/Button/SubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import useGetSearchParams from 'hooks/useGetSearchParams';
import constants from 'constants/constants';
import { refetchAuditLogsQuery } from 'graphql/queries/auditLogs/generated/AuditLogs';
import { useGetAuditLogsFilter } from 'hooks/auditLog/useAuditLogs';
import { useTenantsIdsQuery } from 'graphql/queries/tenant/generated/TenantIds';
import { MenuPortalTarget } from 'components/inputs/Select/useMenuPortalTarget';
import Select from 'components/inputs/Select';
import DatePicker from 'components/inputs/DatePicker';

const { tableRowsCountPerPage } = constants;

interface IAuditLogsFilterForm {
  changePage: (page: number) => void;
}

type FormValues = {
  auditLogsSearchQuery: string;
  tenant?: string;
  startDate?: Date;
  endDate?: Date;
};

const AuditLogsFilterForm: FC<IAuditLogsFilterForm> = ({ changePage }) => {
  const { searchParams, setSearchParams } = useGetSearchParams();
  const filter = useGetAuditLogsFilter();

  const { data: tenantIds } = useTenantsIdsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const tenantSelectData = useMemo(
    () =>
      tenantIds?.tenantsIds.map((tenantIdData) => {
        return {
          label: tenantIdData.companyName,
          value: tenantIdData._id,
        };
      }),
    [tenantIds],
  );

  const initialValues = useMemo(() => {
    return { auditLogsSearchQuery: filter.searchQuery };
  }, [filter.searchQuery]);

  const onSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { auditLogsSearchQuery, tenant, startDate, endDate } = values;
        changePage(1);
        setSearchParams({
          ...searchParams,
          ...(typeof auditLogsSearchQuery === 'string' ? { auditLogsSearchQuery } : {}),
          ...(tenant ? { tenant } : { tenant: '' }),
          ...(startDate ? { startDate: startDate.getTime().toString() } : { startDate: '' }),
          ...(endDate ? { endDate: endDate.getTime().toString() } : { endDate: '' }),
        });

        refetchAuditLogsQuery({
          skip: 0,
          limit: tableRowsCountPerPage,
          searchString: auditLogsSearchQuery,
          ...(typeof tenant === 'string' ? { tenant } : {}),
          ...(startDate ? { startDate: Number(startDate.getTime().toString()) } : {}),
          ...(endDate ? { endDate: Number(endDate.getTime().toString()) } : {}),
        });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [changePage, searchParams, setSearchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <Field name="auditLogsSearchQuery" type="search" label="auditLogs.searchQuery" component={TextInput} />
            {tenantSelectData?.length && (
              <FastField
                name="tenant"
                label="auditLogs.tenantQuery"
                isClearable
                component={Select}
                menuPortalTarget={MenuPortalTarget.body}
                options={tenantSelectData}
              />
            )}
            <div>
              <FastField
                name="startDate"
                label="auditLogs.startDateQuery"
                isClearable
                component={DatePicker}
                menuPortalTarget={MenuPortalTarget.body}
              />
            </div>
            <div>
              <FastField
                name="endDate"
                label="auditLogs.endDateQuery"
                isClearable
                component={DatePicker}
                menuPortalTarget={MenuPortalTarget.body}
              />
            </div>
          </div>
          <div className="filter-buttons">
            <SubmitButton label="buttons.search" icon={faMagnifyingGlass} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AuditLogsFilterForm;
