import { confirmAlert } from 'components/common/Alert';
import { DeleteFileMutationVariables, useDeleteFileMutation } from 'graphql/mutations/file/generated/useDeleteFile';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toastErrorMessage } from 'utils/helpers';

const useDeleteFile = () => {
  const { t } = useTranslation();
  const [deleteFile, { client }] = useDeleteFileMutation({ onError: toastErrorMessage });

  const handleDeleteFile = useCallback(
    async ({ fileId, iframeId }: DeleteFileMutationVariables) => {
      confirmAlert({
        onConfirm: async () => {
          await deleteFile({
            variables: { fileId, iframeId },
            onCompleted: () => {
              client.cache.evict({ id: `File:${fileId}` });
            },
          });
        },
        title: t('files.deleteFileAlert.title'),
        message: t('files.deleteFileAlert.message'),
      });
    },
    [client.cache, deleteFile, t],
  );

  return handleDeleteFile;
};

export default useDeleteFile;
