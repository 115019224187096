import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as MinifiedLogo } from 'assets/icons/minified-logo.svg';
import RNavbar from 'react-bootstrap/Navbar';
import { routePaths } from 'router/routes';
import { useAppContext } from 'contexts/AppContext';

interface NavbarBrandParams {
  auth?: boolean;
}

const NavbarBrand: FC<NavbarBrandParams> = ({ auth }) => {
  const { sidebarOpen } = useAppContext();
  return (
    <Link to={routePaths.dashboard}>
      <RNavbar.Brand>{sidebarOpen || auth ? <Logo /> : <MinifiedLogo />}</RNavbar.Brand>
    </Link>
  );
};

export default NavbarBrand;
