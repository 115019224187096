import { ProjectFeature, SolePlateOrBasement } from 'graphql/types.generated';

const planningCategories = {
  [ProjectFeature.planningAndConstructionManagement]: 'Planning & Construction Management',
  [ProjectFeature.propertyCosts]: 'Property costs',
  [ProjectFeature.chargesAndTaxes]: 'Charges and taxes',
  [ProjectFeature.developmentCosts]: 'Development costs',
  [ProjectFeature.demolitionCosts]: 'Demolition costs',
  [ProjectFeature.reservesForUnexpectedCosts]: 'Reserves for unexpected costs',
  [`${ProjectFeature.reservesForUnexpectedCosts}Description`]:
    'In the case of renovations in particular, we recommend a financial reserve of at least 10% for unforeseen additional expenses.',
  [ProjectFeature.shellConstruction]: 'Shell construction',
  [ProjectFeature.windowsAndDoors]: 'Windows & Doors',
  [ProjectFeature.sanitary]: 'Sanitary',
  [ProjectFeature.electricalInstallation]: 'Electrical installation',
  [ProjectFeature.heatingSystem]: 'Renew Heating System (preferably heat pump or district heating)',
  [`${ProjectFeature.heatingSystem}Description`]:
    'If a connection to a district heating network is not possible, a heat pump is almost always the best solution in terms of cost savings and CO2 reduction. In many cases, this is also possible in existing buildings without additional insulation measures - an on-site energy consultation can quickly clarify this!',
  [`${ProjectFeature.heatingSystem}EquipmentTypeDescription`]:
    'In the <b>standard variant</b>, only the heating system, including all necessary pumps, is replaced. The <b>premium variant</b> includes the costs for (partial) conversion to underfloor heating, and the system can also be controlled via an app (smartphone, etc.).',
  [ProjectFeature.interiorConstruction]: 'Interior construction',
  [ProjectFeature.interiorEquipment]: 'Interior equipment',
  [`${ProjectFeature.interiorEquipment}Description`]:
    'Includes kitchen, furniture of all rooms, lighting, electrical devices and alarm system including installation',
  [`${ProjectFeature.interiorEquipment}TypeDescription`]:
    'The <b> Standard </b> category considers furnishings of average quality from furniture stores. In the higher categories, regionally produced, higher quality and often more durable and therefore more sustainable solutions are calculated',
  [ProjectFeature.terrace]: 'Terrace',
  [ProjectFeature.winterGarden]: 'Winter garden',
  [`${ProjectFeature.winterGarden}Description`]:
    'Includes conservatory, flooring, heated depending on the category and with additional equipment',
  [`${ProjectFeature.winterGarden}EquipmentTypeDescription`]:
    'Differences arise in the type of glazing, shading and heating / ventilation. A <b> "standard winter garden" </b> is more of a closed terrace, a winter garden in the "Exclusive" category is a full-fledged living space that can be used all year round.',
  [ProjectFeature.pool]: 'Pool',
  [`${ProjectFeature.pool}Description`]:
    'Massive pool including earthworks, depending on the category with additional roofing / additional equipment',
  [ProjectFeature.fence]: 'Fence',
  [`${ProjectFeature.fence}EquipmentTypeDescription`]:
    'In the <b> Standard </b> version, a fence with a foundation and simple privacy protection is calculated. Higher quality designs and walls are considered in the higher categories.',
  [`${ProjectFeature.fence}Description`]: 'Includes base, fence, door / gate',
  [ProjectFeature.carport]: 'Carport',
  [`${ProjectFeature.carport}Description`]: 'Includes massive canopy',
  [ProjectFeature.garage]: 'Garage',
  [`${ProjectFeature.garage}Description`]: 'Includes massive garage with electric gate drive',
  [ProjectFeature.gardenLandscaping]: 'Garden landscaping',
  [`${ProjectFeature.gardenLandscaping}Description`]: 'Includes lawns, plants and paths',
  [`${ProjectFeature.gardenLandscaping}EquipmentTypeDescription`]:
    'The <b> "Standard" </b> category largely calculates lawns and inexpensive planting based on simple planning as offered in specialist stores.',
  [ProjectFeature.hillsideLocation]: 'Hillside location',
  [ProjectFeature.limitedAccess]: 'Limited access',
  [ProjectFeature.gardenDesign]: 'Garden design (lawn, plants)',
  [ProjectFeature.specialUnderground]: 'Special underground',
  [ProjectFeature.solePlateOrBasement]: 'Sole plate or Basement',
  [SolePlateOrBasement.solePlate]: 'Sole plate',
  [SolePlateOrBasement.basement]: 'Basement',
  [ProjectFeature.domesticVentilation]: 'Active Residential Ventilation',
  [`${ProjectFeature.domesticVentilation}Description`]:
    'Active residential ventilation provides automatic and energy-efficient air exchange through air inlets and outlets.',
  [`${ProjectFeature.domesticVentilation}EquipmentTypeDescription`]:
    'Differences are found in control options, filters, and component quality.',
  [ProjectFeature.busSystem]: 'Bus system, smart home',
  [`${ProjectFeature.busSystem}Description`]:
    'A bus system instead of the conventional electrical installation allows the central programming and control of all switches and sockets.',
  [`${ProjectFeature.busSystem}EquipmentTypeDescription`]:
    'The basis is a continuous bus system with appropriate cabling and controls for lighting and electricity. There are differences in the bus system itself (important here to rely on a <b> standard </b> such as KNX) and in the type of control.',
  [ProjectFeature.solarHeating]: 'Solar Thermal System',
  [`${ProjectFeature.solarHeating}Description`]:
    'Solar thermal systems are used for hot water preparation and store it in a tank.',
  [`${ProjectFeature.solarHeating}EquipmentTypeDescription`]:
    'The <b>standard variant</b> is used for hot water, while the more expensive categories also serve for heating support and feature higher-quality components.',
  [ProjectFeature.tileStove]: 'Tile Stove',
  [`${ProjectFeature.tileStove}EquipmentTypeDescription`]:
    'The basis is a tiled stove set by the stove. There are differences in size and design, including the water supply and integration into the heating system.',
  [`${ProjectFeature.tileStove}Description`]: 'Solid, tiled stove that is firmly connected to the house',
  [ProjectFeature.solarPowerSystem]: 'Photovoltaic System',
  [`${ProjectFeature.solarPowerSystem}Description`]:
    'A PV system makes you somewhat independent. There are often public incentives available - make sure to inquire!',
  [ProjectFeature.wallboxEMobility]: 'Charging Station for Electric Vehicle - Wallbox',
  [`${ProjectFeature.wallboxEMobility}Description`]:
    'To future-proof the new construction, a wallbox should always be considered in the planning.',
  [`${ProjectFeature.wallboxEMobility}EquipmentTypeDescription`]:
    'In the <b>standard category</b>, a high-quality wallbox with installation by a qualified electrician is planned. The basis here is the use of existing wiring, which often means that only a charging power of 3.7 kW is possible. In the higher categories, additional wiring and a wallbox with integration into a PV system are calculated, allowing for a charging power of up to 11 kW.',
  [ProjectFeature.otherInvestments]: 'Other investments',
  [`${ProjectFeature.otherInvestments}Description`]:
    'You can enter other expenses in connection with your renovation project here individually.',
  additionalCategories: 'Additional costs',
  [ProjectFeature.masonry]: 'Masonry, concrete and reinforced concrete works',
  [ProjectFeature.chimney]: 'Chimney',
  [ProjectFeature.carpentry]: 'Carpentry',
  [ProjectFeature.roofing]: 'Roofing and plumbing work (plumber)',
  [ProjectFeature.facade]: 'Facade - full thermal insulation',
  [ProjectFeature.metal]: 'Metal worker',
  [ProjectFeature.windowsPatioBalcony]: 'Windows, patio and front door',
  [ProjectFeature.interiorDoorsDoorStays]: 'Interior doors - doorstays',
  [ProjectFeature.plumbing]: 'Plumbing',
  [ProjectFeature.sanitaryEquipment]: 'Sanitary equipment',
  [ProjectFeature.interiorPlastering]: 'Interior plastering',
  [ProjectFeature.floorConstruction]: 'Floor construction - screed',
  [ProjectFeature.tiling]: 'Tiling',
  [ProjectFeature.flooring]: 'Flooring',
  [ProjectFeature.paintingWork]: 'Painting work (house painter, upholsterer)',
  [ProjectFeature.drywallConstruction]: 'Drywall construction',
  [ProjectFeature.heatingInstallation]: 'Heating installation total',
  [ProjectFeature.radiator]: 'Radiator or underfloor heating assembly',
  [ProjectFeature.boiler]: 'Boiler',
  [ProjectFeature.costEstimation]: 'Preliminary Planning & cost estimation',
  [ProjectFeature.permitPlanning]: 'Permit planning',
  [ProjectFeature.executionPlanning]: 'Execution planning',
  [ProjectFeature.billOfQuantity]: 'Bill of quantity & Offers',
  [ProjectFeature.constructionManagement]: 'Construction Management',
  [ProjectFeature.electroCableDucts]: 'Electro - cable ducts',
  [ProjectFeature.electroWiring]: 'Electro - wiring',
  [ProjectFeature.electroFinishing]: 'Electro - finishing',
  [ProjectFeature.priceOfProperty]: 'Price of property',
  [ProjectFeature.taxesAndNotaryExpenses]: 'Taxes and notary expenses',
  [ProjectFeature.earthworks]: 'Earthworks',
  [ProjectFeature.channel]: 'Channel work',
  [ProjectFeature.batteryStorage]: 'Battery Storage',
  [`${ProjectFeature.batteryStorage}Description`]:
    'Battery storage, when combined with a PV system, is used to store self-generated electricity. With an intelligent storage solution, you can use your solar power even when the sun is not shining. Otherwise, the generated electricity needs to be either consumed immediately or fed into the grid.',
  batteryStorageSize: 'Battery Storage Size (kWh)',
  batteryStorageSizeDescription:
    'As a rule of thumb, the capacity of the battery storage in kWh should roughly match the peak power (kWp) of the PV system.',
  batteryStorageBlackoutOption: 'Do you want your system to be able to provide backup power?',
  batteryStorageBlackoutOptionDescription: `Supplying electricity from the storage system without the public grid is not possible for safety reasons because the inverter is immediately shut off during a grid outage. Only with an additional backup power switch can the storage system continue to supply power to the house's appliances.`,
  [ProjectFeature.ceilingTopInsulation]: 'Insulation of the Top Floor Ceiling',
  [`${ProjectFeature.ceilingTopInsulation}Description`]:
    'A cost-effective alternative to roof renovation involves adding insulation to the top floor ceiling.',
  [`${ProjectFeature.ceilingTopInsulation}EquipmentTypeDescription`]:
    'The <b>standard version</b> includes loosely laid insulation panels with limited walkability. In the higher categories, durable insulation panels with good foot traffic capability are used.',

  [ProjectFeature.ceilingBasementInsulation]: 'Insulation of the Basement Ceiling',
  [`${ProjectFeature.ceilingBasementInsulation}Description`]:
    'For an unheated basement, the ceiling should be well insulated to minimize heat loss.',
  [`${ProjectFeature.ceilingBasementInsulation}EquipmentTypeDescription`]:
    'Differences exist in the quality and sustainability of the insulation.',
  [ProjectFeature.frontDoor]: 'Renewal of Entrance Door',
  [`${ProjectFeature.frontDoor}Description`]: 'The basis is a modern security door including installation.',
  [`${ProjectFeature.frontDoor}EquipmentTypeDescription`]:
    'Differences are in the size and design of the door (material and features like a fingerprint sensor).',
  [ProjectFeature.newWindows]: 'Window Replacement',
  [`${ProjectFeature.newWindows}Description`]:
    'Cost drivers here are primarily the window size, type (plastic, wood, or wood/aluminum), and additional features such as sun protection, fall protection, etc.',
  [`${ProjectFeature.newWindows}EquipmentTypeDescription`]:
    'The <b>standard variant</b> includes the disposal of old windows and the installation of cost-effective plastic windows. The <b>premium version</b> contains high-quality wood-aluminum windows with continuous shading, which can also be controlled via an app (smartphone, etc.).',
  [ProjectFeature.roof]: 'Renew and Insulate Roof',
  [`${ProjectFeature.roof}Description`]:
    'The basis is the removal of the old roof covering and the installation of a new roof covering with insulation based on the existing roof structure.',
  [`${ProjectFeature.roof}EquipmentTypeDescription`]:
    'The <b>standard variant</b> includes the replacement of the roof covering, including insulation based on the existing roof structure. In the <b>premium variant</b>, a complete replacement of the existing roof structure, including insulation and residential interior finishing, is included.',
  [ProjectFeature.facadeInsulation]: 'Insulate the Facade',
  [`${ProjectFeature.facadeInsulation}Description`]:
    'The basis is the insulation of the exterior walls, with the facade receiving a new exterior plaster. Please note that window replacement should also be planned.',
  [`${ProjectFeature.facadeInsulation}EquipmentTypeDescription`]:
    'In the <b>standard variant</b>, cost-effective insulation and plaster materials as well as simple windowsills are used. The <b>premium variant</b> combines ecological insulation materials (e.g., hemp) with high-quality exterior plaster and windowsills. The thickness of the insulation is calculated as follows: Standard with 10cm/ Premium 20cm - Your builder/architect/energy consultant will gladly inform you about the necessary insulation thickness.',
};

export default planningCategories;
