import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BasicUserAttributesFragmentDoc } from '../../user/generated/BasicUserAttribuesFragment';
import { WebhookFragmentDoc } from './Webhook';
import { TranslationFragmentDoc } from '../../translation/generated/Translation';
import { PartnerSectionFragmentDoc } from './PartnerSection';
export type IframeFragment = {
  __typename?: 'Iframe';
  _id: string;
  name: string;
  title: string;
  tenantId: string;
  projectType: SchemaTypes.ProjectType;
  domainWhiteListTypes: Array<string>;
  termsOfUseUrl?: string | null;
  privacyPolicyUrl?: string | null;
  headInjectionTags?: Array<string> | null;
  archived?: boolean | null;
  changedStatusAt?: any | null;
  contactEmail: string;
  gtmKey?: string | null;
  template: SchemaTypes.TemplateName;
  country?: string | null;
  integrationServices?: Array<SchemaTypes.IntegrationService> | null;
  changedStatusBy?: {
    __typename?: 'BasicUserAttributes';
    _id: string;
    email: string;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  } | null;
  theme: { __typename?: 'Theme'; primaryColor: string; textColor: string; pdfButtonColor?: string | null };
  requestProjectPdfConfig: {
    __typename?: 'RequestProjectPdfEmailConfig';
    newPdfRequestNotification?: boolean | null;
    emailFrom: string;
    subject: string;
    message: string;
    useImplementationStartPoint?: boolean | null;
    usePhoneNumber?: boolean | null;
  };
  projectPdfConfig: {
    __typename?: 'ProjectPdfConfig';
    pdfWebsiteUrl: string;
    pdfDownloadEnabledViaPublicRoute: boolean;
  };
  priceHubbleConfig?: {
    __typename?: 'IframePriceHubbleConfig';
    isEnabled: boolean;
    password: string;
    username: string;
  } | null;
  webhooks: Array<{
    __typename?: 'Webhook';
    _id: string;
    trigger: SchemaTypes.WebhookTrigger;
    events: Array<SchemaTypes.WebhookEvent>;
    url: string;
    headers: Array<{ __typename?: 'WebhookHeader'; name: string; value: string }>;
  }>;
  resultPageConfiguration?: {
    __typename?: 'ResultPageConfiguration';
    headerSection: {
      __typename?: 'HeaderSection';
      position?: number | null;
      isEnabled?: boolean | null;
      header?: { __typename?: 'Translation'; en: string; de: string } | null;
    };
    planSection: { __typename?: 'PlanSection'; position?: number | null; isEnabled?: boolean | null };
    equivalentsSection: { __typename?: 'EquivalentsSection'; position?: number | null; isEnabled?: boolean | null };
    totalCostSection: { __typename?: 'TotalCostSection'; position?: number | null; isEnabled?: boolean | null };
    investmentsPayOfSection: {
      __typename?: 'InvestmentsPayOffSection';
      position?: number | null;
      isEnabled?: boolean | null;
    };
    firstPartnerSection: {
      __typename?: 'PartnerSection';
      position?: number | null;
      isEnabled?: boolean | null;
      header?: { __typename?: 'Translation'; en: string; de: string } | null;
      content?: { __typename?: 'Translation'; en: string; de: string } | null;
      pdfButton?: { __typename?: 'Translation'; en: string; de: string } | null;
    };
    secondPartnerSection: {
      __typename?: 'PartnerSection';
      position?: number | null;
      isEnabled?: boolean | null;
      header?: { __typename?: 'Translation'; en: string; de: string } | null;
      content?: { __typename?: 'Translation'; en: string; de: string } | null;
      pdfButton?: { __typename?: 'Translation'; en: string; de: string } | null;
    };
  } | null;
  fuelMatrix?: {
    __typename?: 'FuelMatrix';
    updatedAt?: any | null;
    configuration: {
      __typename?: 'FuelMatrixConfiguration';
      heatingOil: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
        conversionFactor?: number | null;
        resultInKWhHeating?: number | null;
        avgPricePerKWh?: number | null;
      };
      naturalGas: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
        conversionFactor?: number | null;
        resultInKWhHeating?: number | null;
        avgPricePerKWh?: number | null;
      };
      coal: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
        conversionFactor?: number | null;
        resultInKWhHeating?: number | null;
        avgPricePerKWh?: number | null;
      };
      firewood: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
        conversionFactor?: number | null;
        resultInKWhHeating?: number | null;
        avgPricePerKWh?: number | null;
      };
      woodChips: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
        conversionFactor?: number | null;
        resultInKWhHeating?: number | null;
        avgPricePerKWh?: number | null;
      };
      woodPellets: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
        conversionFactor?: number | null;
        resultInKWhHeating?: number | null;
        avgPricePerKWh?: number | null;
      };
      electricityUsed: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
        conversionFactor?: number | null;
        resultInKWhHeating?: number | null;
        avgPricePerKWh?: number | null;
      };
      electricityProduced: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
      };
      districtHeating: {
        __typename?: 'FuelMatrixConfigurationItem';
        input: number;
        unit: string;
        avgPricePerUnit: number;
        conversionFactor?: number | null;
        resultInKWhHeating?: number | null;
        avgPricePerKWh?: number | null;
      };
    };
    updatedBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  } | null;
  apiIntegrations?: Array<{
    __typename?: 'ApiIntegrations';
    name: SchemaTypes.ApiIntegrationNames;
    clientId: string;
  }> | null;
};

export type IframeFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const IframeFragmentDoc = gql`
  fragment Iframe on Iframe {
    _id
    name
    title
    tenantId
    projectType
    domainWhiteListTypes
    termsOfUseUrl
    privacyPolicyUrl
    headInjectionTags
    archived
    changedStatusAt
    contactEmail
    gtmKey
    changedStatusBy {
      ...BasicUserAttributes
    }
    theme {
      primaryColor
      textColor
      pdfButtonColor
    }
    requestProjectPdfConfig {
      newPdfRequestNotification
      emailFrom
      subject
      message
      useImplementationStartPoint
      usePhoneNumber
    }
    projectPdfConfig {
      pdfWebsiteUrl
      pdfDownloadEnabledViaPublicRoute
    }
    priceHubbleConfig {
      isEnabled
      password
      username
    }
    webhooks {
      ...Webhook
    }
    resultPageConfiguration {
      headerSection {
        header {
          ...Translation
        }
        position
        isEnabled
      }
      planSection {
        position
        isEnabled
      }
      equivalentsSection {
        position
        isEnabled
      }
      totalCostSection {
        position
        isEnabled
      }
      investmentsPayOfSection {
        position
        isEnabled
      }
      firstPartnerSection {
        ...PartnerSection
      }
      secondPartnerSection {
        ...PartnerSection
      }
    }
    fuelMatrix {
      configuration {
        heatingOil {
          input
          unit
          avgPricePerUnit
          conversionFactor
          resultInKWhHeating
          avgPricePerKWh
        }
        naturalGas {
          input
          unit
          avgPricePerUnit
          conversionFactor
          resultInKWhHeating
          avgPricePerKWh
        }
        coal {
          input
          unit
          avgPricePerUnit
          conversionFactor
          resultInKWhHeating
          avgPricePerKWh
        }
        firewood {
          input
          unit
          avgPricePerUnit
          conversionFactor
          resultInKWhHeating
          avgPricePerKWh
        }
        woodChips {
          input
          unit
          avgPricePerUnit
          conversionFactor
          resultInKWhHeating
          avgPricePerKWh
        }
        woodPellets {
          input
          unit
          avgPricePerUnit
          conversionFactor
          resultInKWhHeating
          avgPricePerKWh
        }
        electricityUsed {
          input
          unit
          avgPricePerUnit
          conversionFactor
          resultInKWhHeating
          avgPricePerKWh
        }
        electricityProduced {
          input
          unit
          avgPricePerUnit
        }
        districtHeating {
          input
          unit
          avgPricePerUnit
          conversionFactor
          resultInKWhHeating
          avgPricePerKWh
        }
      }
      updatedBy {
        _id
        email
        profile {
          firstName
          lastName
        }
      }
      updatedAt
    }
    template
    country
    integrationServices
    apiIntegrations {
      name
      clientId
    }
  }
  ${BasicUserAttributesFragmentDoc}
  ${WebhookFragmentDoc}
  ${TranslationFragmentDoc}
  ${PartnerSectionFragmentDoc}
`;
