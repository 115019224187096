import { get } from 'lodash';
import React, { useMemo } from 'react';
import { FileIcon as RFileIcon, defaultStyles } from 'react-file-icon';
import { trimLeadingDot } from './DndContainer.helpers';

interface IFileIcon {
  size?: number;
  extension?: string;
}

const FileIcon: FC<IFileIcon> = ({ size = 30, extension }) => {
  const trimmedExtension = useMemo(() => trimLeadingDot(extension), [extension]);
  return (
    <div
      style={{ width: `${size}px`, height: `${size}px`, minWidth: `${size}px`, minHeight: `${size}ps` }}
      className="d-flex"
    >
      <RFileIcon extension={trimmedExtension} {...get(defaultStyles, trimmedExtension)} />
    </div>
  );
};

export default FileIcon;
