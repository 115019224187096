import React from 'react';
import ReactLoading, { LoadingProps } from 'react-loading';
import theme from 'styles/variables.sass';

interface ILoadingProps extends LoadingProps {
  size?: number;
  color?: string;
  position?: 'start' | 'center';
  style?: React.CSSProperties;
}

const Loading: FC<ILoadingProps> = ({ size = 40, color, style, type = 'spinningBubbles', position = 'start' }) => {
  return (
    <div className={`d-flex justify-content-${position}`} style={style}>
      <ReactLoading type={type} color={color || theme.primaryColor} width={size} height={size} />
    </div>
  );
};

export default Loading;
