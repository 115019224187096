import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllProjectsQueryVariables = SchemaTypes.Exact<{
  iframeId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
  limit: SchemaTypes.Scalars['Int']['input'];
  projectType?: SchemaTypes.InputMaybe<SchemaTypes.ProjectTypeFilter>;
  searchQuery: SchemaTypes.Scalars['String']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
}>;

export type AllProjectsQuery = {
  __typename?: 'Query';
  allProjects: {
    __typename?: 'PaginatedProjectResponse';
    total: number;
    items: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      price: number;
      type: SchemaTypes.ProjectType;
      iframeId: string;
      createdAt: any;
      meta?: { __typename?: 'Meta'; clientIP: string } | null;
    }>;
  };
};

export const AllProjectsDocument = gql`
  query AllProjects($iframeId: ID, $limit: Int!, $projectType: ProjectTypeFilter, $searchQuery: String!, $skip: Int!) {
    allProjects(iframeId: $iframeId, limit: $limit, projectType: $projectType, searchQuery: $searchQuery, skip: $skip) {
      items {
        _id
        name
        price
        type
        iframeId
        meta {
          clientIP
        }
        createdAt
      }
      total
    }
  }
`;

/**
 * __useAllProjectsQuery__
 *
 * To run a query within a React component, call `useAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsQuery({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *      limit: // value for 'limit'
 *      projectType: // value for 'projectType'
 *      searchQuery: // value for 'searchQuery'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAllProjectsQuery(baseOptions: Apollo.QueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllProjectsQuery, AllProjectsQueryVariables>(AllProjectsDocument, options);
}
export function useAllProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllProjectsQuery, AllProjectsQueryVariables>(AllProjectsDocument, options);
}
export type AllProjectsQueryHookResult = ReturnType<typeof useAllProjectsQuery>;
export type AllProjectsLazyQueryHookResult = ReturnType<typeof useAllProjectsLazyQuery>;
export type AllProjectsQueryResult = Apollo.QueryResult<AllProjectsQuery, AllProjectsQueryVariables>;
export function refetchAllProjectsQuery(variables: AllProjectsQueryVariables) {
  return { query: AllProjectsDocument, variables: variables };
}
