import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import {
  MeinBauApiLogsQuery,
  MeinBauApiLogsQueryVariables,
  useMeinBauApiLogsQuery,
} from 'graphql/queries/meinBauApiLogs/generated/MeinBauApiLogs';

interface IGetMeinBauApiLogsProps extends QueryHookOptions<MeinBauApiLogsQuery, MeinBauApiLogsQueryVariables> {
  currentPage: number;
  tenantId?: string;
}

export default function useMeinBauApiLogs({ currentPage, tenantId, ...baseOptions }: IGetMeinBauApiLogsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);

  const { data, loading, error } = useMeinBauApiLogsQuery({
    variables: {
      skip,
      limit: constants.tableRowsCountPerPage,
      tenantId,
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const meinBauApiLogs = data?.meinBauApiLogs?.items || [];
  const total = data?.meinBauApiLogs?.total || 0;

  return {
    meinBauApiLogs,
    total,
    loading,
    error,
  };
}
