import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { GraphQLError } from 'graphql';
import { TFunction } from 'i18next';

export type ErrorResponse = Error | ApolloError;

// Type must be augmented because of https://github.com/apollographql/apollo-link/issues/536
type NetworkError = ApolloError & { result: { errors: Error[] } };

export const formatGraphqlErrors = (error: ApolloError) => {
  const graphqlErrorMsg = Array.isArray(error.graphQLErrors)
    ? error.graphQLErrors.map((err: GraphQLError) => err.message).join('\n')
    : null;

  const networkError = error.networkError as NetworkError;
  const networkErrorResultMsg = Array.isArray(networkError?.result?.errors)
    ? networkError?.result?.errors.map((err) => err.message).join('\n')
    : null;
  // const networkErrorMsg = networkError ? i18n.t('errors.serverConnectionError') : null;
  const networkErrorMsg = networkError
    ? 'Verbindung zum Server derzeit nicht möglich, bitte Internetverbindung prüfen!'
    : null;

  const msg = graphqlErrorMsg || networkErrorResultMsg || networkErrorMsg;
  const e: ErrorResponse = msg ? new Error(msg) : error;

  return e;
};

// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export const grabErrorMessage = (error: unknown) => {
  return error instanceof ApolloError ? formatGraphqlErrors(error).message : toErrorWithMessage(error).message;
};
export const toastErrorMessage = (error: unknown) => {
  const errorMessage = grabErrorMessage(error);
  toast.error(errorMessage);
};

export const toastSuccessMessage = (t: TFunction<'translation', undefined>, translationKey?: string) =>
  toast.success(t(translationKey || 'success'));
