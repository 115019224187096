import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { UserFragmentDoc } from '../../../fragments/user/generated/UserFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddUsersToTenantMutationVariables = SchemaTypes.Exact<{
  userIds: Array<SchemaTypes.Scalars['ID']['input']>;
  tenantId: SchemaTypes.Scalars['ID']['input'];
  role: SchemaTypes.Roles;
}>;

export type AddUsersToTenantMutation = {
  __typename?: 'Mutation';
  addUsersToTenant: Array<{
    __typename?: 'User';
    _id: string;
    email: string;
    role?: SchemaTypes.Roles | null;
    isTwoFAEnabled?: boolean | null;
    createdAt: any;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
  }>;
};

export const AddUsersToTenantDocument = gql`
  mutation AddUsersToTenant($userIds: [ID!]!, $tenantId: ID!, $role: Roles!) {
    addUsersToTenant(userIds: $userIds, tenantId: $tenantId, role: $role) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type AddUsersToTenantMutationFn = Apollo.MutationFunction<
  AddUsersToTenantMutation,
  AddUsersToTenantMutationVariables
>;

/**
 * __useAddUsersToTenantMutation__
 *
 * To run a mutation, you first call `useAddUsersToTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToTenantMutation, { data, loading, error }] = useAddUsersToTenantMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      tenantId: // value for 'tenantId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddUsersToTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUsersToTenantMutation, AddUsersToTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUsersToTenantMutation, AddUsersToTenantMutationVariables>(
    AddUsersToTenantDocument,
    options,
  );
}
export type AddUsersToTenantMutationHookResult = ReturnType<typeof useAddUsersToTenantMutation>;
export type AddUsersToTenantMutationResult = Apollo.MutationResult<AddUsersToTenantMutation>;
export type AddUsersToTenantMutationOptions = Apollo.BaseMutationOptions<
  AddUsersToTenantMutation,
  AddUsersToTenantMutationVariables
>;
