import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useLogin from 'hooks/auth/useLogin';
import LoginForm, { ILoginFormValues } from 'components/auth/LoginForm';
import { toastErrorMessage } from 'utils/helpers';
import PageTitle from 'components/common/PageTitle/PageTitle';
import OTPForm from 'components/auth/OTPForm';

enum FormType {
  PASSWORD = 'PASSWORD',
  TWO_FA_CODE = 'TWO_FA_CODE',
}

const LoginPage = () => {
  const { t } = useTranslation();
  const [currentFormType, setCurrentFormType] = useState<FormType>(FormType.PASSWORD);
  const onSubmitLogin = useLogin();
  const initialValues: ILoginFormValues = { email: '', password: '' };

  const loginFormValuesRef = useRef<ILoginFormValues>(initialValues);

  const onSubmit = useCallback(
    async (values: ILoginFormValues) => {
      try {
        await onSubmitLogin({ variables: values });
      } catch (error: any) {
        if (error.message === 'TWO_FA_CODE_MISSING') {
          loginFormValuesRef.current = values;
          setCurrentFormType(FormType.TWO_FA_CODE);
          return;
        }
        toastErrorMessage(error);
      }
    },
    [onSubmitLogin],
  );

  return (
    <Row className="h-100">
      <Col md={6} lg={5} xxl={4} className="m-auto">
        <PageTitle className="text-center">{t('pageTitles.login')}</PageTitle>
        <div className={currentFormType !== FormType.PASSWORD ? 'd-none' : ''}>
          <LoginForm {...{ initialValues, onSubmit }} />
        </div>
        {currentFormType === FormType.TWO_FA_CODE ? (
          <OTPForm
            initialValues={{ code: '' }}
            onSubmit={(values) => onSubmit({ ...loginFormValuesRef.current, ...values })}
            onCancel={() => setCurrentFormType(FormType.PASSWORD)}
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default LoginPage;
