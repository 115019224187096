import React, { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TenantQuery } from 'graphql/queries/tenant/generated/Tenant';
import { Modal, useModal } from 'components/common/Modal';
import TenantForm from './TenantForm';
import { ButtonVariant } from 'react-bootstrap/esm/types';

export enum TenantModalType {
  CREATE_TENANT = 'CREATE_TENANT',
  UPDATE_TENANT = 'UPDATE_TENANT',
}

interface ITenantButtonParams {
  tenant?: TenantQuery['tenant'] | null;
  variant?: ButtonVariant;
  children?: React.ReactNode;
}

const TenantButton: FC<ITenantButtonParams> = ({ tenant, variant, children }) => {
  const [tenantModalType, setTenantModalType] = useState<TenantModalType>(TenantModalType.CREATE_TENANT);
  const { showModal, isVisible, hideModal } = useModal();
  const { t } = useTranslation();

  const onBtnClick = useCallback(
    (modalContentType: TenantModalType) => {
      setTenantModalType(modalContentType);
      showModal();
    },
    [showModal],
  );

  return (
    <>
      <Modal
        isVisible={isVisible}
        onHide={hideModal}
        headerCloseButton
        headerText={t(
          tenantModalType === TenantModalType.CREATE_TENANT
            ? 'tenantManagement.modal.headerCreate'
            : 'tenantManagement.modal.headerUpdate',
        )}
        size="lg"
      >
        <TenantForm {...{ hideModal, tenant, tenantModalType }} />
      </Modal>
      <Button
        variant={variant}
        onClick={() => onBtnClick(tenant ? TenantModalType.UPDATE_TENANT : TenantModalType.CREATE_TENANT)}
      >
        {children}
      </Button>
    </>
  );
};

export default TenantButton;
