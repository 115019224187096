import constants from 'constants/constants';
import { DeactivateUserMutation, useDeactivateUserMutation } from 'graphql/mutations/user/generated/DeactivateUser';
import { AllUsersDocument, AllUsersQuery, AllUsersQueryVariables } from 'graphql/queries/user/generated/AllUsers';
import { AccountType } from 'graphql/types.generated';
import { useGetUsersFilter } from 'hooks/user/useGetUsers';

import { toastErrorMessage } from 'utils/helpers';

const { tableRowsCountPerPage } = constants;

interface DeactivateUserParams {
  userId: string;
  accountType: AccountType;
}

const useDeactivateUser = ({ userId, accountType }: DeactivateUserParams) => {
  const usersFilter = useGetUsersFilter();

  const [deactivateUser, { loading, client }] = useDeactivateUserMutation({
    onError: toastErrorMessage,
    refetchQueries: ['TenantUsers'],
    onCompleted: (res: DeactivateUserMutation) => {
      if (!res || !res?.deactivateUser) return;
      const variables: AllUsersQueryVariables = {
        limit: tableRowsCountPerPage,
        skip: 0,
        filter: { ...usersFilter, accountType },
      };
      client.cache.updateQuery({ query: AllUsersDocument, variables }, (data: AllUsersQuery | null) => {
        if (!data) return data;
        const { allUsers } = data;
        const { items } = allUsers;
        const newItems = items.filter(({ _id }) => _id !== userId);
        return { ...data, allUsers: { ...allUsers, items: newItems } };
      });
    },
  });

  return { deactivateUser, loading };
};

export default useDeactivateUser;
