import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, useModal } from 'components/common/Modal';
import Button from 'components/common/Button';
import ExcelReportsModalContent from './ExcelReportsModalContent';

interface IExcelReportsButtonProps {
  iframeId?: string;
}

const ExcelReportsButton: FC<IExcelReportsButtonProps> = ({ iframeId }) => {
  const { t } = useTranslation();
  const { showModal, isVisible, hideModal } = useModal();

  return (
    <>
      <Button className="mb-3" onClick={showModal}>
        {t('projectReports.button')}
      </Button>
      <Modal
        isVisible={isVisible}
        onHide={hideModal}
        headerCloseButton
        headerText={t('projectReports.modal.title')}
        fullscreen
      >
        <ExcelReportsModalContent iframeId={iframeId} />
      </Modal>
    </>
  );
};

export default ExcelReportsButton;
