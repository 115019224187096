import { MultiValue, StylesConfig } from 'react-select';
import { ISelectOption } from './Select';
import { IMultiSelectOption } from './MultiSelect';
import theme from 'constants/theme';

const dot = {
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: theme.primaryColor,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    minHeight: 10,
    width: 10,
    minWidth: 10,
  },
};

export const selectStyles: StylesConfig<ISelectOption | MultiValue<IMultiSelectOption>> = {
  container: (style) => ({
    ...style,
    outline: 'none',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
  }),
  control: (style) => ({
    ...style,
    border: '1px solid #ced4da',
    borderRadius: '0.375em',
    outline: 'none',
    boxShadow: 'none',
    minWidth: '150px',
    fontSize: '15px',
    paddingLeft: '12px',
    '&:hover': {
      outline: 'none',
    },
    marginTop: '-1px',
  }),
  dropdownIndicator: (style) => ({
    ...style,
    color: theme.primaryColor,
  }),
  valueContainer: (style) => ({
    ...style,
    padding: 0,
    marginLeft: '-2px',
  }),
  singleValue: (style) => ({
    ...style,
  }),
  option: (style, { isSelected, isDisabled }) => ({
    ...style,
    fontSize: '15px',
    textAlign: 'left',
    ...(isSelected ? { ...dot } : {}),
    backgroundColor: 'white',
    color: isSelected ? theme.primaryColor : 'black',
    ...(isDisabled
      ? { color: isDisabled ? theme.grayColor : 'black', cursor: isDisabled ? 'not-allowed' : 'default' }
      : {}),
    ':hover': {
      ...style[':hover'],
      backgroundColor: theme.primaryLightColor,
    },
  }),
  menu: (style) => ({ ...style, minWidth: 'fit-content', whiteSpace: 'pre-line' }),
  menuPortal: (style) => ({ ...style, zIndex: 50 }),
};

export const multiSelectStyles: StylesConfig<MultiValue<IMultiSelectOption>> = {
  ...(selectStyles as StylesConfig<MultiValue<IMultiSelectOption>>),
  valueContainer: (style) => ({
    ...style,
    padding: '1px',
  }),
  multiValue: (style) => ({
    ...style,
    padding: '2px 3px',
  }),
  menuPortal: (style) => ({ ...style, zIndex: 50 }),
};
