import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import Tabs from 'components/common/Tabs/Tabs';
import UserManagement from 'components/userManagement/UserManagement';
import { AccountType } from 'graphql/types.generated';
import ApplicationManagement from 'components/applications/ApplicationManagement';

enum TabKeys {
  meinbauAdmins = 'meinbauAdmins',
  tenantUsers = 'tenantUsers',
  apiUsers = 'apiUsers',
}

const UserManagementPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('pageTitles.userManagement')}</PageTitle>
      <Tabs
        initialTabEventKey={TabKeys.meinbauAdmins}
        restoreLastActiveTabKey="__last_active_user_management_tab__"
        tabs={[
          {
            eventKey: TabKeys.meinbauAdmins,
            title: <div>{t('userManagement.tabs.meinbauAdmins')}</div>,
            content: <UserManagement accountType={AccountType.meinbau} />,
          },
          {
            eventKey: TabKeys.tenantUsers,
            title: <div>{t('userManagement.tabs.tenantUsers')}</div>,
            content: <UserManagement accountType={AccountType.tenant} />,
          },
          {
            eventKey: TabKeys.apiUsers,
            title: <div>{t('userManagement.tabs.apiUsers')}</div>,
            content: <ApplicationManagement />,
          },
        ]}
      />
    </>
  );
};

export default UserManagementPage;
