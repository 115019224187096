import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Stack, Badge, ListGroup } from 'react-bootstrap';
import Button from 'components/common/Button/Button';
import TenantButton from 'components/tenants/TenantButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { TenantQuery } from 'graphql/queries/tenant/generated/Tenant';
import { confirmAlert } from 'components/common/Alert';
import UserActionBadge from 'components/common/UserActionBadge/UserActionBadge';
import { useArchiveTenantMutation } from 'graphql/mutations/tenant/generated/ArchiveTenant';
import { useReleaseTenantMutation } from 'graphql/mutations/tenant/generated/ReleaseTenant';
import { toastErrorMessage } from 'utils/helpers';
import useUser from 'hooks/user/useUser';
import { Roles } from 'graphql/types.generated';

interface ITenantBaseDataParams {
  tenant: TenantQuery['tenant'];
}

const TenantBaseData: FC<ITenantBaseDataParams> = ({ tenant }) => {
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const { t } = useTranslation('translation', { keyPrefix: 'tenantManagement' });
  const [archiveTenant] = useArchiveTenantMutation({ onError: toastErrorMessage });
  const [releaseTenant] = useReleaseTenantMutation({ onError: toastErrorMessage });

  const onChangeArchivingStatus = useCallback(async () => {
    confirmAlert({
      onConfirm: async () => {
        if (tenant?.archived) {
          await releaseTenant({
            variables: { tenantId: tenant._id },
          });
        } else {
          await archiveTenant({
            variables: { tenantId: tenant._id },
          });
        }
      },
      title: tenant.archived ? t('alert.releaseHeader') : t('alert.suspendHeader'),
      message: tenant.archived ? t('alert.releaseTenantAlert') : t('alert.suspendTenantAlert'),
    });
  }, [archiveTenant, releaseTenant, t, tenant._id, tenant.archived]);

  return (
    <>
      <Card>
        <Card.Header>
          <Stack direction="horizontal" gap={3}>
            <Card.Title className="mb-0">{tenant.companyName}</Card.Title>
            <Badge className="ms-auto" bg={tenant.archived ? 'danger' : 'success'}>
              {tenant.archived ? t('archived') : t('active')}
            </Badge>
          </Stack>
        </Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.createdBy')}</Card.Text>
              <UserActionBadge user={tenant.createdBy} />
            </ListGroup.Item>
            {tenant.updatedBy && (
              <ListGroup.Item>
                <Card.Text className="fw-bold mb-1">{t('baseData.updatedBy')}</Card.Text>
                <UserActionBadge user={tenant.updatedBy} />
              </ListGroup.Item>
            )}
            {tenant.changedStatusBy && (
              <ListGroup.Item>
                <Card.Text className="fw-bold mb-1">
                  {tenant.archived ? t('baseData.archivedBy') : t('baseData.releasedBy')}
                </Card.Text>
                <UserActionBadge user={tenant.changedStatusBy} />
              </ListGroup.Item>
            )}
          </ListGroup>
        </Card.Body>
        {me?.role === Roles.MEINBAU_ADMIN && (
          <Card.Footer>
            <Stack direction="horizontal" gap={3}>
              <TenantButton variant="outline-primary" tenant={tenant}>
                {t('tabs.updateTenantBtn')} <FontAwesomeIcon icon={faEdit} />
              </TenantButton>

              <Button
                className="ms-auto"
                variant={tenant.archived ? 'success' : 'danger'}
                onClick={onChangeArchivingStatus}
              >
                {tenant.archived ? (
                  <>
                    {t('tabs.releaseTenantBtn')} <FontAwesomeIcon icon={faUnlock} />
                  </>
                ) : (
                  <>
                    {t('tabs.suspendTenantBtn')} <FontAwesomeIcon icon={faLock} />
                  </>
                )}
              </Button>
            </Stack>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};

export default TenantBaseData;
