import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateApplicationAccountMutationVariables = SchemaTypes.Exact<{
  appName: SchemaTypes.Scalars['String']['input'];
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']['input']>;
}>;

export type CreateApplicationAccountMutation = {
  __typename?: 'Mutation';
  createApplicationAccount: { __typename?: 'CreatedApplication'; _id: string; secret: string };
};

export const CreateApplicationAccountDocument = gql`
  mutation CreateApplicationAccount($appName: String!, $tenantId: String) {
    createApplicationAccount(appName: $appName, tenantId: $tenantId) {
      _id
      secret
    }
  }
`;
export type CreateApplicationAccountMutationFn = Apollo.MutationFunction<
  CreateApplicationAccountMutation,
  CreateApplicationAccountMutationVariables
>;

/**
 * __useCreateApplicationAccountMutation__
 *
 * To run a mutation, you first call `useCreateApplicationAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationAccountMutation, { data, loading, error }] = useCreateApplicationAccountMutation({
 *   variables: {
 *      appName: // value for 'appName'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useCreateApplicationAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApplicationAccountMutation, CreateApplicationAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApplicationAccountMutation, CreateApplicationAccountMutationVariables>(
    CreateApplicationAccountDocument,
    options,
  );
}
export type CreateApplicationAccountMutationHookResult = ReturnType<typeof useCreateApplicationAccountMutation>;
export type CreateApplicationAccountMutationResult = Apollo.MutationResult<CreateApplicationAccountMutation>;
export type CreateApplicationAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationAccountMutation,
  CreateApplicationAccountMutationVariables
>;
