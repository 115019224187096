import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { AllApplicationsQuery } from 'graphql/queries/applications/generated/AllApplications';
import UserActionBadge from 'components/common/UserActionBadge';
import { BasicUserAttributes } from 'graphql/types.generated';
import { formatDate } from 'utils/helpers';
import ApplicationArchiveButton from './ApplicationArchiveButton';

export type ApplicationListItem = AllApplicationsQuery['allApplications']['items'][0];
interface iApplicationsTableProps extends IPaginationProps {
  applications: ApplicationListItem[];
  loading: boolean;
}

export type ApplicationsTableRow = {
  _id: string;
  appName: string;
  createdBy: BasicUserAttributes;
  createdAt: string;
  archived?: boolean | null;
  archivedBy?: BasicUserAttributes | null;
};

type ColumnDefWithHide = ColumnDef<ApplicationsTableRow> & { hide?: boolean };

const ApplicationsTable: FC<iApplicationsTableProps> = ({ applications, currentPage, changePage, total, loading }) => {
  const { t, i18n } = useTranslation();

  const isAllArchived = useMemo(
    () => applications.filter((application) => !application.archived).length === 0,
    [applications],
  );

  const data: ApplicationsTableRow[] = useMemo(() => {
    return applications.map((application: ApplicationListItem) => {
      const {
        _id,
        profile: { appName },
        createdBy,
        createdAt,
        archived,
        archivedBy,
        tenantName,
      } = application;
      return {
        _id,
        appName,
        tenantName,
        createdBy,
        createdAt,
        archived,
        archivedBy,
      };
    });
  }, [applications]);

  const columns: ColumnDefWithHide[] = useMemo(() => {
    const columnsTmp: ColumnDefWithHide[] = [
      {
        id: 'appName',
        header: t('applicationManagement.tableHeaders.appName'),
        accessorKey: 'appName',
        cell: (info) => (
          <div>
            <FontAwesomeIcon icon={faServer} /> {info.getValue() as string}
          </div>
        ),
      },
      {
        id: 'tenantName',
        header: t('applicationManagement.tableHeaders.tenantName'),
        accessorKey: 'tenantName',
      },
      {
        id: 'createdBy',
        header: t('applicationManagement.tableHeaders.createdBy'),
        accessorKey: 'createdBy',
        cell: (info) => (
          <>
            <UserActionBadge user={info.getValue() as BasicUserAttributes} />
          </>
        ),
      },
      {
        id: 'createdAt',
        header: t('applicationManagement.tableHeaders.createdAt'),
        accessorKey: 'createdAt',
        cell: (info) => (
          <>
            <div className="text-wrap">
              {formatDate({ date: new Date(info.getValue() as string), locale: i18n.language })}
            </div>
          </>
        ),
      },
      {
        id: 'archivedBy',
        header: t('applicationManagement.tableHeaders.archivedBy'),
        accessorKey: 'archivedBy',
        cell: (info) => (
          <>
            <UserActionBadge user={info.getValue() as BasicUserAttributes} />
          </>
        ),
      },
      {
        header: '',
        accessorKey: '_id',
        hide: isAllArchived,
        cell: (info) => (
          <>{!info.row.original.archived && <ApplicationArchiveButton applicationId={info.getValue() as string} />}</>
        ),
      },
    ];

    return columnsTmp.filter((column) => !column.hide);
  }, [i18n.language, isAllArchived, t]);

  return (
    <>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        emptyText="applicationManagement.noApplicationsFound.description"
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default ApplicationsTable;
