import PageTitle from 'components/common/PageTitle';
import IframeForm from 'components/iframe/form/IframeForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const CreateIframePage = () => {
  const { t } = useTranslation();
  const { tenantId } = useParams() as { tenantId: string };
  return (
    <>
      <PageTitle>{t('iframe.form.headerCreate')}</PageTitle>
      <IframeForm tenantId={tenantId} />
    </>
  );
};

export default CreateIframePage;
