import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TenantFragmentDoc } from '../../../fragments/tenant/generated/tenantFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantsQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  searchString: SchemaTypes.Scalars['String']['input'];
  status: SchemaTypes.TenantStatusFilter;
}>;

export type TenantsQuery = {
  __typename?: 'Query';
  tenants: {
    __typename?: 'PaginatedTenantResponse';
    total: number;
    items: Array<{
      __typename?: 'Tenant';
      _id: string;
      companyName: string;
      archived?: boolean | null;
      createdBy: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      };
      updatedBy?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
      changedStatusBy?: {
        __typename?: 'BasicUserAttributes';
        _id: string;
        email: string;
        profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      } | null;
    }>;
  };
};

export const TenantsDocument = gql`
  query Tenants($limit: Int!, $skip: Int!, $searchString: String!, $status: TenantStatusFilter!) {
    tenants(limit: $limit, skip: $skip, searchString: $searchString, status: $status) {
      total
      items {
        ...Tenant
      }
    }
  }
  ${TenantFragmentDoc}
`;

/**
 * __useTenantsQuery__
 *
 * To run a query within a React component, call `useTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      searchString: // value for 'searchString'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTenantsQuery(baseOptions: Apollo.QueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
}
export function useTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
}
export type TenantsQueryHookResult = ReturnType<typeof useTenantsQuery>;
export type TenantsLazyQueryHookResult = ReturnType<typeof useTenantsLazyQuery>;
export type TenantsQueryResult = Apollo.QueryResult<TenantsQuery, TenantsQueryVariables>;
export function refetchTenantsQuery(variables: TenantsQueryVariables) {
  return { query: TenantsDocument, variables: variables };
}
