import { useCallback, useEffect, useMemo } from 'react';
import useGetSearchParams from './useGetSearchParams';

interface IPaginationParams {
  searchParamKey?: any;
  tableRef?: React.RefObject<HTMLTableElement>;
  onScroll?: (props: Pick<IPaginationParams, 'tableRef'>) => void;
  forcePageNumber?: number | null;
}

const defaultOnScroll = ({ tableRef }: Pick<IPaginationParams, 'tableRef'>) => {
  if (tableRef?.current) {
    const position = tableRef.current.getBoundingClientRect();
    window.scrollTo({ top: position.top + window.scrollY - 120, left: position.left, behavior: 'smooth' });
  } else {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
};

const usePagination = ({
  searchParamKey = 'page',
  onScroll = defaultOnScroll,
  tableRef,
  forcePageNumber,
}: IPaginationParams = {}) => {
  const { searchParams, setSearchParams } = useGetSearchParams();
  const currentPage = useMemo(
    () => forcePageNumber || Number(searchParams[searchParamKey]) || 1,
    [forcePageNumber, searchParamKey, searchParams],
  );
  const onChangeCurrentPage = useCallback(
    (page: number) => setSearchParams({ ...searchParams, [searchParamKey]: String(page) }),
    [searchParamKey, searchParams, setSearchParams],
  );

  useEffect(() => {
    if (forcePageNumber && forcePageNumber !== currentPage) {
      onChangeCurrentPage(forcePageNumber);
    }
  }, [currentPage, forcePageNumber, onChangeCurrentPage]);

  const handleChangePage = useCallback(
    (_page: number) => {
      if (forcePageNumber) {
        onChangeCurrentPage(forcePageNumber);
      } else {
        onChangeCurrentPage(_page);
      }
      onScroll({ tableRef });
    },
    [forcePageNumber, onChangeCurrentPage, onScroll, tableRef],
  );

  return { changePage: handleChangePage, currentPage };
};

export default usePagination;
