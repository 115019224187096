import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { MeFragmentDoc } from '../../../fragments/user/generated/MeFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RedeemInvitationMutationVariables = SchemaTypes.Exact<{
  invitationId: SchemaTypes.Scalars['ID']['input'];
  password: SchemaTypes.Scalars['String']['input'];
}>;

export type RedeemInvitationMutation = {
  __typename?: 'Mutation';
  redeemInvitation: {
    __typename?: 'AuthResponse';
    refreshToken?: string | null;
    user: {
      __typename?: 'User';
      _id: string;
      email: string;
      role?: SchemaTypes.Roles | null;
      tenantId?: string | null;
      isTwoFAEnabled?: boolean | null;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      activeRoles: Array<{ __typename?: 'UserRoleClass'; role: SchemaTypes.Roles; tenantId?: string | null }>;
    };
  };
};

export const RedeemInvitationDocument = gql`
  mutation RedeemInvitation($invitationId: ID!, $password: String!) {
    redeemInvitation(invitationId: $invitationId, password: $password) {
      user {
        ...Me
      }
      refreshToken
    }
  }
  ${MeFragmentDoc}
`;
export type RedeemInvitationMutationFn = Apollo.MutationFunction<
  RedeemInvitationMutation,
  RedeemInvitationMutationVariables
>;

/**
 * __useRedeemInvitationMutation__
 *
 * To run a mutation, you first call `useRedeemInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemInvitationMutation, { data, loading, error }] = useRedeemInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRedeemInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<RedeemInvitationMutation, RedeemInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RedeemInvitationMutation, RedeemInvitationMutationVariables>(
    RedeemInvitationDocument,
    options,
  );
}
export type RedeemInvitationMutationHookResult = ReturnType<typeof useRedeemInvitationMutation>;
export type RedeemInvitationMutationResult = Apollo.MutationResult<RedeemInvitationMutation>;
export type RedeemInvitationMutationOptions = Apollo.BaseMutationOptions<
  RedeemInvitationMutation,
  RedeemInvitationMutationVariables
>;
