import React from 'react';
import PlanningItemsForm from './PlanningItemsForm';
import { useIframePlanningItemsQuery } from 'graphql/queries/iframe/generated/IframePlanningItems';
import { ErrorAlert } from 'components/common/Alert';
import ResetPlanningItemsButton from './ResetPlanningItemsButton';

interface IframePlanningItemsTabProps {
  iframeId: string;
}

const IframePlanningItemsTab: FC<IframePlanningItemsTabProps> = ({ iframeId }) => {
  const { data, loading, error } = useIframePlanningItemsQuery({
    variables: { iframeId },
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <ErrorAlert apolloError={error} />;

  return (
    <>
      <div className="mb-3 text-end">
        <ResetPlanningItemsButton iframeId={iframeId} />
      </div>
      <PlanningItemsForm iframeId={iframeId} items={data?.iframe.planningItems} loading={loading} />
    </>
  );
};

export default IframePlanningItemsTab;
