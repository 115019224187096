import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../layout/Footer';
import Navbar from '../layout/Navbar';
import s from './Layout.module.sass';
import { Stack } from 'react-bootstrap';

type iAuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: FC<iAuthLayoutProps> = ({ children }) => {
  return (
    <Container fluid>
      <Row className="p-0">
        <Col xs={12} className={s.col}>
          <Stack as="main" direction="vertical" className={s.content} style={{ padding: 0 }}>
            <Navbar auth />
            <div className={s.children}>{children}</div>
            <Footer hideSidebar />
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthLayout;
