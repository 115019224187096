import { QueryHookOptions } from '@apollo/client';
import {
  MeinBauApiLogQuery,
  MeinBauApiLogQueryVariables,
  useMeinBauApiLogQuery,
} from 'graphql/queries/meinBauApiLogs/generated/MeinBauApiLog';

interface IMeinBauApiLogProps extends QueryHookOptions<MeinBauApiLogQuery, MeinBauApiLogQueryVariables> {
  meinBauApiLogId: string;
}

export default function useMeinBauApiLog({ meinBauApiLogId }: IMeinBauApiLogProps) {
  const { data, loading, error } = useMeinBauApiLogQuery({ variables: { meinBauApiLogId }, skip: !meinBauApiLogId });
  return { meinBauApiLog: (data?.meinBauApiLog || null) as MeinBauApiLogQuery['meinBauApiLog'] | null, loading, error };
}
