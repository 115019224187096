import React, { useMemo } from 'react';
import Table from 'components/common/Table/Table';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/inputs/TextInput';
import { FastField } from 'formik';
import { ColumnDef } from '@tanstack/react-table';

type AssetsTableRow = {
  itemName: string;
};

const InputField = ({ disabled, name }: { disabled?: boolean; name: string }) => {
  return <FastField formGroupClassName="mb-1 w-100" name={name} component={TextInput} disabled={disabled} />;
};

const fuelTypes = [
  'heatingOil',
  'naturalGas',
  'coal',
  'firewood',
  'woodChips',
  'woodPellets',
  'electricityUsed',
  'electricityProduced',
  'districtHeating',
];

const IframeFuelMatrixTable: React.FunctionComponent<{ disabled: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();

  const columns: ColumnDef<AssetsTableRow>[] = useMemo(
    () => [
      {
        header: t('fuelMatrix.tableHeaders.itemName'),
        accessorKey: 'itemName',
        cell: (info) => t(`fuelMatrix.tableRowItems.${info.getValue() as string}`),
      },
      {
        header: t('fuelMatrix.tableHeaders.input'),
        accessorKey: 'input',
        cell: (info) => <InputField name={`${info.row.original.itemName}.input`} disabled />,
      },
      {
        header: t('fuelMatrix.tableHeaders.unit'),
        accessorKey: 'unit',
        cell: (info) => <InputField name={`${info.row.original.itemName}.unit`} disabled />,
      },
      {
        header: t('fuelMatrix.tableHeaders.avgPricePerUnit'),
        accessorKey: 'avgPricePerUnit',
        cell: (info) => <InputField name={`${info.row.original.itemName}.avgPricePerUnit`} disabled={disabled} />,
      },
      {
        header: t('fuelMatrix.tableHeaders.conversionFactor'),
        accessorKey: 'conversionFactor',
        cell: (info) => <InputField name={`${info.row.original.itemName}.conversionFactor`} disabled />,
      },
      {
        header: t('fuelMatrix.tableHeaders.resultInKwh'),
        accessorKey: 'resultInKWhHeating',
        cell: (info) => <InputField name={`${info.row.original.itemName}.resultInKWhHeating`} disabled />,
      },
      {
        header: t('fuelMatrix.tableHeaders.avgPricePerKwh'),
        accessorKey: 'avgPricePerKWh',
        cell: (info) => <InputField name={`${info.row.original.itemName}.avgPricePerKWh`} disabled />,
      },
    ],
    [disabled, t],
  );

  const data = fuelTypes.map((item) => {
    return { itemName: item };
  });

  return <Table columns={columns} data={data} />;
};

export default IframeFuelMatrixTable;
