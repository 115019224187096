import React, { useCallback, useState } from 'react';
import { ButtonProps, Card, Col, Row } from 'react-bootstrap';
import { confirmAlert as reactConfirmAlert, ReactConfirmAlertProps } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toastErrorMessage } from '../../../utils/helpers';
import Button from '../Button/Button';

interface IAlert<V> {
  title: string;
  message: string;
  type: ButtonProps['variant'];
  buttons?: FC<{ onClose: () => void; onSubmit: (props?: V) => void; isSubmitting: boolean }>;
  onClose: () => void;
  onConfirm?: (props?: V) => void;
}

const Alert = <V,>({ title, message, onClose, type, onConfirm, buttons: Buttons }: IAlert<V>) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      if (typeof onConfirm === 'function') await onConfirm();
      onClose();
    } catch (error: any) {
      toastErrorMessage(error);
    } finally {
      setSubmitting(false);
    }
  }, [onClose, onConfirm]);

  return (
    <Row className="justify-content-center" style={{ minWidth: '350px' }}>
      <Col md={10}>
        <Card>
          <Card.Header className="bg-white text-center">
            <Card.Title className="mb-0">{title}</Card.Title>
          </Card.Header>
          <Card.Body className="text-center">{message}</Card.Body>
          <Card.Footer className="bg-white d-flex align-items-center justify-content-around">
            {Buttons ? (
              <Buttons onClose={onClose} onSubmit={onSubmit} isSubmitting={isSubmitting} />
            ) : (
              <>
                <Button variant="outline-secondary" disabled={isSubmitting} onClick={onClose}>
                  {t('buttons.cancel')}
                </Button>
                <Button variant={type} loading={isSubmitting} onClick={onSubmit}>
                  {t('buttons.confirm')}
                </Button>
              </>
            )}
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

const confirmAlert = <V,>({
  title,
  message,
  onConfirm,
  type = 'danger',
  customUI,
  buttons,
}: {
  title?: string;
  message?: string;
  onConfirm?: (props?: V) => void;
  buttons?: FC<{ onClose: () => void; onSubmit: (props?: V) => void; isSubmitting: boolean }>;
  type?: string;
  customUI?: ReactConfirmAlertProps['customUI'];
  targetId?: string;
}) => {
  return reactConfirmAlert({
    title,
    message,
    closeOnClickOutside: true,
    closeOnEscape: true,
    customUI: customUI ? customUI : (props) => <Alert {...props} {...{ onConfirm, type, buttons }} />,
  });
};

export default confirmAlert;
