import React, { useMemo } from 'react';
import {
  TableOptions,
  flexRender,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
} from '@tanstack/react-table';
import s from './Table.module.sass';
import { ExpandedHeader } from './components/ExpandedWrapper';
import LoadingOverlay from '../Loading/LoadingOverlay';
import EmptyRow, { iEmptyRow } from './components/EmptyRow';
import { useTranslation } from 'react-i18next';
import ReactTableRow, { iReactTableRow } from './TableRow';
import { getCommonPinningStyles, getColumnSize } from './helpers';
import { isUndefined } from 'lodash';
import cn from 'classnames';
import getRowSelectionColumn from './components/RowSelection/RowSelectionColumn';
import FetchingMoreItemsRow, { iFetchingMoreItemsRow } from './components/FetchingMoreItemsRow';
import RowPlaceholder from './components/RowPlaceholder';

export interface iReactTable<T extends object>
  extends Omit<TableOptions<T>, 'getCoreRowModel' | 'data'>,
    Partial<Pick<TableOptions<T>, 'getCoreRowModel' | 'data'>>,
    Pick<iEmptyRow, 'emptyText'>,
    Pick<iReactTableRow<T>, 'expandedFieldId' | 'getRowProps'>,
    Pick<iFetchingMoreItemsRow, 'sentryRef'> {
  loading?: boolean;
  maxExpandedSpace?: number;
  dataQa?: string;
  className?: string;
  containerClassName?: string;
  isFetchingMoreItems?: boolean;
  omitColgroup?: boolean;
  hideFooter?: boolean;
  preRows?: (params: { numberOfColumns: number }) => React.ReactNode | React.ReactNode[];
}

const ReactTable = <T extends object>({
  emptyText,
  expandedFieldId,
  loading,
  getRowProps,
  maxExpandedSpace,
  data,
  columns,
  enableRowSelection,
  dataQa,
  className,
  isFetchingMoreItems,
  sentryRef,
  preRows,
  containerClassName,
  omitColgroup,
  hideFooter = true,
  ...options
}: iReactTable<T>) => {
  const { t } = useTranslation();

  const enhancedColumns = useMemo<ColumnDef<T>[]>(() => {
    if (enableRowSelection) {
      const rowSelectionColumn = getRowSelectionColumn<T>();
      return [rowSelectionColumn, ...columns];
    }
    return columns;
  }, [columns, enableRowSelection]);

  const table = useReactTable({
    ...options,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection,
    columns: enhancedColumns,
    data: data ?? [],
  });
  const { rows } = table.getRowModel();
  const headerGroups = table.getHeaderGroups();
  const numberOfColumns = useMemo(() => headerGroups.reduce((acc, e) => e.headers.length, 0), [headerGroups]);
  const expandedSpace = useMemo(() => {
    if (typeof maxExpandedSpace === 'number' && Number.isFinite(maxExpandedSpace)) {
      const depth = table.getExpandedDepth();
      return depth && maxExpandedSpace / depth;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxExpandedSpace, table, data]);

  return (
    <div className={cn('position-relative overflow-x-auto', containerClassName)} data-qa={dataQa}>
      <table className={cn(s.table, className)} role="grid">
        {!omitColgroup ? (
          <colgroup>
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((header) => <col style={getColumnSize(header.column)} key={header.id} />),
            )}
          </colgroup>
        ) : null}
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                let children = null;
                if (!header.isPlaceholder) {
                  if (typeof header.column.columnDef.header === 'string') children = t(header.column.columnDef.header);
                  else if (isUndefined(header.column.columnDef.header)) return null;
                  else children = flexRender(header.column.columnDef.header, header.getContext());
                }
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    style={{ ...getCommonPinningStyles(header.column) }}
                  >
                    <ExpandedHeader
                      table={table}
                      isExpanded={expandedFieldId === header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {children}
                      <span>
                        {{
                          asc: ' ▲',
                          desc: ' ▼',
                        }[header.column.getIsSorted() as string] ?? null}
                      </span>
                    </ExpandedHeader>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {preRows ? preRows({ numberOfColumns }) : null}
          {rows.length
            ? rows.map((row) => {
                return (
                  <ReactTableRow
                    expandedSpace={expandedSpace}
                    key={row.id}
                    row={row}
                    expandedFieldId={expandedFieldId}
                    getRowProps={getRowProps}
                  />
                );
              })
            : null}
          {!loading && !rows.length ? <EmptyRow colSpan={numberOfColumns} emptyText={emptyText} /> : null}
          {isFetchingMoreItems ? <FetchingMoreItemsRow sentryRef={sentryRef} colSpan={numberOfColumns} /> : null}
          {loading && !data?.length ? <RowPlaceholder columns={numberOfColumns} /> : null}
        </tbody>
        {!hideFooter ? (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        ) : null}
      </table>
      {loading && data?.length ? <LoadingOverlay /> : null}
    </div>
  );
};

export default ReactTable;
