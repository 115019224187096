import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';
import { ResultPageConfiguration, ResultPageConfigurationInput } from 'graphql/types.generated';

export const resultPageConfiguration = (iframe?: IframeQuery['iframe']): ResultPageConfigurationInput => {
  const resultPageConfiguration = iframe?.resultPageConfiguration || {};
  const {
    headerSection,
    secondPartnerSection,
    planSection,
    totalCostSection,
    equivalentsSection,
    firstPartnerSection,
    investmentsPayOfSection,
  } = resultPageConfiguration as ResultPageConfiguration;
  return {
    headerSection: {
      header: {
        en: headerSection?.header?.en || '',
        de: headerSection?.header?.de || '',
      },
      position: headerSection?.position || 0,
      isEnabled: headerSection ? headerSection.isEnabled : true,
    },
    planSection: {
      position: planSection?.position || 1,
      isEnabled: equivalentsSection ? planSection.isEnabled : true,
    },
    equivalentsSection: {
      position: equivalentsSection?.position || 2,
      isEnabled: equivalentsSection ? equivalentsSection.isEnabled : true,
    },
    totalCostSection: {
      position: totalCostSection?.position || 3,
      isEnabled: totalCostSection ? totalCostSection.isEnabled : true,
    },
    investmentsPayOfSection: {
      position: investmentsPayOfSection?.position || 4,
      isEnabled: investmentsPayOfSection ? investmentsPayOfSection.isEnabled : true,
    },
    firstPartnerSection: {
      header: {
        en: firstPartnerSection?.header?.en || '',
        de: firstPartnerSection?.header?.de || '',
      },
      content: {
        en: firstPartnerSection?.content?.en || '',
        de: firstPartnerSection?.content?.de || '',
      },
      pdfButton: {
        en: firstPartnerSection?.pdfButton?.en || '',
        de: firstPartnerSection?.pdfButton?.de || '',
      },
      position: firstPartnerSection?.position || 5,
      isEnabled: firstPartnerSection ? firstPartnerSection.isEnabled : true,
    },
    secondPartnerSection: {
      header: {
        en: secondPartnerSection?.header?.en || '',
        de: secondPartnerSection?.header?.de || '',
      },
      content: {
        en: secondPartnerSection?.content?.en || '',
        de: secondPartnerSection?.content?.de || '',
      },
      pdfButton: {
        en: secondPartnerSection?.pdfButton?.en || '',
        de: secondPartnerSection?.pdfButton?.de || '',
      },
      position: secondPartnerSection?.position || 6,
      isEnabled: secondPartnerSection ? secondPartnerSection.isEnabled : true,
    },
  };
};
