import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Alert, ListGroup } from 'react-bootstrap';
import { useApolloClient } from '@apollo/client';
import { MeDocument } from 'graphql/queries/user/generated/Me';
import constants from 'constants/constants';
import s from './TenantsList.module.sass';
import { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';
import { useTranslation } from 'react-i18next';
import { ctxTenantIdVar } from 'graphql/apollo';
import { routePaths } from 'router/routes';
import Loading from 'components/common/Loading/Loading';
import { AllUserTenantsDocument, AllUserTenantsQuery } from 'graphql/queries/user/generated/AllUserTenants';

const { selectOptionsCount } = constants;

interface ITenantsList {
  data: AllUserTenantsQuery;
  searchQuery: string;
  isFetching: boolean;
  sentryRef: UseInfiniteScrollHookRefCallback;
}

const TenantsList: FC<ITenantsList> = ({ data, searchQuery, isFetching, sentryRef }) => {
  const { allUserTenants, me } = data;
  const client = useApolloClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSelectTenant = useCallback(
    async (id: string) => {
      const userRoleByTenantId = me?.activeRoles.find(({ tenantId }) => tenantId === id);
      const data = client.readQuery({
        query: AllUserTenantsDocument,
        variables: { limit: selectOptionsCount, skip: allUserTenants.items.length, searchQuery },
      });
      const user = { ...data.me, ...userRoleByTenantId, __typename: 'User' };
      client.writeQuery({ query: MeDocument, data: { me: user } });
      ctxTenantIdVar(id);
      navigate(generatePath(routePaths.tenantDashboard, { tenantId: id }));
    },
    [allUserTenants.items.length, client, me?.activeRoles, navigate, searchQuery],
  );

  if (!me) {
    return <Alert variant="danger">{t('errors.userNotFound')}</Alert>;
  }

  return (
    <ListGroup>
      {allUserTenants.items.map(({ _id, companyName }) => (
        <ListGroup.Item as="button" key={_id} className={s.listGroupItem} onClick={() => handleSelectTenant(_id)}>
          <div className="d-flex flex-column">
            <h5 className="mb-0 text-start">{companyName}</h5>
          </div>
          <FontAwesomeIcon icon={faChevronRight} className={s.chevronIcon} />
        </ListGroup.Item>
      ))}
      {isFetching ? (
        <div ref={sentryRef}>
          <Loading position="center" />
        </div>
      ) : null}
    </ListGroup>
  );
};

export default TenantsList;
