import { faEdit, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import SubmitButton from 'components/common/Button/SubmitButton';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { routePaths } from 'router/routes';

interface IframeFormButtonsProps {
  iframeId?: string;
  tenantId: string;
}

const IframeFormButtons: FC<IframeFormButtonsProps> = ({ iframeId, tenantId }) => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext();
  const navigate = useNavigate();

  const onCloseBtn = useCallback(() => {
    if (!iframeId) {
      navigate(generatePath(routePaths.tenantManagement, { tenantId }));
    } else {
      navigate(generatePath(routePaths.tenantIframe, { iframeId, tenantId }));
    }
  }, [iframeId, navigate, tenantId]);

  return (
    <Stack direction="horizontal" className="justify-content-end" gap={3}>
      <Button variant="secondary" disabled={isSubmitting} onClick={onCloseBtn}>
        {t('iframe.form.close')} <FontAwesomeIcon icon={faXmark} />
      </Button>
      <SubmitButton label={iframeId ? 'iframe.form.update' : 'iframe.form.create'} icon={iframeId ? faEdit : faPlus} />
    </Stack>
  );
};

export default IframeFormButtons;
