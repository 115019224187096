import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AllProjectsQuery } from '../../graphql/queries/project/generated/AllProjects';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLink, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Badge, Stack } from 'react-bootstrap';
import config from 'config/config';
import { copyToClipBoard, formatDate, toastErrorMessage } from 'utils/helpers';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { useTokenForProjectEditMutation } from 'graphql/mutations/auth/generated/TokenForProjectEdit';

export type ProjectListItem = First<AllProjectsQuery['allProjects']['items']>;
interface iProjectsTableProps extends IPaginationProps {
  projects: ProjectListItem[];
  loading: boolean;
  showModal?: (data?: { projectId: string; token: string }) => void;
}

export type ProjectTableTableRow = Omit<ProjectListItem, 'iframeId'> & {
  pdf: { _id: string };
  excel: { _id: string };
  projectResult: { projectId: string; iframeId: string };
};

const ProjectsTable: FC<iProjectsTableProps> = ({ projects, showModal, currentPage, changePage, total, loading }) => {
  const { t } = useTranslation();
  const [tokenForProjectEditMutation] = useTokenForProjectEditMutation({
    onError: toastErrorMessage,
    onCompleted: async (token) => {
      return token;
    },
  });

  const data: ProjectTableTableRow[] = useMemo(() => {
    return projects.map((project: ProjectListItem) => {
      const { _id, name, price, meta, type, iframeId, createdAt } = project;
      return {
        _id,
        name,
        price,
        meta,
        type,
        pdf: { _id },
        excel: { _id },
        projectResult: { projectId: _id, iframeId },
        createdAt,
      };
    });
  }, [projects]);

  const columns: ColumnDef<ProjectTableTableRow>[] = useMemo(
    () => [
      {
        header: t('projects.tableHeaders.name'),
        accessorKey: 'name',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('projects.tableHeaders.projectType'),
        accessorKey: 'type',
        cell: (info) => <Badge>{t(`projectTypes.${info.getValue()}`)}</Badge>,
      },
      {
        header: t('projects.tableHeaders.price'),
        accessorKey: 'price',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('projects.tableHeaders.clientIP'),
        accessorKey: 'meta.clientIP',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('projects.tableHeaders.createdAt'),
        accessorKey: 'createdAt',
        cell: (info) => formatDate({ date: new Date(info.getValue() as string) }),
      },
      {
        header: t('projects.tableHeaders.pdf'),
        accessorKey: 'pdf',
        cell: (info) => {
          const value = info.getValue() as { _id: string };
          return (
            <a href={config.apiRoutes.projectEstimationUrl(value._id)} target="_blank" rel="noopener noreferrer">
              <Button variant="outline-primary">
                <FontAwesomeIcon icon={faFilePdf} />
              </Button>
            </a>
          );
        },
      },
      {
        header: t('projects.tableHeaders.excel'),
        accessorKey: 'excel',
        cell: (info) => {
          const value = info.getValue() as { _id: string };
          return (
            <a href={config.apiRoutes.projectReportUrl(value._id)} target="_blank" rel="noopener noreferrer">
              <Button variant="outline-primary">
                <FontAwesomeIcon icon={faFileExcel} />
              </Button>
            </a>
          );
        },
      },
      {
        header: t('projects.tableHeaders.projectOverviewPage'),
        accessorKey: 'projectResult',
        cell: (info) => {
          const { projectId, iframeId } = info.getValue() as ProjectTableTableRow['projectResult'];
          const url = config.projectResultPageUrl({ iframeId, projectId });
          return (
            <Stack direction="horizontal" gap={2}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <Button variant="outline-primary">
                  <FontAwesomeIcon icon={faExternalLink} />
                </Button>
              </a>
              <Button variant="outline-primary" onClick={() => copyToClipBoard(url, t('copiedToClipboard'))}>
                <FontAwesomeIcon icon={faCopy} />
              </Button>
              {showModal && (
                <Button
                  variant="outline-primary"
                  onClick={async () => {
                    const token = await tokenForProjectEditMutation({ variables: { projectId, iframeId } });
                    if (token.data?.tokenForProjectEdit) {
                      showModal({ projectId, token: token.data.tokenForProjectEdit });
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
            </Stack>
          );
        },
      },
    ],
    [showModal, t, tokenForProjectEditMutation],
  );

  return (
    <>
      <Table columns={columns} data={data} loading={loading} emptyText="projects.noProjectsFound.description" />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default ProjectsTable;
