import React from 'react';
import Loading from '../../Loading/Loading';
import { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook';

export interface iFetchingMoreItemsRow {
  colSpan?: number;
  sentryRef?: UseInfiniteScrollHookRefCallback;
}

const FetchingMoreItemsRow = ({ sentryRef, colSpan }: iFetchingMoreItemsRow) => {
  return (
    <tr ref={sentryRef}>
      <td colSpan={colSpan}>
        <Loading size={30} position="center" />
      </td>
    </tr>
  );
};

export default FetchingMoreItemsRow;
