import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type WebhookFragment = {
  __typename?: 'Webhook';
  _id: string;
  trigger: SchemaTypes.WebhookTrigger;
  events: Array<SchemaTypes.WebhookEvent>;
  url: string;
  headers: Array<{ __typename?: 'WebhookHeader'; name: string; value: string }>;
};

export type WebhookFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const WebhookFragmentDoc = gql`
  fragment Webhook on Webhook {
    _id
    trigger
    events
    url
    headers {
      name
      value
    }
  }
`;
