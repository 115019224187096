import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router-dom';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { routePaths } from 'router/routes';
import { Iframe, ProjectType, Roles } from 'graphql/types.generated';
import useUser from 'hooks/user/useUser';

export type IframeListItem = Iframe;
interface iIframesTableProps extends IPaginationProps {
  iframes: IframeListItem[];
  loading: boolean;
}

export type IframeTableRow = {
  _id: string;
  name: string;
  tenantId: string;
  archived?: boolean | null;
  projectType?: string | null;
};

const IframesTable: FC<iIframesTableProps> = ({ iframes, currentPage, changePage, total, loading }) => {
  const { t } = useTranslation();
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const navigate = useNavigate();

  const data: IframeTableRow[] = useMemo(() => {
    return iframes.map((iframe: IframeListItem) => {
      const { _id, name, projectType, archived, tenantId } = iframe;
      return {
        _id,
        name,
        projectType,
        archived,
        tenantId,
      };
    });
  }, [iframes]);

  const columns: ColumnDef<IframeTableRow>[] = useMemo(
    () => [
      {
        header: t('iframes.tableHeaders.name'),
        accessorKey: 'name',
        cell: (info) => (
          <div>
            <FontAwesomeIcon icon={faUpRightFromSquare} /> {info.getValue() as string}
          </div>
        ),
      },
      {
        header: t('iframes.tableHeaders.projectTypes'),
        accessorKey: 'projectType',
        cell: (info) => {
          return <Badge>{t(`projectTypes.${info.getValue()}`)}</Badge>;
        },
      },
      {
        header: t('iframes.tableHeaders.archived'),
        accessorKey: 'archived',
        cell: (info) => (
          <Badge bg={(info.getValue() as boolean) ? 'danger' : 'success'}>
            {(info.getValue() as boolean) ? t('tenants.archived') : t('tenants.active')}
          </Badge>
        ),
      },
    ],
    [t],
  );

  const onRowClick = useCallback(
    (row: IframeTableRow) => {
      if (me?.role !== Roles.MEINBAU_ADMIN && row.archived) return;
      navigate(generatePath(routePaths.tenantIframe, { iframeId: row._id, tenantId: row.tenantId }));
    },
    [me?.role, navigate],
  );

  return (
    <>
      <Table
        getRowProps={(row) => ({
          className: 'clickableRow',
          onClick: () => onRowClick(row.original),
        })}
        columns={columns}
        data={data}
        loading={loading}
        emptyText="iframes.noIframesFound.description"
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default IframesTable;
