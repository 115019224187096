import { QueryHookOptions } from '@apollo/client';
import {
  GeneralAnalyticQuery,
  GeneralAnalyticQueryVariables,
  useGeneralAnalyticQuery,
} from 'graphql/queries/analytics/generated/GeneralAnalytic';

interface IGeneralAnalyticProps extends QueryHookOptions<GeneralAnalyticQuery, GeneralAnalyticQueryVariables> {
  rangeStart?: Date;
  rangeEnd?: Date;
}

export default function useGeneralAnalytic(params?: IGeneralAnalyticProps) {
  const { data, loading, error, refetch } = useGeneralAnalyticQuery({
    variables: {
      ...(params?.rangeStart && { rangeStart: params.rangeStart }),
      ...(params?.rangeEnd && { rangeStart: params.rangeEnd }),
    },
  });
  return {
    generalAnalytic: (data?.generalAnalytic || null) as GeneralAnalyticQuery['generalAnalytic'] | null,
    refetchGeneralAnalytic: refetch,
    loading,
    error,
  };
}
