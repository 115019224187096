import { faRotate, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import cn from 'classnames';
import s from './DroppedFiles.module.sass';
import { EnhancedFile, UploadStatus, cancelAllUploadRequests } from '../DroppedFiles.helpers';
import { mimeType2Extension } from '../DndContainer.helpers';
import Button from 'components/common/Button';
import FileIcon from '../FileIcon';
import { usePrompt } from 'hooks/usePrompt';

export interface IDroppedFilesProps {
  droppedFiles: EnhancedFile[];
  onUnsetFile: (fileId: string) => void;
  onRetryUpload: (fileId: string) => Promise<void>;
}

const DroppedFiles: FC<IDroppedFilesProps> = ({ droppedFiles, onUnsetFile, onRetryUpload }) => {
  usePrompt('dnd.uploadAlertWarningMessage', () => cancelAllUploadRequests(droppedFiles), !!droppedFiles.length);

  return (
    <div className={s.droppedFilesContainer}>
      {droppedFiles.map(({ webClientFileId, file, uploadPercentage, uploadStatus }) => {
        const extension = mimeType2Extension(file.type);
        const uploadFailed = uploadStatus === UploadStatus.FAILED;

        return (
          <div key={webClientFileId} className={cn(s.droppedFile, { [s.uploadFailed]: uploadFailed })}>
            <div className={s.progressBar} style={{ width: `${uploadPercentage}%` }} />
            <div className="d-flex align-items-center" style={{ overflowX: 'auto', zIndex: 1 }}>
              {uploadFailed ? (
                <Button variant="link" onClick={() => onRetryUpload(webClientFileId)}>
                  <FontAwesomeIcon icon={faRotate} className="text-primary" />
                </Button>
              ) : (
                <div className="py-2 px-3">
                  <FileIcon extension={extension} size={24} />
                </div>
              )}
              <div className={s.fileName}>{file.name}</div>
            </div>
            <Button variant="link" className={s.deleteFileBtn} onClick={() => onUnsetFile(webClientFileId)}>
              <FontAwesomeIcon icon={faXmark} className="text-danger" />
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default DroppedFiles;
