import { faCloudDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import React, { useCallback } from 'react';
import { Stack } from 'react-bootstrap';
import useDownloadFileRequest from '../hooks/useDownloadFile';
import useDeleteFileInstanceLink from 'hooks/file/useDeleteFileInstanceLink';
import { useTranslation } from 'react-i18next';
import { FileInstancesQuery } from 'graphql/queries/file/generated/FileInstances';
import { ImageContainer } from 'components/common/ImageContainer';

export type FileInstanceLinkItem = First<FileInstancesQuery['fileInstances']>;

interface ImgParams {
  url: string;
  fileInstanceLinkId: string;
  hideModal: () => void;
}

const Img: FC<ImgParams> = ({ url, fileInstanceLinkId, hideModal }) => {
  const { t } = useTranslation();
  const onDownloadFileClick = useDownloadFileRequest();
  const deleteFileInstanceLink = useDeleteFileInstanceLink();

  const onDeleteFileInstanceLink = useCallback(async () => {
    hideModal();
    await deleteFileInstanceLink(fileInstanceLinkId);
  }, [deleteFileInstanceLink, fileInstanceLinkId, hideModal]);

  return (
    <div className="d-flex flex-column">
      <ImageContainer src={url} alt="preview image" />
      <Stack gap={3} direction="horizontal" className="mt-3">
        <Button onClick={() => onDownloadFileClick(url)}>
          {t('buttons.download')} <FontAwesomeIcon icon={faCloudDownload} />
        </Button>

        <Button variant="danger" onClick={onDeleteFileInstanceLink}>
          {t('buttons.delete')} <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Stack>
    </div>
  );
};

interface PreviewImageParams {
  onHideModal: () => void;
  fileInstanceLink?: FileInstanceLinkItem;
  imageUrl: string | null;
}

const PreviewImage: FC<PreviewImageParams> = ({ imageUrl, onHideModal, fileInstanceLink }) => {
  return (
    <div className="mb-3 mb-lg-0">
      {fileInstanceLink && imageUrl ? (
        <Img url={imageUrl} fileInstanceLinkId={fileInstanceLink._id} hideModal={onHideModal} />
      ) : null}
    </div>
  );
};

export default PreviewImage;
