import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import s from './ImageContainer.module.sass';
import LoadingOverlay from '../Loading/LoadingOverlay';

interface ImageContainerParams {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  containerClassnames?: string;
  imgClassnames?: string;
}

const ImageContainer: React.FunctionComponent<ImageContainerParams> = ({
  src,
  alt,
  width = '100%',
  height = '100%',
  containerClassnames = '',
  imgClassnames = '',
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className="overflow-auto position-relative">
      <div className={cn(s.imageContainer, containerClassnames)}>
        <img
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          width={width}
          height={height}
          className={cn(s.img, imgClassnames)}
        />
        {!isLoaded && <LoadingOverlay />}
      </div>
    </div>
  );
};

export default ImageContainer;
