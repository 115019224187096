import React, { useMemo, useCallback } from 'react';
import ReactSelect, { MultiValue, OnChangeValue, OptionsOrGroups } from 'react-select';
import Form from 'react-bootstrap/Form';
import { FieldInputProps, FormikProps } from 'formik';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { MenuPortalTarget, useMenuPortalTarget } from './useMenuPortalTarget';
import { multiSelectStyles } from './Select.styles';

export interface IMultiSelectOption {
  label: string;
  value: string | number;
  isDisabled?: boolean;
}

interface CMultiValueProps {
  getValue: () => IMultiSelectOption[];
  isShowValues: boolean;
  index: number;
}

export const CMultiValue: React.FC<CMultiValueProps> = ({ getValue, index, isShowValues }) => {
  const value = useMemo(() => getValue() || [], [getValue]);
  const { t } = useTranslation();

  if (index !== 0) return null;

  const renderSelectedValue = () => {
    if (value.length === 0) {
      return null;
    }

    if (isShowValues) {
      return value.map((option: IMultiSelectOption) => option.label).join(', ');
    }

    return (
      <>
        <div>{t('forms.selected')}</div>
        <div
          className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle ms-1"
          style={{ width: '20px', minWidth: '20px', minHeight: '20px', height: '20px', fontSize: '14px' }}
        >
          <span style={{ lineHeight: '14px' }}>{value.length}</span>
        </div>
      </>
    );
  };

  return (
    <div className="d-flex">
      <div className="d-flex align-items-center">{renderSelectedValue()}</div>
    </div>
  );
};

export interface IMultiSelectProps {
  field: FieldInputProps<Array<string | number>>;
  form: FormikProps<Array<string | number>>;
  label: string;
  options: OptionsOrGroups<IMultiSelectOption, any>;
  isSearchable?: boolean;
  disabled?: boolean;
  isShowValues?: boolean;
  helpText?: string;
  menuPortalTarget?: MenuPortalTarget;
}

const MultiSelect: FC<IMultiSelectProps> = ({
  field,
  form,
  options,
  label,
  disabled = false,
  menuPortalTarget: _menuPortalTarget = MenuPortalTarget.body,
  isShowValues = false,
}) => {
  const { name, onBlur } = field;
  const { setFieldValue, errors, touched } = form;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_options: OnChangeValue<MultiValue<IMultiSelectOption>, boolean>) => {
      const selectedOptions = _options as MultiValue<IMultiSelectOption>;
      const values = selectedOptions ? selectedOptions.map((option: IMultiSelectOption) => option.value) : [];
      setFieldValue(field.name, values);
    },
    [field.name, setFieldValue],
  );

  const optionValue = useMemo(
    () =>
      field.value &&
      options.filter((option) => {
        const { value } = option;
        return field.value.includes(value);
      }),
    [field.value, options],
  );

  const localizedOptions = useMemo(
    () => options.map((option) => ({ ...option, label: t(option.label) })),
    [options, t],
  );

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  const menuPortalTarget = useMenuPortalTarget(_menuPortalTarget);

  return (
    <Form.Group className="mb-3 w-100">
      <Form.Label htmlFor={name} className="text-primary">
        {t(label)}
      </Form.Label>
      <ReactSelect
        onBlur={onBlur}
        isMulti
        options={localizedOptions}
        styles={multiSelectStyles}
        onChange={handleChange}
        inputId={field.name}
        value={optionValue}
        name={field.name}
        placeholder=""
        components={{
          IndicatorSeparator: () => null,
          MultiValue: (props: any) => <CMultiValue isShowValues={isShowValues} {...props} />,
        }}
        isSearchable
        menuPortalTarget={menuPortalTarget}
        isDisabled={disabled}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        noOptionsMessage={() => <div>{t('forms.noOptions')}</div>}
      />
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(error)}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default MultiSelect;
