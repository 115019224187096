import React, { useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ForgotPasswordMutationVariables,
  useForgotPasswordMutation,
} from 'graphql/mutations/auth/generated/ForgotPassword';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { toastErrorMessage } from 'utils/helpers';
import { routePaths } from 'router/routes';
import ForgotPasswordForm from 'components/auth/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialValues: ForgotPasswordMutationVariables = { email: '' };

  const [forgotPassword] = useForgotPasswordMutation({
    onCompleted: () => {
      toast.success(t('auth.forgotPassword.passwordResetEmailSent'));
      navigate(routePaths.login);
    },
    onError: toastErrorMessage,
  });

  const onSubmit = useCallback(
    async (values: ForgotPasswordMutationVariables, helpers: FormikHelpers<ForgotPasswordMutationVariables>) => {
      try {
        await forgotPassword({ variables: { email: values.email } });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [forgotPassword],
  );

  return (
    <Row className="h-100">
      <Col md={8} lg={6} xl={4} className="m-auto">
        <PageTitle className="text-center">{t('pageTitles.forgotPassword')}</PageTitle>
        <ForgotPasswordForm {...{ initialValues, onSubmit }} />
      </Col>
    </Row>
  );
};

export default ForgotPasswordPage;
