import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from '../../utils/helpers';
import SubmitButton from 'components/common/Button/SubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import useGetSearchParams from 'hooks/useGetSearchParams';
import constants from 'constants/constants';
import { refetchAllApplicationsQuery } from 'graphql/queries/applications/generated/AllApplications';
import { useGetAllApplicationsFilter } from 'hooks/application/useAllApplications';

const { tableRowsCountPerPage } = constants;

interface IApplicationsFilterForm {
  changePage: (page: number) => void;
}

type FormValues = {
  applicationsSearchQuery: string;
};

const ApplicationsFilterForm: FC<IApplicationsFilterForm> = ({ changePage }) => {
  const { searchParams, setSearchParams } = useGetSearchParams();
  const filter = useGetAllApplicationsFilter();

  const initialValues = useMemo(() => {
    return { applicationsSearchQuery: filter.searchQuery };
  }, [filter.searchQuery]);

  const onSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { applicationsSearchQuery } = values;
        changePage(1);
        setSearchParams({
          ...searchParams,
          ...(typeof applicationsSearchQuery === 'string' ? { applicationsSearchQuery } : {}),
        });

        refetchAllApplicationsQuery({
          skip: 0,
          limit: tableRowsCountPerPage,
          filter: {
            searchQuery: applicationsSearchQuery,
          },
        });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [changePage, searchParams, setSearchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <Field
              name="applicationsSearchQuery"
              type="search"
              label="applicationManagement.searchQuery"
              component={TextInput}
            />
          </div>
          <div className="filter-buttons">
            <SubmitButton label="buttons.search" icon={faMagnifyingGlass} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApplicationsFilterForm;
