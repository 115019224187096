import { ColumnDef } from '@tanstack/react-table';
import { Modal, useModal } from 'components/common/Modal';
import Table from 'components/common/Table/Table';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';
import { FileInstanceType } from 'graphql/types.generated';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Alert, Col, Row, Stack } from 'react-bootstrap';
import { grabErrorMessage, toastErrorMessage } from 'utils/helpers';
import { FileInstancesDocument, useFileInstancesQuery } from 'graphql/queries/file/generated/FileInstances';
import useDeleteFileInstanceLink from 'hooks/file/useDeleteFileInstanceLink';
import Loading from 'components/common/Loading/Loading';
import PreviewImage, { FileInstanceLinkItem } from './PreviewImage';
import IframeAssetsTable from './IframeAssetsTable';
import FilesSelect from 'components/inputs/Select/FilesSelect';
import { uniqueId } from 'lodash';
import { useCreateFileInstanceLinkMutation } from 'graphql/mutations/file/generated/CreateFileInstanceFink';
import { ISelectOption } from 'components/inputs/Select/Select';
import { useDownloadFileRequestLazyQuery } from 'graphql/queries/file/generated/DownloadFileRequest';
import { fileByFileProjectType } from './fileTypes';

export type AssetsTableRow = {
  type: string;
  file: { type: FileInstanceType; fileInstanceLink?: FileInstanceLinkItem };
  actions: { type: FileInstanceType; fileInstanceLink?: FileInstanceLinkItem };
};

type ModalContentType = {
  fileInstanceType: FileInstanceType;
  fileInstanceLink?: FileInstanceLinkItem;
};

interface GetColumnsParams {
  iframeId: string;
  onPreviewImageClick: (params: Required<ModalContentType>) => Promise<void>;
}

const useGetColumns = ({ onPreviewImageClick, iframeId }: GetColumnsParams): ColumnDef<AssetsTableRow>[] => {
  const { t } = useTranslation();
  const onDeleteFileInstanceLink = useDeleteFileInstanceLink();
  const [createFileInstanceLink] = useCreateFileInstanceLinkMutation({ onError: toastErrorMessage });
  const [previewLoading, setPreviewLoading] = useState<{ loading: boolean; instanceId: string } | null>(null);

  return [
    {
      header: t('iframeAssets.table.headers.type'),
      accessorKey: 'type',
      cell: (info) => {
        const key = info.getValue() as string;
        return t(`iframeAssets.types.${key}`);
      },
    },
    {
      header: t('iframeAssets.table.headers.file'),
      accessorKey: 'file',
      cell: (info) => {
        const { type, fileInstanceLink } = info.getValue() as AssetsTableRow['actions'];
        const onSelectFile = useCallback(
          async (selectionOption: ISelectOption) => {
            const fileId = selectionOption.value as string;
            await createFileInstanceLink({
              variables: { input: { fileId, instanceType: type, instanceId: iframeId, iframeId } },
              refetchQueries: [{ query: FileInstancesDocument, variables: { iframeId } }],
            });
          },
          [type],
        );
        return (
          <FilesSelect
            initialValue={fileInstanceLink?.file._id}
            onSelectOption={onSelectFile}
            name={`file-${uniqueId()}`}
          />
        );
      },
    },
    {
      header: '',
      accessorKey: 'actions',
      cell: (info) => {
        const { type, fileInstanceLink } = info.getValue() as AssetsTableRow['actions'];
        const isLoading = fileInstanceLink?._id === previewLoading?.instanceId ? previewLoading?.loading : false;

        const onPreviewClick = useCallback(async () => {
          if (fileInstanceLink?.file) {
            const { _id } = fileInstanceLink;
            setPreviewLoading({ instanceId: _id, loading: true });
            await onPreviewImageClick({ fileInstanceType: type, fileInstanceLink });
            setPreviewLoading({ instanceId: _id, loading: false });
          }
        }, [fileInstanceLink, type]);

        return (
          <Stack gap={2} direction="horizontal">
            <Button loading={isLoading} disabled={isLoading || !fileInstanceLink?.file} onClick={onPreviewClick}>
              <FontAwesomeIcon icon={faEye} />
            </Button>
            {fileInstanceLink?._id ? (
              <Button
                variant="danger"
                disabled={!fileInstanceLink?._id}
                onClick={() => onDeleteFileInstanceLink(fileInstanceLink._id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            ) : null}
          </Stack>
        );
      },
    },
  ];
};

interface IframeAssetsParams {
  iframe: IframeQuery['iframe'];
}

const IframeAssets: FC<IframeAssetsParams> = ({ iframe }) => {
  const { t } = useTranslation();
  const { data, error, loading } = useFileInstancesQuery({ variables: { iframeId: iframe._id } });

  const [loadPreviewImage] = useDownloadFileRequestLazyQuery();
  const { showModal, hideModal, isVisible, modalProps } = useModal<ModalContentType>();
  const previewImageRef = useRef<string | null>('');

  const onPreviewImageClick = useCallback(
    async ({ fileInstanceLink, fileInstanceType }: Required<ModalContentType>) => {
      try {
        previewImageRef.current = '';
        const { data } = await loadPreviewImage({
          variables: { fileId: fileInstanceLink?.file?._id },
          fetchPolicy: 'cache-and-network',
        });
        if (data?.downloadFileRequest) {
          previewImageRef.current = data?.downloadFileRequest?.signedURL;
        } else {
          previewImageRef.current = '';
        }
        showModal({ fileInstanceType, fileInstanceLink });
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [loadPreviewImage, showModal],
  );

  const columns = useGetColumns({ onPreviewImageClick, iframeId: iframe._id });

  const rows: AssetsTableRow[] = useMemo(() => {
    const iframeAssetTypes = fileByFileProjectType[iframe.projectType] as FileInstanceType[];
    return iframeAssetTypes
      .map((type) => ({ type }))
      .map((obj) => {
        const fileInstanceLink = data?.fileInstances.find(({ instanceType }) => obj.type === instanceType);
        return {
          ...obj,
          file: { type: obj.type, fileInstanceLink },
          actions: { type: obj.type, fileInstanceLink },
        };
      });
  }, [data?.fileInstances, iframe.projectType]);

  const onHideModal = useCallback(() => {
    hideModal();
    previewImageRef.current = null;
  }, [hideModal]);

  if (loading) return <Loading />;
  if (error) return <Alert variant="danger">{grabErrorMessage(error)}</Alert>;
  if (!data?.fileInstances) {
    return <Alert variant="danger">{t('iframeAssets.errorOccurredWhiteGettingFileInstances')}</Alert>;
  }

  return (
    <>
      <Table columns={columns} data={rows} />
      <Modal
        isVisible={isVisible}
        onHide={onHideModal}
        headerCloseButton
        headerText={t('iframeAssets.modal.header')}
        size="lg"
      >
        <Row>
          <Col>
            <p>
              <FontAwesomeIcon icon={faInfoCircle} className="text-primary" />{' '}
              {t(
                `iframeAssets.modal.${
                  !previewImageRef.current ? 'newFileInteractionDescription' : 'existingFileInteractionDescription'
                }`,
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <PreviewImage {...{ iframe, onHideModal, imageUrl: previewImageRef.current, ...modalProps }} />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

interface IframeAssetsContainerParams {
  iframe: IframeQuery['iframe'];
}

const IframeAssetsTabContainer: FC<IframeAssetsContainerParams> = ({ iframe }) => {
  return (
    <>
      <IframeAssetsTable {...{ iframe }} />
      <br />
      <br />
      <IframeAssets {...{ iframe }} />
    </>
  );
};

export default IframeAssetsTabContainer;
