import React from 'react';
import { useTranslation } from 'react-i18next';

export interface iEmptyRow {
  emptyText?: React.ReactNode;
  colSpan?: number;
}

const EmptyRow = ({ emptyText, colSpan }: iEmptyRow) => {
  const { t } = useTranslation();

  return (
    <tr>
      <td colSpan={colSpan}>
        {emptyText ? (
          <div className="text-center">{typeof emptyText === 'string' ? t(emptyText) : emptyText}</div>
        ) : (
          <>&nbsp;</>
        )}
      </td>
    </tr>
  );
};

export default EmptyRow;
