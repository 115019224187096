import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { MeFragmentDoc } from '../../../fragments/user/generated/MeFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnableTwoFAMutationVariables = SchemaTypes.Exact<{
  code: SchemaTypes.Scalars['String']['input'];
}>;

export type EnableTwoFAMutation = {
  __typename?: 'Mutation';
  enableTwoFA: {
    __typename?: 'User';
    _id: string;
    email: string;
    role?: SchemaTypes.Roles | null;
    tenantId?: string | null;
    isTwoFAEnabled?: boolean | null;
    profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    activeRoles: Array<{ __typename?: 'UserRoleClass'; role: SchemaTypes.Roles; tenantId?: string | null }>;
  };
};

export const EnableTwoFADocument = gql`
  mutation EnableTwoFA($code: String!) {
    enableTwoFA(code: $code) {
      ...Me
    }
  }
  ${MeFragmentDoc}
`;
export type EnableTwoFAMutationFn = Apollo.MutationFunction<EnableTwoFAMutation, EnableTwoFAMutationVariables>;

/**
 * __useEnableTwoFAMutation__
 *
 * To run a mutation, you first call `useEnableTwoFAMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableTwoFAMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableTwoFaMutation, { data, loading, error }] = useEnableTwoFAMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useEnableTwoFAMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableTwoFAMutation, EnableTwoFAMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableTwoFAMutation, EnableTwoFAMutationVariables>(EnableTwoFADocument, options);
}
export type EnableTwoFAMutationHookResult = ReturnType<typeof useEnableTwoFAMutation>;
export type EnableTwoFAMutationResult = Apollo.MutationResult<EnableTwoFAMutation>;
export type EnableTwoFAMutationOptions = Apollo.BaseMutationOptions<EnableTwoFAMutation, EnableTwoFAMutationVariables>;
