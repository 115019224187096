import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRouter from './AuthRouter';
import LoadingOverlay from 'components/common/Loading/LoadingOverlay';
import useUser from 'hooks/user/useUser';
import * as Sentry from '@sentry/react';
import AppRouter from './AppRouter';
import Button from 'components/common/Button/Button';
import AuthLayout from 'components/layouts/AuthLayout';
import AppLayout from 'components/layouts/AppLayout';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as ExclamationTriangle } from 'assets/icons/exclamation-triangle.svg';

const FallbackComponent: FC<{ auth: boolean }> = ({ auth }) => {
  const Layout = auth ? AuthLayout : AppLayout;
  return (
    <Layout>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateY(-50%) translateX(-50%)',
          textAlign: 'center',
        }}
      >
        <ExclamationTriangle width={80} height={80} />
        <h2>Unerwarteter Fehler aufgetreten</h2>
        <a href=".">
          <Button>
            <Home /> Zurück zur Startseite
          </Button>
        </a>
      </div>
    </Layout>
  );
};

const RootRouter: React.FunctionComponent = () => {
  const { me, loading } = useUser();

  if (loading && !me) {
    return <LoadingOverlay />;
  }

  return (
    <Router>
      <Sentry.ErrorBoundary fallback={<FallbackComponent auth={!me} />}>
        {me ? <AppRouter me={me} /> : <AuthRouter />}
      </Sentry.ErrorBoundary>
    </Router>
  );
};

export default RootRouter;
