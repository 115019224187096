import React from 'react';
import { Navbar as RNavbar, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divide as HamburgerIcon } from 'hamburger-react';
import s from './Navbar.module.sass';
import { useAppContext } from 'contexts/AppContext';
import { routePaths } from 'router/routes';
import { Visible } from 'components/common/Visibility';
import Button from 'components/common/Button/Button';
import NavbarDropdownMenu from '../NavbarDropdownMenu/NavbarDropdownMenu';
import NavbarBrand from 'components/common/NavbarBrand';
import { useTenantNameQuery } from 'graphql/queries/tenant/generated/TenantName';
import useUser from 'hooks/user/useUser';

interface iNavbarProps {
  auth?: boolean;
}

const Navbar: FC<iNavbarProps> = ({ auth }) => {
  const { t } = useTranslation();
  const { me } = useUser({ fetchPolicy: 'cache-first' });
  const { data } = useTenantNameQuery({ variables: { tenantId: me?.tenantId as string }, skip: !me?.tenantId });
  const { sidebarOpen, toggleSidebar } = useAppContext();

  return (
    <RNavbar bg="light" expand="lg" className={s.navbar}>
      {auth ? <NavbarBrand auth /> : null}
      {!auth ? (
        <Stack direction="horizontal" gap={3} className="w-100 justify-content-between flex-1">
          <Visible xs sm>
            <HamburgerIcon toggled={sidebarOpen} toggle={() => toggleSidebar(!sidebarOpen)} />
          </Visible>
          <Stack direction="horizontal" className="ms-auto d-flex">
            <h6 className="text-primary me-3 fw-bold m-0">{data?.tenant.companyName || ''}</h6>
            <NavbarDropdownMenu />
          </Stack>
        </Stack>
      ) : (
        <Link to={routePaths.login}>
          <Button className={s.loginBtn}>{t('pageTitles.login')}</Button>
        </Link>
      )}
    </RNavbar>
  );
};

export default Navbar;
