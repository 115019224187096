import React, { useCallback } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from '../../../utils/helpers';
import { useResendInvitationMutation } from '../../../graphql/mutations/invitation/generated/ResendInvitation';
import { useParams } from 'react-router-dom';
import constants from '../../../constants/constants';
import { confirmAlert } from 'components/common/Alert';
import Button from 'components/common/Button/Button';
import {
  DeleteInvitationMutation,
  useDeleteInvitationMutation,
} from 'graphql/mutations/invitation/generated/DeleteInvitation';
import { UserManagementDocument, UserManagementQuery } from 'graphql/queries/user/generated/UserManagement';

const { tableRowsCountPerPage } = constants;

interface IDeleteInvitationBtn {
  invitationId: string;
  email: string;
}

export const DeleteInvitationBtn: FC<IDeleteInvitationBtn> = ({ invitationId, email }) => {
  const { t } = useTranslation();
  const { tenantId } = useParams();

  const [deleteInvitationMutation, { client, loading }] = useDeleteInvitationMutation({
    onCompleted: (data: DeleteInvitationMutation) => {
      if (!data.deleteInvitation) return null;

      const variables = { tenantId, limit: tableRowsCountPerPage, skip: 0 };

      client.cache.updateQuery({ query: UserManagementDocument, variables }, (data: UserManagementQuery | null) => {
        if (!data) return data;
        const { plainTotal, total } = data.allInvitations;
        const newPlainTotal = (plainTotal ?? 0) - 1;
        const newTotal = total - 1;
        return { ...data, allInvitations: { ...data.allInvitations, plainTotal: newPlainTotal, total: newTotal } };
      });

      client.cache.evict({ id: `Invitation:${invitationId}` });
      toast.success(t('userManagement.invitations.deleteInvitation.invitationSuccessfullyDeleted'));
    },
    onError: toastErrorMessage,
  });

  const onDeleteInvitation = useCallback(async () => {
    confirmAlert({
      onConfirm: async () => {
        await deleteInvitationMutation({ variables: { invitationId } });
      },
      title: t('userManagement.invitations.deleteInvitation.title'),
      message: t('userManagement.invitations.deleteInvitation.message', { email }),
    });
  }, [deleteInvitationMutation, email, invitationId, t]);

  return (
    <Button variant="outline-danger" loading={loading} onClick={onDeleteInvitation}>
      <FontAwesomeIcon icon={faTrash} /> {t('userManagement.invitations.deleteInvitation.title')}
    </Button>
  );
};

export const ResendInvitationBtn: FC<Pick<IActionButtons, 'invitationId' | 'isDraft'>> = ({
  invitationId,
  isDraft,
}) => {
  const { t } = useTranslation();

  const [resendInvitationMutation, { loading }] = useResendInvitationMutation({
    onCompleted: () => {
      toast.success(t('userManagement.invitations.invitationSuccessfullySent'));
    },
    onError: toastErrorMessage,
  });

  const onResendInvitation = useCallback(async () => {
    await resendInvitationMutation({
      variables: { invitationId },
      refetchQueries: ['AllInvitations'],
    });
  }, [invitationId, resendInvitationMutation]);

  return (
    <Button loading={loading} disabled={loading} variant="outline-primary" onClick={onResendInvitation}>
      <FontAwesomeIcon icon={faEnvelope} color="currentColor" />
      {t(isDraft ? 'buttons.send' : 'userManagement.invitations.retryInvitation')}
    </Button>
  );
};

interface IActionButtons {
  invitationId: string;
  email: string;
  isDraft?: boolean | null;
}

const ActionButtons: FC<IActionButtons> = ({ invitationId, email, isDraft }) => {
  return (
    <ButtonGroup>
      <ResendInvitationBtn {...{ invitationId, isDraft }} />
      <DeleteInvitationBtn {...{ email, invitationId }} />
    </ButtonGroup>
  );
};

export default ActionButtons;
