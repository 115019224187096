import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TokenForProjectEditMutationVariables = SchemaTypes.Exact<{
  projectId: SchemaTypes.Scalars['String']['input'];
  iframeId: SchemaTypes.Scalars['String']['input'];
}>;

export type TokenForProjectEditMutation = { __typename?: 'Mutation'; tokenForProjectEdit: string };

export const TokenForProjectEditDocument = gql`
  mutation TokenForProjectEdit($projectId: String!, $iframeId: String!) {
    tokenForProjectEdit(projectId: $projectId, iframeId: $iframeId)
  }
`;
export type TokenForProjectEditMutationFn = Apollo.MutationFunction<
  TokenForProjectEditMutation,
  TokenForProjectEditMutationVariables
>;

/**
 * __useTokenForProjectEditMutation__
 *
 * To run a mutation, you first call `useTokenForProjectEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenForProjectEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenForProjectEditMutation, { data, loading, error }] = useTokenForProjectEditMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useTokenForProjectEditMutation(
  baseOptions?: Apollo.MutationHookOptions<TokenForProjectEditMutation, TokenForProjectEditMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TokenForProjectEditMutation, TokenForProjectEditMutationVariables>(
    TokenForProjectEditDocument,
    options,
  );
}
export type TokenForProjectEditMutationHookResult = ReturnType<typeof useTokenForProjectEditMutation>;
export type TokenForProjectEditMutationResult = Apollo.MutationResult<TokenForProjectEditMutation>;
export type TokenForProjectEditMutationOptions = Apollo.BaseMutationOptions<
  TokenForProjectEditMutation,
  TokenForProjectEditMutationVariables
>;
