import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeinBauApiLogsQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']['input']>;
}>;

export type MeinBauApiLogsQuery = {
  __typename?: 'Query';
  meinBauApiLogs: {
    __typename?: 'PaginatedMeinBauApiLogResponse';
    total: number;
    items: Array<{
      __typename?: 'MeinBauApiLog';
      _id: string;
      createdAt: any;
      status?: number | null;
      ip?: string | null;
      appName?: string | null;
      useCase: string;
      method: SchemaTypes.MethodType;
    }>;
  };
};

export const MeinBauApiLogsDocument = gql`
  query MeinBauApiLogs($limit: Int!, $skip: Int!, $tenantId: String) {
    meinBauApiLogs(limit: $limit, skip: $skip, tenantId: $tenantId) {
      total
      items {
        _id
        createdAt
        status
        ip
        appName
        useCase
        method
      }
    }
  }
`;

/**
 * __useMeinBauApiLogsQuery__
 *
 * To run a query within a React component, call `useMeinBauApiLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeinBauApiLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeinBauApiLogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useMeinBauApiLogsQuery(
  baseOptions: Apollo.QueryHookOptions<MeinBauApiLogsQuery, MeinBauApiLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeinBauApiLogsQuery, MeinBauApiLogsQueryVariables>(MeinBauApiLogsDocument, options);
}
export function useMeinBauApiLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeinBauApiLogsQuery, MeinBauApiLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeinBauApiLogsQuery, MeinBauApiLogsQueryVariables>(MeinBauApiLogsDocument, options);
}
export type MeinBauApiLogsQueryHookResult = ReturnType<typeof useMeinBauApiLogsQuery>;
export type MeinBauApiLogsLazyQueryHookResult = ReturnType<typeof useMeinBauApiLogsLazyQuery>;
export type MeinBauApiLogsQueryResult = Apollo.QueryResult<MeinBauApiLogsQuery, MeinBauApiLogsQueryVariables>;
export function refetchMeinBauApiLogsQuery(variables: MeinBauApiLogsQueryVariables) {
  return { query: MeinBauApiLogsDocument, variables: variables };
}
