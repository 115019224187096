import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'components/common/Pagination';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { AccountType, BasicUserAttributes } from 'graphql/types.generated';
import UserActionBadge from 'components/common/UserActionBadge/UserActionBadge';
import { formatDate } from 'utils/helpers';
import { ActionButtonProps, UserTableRow, UsersQueryItem, iUsersTableProps } from './User.types';
import ActionButtons from './ActionButtons';
import { useParams } from 'react-router-dom';
import { getColumnVisibility } from 'components/common/Table/helpers';

const UsersTable: FC<iUsersTableProps> = ({ users, total, currentPage, changePage, accountType, loading }) => {
  const { t, i18n } = useTranslation();
  const { tenantId } = useParams();

  const data: UserTableRow[] = useMemo(() => {
    return users.map((user: UsersQueryItem) => {
      const { email, profile, role, inviter, createdAt } = user;
      const { firstName, lastName } = profile;
      return { email, firstName, lastName, role, inviter, createdAt, actionButtons: { user } };
    });
  }, [users]);

  const columns: ColumnDef<UserTableRow>[] = useMemo(
    () => [
      {
        id: 'email',
        header: t('auth.email'),
        accessorKey: 'email',
        cell: (info) => info.getValue(),
      },
      {
        id: 'firstName',
        header: t('auth.firstName'),
        accessorKey: 'firstName',
        cell: (info) => info.getValue(),
      },
      {
        id: 'lastName',
        header: t('auth.lastName'),
        accessorKey: 'lastName',
        cell: (info) => info.getValue(),
      },
      {
        id: 'role',
        header: t('auth.role'),
        accessorKey: 'role',
        cell: ({ row }) => t(`roles.${row.original.role}`),
        enableHiding: accountType === AccountType.tenant && !tenantId,
      },
      {
        id: 'inviter',
        header: t('auth.inviter'),
        accessorKey: 'inviter',
        cell: (info) => {
          const inviter = info.getValue();
          if (!inviter) return null;
          return <UserActionBadge user={inviter as BasicUserAttributes} />;
        },
      },
      {
        id: 'registeredAt',
        header: t('userManagement.users.registeredAt'),
        accessorKey: 'createdAt',
        cell: (info) => formatDate({ date: new Date(info.getValue() as Date), locale: i18n.language }),
      },
      {
        id: 'actionButtons',
        header: '',
        accessorKey: 'actionButtons',
        cell: (info) => {
          const props = info.getValue() as ActionButtonProps;
          return <ActionButtons {...{ user: props.user, accountType }} />;
        },
      },
    ],
    [accountType, i18n.language, t, tenantId],
  );

  return (
    <>
      <Table
        initialState={{ columnVisibility: getColumnVisibility(columns) }}
        state={{ columnVisibility: getColumnVisibility(columns) }}
        columns={columns}
        data={data}
        loading={loading}
        emptyText="userManagement.users.noUsersFound.description"
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default UsersTable;
