import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { InvitationFragmentDoc } from '../../../fragments/invitation/generated/InvitationFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendInvitationMutationVariables = SchemaTypes.Exact<{
  invitationId: SchemaTypes.Scalars['ID']['input'];
}>;

export type ResendInvitationMutation = {
  __typename?: 'Mutation';
  resendInvitation: {
    __typename?: 'Invitation';
    _id: string;
    role: SchemaTypes.Roles;
    email: string;
    createdAt: any;
    isDraft?: boolean | null;
    profile: { __typename?: 'UserProfile'; lastName: string; firstName: string };
    inviter?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  };
};

export const ResendInvitationDocument = gql`
  mutation ResendInvitation($invitationId: ID!) {
    resendInvitation(invitationId: $invitationId) {
      ...Invitation
    }
  }
  ${InvitationFragmentDoc}
`;
export type ResendInvitationMutationFn = Apollo.MutationFunction<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useResendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(
    ResendInvitationDocument,
    options,
  );
}
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;
