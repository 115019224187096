export enum MenuPortalTarget {
  body = 'body',
  modal = 'modal',
}

export const useMenuPortalTarget = (menuPortalTarget: MenuPortalTarget) => {
  if (menuPortalTarget === MenuPortalTarget.modal) {
    return document.body.querySelector('.modal') as HTMLElement;
  }
  return document.body;
};
