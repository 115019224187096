import React, { useCallback } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faClock } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import Button from 'components/common/Button/Button';
import { InvitationInput } from 'graphql/types.generated';

const SubmitButtons: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { isSubmitting, setFieldValue, values } = useFormikContext<InvitationInput>();

  const saveAsDraft = useCallback(() => {
    setFieldValue('isDraft', true);
  }, [setFieldValue]);

  return (
    <Stack direction="horizontal" className="mt-4 justify-content-end flex-wrap" gap={3}>
      <Button
        onClick={saveAsDraft}
        disabled={isSubmitting}
        loading={values.isDraft ? isSubmitting : false}
        variant="warning"
        className="text-primary w-100 w-md-initial"
        type="submit"
      >
        {t('userManagement.invitations.form.saveAsDraft')}
        <FontAwesomeIcon icon={faClock} />
      </Button>
      <Button
        disabled={isSubmitting}
        loading={!values.isDraft ? isSubmitting : false}
        type="submit"
        className="w-100 w-md-initial"
      >
        {t('userManagement.invitations.form.saveAndSend')}
        <FontAwesomeIcon icon={faPaperPlane} />
      </Button>
    </Stack>
  );
};

export default SubmitButtons;
