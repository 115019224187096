import { useFormikContext } from 'formik';
import React from 'react';
import cn from 'classnames';
import { ErrorAlert } from '../Alert';

export interface iErrorFormValues {
  form?: string;
}

interface ErrorMessageParams {
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageParams> = ({ className }) => {
  const { errors } = useFormikContext<iErrorFormValues>();

  if (!errors.form) return null;

  return <ErrorAlert message={errors.form} className={cn('my-3', className)} />;
};

export default ErrorMessage;
