import { QueryHookOptions } from '@apollo/client';
import {
  TenantAnalyticQuery,
  TenantAnalyticQueryVariables,
  useTenantAnalyticQuery,
} from 'graphql/queries/analytics/generated/TenantAnalytic';

interface ITenantAnalyticProps extends QueryHookOptions<TenantAnalyticQuery, TenantAnalyticQueryVariables> {
  tenantId: string;
  rangeStart?: Date;
  rangeEnd?: Date;
}

export default function useTenantAnalytic({ tenantId, rangeStart, rangeEnd }: ITenantAnalyticProps) {
  const { data, loading, error, refetch } = useTenantAnalyticQuery({
    variables: { tenantId, rangeStart, rangeEnd },
    skip: !tenantId,
  });
  return {
    tenantAnalytic: (data?.tenantAnalytic || null) as TenantAnalyticQuery['tenantAnalytic'] | null,
    refetchTenantAnalytic: refetch,
    loading,
    error,
  };
}
