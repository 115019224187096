export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian
   * calendar.This scalar is serialized to a string in ISO 8601 format and parsed
   * from a string in ISO 8601 format.
   */
  DateTimeISO: { input: any; output: any };
};

export enum AccountType {
  meinbau = 'meinbau',
  tenant = 'tenant',
}

export type Adresse = {
  __typename?: 'Adresse';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type AnalyticsResponse = {
  __typename?: 'AnalyticsResponse';
  projectsPerTenants: Array<CountEntitiesPerTenant>;
  pdfRequestsPerTenants: Array<CountEntitiesPerTenant>;
  allTenantsCount: Scalars['Int']['output'];
  allUsersCount: Scalars['Int']['output'];
  allProjectsCount: Scalars['Int']['output'];
  allPdfRequestsCount: Scalars['Int']['output'];
};

export enum ApiIntegrationNames {
  twilio = 'twilio',
}

export type ApiIntegrations = {
  __typename?: 'ApiIntegrations';
  name: ApiIntegrationNames;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export interface ApiIntegrationsInput {
  name: ApiIntegrationNames;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
}

export type ApiLog = {
  __typename?: 'ApiLog';
  _id: Scalars['ID']['output'];
  apiName: ApiName;
  eventType: EventType;
  method: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  params?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
};

export enum ApiName {
  dataDelivery = 'dataDelivery',
  priceHubble = 'priceHubble',
}

export enum ApiNameFilter {
  effi = 'effi',
  priceHubble = 'priceHubble',
  all = 'all',
}

export type Application = {
  __typename?: 'Application';
  _id: Scalars['ID']['output'];
  tenantId?: Maybe<Scalars['String']['output']>;
  profile: ApplicationProfile;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: BasicUserAttributes;
  archived?: Maybe<Scalars['Boolean']['output']>;
  archivedBy?: Maybe<BasicUserAttributes>;
  tenantName: Scalars['String']['output'];
};

export interface ApplicationFilter {
  searchQuery?: InputMaybe<Scalars['String']['input']>;
}

export type ApplicationProfile = {
  __typename?: 'ApplicationProfile';
  appName: Scalars['String']['output'];
};

export enum AuditLogEventName {
  login = 'login',
  logout = 'logout',
  resetPassword = 'resetPassword',
  forgotPassword = 'forgotPassword',
  createInvitation = 'createInvitation',
  deleteInvitation = 'deleteInvitation',
  resendInvitation = 'resendInvitation',
  redeemInvitation = 'redeemInvitation',
  createTenant = 'createTenant',
  updateTenant = 'updateTenant',
  archiveTenant = 'archiveTenant',
  releaseTenant = 'releaseTenant',
  updateUserAccount = 'updateUserAccount',
  addUsersToTenant = 'addUsersToTenant',
  createApplicationAccount = 'createApplicationAccount',
  createIframe = 'createIframe',
  updateIframe = 'updateIframe',
  archiveIframe = 'archiveIframe',
  releaseIframe = 'releaseIframe',
  archiveAllTenantIframe = 'archiveAllTenantIframe',
  releaseAllTenantIframe = 'releaseAllTenantIframe',
  integrateWithPriceHubbleIframe = 'integrateWithPriceHubbleIframe',
  disablePriceHubbleIntegrationIframe = 'disablePriceHubbleIntegrationIframe',
  iframeResultPageConfigurationChanged = 'iframeResultPageConfigurationChanged',
  integrateWithGTMIframe = 'integrateWithGTMIframe',
  disableGTMIntegrationIframe = 'disableGTMIntegrationIframe',
  addWebhook = 'addWebhook',
  updateWebhook = 'updateWebhook',
  deleteWebhook = 'deleteWebhook',
  archivedApplication = 'archivedApplication',
  publicProjectEstimation = 'publicProjectEstimation',
  projectEstimation = 'projectEstimation',
  iframeFuelMatrixConfigurationChanged = 'iframeFuelMatrixConfigurationChanged',
  updateIframePlanningItems = 'updateIframePlanningItems',
  resetIframePlanningItems = 'resetIframePlanningItems',
  createProject = 'createProject',
  updateProject = 'updateProject',
  requestProjectPdf = 'requestProjectPdf',
}

export type AuditLogEventsClass = {
  __typename?: 'AuditLogEventsClass';
  _id: Scalars['String']['output'];
  name: AuditLogEventName;
  eventInitiator?: Maybe<Scalars['String']['output']>;
  affectedUser?: Maybe<Scalars['String']['output']>;
  affectedUserId?: Maybe<Scalars['String']['output']>;
  tenantInfo?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  meta: Meta;
  tenantId?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<BasicUserAttributes>;
  createdAt: Scalars['DateTimeISO']['output'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  accessToken: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type BasicUserAttributes = {
  __typename?: 'BasicUserAttributes';
  _id: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  profile: UserProfile;
};

export type Beleihungswert = {
  __typename?: 'Beleihungswert';
  wert?: Maybe<Scalars['Int']['output']>;
};

export type Bewertung = {
  __typename?: 'Bewertung';
  objekt?: Maybe<ObjektParams>;
  grund?: Maybe<Grund>;
};

export type BriefFileInfo = {
  __typename?: 'BriefFileInfo';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BuildingStatus = {
  __typename?: 'BuildingStatus';
  formValues: BuildingStatusFormValues;
  calculations: BuildingStatusCalculations;
};

export type BuildingStatusCalculations = {
  __typename?: 'BuildingStatusCalculations';
  userValue: Scalars['Boolean']['output'];
  userTotalHeatingHotWaterConsumption: Scalars['Float']['output'];
  userHotWaterConsumption: Scalars['Float']['output'];
  userHeatingConsumption: Scalars['Float']['output'];
  userRatioHeatingHotWater: Scalars['Float']['output'];
  percentDiffHeatingUserVsCalculated: Scalars['Float']['output'];
  userHeatingHotWaterGhgEmission: Scalars['Float']['output'];
  userHeatingGhgEmission: Scalars['Float']['output'];
  userHotWaterGhgEmission: Scalars['Float']['output'];
  userHeatingHotWaterCosts: Scalars['Float']['output'];
  userHeatingCosts: Scalars['Float']['output'];
  userHotWaterCosts: Scalars['Float']['output'];
  specificHeatingDemand: Scalars['Float']['output'];
  heatingFactorKea: Scalars['Float']['output'];
  heatingFactorGhg: Scalars['Float']['output'];
  totalHeatingDemand: Scalars['Float']['output'];
  primaryEnergyForHeatingType?: Maybe<CalculationPreference>;
  heatingGhgEmission: Scalars['Float']['output'];
  hotWaterGhgEmission: Scalars['Float']['output'];
  heatingGhgEquivalentCar: Scalars['Float']['output'];
  hotWaterGhgEquivalentCar: Scalars['Float']['output'];
  electricityGhgEquivalentCar: Scalars['Float']['output'];
  energyGhgEquivalentCar: Scalars['Float']['output'];
  heatingGhgEquivalentPlane: Scalars['Float']['output'];
  hotWaterGhgEquivalentPlane: Scalars['Float']['output'];
  electricityGhgEquivalentPlane: Scalars['Float']['output'];
  energyGhgEquivalentPlane: Scalars['Float']['output'];
  heatingGhgEquivalentTrees: Scalars['Float']['output'];
  hotWaterGhgEquivalentTrees: Scalars['Float']['output'];
  electricityGhgEquivalentTrees: Scalars['Float']['output'];
  energyGhgEquivalentTrees: Scalars['Float']['output'];
  totalHeatingCosts: Scalars['Float']['output'];
  totalHotWaterCosts: Scalars['Float']['output'];
  totalElectricityCosts: Scalars['Float']['output'];
  totalEnergyCosts: CalculationItem;
  totalEnergyBalancePerYear: CalculationItem;
  energyGhgBalancePerYear: CalculationItem;
  primaryEnergyForHeating: CalculationItem;
  primaryEnergyForHotWater: CalculationItem;
  totalPowerConsumptionPerYear: CalculationItem;
  totalPowerProductionPerYear: Scalars['Float']['output'];
  ownConsumption: Scalars['Float']['output'];
  powerConsumptionFromEnergyCosts: Scalars['Float']['output'];
  powerConsumptionFromEnergy: Scalars['Float']['output'];
  electricalFeedIntoGrid: Scalars['Float']['output'];
  electricalFeedIntoGridCosts: Scalars['Float']['output'];
  ghgSavingsSolarPowerSystem: Scalars['Float']['output'];
  ghgBalanceOfPowerConsumption: Scalars['Float']['output'];
  solarPowerSystemHelpsToSave: Scalars['Float']['output'];
  specificHeatingDemandPerSqm?: Maybe<Scalars['Float']['output']>;
  hwbCategory?: Maybe<Scalars['String']['output']>;
  primaryEnergyPerSqm?: Maybe<Scalars['Float']['output']>;
  pebCategory?: Maybe<Scalars['String']['output']>;
  ghgPerSqm?: Maybe<Scalars['Float']['output']>;
  ghgCategory?: Maybe<Scalars['String']['output']>;
  efficiencyFactor?: Maybe<Scalars['Float']['output']>;
  efficiencyFactorCat?: Maybe<Scalars['String']['output']>;
};

export type BuildingStatusFormValues = IBuildingStatusFormValues & {
  __typename?: 'BuildingStatusFormValues';
  heatedLivingArea: Scalars['Int']['output'];
  houseType: HouseType;
  energyStandard: EnergyStandard;
  powerConsumers: Array<PowerConsumer>;
  hotWaterAmount: HotWaterAmount;
  hotWaterProducer: HotWaterProducer;
  roomTemperature: RoomTemperature;
  heatingSystem: HeatingSystem;
  isSolarHeatingSystem: Scalars['Boolean']['output'];
  solarHeatingSystemType?: Maybe<SolarHeatingSystemType>;
  isSolarPowerSystem: Scalars['Boolean']['output'];
  solarPowerSystemSize?: Maybe<Scalars['Int']['output']>;
  devicesAreYoungerThan10Years: Scalars['Boolean']['output'];
  heatingSystemAge: HeatingSystemAge;
  residentsCount: Scalars['Int']['output'];
  renovations: Array<Renovation>;
};

export interface BuildingStatusFormValuesInput {
  heatedLivingArea: Scalars['Int']['input'];
  houseType: HouseType;
  energyStandard: EnergyStandard;
  powerConsumers: Array<PowerConsumer>;
  hotWaterAmount: HotWaterAmount;
  hotWaterProducer: HotWaterProducer;
  roomTemperature: RoomTemperature;
  heatingSystem: HeatingSystem;
  isSolarHeatingSystem: Scalars['Boolean']['input'];
  solarHeatingSystemType?: InputMaybe<SolarHeatingSystemType>;
  isSolarPowerSystem: Scalars['Boolean']['input'];
  solarPowerSystemSize?: InputMaybe<Scalars['Int']['input']>;
  devicesAreYoungerThan10Years: Scalars['Boolean']['input'];
  heatingSystemAge: HeatingSystemAge;
  residentsCount: Scalars['Int']['input'];
  renovations: Array<Renovation>;
  step?: InputMaybe<Scalars['Int']['input']>;
  customEnergyRequirements: CustomEnergyRequirementsInput;
  coordinates?: InputMaybe<LatLngInput>;
  floorsNumber?: InputMaybe<Scalars['Int']['input']>;
  houseArea?: InputMaybe<Scalars['Int']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
}

export interface CalculateBreakEvenPointInput {
  equity: Scalars['Float']['input'];
  governmentFunding: Scalars['Float']['input'];
  newEnergyCosts: Scalars['Float']['input'];
  oldEnergyCosts: Scalars['Float']['input'];
  period: Scalars['Int']['input'];
  interestRate: Scalars['Float']['input'];
}

export type CalculateBreakEvenPointResponse = {
  __typename?: 'CalculateBreakEvenPointResponse';
  monthlyRate: Scalars['Float']['output'];
  loanAmount: Scalars['Float']['output'];
  breakEvenPoint: Scalars['Int']['output'];
};

export type CalculatePriceHubbleResult = {
  __typename?: 'CalculatePriceHubbleResult';
  actualSalePrice?: Maybe<Scalars['Float']['output']>;
  plannedSalePrice?: Maybe<Scalars['Float']['output']>;
};

export type CalculationItem = {
  __typename?: 'CalculationItem';
  calculatedValue: Scalars['Float']['output'];
  userValue?: Maybe<Scalars['Float']['output']>;
};

export enum CalculationPreference {
  system = 'system',
  custom = 'custom',
}

export type CategoryPlanningItem = {
  __typename?: 'CategoryPlanningItem';
  name: Scalars['String']['output'];
  calculatedPrice: Scalars['Float']['output'];
  userPrice?: Maybe<Scalars['Float']['output']>;
  hasEquipmentTypes: Scalars['Boolean']['output'];
  equipmentType?: Maybe<EquipmentType>;
};

export type CO2Calculations = {
  __typename?: 'CO2Calculations';
  _id: Scalars['ID']['output'];
  project: Project;
  createdAt: Scalars['DateTimeISO']['output'];
  customEnergyRequirements?: Maybe<CustomEnergyRequirements>;
  energyRenovations?: Maybe<EnergyRenovations>;
  actualBuildingStatus: BuildingStatus;
  possibleBuildingStatus?: Maybe<BuildingStatus>;
  plannedBuildingStatus?: Maybe<BuildingStatus>;
  systemCalculatedTotalEnergyBalancePerYear?: Maybe<Scalars['Float']['output']>;
  systemCalculatedEnergyGhgBalancePerYear?: Maybe<Scalars['Float']['output']>;
  systemCalculatedTotalEnergyCosts?: Maybe<Scalars['Float']['output']>;
};

export type CO2Category = {
  __typename?: 'CO2Category';
  co2Savings: Scalars['Float']['output'];
  projectsCount: Scalars['Float']['output'];
};

export type CO2SavingsReport = {
  __typename?: 'CO2SavingsReport';
  newWindows: CO2Category;
  ceilingTopInsulation: CO2Category;
  ceilingBasementInsulation: CO2Category;
  facadeInsulation: CO2Category;
  domesticVentilation: CO2Category;
  solarPowerSystem: CO2Category;
  heatingSystem: CO2Category;
};

export type CountEntitiesPerIframe = {
  __typename?: 'CountEntitiesPerIframe';
  iframeId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  count: Scalars['Int']['output'];
};

export type CountEntitiesPerTenant = {
  __typename?: 'CountEntitiesPerTenant';
  tenantId: Scalars['ID']['output'];
  companyName: Scalars['String']['output'];
  count: Scalars['Int']['output'];
};

export type CreatedApplication = {
  __typename?: 'CreatedApplication';
  _id: Scalars['ID']['output'];
  secret: Scalars['String']['output'];
};

export interface CreateFileInstanceInput {
  fileId: Scalars['ID']['input'];
  instanceType: FileInstanceType;
  instanceId: Scalars['ID']['input'];
  iframeId: Scalars['ID']['input'];
}

export interface CreateProjectInput {
  name: Scalars['String']['input'];
  type: ProjectType;
  country: Scalars['String']['input'];
}

export type CustomEnergyRequirements = {
  __typename?: 'CustomEnergyRequirements';
  coal: CustomEnergyRequirementsItem;
  heatingOil: CustomEnergyRequirementsItem;
  naturalGas: CustomEnergyRequirementsItem;
  firewood: CustomEnergyRequirementsItem;
  districtHeating: CustomEnergyRequirementsItem;
  woodChips: CustomEnergyRequirementsItem;
  woodPellets: CustomEnergyRequirementsItem;
};

export interface CustomEnergyRequirementsInput {
  coal?: InputMaybe<Scalars['Float']['input']>;
  heatingOil?: InputMaybe<Scalars['Float']['input']>;
  naturalGas?: InputMaybe<Scalars['Float']['input']>;
  firewood?: InputMaybe<Scalars['Float']['input']>;
  districtHeating?: InputMaybe<Scalars['Float']['input']>;
  woodChips?: InputMaybe<Scalars['Float']['input']>;
  woodPellets?: InputMaybe<Scalars['Float']['input']>;
}

export type CustomEnergyRequirementsItem = {
  __typename?: 'CustomEnergyRequirementsItem';
  userValue?: Maybe<Scalars['Float']['output']>;
  costs?: Maybe<Scalars['Float']['output']>;
  energy?: Maybe<Scalars['Float']['output']>;
  ghg?: Maybe<Scalars['Float']['output']>;
};

export type DownloadFileRequestResponse = {
  __typename?: 'DownloadFileRequestResponse';
  signedURL: Scalars['String']['output'];
};

export enum EGroup {
  additionalCosts = 'additionalCosts',
  costDrivers = 'costDrivers',
  main = 'main',
  options = 'options',
}

export type EnergyRenovationItem = {
  __typename?: 'EnergyRenovationItem';
  co2Savings: Scalars['Float']['output'];
  energyCostSavings: Scalars['Float']['output'];
};

export type EnergyRenovations = {
  __typename?: 'EnergyRenovations';
  newWindows?: Maybe<EnergyRenovationItem>;
  ceilingTopInsulation?: Maybe<EnergyRenovationItem>;
  ceilingBasementInsulation?: Maybe<EnergyRenovationItem>;
  facadeInsulation?: Maybe<EnergyRenovationItem>;
  domesticVentilation?: Maybe<EnergyRenovationItem>;
  solarPowerSystem?: Maybe<EnergyRenovationItem>;
  heatingSystem?: Maybe<EnergyRenovationItem>;
};

export enum EnergyStandard {
  before1960 = 'before1960',
  between1960And1975 = 'between1960And1975',
  between1976And1990 = 'between1976And1990',
  after1990 = 'after1990',
  lowEnergyHouse = 'lowEnergyHouse',
  passiveHouse = 'passiveHouse',
}

export enum EquipmentType {
  standard = 'standard',
  premium = 'premium',
  superior = 'superior',
  noEquipmentType = 'noEquipmentType',
}

export type EquipmentTypes = IEquipmentTypes & {
  __typename?: 'EquipmentTypes';
  standard: Scalars['Float']['output'];
  premium: Scalars['Float']['output'];
  superior: Scalars['Float']['output'];
};

export interface EquipmentTypesInput {
  standard: Scalars['Float']['input'];
  premium: Scalars['Float']['input'];
  superior: Scalars['Float']['input'];
}

export type EquivalentsSection = ISection & {
  __typename?: 'EquivalentsSection';
  position?: Maybe<Scalars['Int']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export interface EquivalentsSectionInput {
  position?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum EventType {
  request = 'request',
  response = 'response',
  error = 'error',
}

export enum FacadeInsulationType {
  single = 'single',
  double = 'double',
  middle = 'middle',
}

export type File = {
  __typename?: 'File';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  iframeId: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  sizeBytes: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export interface FileInput {
  filename: Scalars['String']['input'];
  sizeBytes: Scalars['Float']['input'];
  contentType: Scalars['String']['input'];
  webClientFileId: Scalars['ID']['input'];
  instanceType?: InputMaybe<FileInstanceType>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
}

export type FileInstanceLink = {
  __typename?: 'FileInstanceLink';
  _id: Scalars['ID']['output'];
  file: File;
  instanceType: FileInstanceType;
  instanceId: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  iframeId: Scalars['String']['output'];
  updatedBy?: Maybe<BasicUserAttributes>;
};

export enum FileInstanceType {
  EMAIL_LOGO = 'EMAIL_LOGO',
  UI_LOGO = 'UI_LOGO',
  PDF_LOGO = 'PDF_LOGO',
  FAVICON = 'FAVICON',
  APPLE_TOUCH_ICON = 'APPLE_TOUCH_ICON',
  FAVICON_32 = 'FAVICON_32',
  FAVICON_16 = 'FAVICON_16',
  SAFARI_PINNED_TAB = 'SAFARI_PINNED_TAB',
  ANDROID_CHROME_192 = 'ANDROID_CHROME_192',
  ANDROID_CHROME_512 = 'ANDROID_CHROME_512',
  IMG_HOME_PAGE = 'IMG_HOME_PAGE',
  IMG_FIRST_STEP = 'IMG_FIRST_STEP',
  IMG_SECOND_STEP = 'IMG_SECOND_STEP',
  IMG_THIRD_STEP = 'IMG_THIRD_STEP',
  IMG_FOURTH_STEP = 'IMG_FOURTH_STEP',
  IMG_FIFTH_STEP = 'IMG_FIFTH_STEP',
  PARTNERS_FIRST_SECTION_BG = 'PARTNERS_FIRST_SECTION_BG',
  PARTNERS_FIRST_SECTION_LOGO = 'PARTNERS_FIRST_SECTION_LOGO',
  PARTNERS_SECOND_SECTION_BG = 'PARTNERS_SECOND_SECTION_BG',
  PARTNERS_SECOND_SECTION_LOGO = 'PARTNERS_SECOND_SECTION_LOGO',
  PARTNERS_THIRD_SECTION_BG = 'PARTNERS_THIRD_SECTION_BG',
  PARTNERS_THIRD_SECTION_LOGO = 'PARTNERS_THIRD_SECTION_LOGO',
}

export enum FormOfAddress {
  HERR = 'HERR',
  FRAU = 'FRAU',
  DIVERS = 'DIVERS',
}

export type FormValues = RenovatingHouseFormValues | NewBuildingFormValues;

export type FuelMatrix = {
  __typename?: 'FuelMatrix';
  configuration: FuelMatrixConfiguration;
  updatedBy?: Maybe<BasicUserAttributes>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type FuelMatrixConfiguration = {
  __typename?: 'FuelMatrixConfiguration';
  heatingOil: FuelMatrixConfigurationItem;
  naturalGas: FuelMatrixConfigurationItem;
  coal: FuelMatrixConfigurationItem;
  firewood: FuelMatrixConfigurationItem;
  woodChips: FuelMatrixConfigurationItem;
  woodPellets: FuelMatrixConfigurationItem;
  electricityUsed: FuelMatrixConfigurationItem;
  electricityProduced: FuelMatrixConfigurationItem;
  districtHeating: FuelMatrixConfigurationItem;
};

export interface FuelMatrixConfigurationInput {
  heatingOil: FuelMatrixConfigurationItemInput;
  naturalGas: FuelMatrixConfigurationItemInput;
  coal: FuelMatrixConfigurationItemInput;
  firewood: FuelMatrixConfigurationItemInput;
  woodChips: FuelMatrixConfigurationItemInput;
  woodPellets: FuelMatrixConfigurationItemInput;
  electricityUsed: FuelMatrixConfigurationItemInput;
  electricityProduced: FuelMatrixConfigurationItemInput;
  districtHeating: FuelMatrixConfigurationItemInput;
}

export type FuelMatrixConfigurationItem = {
  __typename?: 'FuelMatrixConfigurationItem';
  input: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
  avgPricePerUnit: Scalars['Float']['output'];
  conversionFactor?: Maybe<Scalars['Float']['output']>;
  resultInKWhHeating?: Maybe<Scalars['Float']['output']>;
  avgPricePerKWh?: Maybe<Scalars['Float']['output']>;
};

export interface FuelMatrixConfigurationItemInput {
  input: Scalars['Float']['input'];
  unit: Scalars['String']['input'];
  avgPricePerUnit: Scalars['Float']['input'];
  conversionFactor?: InputMaybe<Scalars['Float']['input']>;
  resultInKWhHeating?: InputMaybe<Scalars['Float']['input']>;
  avgPricePerKWh?: InputMaybe<Scalars['Float']['input']>;
}

export type GetCO2SavingsByProjectsItem = {
  __typename?: 'GetCO2SavingsByProjectsItem';
  projectId: Scalars['ID']['output'];
  energyRenovations: EnergyRenovations;
  name: Scalars['String']['output'];
};

export type GetIframeIdsResponse = {
  __typename?: 'GetIframeIdsResponse';
  iframeIds: Array<GetIframeIdsResponseItem>;
};

export type GetIframeIdsResponseItem = {
  __typename?: 'GetIframeIdsResponseItem';
  _id: Scalars['ID']['output'];
  projectIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type Grund = {
  __typename?: 'Grund';
  flaeche?: Maybe<Scalars['Float']['output']>;
};

export type HeaderSection = ISection & {
  __typename?: 'HeaderSection';
  position?: Maybe<Scalars['Int']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  header?: Maybe<Translation>;
};

export interface HeaderSectionInput {
  position?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  header?: InputMaybe<TranslationInput>;
}

export enum HeatingSystem {
  heatPump = 'heatPump',
  districtHeatingBiomass = 'districtHeatingBiomass',
  districtHeatingStandard = 'districtHeatingStandard',
  woodChips = 'woodChips',
  woodPellets = 'woodPellets',
  firewood = 'firewood',
  coal = 'coal',
  heatingOil = 'heatingOil',
  electric = 'electric',
  naturalGas = 'naturalGas',
}

export enum HeatingSystemAge {
  olderThan20Years = 'olderThan20Years',
  between10And20Years = 'between10And20Years',
  lessThan10Years = 'lessThan10Years',
}

export enum HotWaterAmount {
  low = 'low',
  medium = 'medium',
  hight = 'hight',
}

export enum HotWaterProducer {
  electricBoiler = 'electricBoiler',
  gasHeater = 'gasHeater',
  heatPump = 'heatPump',
  heatingSystem = 'heatingSystem',
}

export enum HouseType {
  singleFamilyHouse = 'singleFamilyHouse',
  bungalowOrComplexFloorPlan = 'bungalowOrComplexFloorPlan',
  townHouse = 'townHouse',
  semiDetachedHouse = 'semiDetachedHouse',
}

export type IBuildingStatusFormValues = {
  heatedLivingArea: Scalars['Int']['output'];
  houseType: HouseType;
  energyStandard: EnergyStandard;
  powerConsumers: Array<PowerConsumer>;
  hotWaterAmount: HotWaterAmount;
  hotWaterProducer: HotWaterProducer;
  roomTemperature: RoomTemperature;
  heatingSystem: HeatingSystem;
  isSolarHeatingSystem: Scalars['Boolean']['output'];
  solarHeatingSystemType?: Maybe<SolarHeatingSystemType>;
  isSolarPowerSystem: Scalars['Boolean']['output'];
  solarPowerSystemSize?: Maybe<Scalars['Int']['output']>;
  devicesAreYoungerThan10Years: Scalars['Boolean']['output'];
  heatingSystemAge: HeatingSystemAge;
  residentsCount: Scalars['Int']['output'];
  renovations: Array<Renovation>;
};

export type IEquipmentTypes = {
  standard: Scalars['Float']['output'];
  premium: Scalars['Float']['output'];
  superior: Scalars['Float']['output'];
};

export type Iframe = IIframe & {
  __typename?: 'Iframe';
  _id: Scalars['ID']['output'];
  tenantId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectType: ProjectType;
  headInjectionTags?: Maybe<Array<Scalars['String']['output']>>;
  requestProjectPdfConfig: RequestProjectPdfEmailConfig;
  theme: Theme;
  privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  termsOfUseUrl?: Maybe<Scalars['String']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  resultPageConfiguration?: Maybe<ResultPageConfiguration>;
  title: Scalars['String']['output'];
  template: TemplateName;
  gtmKey?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  fuelMatrix?: Maybe<FuelMatrix>;
  integrationServices?: Maybe<Array<IntegrationService>>;
  contactEmail: Scalars['String']['output'];
  domainWhiteListTypes: Array<Scalars['String']['output']>;
  projectPdfConfig: ProjectPdfConfig;
  changedStatusBy?: Maybe<BasicUserAttributes>;
  changedStatusAt?: Maybe<Scalars['DateTimeISO']['output']>;
  priceHubbleConfig?: Maybe<IframePriceHubbleConfig>;
  apiIntegrations?: Maybe<Array<ApiIntegrations>>;
  webhooks: Array<Webhook>;
  planningItems?: Maybe<Array<IframePlanningItem>>;
};

export type IframeAsset = {
  __typename?: 'IframeAsset';
  _id: Scalars['ID']['output'];
  type: FileInstanceType;
  contentType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type IframeConfigurationResponse = IIframe & {
  __typename?: 'IframeConfigurationResponse';
  _id: Scalars['ID']['output'];
  tenantId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectType: ProjectType;
  headInjectionTags?: Maybe<Array<Scalars['String']['output']>>;
  requestProjectPdfConfig: RequestProjectPdfEmailConfig;
  theme: Theme;
  privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  termsOfUseUrl?: Maybe<Scalars['String']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  resultPageConfiguration?: Maybe<ResultPageConfiguration>;
  title: Scalars['String']['output'];
  template: TemplateName;
  gtmKey?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  fuelMatrix?: Maybe<FuelMatrix>;
  integrationServices?: Maybe<Array<IntegrationService>>;
};

export interface IframeInput {
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
  projectType: ProjectType;
  domainWhiteListTypes: Array<Scalars['String']['input']>;
  headInjectionTags?: InputMaybe<Array<Scalars['String']['input']>>;
  theme: ThemeInput;
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  termsOfUseUrl?: InputMaybe<Scalars['String']['input']>;
  contactEmail: Scalars['String']['input'];
  requestProjectPdfConfig: RequestProjectPdfEmailConfigInput;
  projectPdfConfig: ProjectPdfConfigInput;
  template: TemplateName;
  country?: InputMaybe<Scalars['String']['input']>;
  integrationServices?: InputMaybe<Array<IntegrationService>>;
  apiIntegrations?: InputMaybe<ApiIntegrationsInput>;
}

export type IframePlanningItem = {
  __typename?: 'IframePlanningItem';
  parentId: Scalars['ID']['output'];
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  equipmentTypes?: Maybe<EquipmentTypes>;
  pricesPerUnit?: Maybe<SolePlateOrBasementPricesPerUnit>;
  name: ProjectFeature;
  parent: ProjectFeature;
};

export interface IframePlanningItemInput {
  parentId: Scalars['ID']['input'];
  pricePerUnit?: InputMaybe<Scalars['Float']['input']>;
  equipmentTypes?: InputMaybe<EquipmentTypesInput>;
  pricesPerUnit?: InputMaybe<SolePlateOrBasementPricesPerUnitInput>;
}

export type IframePriceHubbleConfig = {
  __typename?: 'IframePriceHubbleConfig';
  isEnabled: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
  password: Scalars['String']['output'];
};

export interface IframePriceHubbleInput {
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface IframeWebhookInput {
  trigger: WebhookTrigger;
  events: Array<WebhookEvent>;
  url: Scalars['String']['input'];
  headers: Array<WebhookHeaderInput>;
}

export type IIframe = {
  _id: Scalars['ID']['output'];
  tenantId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectType: ProjectType;
  headInjectionTags?: Maybe<Array<Scalars['String']['output']>>;
  requestProjectPdfConfig: RequestProjectPdfEmailConfig;
  theme: Theme;
  privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  termsOfUseUrl?: Maybe<Scalars['String']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  resultPageConfiguration?: Maybe<ResultPageConfiguration>;
  title: Scalars['String']['output'];
  template: TemplateName;
  gtmKey?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  fuelMatrix?: Maybe<FuelMatrix>;
  integrationServices?: Maybe<Array<IntegrationService>>;
};

export type INewBuildingFormValues = {
  landArea?: Maybe<Scalars['Int']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  hasProperty?: Maybe<Scalars['Boolean']['output']>;
  houseType?: Maybe<NewBuildingHouseType>;
  step?: Maybe<Scalars['Int']['output']>;
  avgPropertyPriceSqM?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Int']['output']>;
  shower?: Maybe<Scalars['Boolean']['output']>;
  bathtub?: Maybe<Scalars['Boolean']['output']>;
  estimatedPropertyPrice?: Maybe<Scalars['Float']['output']>;
  carPorts?: Maybe<Scalars['Int']['output']>;
  demolitionArea?: Maybe<Scalars['Int']['output']>;
  floors?: Maybe<Scalars['Int']['output']>;
  garagePlaces?: Maybe<Scalars['Int']['output']>;
  heatingSystemType?: Maybe<NewBuildingHeatingType>;
  livingSpace?: Maybe<Scalars['Int']['output']>;
  livingRoomDirection?: Maybe<LivingRoomDirection>;
  livingSpaceByFloor?: Maybe<Array<Scalars['Int']['output']>>;
  terraceArea?: Maybe<Scalars['Int']['output']>;
  solarPowerSizeKwp?: Maybe<Scalars['Int']['output']>;
  otherInvestmentsAmount?: Maybe<Scalars['Int']['output']>;
  otherInvestmentsComment?: Maybe<Scalars['String']['output']>;
  personalContribution?: Maybe<PersonalContribution>;
  solePlateOrBasement?: Maybe<SolePlateOrBasement>;
  solePlateOrBasementArea?: Maybe<Scalars['Int']['output']>;
  solarPowerEquipmentType?: Maybe<EquipmentType>;
  interiorEquipmentType?: Maybe<EquipmentType>;
  winterGardenEquipmentType?: Maybe<EquipmentType>;
  poolEquipmentType?: Maybe<EquipmentType>;
  gardenLandscapingEquipmentType?: Maybe<EquipmentType>;
  fenceEquipmentType?: Maybe<EquipmentType>;
  tileStoveEquipmentType?: Maybe<EquipmentType>;
  domesticVentilationEquipmentType?: Maybe<EquipmentType>;
  busSystemEquipmentType?: Maybe<EquipmentType>;
  carportEquipmentType?: Maybe<EquipmentType>;
  garageEquipmentType?: Maybe<EquipmentType>;
  city?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export enum IntegrationService {
  aiHints = 'aiHints',
  propertyValuation = 'propertyValuation',
  solarApi = 'solarApi',
}

export type InvestmentsPayOffSection = ISection & {
  __typename?: 'InvestmentsPayOffSection';
  position?: Maybe<Scalars['Int']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export interface InvestmentsPayOffSectionInput {
  position?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}

export type Invitation = {
  __typename?: 'Invitation';
  _id: Scalars['ID']['output'];
  profile: UserProfile;
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  inviter?: Maybe<BasicUserAttributes>;
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  tenantId?: Maybe<Scalars['ID']['output']>;
  role: Roles;
};

export interface InvitationInput {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  role: Roles;
  invitationMessage?: InputMaybe<Scalars['String']['input']>;
  isDraft: Scalars['Boolean']['input'];
}

export type InvitationOrUser = Invitation | User;

export type InvitationRedemptionBrief = {
  __typename?: 'InvitationRedemptionBrief';
  _id: Scalars['ID']['output'];
  email: Scalars['String']['output'];
};

export interface InvitationsFilter {
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  accountType: AccountType;
}

export type IRenovatingHouseFormValues = {
  fullAddress?: Maybe<Scalars['String']['output']>;
  houseArea?: Maybe<Scalars['Int']['output']>;
  floorsNumber?: Maybe<Scalars['Int']['output']>;
  facadeInsulationType?: Maybe<FacadeInsulationType>;
  facadeInsulationArea?: Maybe<Scalars['Int']['output']>;
  facadeInsulationEquipmentType?: Maybe<EquipmentType>;
  domesticVentilationEquipmentType?: Maybe<EquipmentType>;
  newSolarPowerSystemSize?: Maybe<Scalars['Int']['output']>;
  solarPowerSystemEquipmentType?: Maybe<EquipmentType>;
  frontDoor?: Maybe<Scalars['Boolean']['output']>;
  frontDoorEquipmentType?: Maybe<EquipmentType>;
  newWindowsNumber?: Maybe<Scalars['Int']['output']>;
  liftAndSlideDoorsNumber?: Maybe<Scalars['Int']['output']>;
  extraLargeWindowsNumber?: Maybe<Scalars['Int']['output']>;
  newWindowsEquipmentType?: Maybe<EquipmentType>;
  heatingSystemEquipmentType?: Maybe<EquipmentType>;
  batteryStorageSize?: Maybe<Scalars['Int']['output']>;
  roofArea?: Maybe<Scalars['Int']['output']>;
  roofEquipmentType?: Maybe<EquipmentType>;
  roofType?: Maybe<RoofType>;
  batteryStorageBlackoutOption?: Maybe<Scalars['Boolean']['output']>;
  ceilingBasementInsulationArea?: Maybe<Scalars['Int']['output']>;
  ceilingTopInsulationArea?: Maybe<Scalars['Int']['output']>;
  hotWaterNewHeatingSystem?: Maybe<Scalars['Boolean']['output']>;
  newHeatingSystem?: Maybe<NewHeatingSystem>;
  state?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  sanitaryRenovationArea?: Maybe<Scalars['Float']['output']>;
  numberOfBathrooms?: Maybe<Scalars['Float']['output']>;
  numberOfToilets?: Maybe<Scalars['Float']['output']>;
  newFlooringArea?: Maybe<Scalars['Float']['output']>;
  numberOfInteriorDoors?: Maybe<Scalars['Float']['output']>;
  wallsAndInteriorDoorsArea?: Maybe<Scalars['Float']['output']>;
  numberOfCarPorts?: Maybe<Scalars['Float']['output']>;
  numberOfGaragePlaces?: Maybe<Scalars['Float']['output']>;
  kitchenEquipmentType?: Maybe<EquipmentType>;
  airConditionEquipmentType?: Maybe<EquipmentType>;
  sanitaryRenovationEquipmentType?: Maybe<EquipmentType>;
  newFlooringEquipmentType?: Maybe<EquipmentType>;
  wallsAndInteriorDoorsEquipmentType?: Maybe<EquipmentType>;
  renewalOfElectricInstallationEquipmentType?: Maybe<EquipmentType>;
  alarmSystemEquipmentType?: Maybe<EquipmentType>;
  carportEquipmentType?: Maybe<EquipmentType>;
  garageEquipmentType?: Maybe<EquipmentType>;
  poolEquipmentType?: Maybe<EquipmentType>;
  winterGardenEquipmentType?: Maybe<EquipmentType>;
  tileStoveEquipmentType?: Maybe<EquipmentType>;
  busSystemEquipmentType?: Maybe<EquipmentType>;
  otherInvestmentsAmount?: Maybe<Scalars['Int']['output']>;
  otherInvestmentsComment?: Maybe<Scalars['String']['output']>;
  terraceArea?: Maybe<Scalars['Int']['output']>;
};

export type ISection = {
  position?: Maybe<Scalars['Int']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type ISolePlateOrBasementPricesPerUnit = {
  solePlate?: Maybe<Scalars['Float']['output']>;
  basement?: Maybe<Scalars['Float']['output']>;
};

export type LandEstimationAPIParams = {
  __typename?: 'LandEstimationAPIParams';
  liegenschaft: LandEstimationLiegenschaft;
  bewertung: LandEstimationBewertung;
};

export type LandEstimationBewertung = {
  __typename?: 'LandEstimationBewertung';
  objektart?: Maybe<Scalars['String']['output']>;
  grund?: Maybe<Grund>;
};

export type LandEstimationLiegenschaft = {
  __typename?: 'LandEstimationLiegenschaft';
  kaufpreis?: Maybe<Scalars['Float']['output']>;
  kaufdatum?: Maybe<Scalars['DateTimeISO']['output']>;
  adresse?: Maybe<Adresse>;
};

export type LatLng = {
  __typename?: 'LatLng';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export interface LatLngInput {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
}

export type Liegenschaft = {
  __typename?: 'Liegenschaft';
  kaufpreis?: Maybe<Scalars['Float']['output']>;
  vorhaben?: Maybe<Scalars['String']['output']>;
  adresse?: Maybe<Adresse>;
};

export enum LivingRoomDirection {
  north = 'north',
  south = 'south',
  east = 'east',
  west = 'west',
}

export enum Locale {
  de = 'de',
  en = 'en',
}

export type Marktwert = {
  __typename?: 'Marktwert';
  wert?: Maybe<Scalars['Int']['output']>;
  datum?: Maybe<Scalars['DateTimeISO']['output']>;
  pro_m2?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type MeinBauApiLog = {
  __typename?: 'MeinBauApiLog';
  _id: Scalars['ID']['output'];
  applicationId: Scalars['ID']['output'];
  tenantId: Scalars['ID']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  input: Scalars['String']['output'];
  output?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  appName?: Maybe<Scalars['String']['output']>;
  useCase: Scalars['String']['output'];
  method: MethodType;
};

export type Meta = {
  __typename?: 'Meta';
  clientIP: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  utmParams?: Maybe<Scalars['String']['output']>;
};

export enum MethodType {
  POST = 'POST',
}

export type Mutation = {
  __typename?: 'Mutation';
  logoutOtherSessions: Scalars['Boolean']['output'];
  updateLastActivityDate: Scalars['Boolean']['output'];
  logoutSession: Scalars['Boolean']['output'];
  updateUserAccount: User;
  changeLocale: Scalars['Boolean']['output'];
  deactivateUser: Scalars['Boolean']['output'];
  createTenant: Tenant;
  updateTenant: Tenant;
  archiveTenant: Tenant;
  releaseTenant: Tenant;
  addUsersToTenant: Array<User>;
  deleteUserFromTenant: Scalars['Boolean']['output'];
  createIframe: Iframe;
  updateIframe: Iframe;
  addApiIntegration: Iframe;
  removeApiIntegration: Iframe;
  archiveIframe: Iframe;
  releaseIframe: Iframe;
  integrateWithPriceHubbleIframe: Iframe;
  disablePriceHubbleIntegrationIframe: Iframe;
  integrateWithGTM: Iframe;
  disableGTMIntegration: Iframe;
  configureIframeResultPage: Iframe;
  configureIframeFuelMatrix: Iframe;
  login: AuthResponse;
  enableTwoFA: User;
  forgotPassword: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  token: AuthResponse;
  logout: Scalars['Boolean']['output'];
  tokenForProjectEdit: Scalars['String']['output'];
  resendInvitation: Invitation;
  createInvitation: InvitationOrUser;
  deleteInvitation: Scalars['Boolean']['output'];
  redeemInvitation: AuthResponse;
  createProject: QuickCalculatorResult;
  updateRenovationHouseProject: Project;
  updateNewBuildingProject: Project;
  updateCO2CalculatorValues: CO2Calculations;
  updateCO2CalculatedValues: CO2Calculations;
  selectCalculationPreference: SuccessResponse;
  requestProjectPdf: SuccessResponse;
  calculateBreakEvenPoint: CalculateBreakEvenPointResponse;
  createApplicationAccount: CreatedApplication;
  archiveApplication: Application;
  uploadFileRequests: Array<UploadFileRequestResponseItem>;
  persistFile?: Maybe<File>;
  deleteFile: Scalars['Boolean']['output'];
  createFileInstanceLink: FileInstanceLink;
  deleteFileInstanceLink: Scalars['Boolean']['output'];
  calculatePriceHubble: CalculatePriceHubbleResult;
  addWebhook: Iframe;
  updateWebhook: Iframe;
  deleteWebhook: Iframe;
  generateReport: ReportFile;
  updateSprengnetterLandEstimationPrice: SprengnetterApiResult;
  updateSprengnetterRenovationHousePrice: SprengnetterApiResult;
  updateNewBuildingPlanningItems: Scalars['Boolean']['output'];
  resetPlanningItems: Iframe;
};

export type MutationlogoutSessionArgs = {
  id: Scalars['String']['input'];
};

export type MutationupdateUserAccountArgs = {
  userId: Scalars['ID']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  input: UpdateUserAccountInput;
};

export type MutationchangeLocaleArgs = {
  locale: Locale;
};

export type MutationdeactivateUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationcreateTenantArgs = {
  formData: TenantInput;
};

export type MutationupdateTenantArgs = {
  tenantId: Scalars['ID']['input'];
  formData: TenantInput;
};

export type MutationarchiveTenantArgs = {
  tenantId: Scalars['ID']['input'];
};

export type MutationreleaseTenantArgs = {
  tenantId: Scalars['ID']['input'];
};

export type MutationaddUsersToTenantArgs = {
  userIds: Array<Scalars['ID']['input']>;
  role: Roles;
  tenantId: Scalars['ID']['input'];
};

export type MutationdeleteUserFromTenantArgs = {
  tenantId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationcreateIframeArgs = {
  tenantId: Scalars['ID']['input'];
  formData: IframeInput;
};

export type MutationupdateIframeArgs = {
  iframeId: Scalars['ID']['input'];
  formData: IframeInput;
};

export type MutationaddApiIntegrationArgs = {
  iframeId: Scalars['ID']['input'];
  formData: ApiIntegrationsInput;
};

export type MutationremoveApiIntegrationArgs = {
  iframeId: Scalars['ID']['input'];
  input: RemoveApiIntegrationsInput;
};

export type MutationarchiveIframeArgs = {
  iframeId: Scalars['ID']['input'];
};

export type MutationreleaseIframeArgs = {
  iframeId: Scalars['ID']['input'];
};

export type MutationintegrateWithPriceHubbleIframeArgs = {
  priceHubbleData: IframePriceHubbleInput;
  iframeId: Scalars['ID']['input'];
};

export type MutationdisablePriceHubbleIntegrationIframeArgs = {
  iframeId: Scalars['ID']['input'];
};

export type MutationintegrateWithGTMArgs = {
  gtmKey: Scalars['String']['input'];
  iframeId: Scalars['ID']['input'];
};

export type MutationdisableGTMIntegrationArgs = {
  iframeId: Scalars['ID']['input'];
};

export type MutationconfigureIframeResultPageArgs = {
  resultPageConfiguration: ResultPageConfigurationInput;
  iframeId: Scalars['ID']['input'];
};

export type MutationconfigureIframeFuelMatrixArgs = {
  fuelMatrix?: InputMaybe<FuelMatrixConfigurationInput>;
  iframeId: Scalars['ID']['input'];
};

export type MutationloginArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type MutationenableTwoFAArgs = {
  code: Scalars['String']['input'];
};

export type MutationforgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationresetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationtokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationtokenForProjectEditArgs = {
  projectId: Scalars['String']['input'];
  iframeId: Scalars['String']['input'];
};

export type MutationresendInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};

export type MutationcreateInvitationArgs = {
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  formData: InvitationInput;
};

export type MutationdeleteInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};

export type MutationredeemInvitationArgs = {
  password: Scalars['String']['input'];
  invitationId: Scalars['ID']['input'];
};

export type MutationcreateProjectArgs = {
  utmParams?: InputMaybe<Scalars['String']['input']>;
  input: CreateProjectInput;
  iframeId: Scalars['ID']['input'];
};

export type MutationupdateRenovationHouseProjectArgs = {
  input: UpdateRenovatingHouseInput;
  projectId: Scalars['ID']['input'];
};

export type MutationupdateNewBuildingProjectArgs = {
  input: UpdateNewBuildingInput;
  projectId: Scalars['ID']['input'];
};

export type MutationupdateCO2CalculatorValuesArgs = {
  input: BuildingStatusFormValuesInput;
  projectId: Scalars['ID']['input'];
};

export type MutationupdateCO2CalculatedValuesArgs = {
  input: UpdateCO2CalculatedValuesInput;
  projectId: Scalars['ID']['input'];
};

export type MutationselectCalculationPreferenceArgs = {
  calculationPreference: CalculationPreference;
  projectId: Scalars['ID']['input'];
};

export type MutationrequestProjectPdfArgs = {
  projectId: Scalars['ID']['input'];
  input: RequestProjectPdfInput;
};

export type MutationcalculateBreakEvenPointArgs = {
  projectId: Scalars['ID']['input'];
  input: CalculateBreakEvenPointInput;
};

export type MutationcreateApplicationAccountArgs = {
  appName: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationarchiveApplicationArgs = {
  applicationId: Scalars['ID']['input'];
};

export type MutationuploadFileRequestsArgs = {
  tenantId: Scalars['ID']['input'];
  iframeId: Scalars['ID']['input'];
  files: Array<FileInput>;
};

export type MutationpersistFileArgs = {
  requestId: Scalars['ID']['input'];
};

export type MutationdeleteFileArgs = {
  iframeId: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
};

export type MutationcreateFileInstanceLinkArgs = {
  input: CreateFileInstanceInput;
};

export type MutationdeleteFileInstanceLinkArgs = {
  fileInstanceLinkId: Scalars['ID']['input'];
};

export type MutationcalculatePriceHubbleArgs = {
  projectId: Scalars['ID']['input'];
};

export type MutationaddWebhookArgs = {
  input: IframeWebhookInput;
  iframeId: Scalars['ID']['input'];
};

export type MutationupdateWebhookArgs = {
  input: IframeWebhookInput;
  iframeId: Scalars['ID']['input'];
  webhookId: Scalars['ID']['input'];
};

export type MutationdeleteWebhookArgs = {
  webhookId: Scalars['ID']['input'];
  iframeId: Scalars['ID']['input'];
};

export type MutationgenerateReportArgs = {
  input: ReportFileInput;
};

export type MutationupdateSprengnetterLandEstimationPriceArgs = {
  input: SprengnetterLandEstimationInput;
  projectId: Scalars['ID']['input'];
};

export type MutationupdateSprengnetterRenovationHousePriceArgs = {
  projectId: Scalars['ID']['input'];
};

export type MutationupdateNewBuildingPlanningItemsArgs = {
  planningItems: Array<IframePlanningItemInput>;
  iframeId: Scalars['ID']['input'];
};

export type MutationresetPlanningItemsArgs = {
  iframeId: Scalars['ID']['input'];
};

export type NewBuildingFormValues = INewBuildingFormValues & {
  __typename?: 'NewBuildingFormValues';
  landArea?: Maybe<Scalars['Int']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  hasProperty?: Maybe<Scalars['Boolean']['output']>;
  houseType?: Maybe<NewBuildingHouseType>;
  step?: Maybe<Scalars['Int']['output']>;
  avgPropertyPriceSqM?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Int']['output']>;
  shower?: Maybe<Scalars['Boolean']['output']>;
  bathtub?: Maybe<Scalars['Boolean']['output']>;
  estimatedPropertyPrice?: Maybe<Scalars['Float']['output']>;
  carPorts?: Maybe<Scalars['Int']['output']>;
  demolitionArea?: Maybe<Scalars['Int']['output']>;
  floors?: Maybe<Scalars['Int']['output']>;
  garagePlaces?: Maybe<Scalars['Int']['output']>;
  heatingSystemType?: Maybe<NewBuildingHeatingType>;
  livingSpace?: Maybe<Scalars['Int']['output']>;
  livingRoomDirection?: Maybe<LivingRoomDirection>;
  livingSpaceByFloor?: Maybe<Array<Scalars['Int']['output']>>;
  terraceArea?: Maybe<Scalars['Int']['output']>;
  solarPowerSizeKwp?: Maybe<Scalars['Int']['output']>;
  otherInvestmentsAmount?: Maybe<Scalars['Int']['output']>;
  otherInvestmentsComment?: Maybe<Scalars['String']['output']>;
  personalContribution?: Maybe<PersonalContribution>;
  solePlateOrBasement?: Maybe<SolePlateOrBasement>;
  solePlateOrBasementArea?: Maybe<Scalars['Int']['output']>;
  solarPowerEquipmentType?: Maybe<EquipmentType>;
  interiorEquipmentType?: Maybe<EquipmentType>;
  winterGardenEquipmentType?: Maybe<EquipmentType>;
  poolEquipmentType?: Maybe<EquipmentType>;
  gardenLandscapingEquipmentType?: Maybe<EquipmentType>;
  fenceEquipmentType?: Maybe<EquipmentType>;
  tileStoveEquipmentType?: Maybe<EquipmentType>;
  domesticVentilationEquipmentType?: Maybe<EquipmentType>;
  busSystemEquipmentType?: Maybe<EquipmentType>;
  carportEquipmentType?: Maybe<EquipmentType>;
  garageEquipmentType?: Maybe<EquipmentType>;
  city?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<LatLng>;
};

export enum NewBuildingHeatingType {
  gas = 'gas',
  solidFuel = 'solidFuel',
  districtHeating = 'districtHeating',
  heatPump = 'heatPump',
  electric = 'electric',
  other = 'other',
}

export enum NewBuildingHouseType {
  prefabricated = 'prefabricated',
  architect = 'architect',
  builder = 'builder',
}

export enum NewHeatingSystem {
  heatPump = 'heatPump',
  districtHeatingStandard = 'districtHeatingStandard',
  woodPellets = 'woodPellets',
  naturalGas = 'naturalGas',
}

export type ObjektParams = {
  __typename?: 'ObjektParams';
  gebaeude_art?: Maybe<Scalars['String']['output']>;
  bauweise?: Maybe<Scalars['String']['output']>;
  dach?: Maybe<Scalars['String']['output']>;
  keller?: Maybe<Scalars['String']['output']>;
  keller_ausbau?: Maybe<Scalars['String']['output']>;
  anzahl_geschosse?: Maybe<Scalars['Float']['output']>;
  wohnflaeche?: Maybe<Scalars['Float']['output']>;
  baujahr?: Maybe<Scalars['Float']['output']>;
  ausstattung?: Maybe<Scalars['String']['output']>;
  modernisierung?: Maybe<Scalars['String']['output']>;
  sanierungsjahr?: Maybe<Scalars['Float']['output']>;
  minderung_typ?: Maybe<Scalars['String']['output']>;
  zuschlag_typ?: Maybe<Scalars['String']['output']>;
  modernisierung_ermittlungsart?: Maybe<Scalars['String']['output']>;
};

export type PaginatedApiLogResponse = {
  __typename?: 'PaginatedApiLogResponse';
  items: Array<ApiLog>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedApplicationResponse = {
  __typename?: 'PaginatedApplicationResponse';
  items: Array<Application>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedAuditLogEventsClassResponse = {
  __typename?: 'PaginatedAuditLogEventsClassResponse';
  items: Array<AuditLogEventsClass>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedFileResponse = {
  __typename?: 'PaginatedFileResponse';
  items: Array<File>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedGetCO2SavingsByProjectsItemResponse = {
  __typename?: 'PaginatedGetCO2SavingsByProjectsItemResponse';
  items: Array<GetCO2SavingsByProjectsItem>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedIframeResponse = {
  __typename?: 'PaginatedIframeResponse';
  items: Array<Iframe>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedInvitationResponse = {
  __typename?: 'PaginatedInvitationResponse';
  items: Array<Invitation>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedMeinBauApiLogResponse = {
  __typename?: 'PaginatedMeinBauApiLogResponse';
  items: Array<MeinBauApiLog>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedProjectPDFRequestResponse = {
  __typename?: 'PaginatedProjectPDFRequestResponse';
  items: Array<ProjectPDFRequest>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedProjectResponse = {
  __typename?: 'PaginatedProjectResponse';
  items: Array<Project>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedReportFileResponse = {
  __typename?: 'PaginatedReportFileResponse';
  items: Array<ReportFile>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedSentEmailResponse = {
  __typename?: 'PaginatedSentEmailResponse';
  items: Array<SentEmail>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedTenantResponse = {
  __typename?: 'PaginatedTenantResponse';
  items: Array<Tenant>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedUserResponse = {
  __typename?: 'PaginatedUserResponse';
  items: Array<User>;
  total: Scalars['Int']['output'];
  plainTotal?: Maybe<Scalars['Int']['output']>;
};

export type PartnerSection = ISection & {
  __typename?: 'PartnerSection';
  position?: Maybe<Scalars['Int']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  header?: Maybe<Translation>;
  content?: Maybe<Translation>;
  pdfButton?: Maybe<Translation>;
};

export interface PartnerSectionInput {
  position?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  header?: InputMaybe<TranslationInput>;
  content?: InputMaybe<TranslationInput>;
  pdfButton?: InputMaybe<TranslationInput>;
}

export enum PersonalContribution {
  none = 'none',
  low = 'low',
  moderate = 'moderate',
  high = 'high',
}

export type PlanSection = ISection & {
  __typename?: 'PlanSection';
  position?: Maybe<Scalars['Int']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export interface PlanSectionInput {
  position?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum PlatformType {
  ADMIN = 'ADMIN',
  IFRAME = 'IFRAME',
}

export enum PowerConsumer {
  sauna = 'sauna',
  outdoorWhirlpool = 'outdoorWhirlpool',
  swimmingPool = 'swimmingPool',
  airCondition = 'airCondition',
  waterbed = 'waterbed',
}

export type PriceHubbleCalculations = {
  __typename?: 'PriceHubbleCalculations';
  actualSalePrice?: Maybe<Scalars['Float']['output']>;
  plannedSalePrice?: Maybe<Scalars['Float']['output']>;
  apiCallsCount?: Maybe<Scalars['Int']['output']>;
};

export type PriceHubbleClass = {
  __typename?: 'PriceHubbleClass';
  apiCallsCount?: Maybe<Scalars['Int']['output']>;
  actualSalePrice?: Maybe<Scalars['Float']['output']>;
  plannedSalePrice?: Maybe<Scalars['Float']['output']>;
};

export type Project = {
  __typename?: 'Project';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  step?: Maybe<Scalars['Float']['output']>;
  type: ProjectType;
  formValues: FormValues;
  priceHubble?: Maybe<PriceHubbleCalculations>;
  calculations?: Maybe<ProjectCalculations>;
  budget: Scalars['Float']['output'];
  country: Scalars['String']['output'];
  countryIndex?: Maybe<Scalars['Float']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
  equipmentType: EquipmentType;
  features: Array<ProjectFeature>;
  projectCreator: BasicUserAttributes;
  archived?: Maybe<Scalars['Boolean']['output']>;
  archivedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  archivedBy?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  meta?: Maybe<Meta>;
  createdAt: Scalars['DateTimeISO']['output'];
  iframeId: Scalars['String']['output'];
  creator?: Maybe<BasicUserAttributes>;
  price: Scalars['Float']['output'];
  sprengnetterPrice?: Maybe<SprengnetterApiResult>;
};

export type ProjectCalculations = {
  __typename?: 'ProjectCalculations';
  priceHubble: PriceHubbleClass;
};

export enum ProjectFeature {
  facadeInsulation = 'facadeInsulation',
  newWindows = 'newWindows',
  roof = 'roof',
  ceilingTopInsulation = 'ceilingTopInsulation',
  ceilingBasementInsulation = 'ceilingBasementInsulation',
  heatingSystem = 'heatingSystem',
  solarPowerSystem = 'solarPowerSystem',
  batteryStorage = 'batteryStorage',
  solarHeating = 'solarHeating',
  domesticVentilation = 'domesticVentilation',
  frontDoor = 'frontDoor',
  wallboxEMobility = 'wallboxEMobility',
  planningAndConstructionManagement = 'planningAndConstructionManagement',
  propertyCosts = 'propertyCosts',
  chargesAndTaxes = 'chargesAndTaxes',
  developmentCosts = 'developmentCosts',
  demolitionCosts = 'demolitionCosts',
  solePlateOrBasement = 'solePlateOrBasement',
  shellConstruction = 'shellConstruction',
  windowsAndDoors = 'windowsAndDoors',
  sanitary = 'sanitary',
  electricalInstallation = 'electricalInstallation',
  interiorConstruction = 'interiorConstruction',
  interiorEquipment = 'interiorEquipment',
  terrace = 'terrace',
  winterGarden = 'winterGarden',
  pool = 'pool',
  gardenLandscaping = 'gardenLandscaping',
  fence = 'fence',
  carport = 'carport',
  garage = 'garage',
  tileStove = 'tileStove',
  busSystem = 'busSystem',
  hillsideLocation = 'hillsideLocation',
  limitedAccess = 'limitedAccess',
  specialUnderground = 'specialUnderground',
  otherInvestments = 'otherInvestments',
  reservesForUnexpectedCosts = 'reservesForUnexpectedCosts',
  earthworks = 'earthworks',
  channel = 'channel',
  masonry = 'masonry',
  chimney = 'chimney',
  carpentry = 'carpentry',
  roofing = 'roofing',
  facade = 'facade',
  metal = 'metal',
  plumbing = 'plumbing',
  sanitaryEquipment = 'sanitaryEquipment',
  heatingInstallation = 'heatingInstallation',
  radiator = 'radiator',
  boiler = 'boiler',
  electroCableDucts = 'electroCableDucts',
  electroWiring = 'electroWiring',
  electroFinishing = 'electroFinishing',
  windowsPatioBalcony = 'windowsPatioBalcony',
  interiorPlastering = 'interiorPlastering',
  floorConstruction = 'floorConstruction',
  tiling = 'tiling',
  interiorDoorsDoorStays = 'interiorDoorsDoorStays',
  flooring = 'flooring',
  paintingWork = 'paintingWork',
  drywallConstruction = 'drywallConstruction',
  priceOfProperty = 'priceOfProperty',
  taxesAndNotaryExpenses = 'taxesAndNotaryExpenses',
  costEstimation = 'costEstimation',
  permitPlanning = 'permitPlanning',
  executionPlanning = 'executionPlanning',
  billOfQuantity = 'billOfQuantity',
  constructionManagement = 'constructionManagement',
  gardenDesign = 'gardenDesign',
  undergroundType = 'undergroundType',
  kitchen = 'kitchen',
  airCondition = 'airCondition',
  sanitaryRenovation = 'sanitaryRenovation',
  newFlooring = 'newFlooring',
  wallsAndInteriorDoors = 'wallsAndInteriorDoors',
  renewalOfElectricInstallation = 'renewalOfElectricInstallation',
  alarmSystem = 'alarmSystem',
}

export enum ProjectImplementationStartPointEnum {
  withinThreeMonths = 'withinThreeMonths',
  inThreeToSixMonths = 'inThreeToSixMonths',
  laterThanInSixMonths = 'laterThanInSixMonths',
}

export type ProjectPdfConfig = {
  __typename?: 'ProjectPdfConfig';
  pdfWebsiteUrl: Scalars['String']['output'];
  pdfDownloadEnabledViaPublicRoute: Scalars['Boolean']['output'];
};

export interface ProjectPdfConfigInput {
  pdfWebsiteUrl: Scalars['String']['input'];
  pdfDownloadEnabledViaPublicRoute: Scalars['Boolean']['input'];
}

export type ProjectPDFRequest = {
  __typename?: 'ProjectPDFRequest';
  _id: Scalars['ID']['output'];
  project: Project;
  meta: Meta;
  user: ProjectPdfRequestUser;
  projectImplementationStartPoint?: Maybe<ProjectImplementationStartPointEnum>;
  createdAt: Scalars['DateTimeISO']['output'];
};

export type ProjectPdfRequestUser = {
  __typename?: 'ProjectPdfRequestUser';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  formOfAddress?: Maybe<FormOfAddress>;
};

export type ProjectPlan = {
  __typename?: 'ProjectPlan';
  _id: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  price: Scalars['Float']['output'];
  buildingPrice?: Maybe<Scalars['Float']['output']>;
  buildingSitePrice?: Maybe<Scalars['Float']['output']>;
  personalContributionValue?: Maybe<Scalars['Float']['output']>;
  categories: Array<ProjectPlanCategory>;
};

export type ProjectPlanCategory = {
  __typename?: 'ProjectPlanCategory';
  name: ProjectFeature;
  label: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  calculatedPrice: Scalars['Float']['output'];
  userPrice?: Maybe<Scalars['Float']['output']>;
  avgEquipmentType?: Maybe<EquipmentType>;
  equipmentType?: Maybe<EquipmentType>;
  hasMixedEquipmentType: Scalars['Boolean']['output'];
  planningItems: Array<CategoryPlanningItem>;
  subitems: Array<ProjectPlanCategory>;
  group?: Maybe<EGroup>;
};

export type ProjectPlanCategorysubitemsArgs = {
  projectId: Scalars['ID']['input'];
};

export type ProjectPlanCategorygroupArgs = {
  projectId: Scalars['ID']['input'];
};

export enum ProjectType {
  renovatingHouse = 'renovatingHouse',
  newBuilding = 'newBuilding',
}

export enum ProjectTypeFilter {
  all = 'all',
  renovatingHouse = 'renovatingHouse',
  newBuilding = 'newBuilding',
}

export type Query = {
  __typename?: 'Query';
  sessions: Array<SessionResponse>;
  me: User;
  allUsers: PaginatedUserResponse;
  tenantUsers: Array<BasicUserAttributes>;
  tenant: Tenant;
  tenants: PaginatedTenantResponse;
  tenantsIds: Array<TenantIds>;
  allUserTenants: PaginatedTenantResponse;
  iframeIds: GetIframeIdsResponse;
  iframeConfiguration: IframeConfigurationResponse;
  iframe: Iframe;
  iframes: PaginatedIframeResponse;
  twoFaQrCode: Scalars['String']['output'];
  invitationRedemptionBrief: InvitationRedemptionBrief;
  allInvitations: PaginatedInvitationResponse;
  project: Project;
  allProjects: PaginatedProjectResponse;
  co2Calculations?: Maybe<CO2Calculations>;
  co2SavingsReport: CO2SavingsReport;
  co2SavingsReportByProject: PaginatedGetCO2SavingsByProjectsItemResponse;
  projectPdfRequests: PaginatedProjectPDFRequestResponse;
  meinBauApiLogs: PaginatedMeinBauApiLogResponse;
  meinBauApiLog: MeinBauApiLog;
  sentEmails: PaginatedSentEmailResponse;
  apiLogs: PaginatedApiLogResponse;
  apiLog: ApiLog;
  generalAnalytic: AnalyticsResponse;
  tenantAnalytic: TenantAnalyticsResponse;
  projectPlan: ProjectPlan;
  allApplications: PaginatedApplicationResponse;
  fileInstances: Array<FileInstanceLink>;
  files: PaginatedFileResponse;
  iframeFiles: Array<BriefFileInfo>;
  previewImage: DownloadFileRequestResponse;
  downloadFileRequest: DownloadFileRequestResponse;
  iframeAssets: Array<IframeAsset>;
  auditLogs: PaginatedAuditLogEventsClassResponse;
  auditLog: AuditLogEventsClass;
  checkStatusOfReports: PaginatedReportFileResponse;
};

export type QueryallUsersArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  filter: UsersFilter;
};

export type QuerytenantUsersArgs = {
  searchQuery: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerytenantArgs = {
  tenantId: Scalars['ID']['input'];
};

export type QuerytenantsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  searchString: Scalars['String']['input'];
  status: TenantStatusFilter;
};

export type QueryallUserTenantsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  searchQuery: Scalars['String']['input'];
};

export type QueryiframeIdsArgs = {
  withProjects?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryiframeConfigurationArgs = {
  iframeId: Scalars['ID']['input'];
};

export type QueryiframeArgs = {
  iframeId: Scalars['ID']['input'];
};

export type QueryiframesArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  searchString: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryinvitationRedemptionBriefArgs = {
  invitationId: Scalars['ID']['input'];
};

export type QueryallInvitationsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  filter: InvitationsFilter;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryprojectArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryallProjectsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  iframeId?: InputMaybe<Scalars['ID']['input']>;
  searchQuery: Scalars['String']['input'];
  projectType?: InputMaybe<ProjectTypeFilter>;
};

export type Queryco2CalculationsArgs = {
  projectId: Scalars['ID']['input'];
};

export type Queryco2SavingsReportArgs = {
  iframeId: Scalars['ID']['input'];
};

export type Queryco2SavingsReportByProjectArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  searchString: Scalars['String']['input'];
  iframeId: Scalars['String']['input'];
};

export type QueryprojectPdfRequestsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  iframeId?: InputMaybe<Scalars['ID']['input']>;
  searchQuery: Scalars['String']['input'];
};

export type QuerymeinBauApiLogsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerymeinBauApiLogArgs = {
  meinBauApiLogId: Scalars['ID']['input'];
};

export type QuerysentEmailsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  to?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SentEmailType>;
};

export type QueryapiLogsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  apiName?: InputMaybe<ApiNameFilter>;
};

export type QueryapiLogArgs = {
  apiLogId: Scalars['ID']['input'];
};

export type QuerygeneralAnalyticArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type QuerytenantAnalyticArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTimeISO']['input']>;
  tenantId: Scalars['ID']['input'];
};

export type QueryprojectPlanArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryallApplicationsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  filter: ApplicationFilter;
};

export type QueryfileInstancesArgs = {
  iframeId: Scalars['ID']['input'];
};

export type QueryfilesArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  iframeId: Scalars['ID']['input'];
};

export type QueryiframeFilesArgs = {
  iframeId: Scalars['ID']['input'];
};

export type QuerypreviewImageArgs = {
  fileId: Scalars['ID']['input'];
};

export type QuerydownloadFileRequestArgs = {
  fileId: Scalars['ID']['input'];
};

export type QueryiframeAssetsArgs = {
  iframeId: Scalars['ID']['input'];
};

export type QueryauditLogsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  searchString: Scalars['String']['input'];
  tenant?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryauditLogArgs = {
  auditLogId: Scalars['ID']['input'];
};

export type QuerycheckStatusOfReportsArgs = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  iframeId?: InputMaybe<Scalars['String']['input']>;
};

export type QuickCalculatorResult = {
  __typename?: 'QuickCalculatorResult';
  projectId: Scalars['String']['output'];
  iframeId: Scalars['String']['output'];
};

export interface RemoveApiIntegrationsInput {
  name: ApiIntegrationNames;
  clientId: Scalars['String']['input'];
}

export type RenovatingHouseFormValues = IRenovatingHouseFormValues & {
  __typename?: 'RenovatingHouseFormValues';
  fullAddress?: Maybe<Scalars['String']['output']>;
  houseArea?: Maybe<Scalars['Int']['output']>;
  floorsNumber?: Maybe<Scalars['Int']['output']>;
  facadeInsulationType?: Maybe<FacadeInsulationType>;
  facadeInsulationArea?: Maybe<Scalars['Int']['output']>;
  facadeInsulationEquipmentType?: Maybe<EquipmentType>;
  domesticVentilationEquipmentType?: Maybe<EquipmentType>;
  newSolarPowerSystemSize?: Maybe<Scalars['Int']['output']>;
  solarPowerSystemEquipmentType?: Maybe<EquipmentType>;
  frontDoor?: Maybe<Scalars['Boolean']['output']>;
  frontDoorEquipmentType?: Maybe<EquipmentType>;
  newWindowsNumber?: Maybe<Scalars['Int']['output']>;
  liftAndSlideDoorsNumber?: Maybe<Scalars['Int']['output']>;
  extraLargeWindowsNumber?: Maybe<Scalars['Int']['output']>;
  newWindowsEquipmentType?: Maybe<EquipmentType>;
  heatingSystemEquipmentType?: Maybe<EquipmentType>;
  batteryStorageSize?: Maybe<Scalars['Int']['output']>;
  roofArea?: Maybe<Scalars['Int']['output']>;
  roofEquipmentType?: Maybe<EquipmentType>;
  roofType?: Maybe<RoofType>;
  batteryStorageBlackoutOption?: Maybe<Scalars['Boolean']['output']>;
  ceilingBasementInsulationArea?: Maybe<Scalars['Int']['output']>;
  ceilingTopInsulationArea?: Maybe<Scalars['Int']['output']>;
  hotWaterNewHeatingSystem?: Maybe<Scalars['Boolean']['output']>;
  newHeatingSystem?: Maybe<NewHeatingSystem>;
  state?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  sanitaryRenovationArea?: Maybe<Scalars['Float']['output']>;
  numberOfBathrooms?: Maybe<Scalars['Float']['output']>;
  numberOfToilets?: Maybe<Scalars['Float']['output']>;
  newFlooringArea?: Maybe<Scalars['Float']['output']>;
  numberOfInteriorDoors?: Maybe<Scalars['Float']['output']>;
  wallsAndInteriorDoorsArea?: Maybe<Scalars['Float']['output']>;
  numberOfCarPorts?: Maybe<Scalars['Float']['output']>;
  numberOfGaragePlaces?: Maybe<Scalars['Float']['output']>;
  kitchenEquipmentType?: Maybe<EquipmentType>;
  airConditionEquipmentType?: Maybe<EquipmentType>;
  sanitaryRenovationEquipmentType?: Maybe<EquipmentType>;
  newFlooringEquipmentType?: Maybe<EquipmentType>;
  wallsAndInteriorDoorsEquipmentType?: Maybe<EquipmentType>;
  renewalOfElectricInstallationEquipmentType?: Maybe<EquipmentType>;
  alarmSystemEquipmentType?: Maybe<EquipmentType>;
  carportEquipmentType?: Maybe<EquipmentType>;
  garageEquipmentType?: Maybe<EquipmentType>;
  poolEquipmentType?: Maybe<EquipmentType>;
  winterGardenEquipmentType?: Maybe<EquipmentType>;
  tileStoveEquipmentType?: Maybe<EquipmentType>;
  busSystemEquipmentType?: Maybe<EquipmentType>;
  otherInvestmentsAmount?: Maybe<Scalars['Int']['output']>;
  otherInvestmentsComment?: Maybe<Scalars['String']['output']>;
  terraceArea?: Maybe<Scalars['Int']['output']>;
  coordinates?: Maybe<LatLng>;
};

export enum Renovation {
  newWindows = 'newWindows',
  ceilingTopInsulation = 'ceilingTopInsulation',
  ceilingBasementInsulation = 'ceilingBasementInsulation',
  facadeInsulation = 'facadeInsulation',
  domesticVentilation = 'domesticVentilation',
}

export type RenovationHouseAPIParams = {
  __typename?: 'RenovationHouseAPIParams';
  liegenschaft: Liegenschaft;
  bewertung: Bewertung;
};

export interface RenovationUserPricesInput {
  facadeInsulation?: InputMaybe<Scalars['Float']['input']>;
  newWindows?: InputMaybe<Scalars['Float']['input']>;
  roof?: InputMaybe<Scalars['Float']['input']>;
  ceilingTopInsulation?: InputMaybe<Scalars['Float']['input']>;
  ceilingBasementInsulation?: InputMaybe<Scalars['Float']['input']>;
  heatingSystem?: InputMaybe<Scalars['Float']['input']>;
  solarPowerSystem?: InputMaybe<Scalars['Float']['input']>;
  batteryStorage?: InputMaybe<Scalars['Float']['input']>;
  solarHeating?: InputMaybe<Scalars['Float']['input']>;
  domesticVentilation?: InputMaybe<Scalars['Float']['input']>;
  frontDoor?: InputMaybe<Scalars['Float']['input']>;
  wallboxEMobility?: InputMaybe<Scalars['Float']['input']>;
  busSystem?: InputMaybe<Scalars['Float']['input']>;
  otherInvestments?: InputMaybe<Scalars['Float']['input']>;
  terrace?: InputMaybe<Scalars['Float']['input']>;
  tileStove?: InputMaybe<Scalars['Float']['input']>;
  winterGarden?: InputMaybe<Scalars['Float']['input']>;
  pool?: InputMaybe<Scalars['Float']['input']>;
  garage?: InputMaybe<Scalars['Float']['input']>;
  carport?: InputMaybe<Scalars['Float']['input']>;
  alarmSystem?: InputMaybe<Scalars['Float']['input']>;
  renewalOfElectricInstallation?: InputMaybe<Scalars['Float']['input']>;
  wallsAndInteriorDoors?: InputMaybe<Scalars['Float']['input']>;
  newFlooring?: InputMaybe<Scalars['Float']['input']>;
  sanitaryRenovation?: InputMaybe<Scalars['Float']['input']>;
  airCondition?: InputMaybe<Scalars['Float']['input']>;
  kitchen?: InputMaybe<Scalars['Float']['input']>;
}

export type ReportFile = {
  __typename?: 'ReportFile';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rangeStartDate?: Maybe<Scalars['DateTimeISO']['output']>;
  rangeEndDate?: Maybe<Scalars['DateTimeISO']['output']>;
  status: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  signedUrl?: Maybe<Scalars['String']['output']>;
  iframeName?: Maybe<Scalars['String']['output']>;
};

export interface ReportFileInput {
  iframeId?: InputMaybe<Scalars['String']['input']>;
  rangeStartDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rangeEndDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
}

export type RequestProjectPdfEmailConfig = {
  __typename?: 'RequestProjectPdfEmailConfig';
  emailFrom: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  message: Scalars['String']['output'];
  usePhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  useImplementationStartPoint?: Maybe<Scalars['Boolean']['output']>;
  newPdfRequestNotification?: Maybe<Scalars['Boolean']['output']>;
};

export interface RequestProjectPdfEmailConfigInput {
  emailFrom: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  message: Scalars['String']['input'];
  usePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  useImplementationStartPoint?: InputMaybe<Scalars['Boolean']['input']>;
  newPdfRequestNotification?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface RequestProjectPdfInput {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  projectImplementationStartPoint?: InputMaybe<ProjectImplementationStartPointEnum>;
  formOfAddress?: InputMaybe<FormOfAddress>;
  pvSystem?: InputMaybe<Scalars['Boolean']['input']>;
  heatPump?: InputMaybe<Scalars['Boolean']['input']>;
  thermalInsulation?: InputMaybe<Scalars['Boolean']['input']>;
  windowReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  wallbox?: InputMaybe<Scalars['Boolean']['input']>;
  fundingAndFinancing?: InputMaybe<Scalars['Boolean']['input']>;
  energyConsulting?: InputMaybe<Scalars['Boolean']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
}

export type ResultPageConfiguration = {
  __typename?: 'ResultPageConfiguration';
  headerSection: HeaderSection;
  planSection: PlanSection;
  equivalentsSection: EquivalentsSection;
  totalCostSection: TotalCostSection;
  investmentsPayOfSection: InvestmentsPayOffSection;
  firstPartnerSection: PartnerSection;
  secondPartnerSection: PartnerSection;
};

export interface ResultPageConfigurationInput {
  headerSection: HeaderSectionInput;
  planSection: PlanSectionInput;
  equivalentsSection: EquivalentsSectionInput;
  totalCostSection: TotalCostSectionInput;
  investmentsPayOfSection: InvestmentsPayOffSectionInput;
  firstPartnerSection: PartnerSectionInput;
  secondPartnerSection: PartnerSectionInput;
}

export enum Roles {
  MEINBAU_ADMIN = 'MEINBAU_ADMIN',
  TENANT_ADMIN = 'TENANT_ADMIN',
  GUEST = 'GUEST',
}

export enum RoofType {
  gable = 'gable',
  hipped = 'hipped',
  flat = 'flat',
}

export enum RoomTemperature {
  lessThan21 = 'lessThan21',
  between21And23 = 'between21And23',
  moreThan23 = 'moreThan23',
}

export type SentEmail = {
  __typename?: 'SentEmail';
  _id: Scalars['ID']['output'];
  to: Scalars['String']['output'];
  from: Scalars['String']['output'];
  type: SentEmailType;
  status: Scalars['Float']['output'];
  responseMessage?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  senderEmail: Scalars['String']['output'];
};

export enum SentEmailType {
  INVITATION = 'INVITATION',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REQUEST_PROJECT_PDF = 'REQUEST_PROJECT_PDF',
  NEW_PROJECT_PDF_REQUEST_TO_ADMIN = 'NEW_PROJECT_PDF_REQUEST_TO_ADMIN',
}

export type SessionResponse = {
  __typename?: 'SessionResponse';
  _id: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
  ip: Scalars['String']['output'];
  lastActivityDate: Scalars['DateTimeISO']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
};

export enum SolarHeatingSystemType {
  heatingAndHotWater = 'heatingAndHotWater',
  onlyHotWater = 'onlyHotWater',
}

export enum SolePlateOrBasement {
  solePlate = 'solePlate',
  basement = 'basement',
}

export type SolePlateOrBasementPricesPerUnit = ISolePlateOrBasementPricesPerUnit & {
  __typename?: 'SolePlateOrBasementPricesPerUnit';
  solePlate?: Maybe<Scalars['Float']['output']>;
  basement?: Maybe<Scalars['Float']['output']>;
};

export interface SolePlateOrBasementPricesPerUnitInput {
  solePlate?: InputMaybe<Scalars['Float']['input']>;
  basement?: InputMaybe<Scalars['Float']['input']>;
}

export type SprengnetterApiResult = {
  __typename?: 'SprengnetterApiResult';
  projectId: Scalars['ID']['output'];
  beforeRenovation?: Maybe<SprengnetterErgebnis>;
  afterRenovation?: Maybe<SprengnetterErgebnis>;
  estimation?: Maybe<SprengnetterErgebnis>;
};

export type SprengnetterErgebnis = {
  __typename?: 'SprengnetterErgebnis';
  type?: Maybe<SprengnetterEstimationType>;
  marktwert?: Maybe<Marktwert>;
  beleihungswert?: Maybe<Beleihungswert>;
  error?: Maybe<Scalars['String']['output']>;
  params?: Maybe<SprengnetterErgebnisParams>;
};

export type SprengnetterErgebnisParams = RenovationHouseAPIParams | LandEstimationAPIParams;

export enum SprengnetterEstimationType {
  landEstimation = 'landEstimation',
  renovationHouse = 'renovationHouse',
}

export interface SprengnetterLandEstimationInput {
  coordinates: LatLngInput;
  landArea: Scalars['Int']['input'];
}

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean']['output'];
};

export enum TemplateName {
  hiil = 'hiil',
  hiilIxoo = 'hiilIxoo',
  vsdundvvhErghqvhh = 'vsdundvvhErghqvhh',
  vsdundvvhGxuhq = 'vsdundvvhGxuhq',
  vsdundvvhPrqfkhqjodgedfk = 'vsdundvvhPrqfkhqjodgedfk',
  vdqlhuxqjvuhfkqhuDW = 'vdqlhuxqjvuhfkqhuDW',
  phlqedx = 'phlqedx',
  uhg = 'uhg',
  eoxh = 'eoxh',
  kbsoQrh = 'kbsoQrh',
}

export type Tenant = {
  __typename?: 'Tenant';
  _id: Scalars['ID']['output'];
  companyName: Scalars['String']['output'];
  createdBy: BasicUserAttributes;
  updatedBy?: Maybe<BasicUserAttributes>;
  changedStatusBy?: Maybe<BasicUserAttributes>;
  changedStatusAt?: Maybe<Scalars['DateTimeISO']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
};

export type TenantAnalyticsResponse = {
  __typename?: 'TenantAnalyticsResponse';
  projectsPerIframes: Array<CountEntitiesPerIframe>;
  pdfRequestsPerIframes: Array<CountEntitiesPerIframe>;
};

export type TenantIds = {
  __typename?: 'TenantIds';
  _id: Scalars['ID']['output'];
  companyName: Scalars['String']['output'];
};

export interface TenantInput {
  companyName: Scalars['String']['input'];
}

export enum TenantStatusFilter {
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
  ACTIVE = 'ACTIVE',
}

export type Theme = {
  __typename?: 'Theme';
  pdfButtonColor?: Maybe<Scalars['String']['output']>;
  primaryColor: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
};

export interface ThemeInput {
  pdfButtonColor: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
  textColor: Scalars['String']['input'];
}

export type TotalCostSection = ISection & {
  __typename?: 'TotalCostSection';
  position?: Maybe<Scalars['Int']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export interface TotalCostSectionInput {
  position?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}

export type Translation = {
  __typename?: 'Translation';
  de: Scalars['String']['output'];
  en: Scalars['String']['output'];
};

export interface TranslationInput {
  de: Scalars['String']['input'];
  en: Scalars['String']['input'];
}

export interface UpdateCO2CalculatedValuesInput {
  primaryEnergyForHeating?: InputMaybe<Scalars['Float']['input']>;
  primaryEnergyForHotWater?: InputMaybe<Scalars['Float']['input']>;
  totalPowerConsumptionPerYear?: InputMaybe<Scalars['Float']['input']>;
}

export interface UpdateNewBuildingInput {
  landArea?: InputMaybe<Scalars['Int']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  hasProperty?: InputMaybe<Scalars['Boolean']['input']>;
  houseType?: InputMaybe<NewBuildingHouseType>;
  step?: InputMaybe<Scalars['Int']['input']>;
  avgPropertyPriceSqM?: InputMaybe<Scalars['Float']['input']>;
  bathrooms?: InputMaybe<Scalars['Int']['input']>;
  shower?: InputMaybe<Scalars['Boolean']['input']>;
  bathtub?: InputMaybe<Scalars['Boolean']['input']>;
  estimatedPropertyPrice?: InputMaybe<Scalars['Float']['input']>;
  carPorts?: InputMaybe<Scalars['Int']['input']>;
  demolitionArea?: InputMaybe<Scalars['Int']['input']>;
  floors?: InputMaybe<Scalars['Int']['input']>;
  garagePlaces?: InputMaybe<Scalars['Int']['input']>;
  heatingSystemType?: InputMaybe<NewBuildingHeatingType>;
  livingSpace?: InputMaybe<Scalars['Int']['input']>;
  livingRoomDirection?: InputMaybe<LivingRoomDirection>;
  livingSpaceByFloor?: InputMaybe<Array<Scalars['Int']['input']>>;
  terraceArea?: InputMaybe<Scalars['Int']['input']>;
  solarPowerSizeKwp?: InputMaybe<Scalars['Int']['input']>;
  otherInvestmentsAmount?: InputMaybe<Scalars['Int']['input']>;
  otherInvestmentsComment?: InputMaybe<Scalars['String']['input']>;
  personalContribution?: InputMaybe<PersonalContribution>;
  solePlateOrBasement?: InputMaybe<SolePlateOrBasement>;
  solePlateOrBasementArea?: InputMaybe<Scalars['Int']['input']>;
  solarPowerEquipmentType?: InputMaybe<EquipmentType>;
  interiorEquipmentType?: InputMaybe<EquipmentType>;
  winterGardenEquipmentType?: InputMaybe<EquipmentType>;
  poolEquipmentType?: InputMaybe<EquipmentType>;
  gardenLandscapingEquipmentType?: InputMaybe<EquipmentType>;
  fenceEquipmentType?: InputMaybe<EquipmentType>;
  tileStoveEquipmentType?: InputMaybe<EquipmentType>;
  domesticVentilationEquipmentType?: InputMaybe<EquipmentType>;
  busSystemEquipmentType?: InputMaybe<EquipmentType>;
  carportEquipmentType?: InputMaybe<EquipmentType>;
  garageEquipmentType?: InputMaybe<EquipmentType>;
  city?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<ProjectFeature>>;
  zip?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  equipmentType?: InputMaybe<Scalars['String']['input']>;
  coordinates?: InputMaybe<LatLngInput>;
}

export interface UpdateRenovatingHouseInput {
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  houseArea?: InputMaybe<Scalars['Int']['input']>;
  floorsNumber?: InputMaybe<Scalars['Int']['input']>;
  facadeInsulationType?: InputMaybe<FacadeInsulationType>;
  facadeInsulationArea?: InputMaybe<Scalars['Int']['input']>;
  facadeInsulationEquipmentType?: InputMaybe<EquipmentType>;
  domesticVentilationEquipmentType?: InputMaybe<EquipmentType>;
  newSolarPowerSystemSize?: InputMaybe<Scalars['Int']['input']>;
  solarPowerSystemEquipmentType?: InputMaybe<EquipmentType>;
  frontDoor?: InputMaybe<Scalars['Boolean']['input']>;
  frontDoorEquipmentType?: InputMaybe<EquipmentType>;
  newWindowsNumber?: InputMaybe<Scalars['Int']['input']>;
  liftAndSlideDoorsNumber?: InputMaybe<Scalars['Int']['input']>;
  extraLargeWindowsNumber?: InputMaybe<Scalars['Int']['input']>;
  newWindowsEquipmentType?: InputMaybe<EquipmentType>;
  heatingSystemEquipmentType?: InputMaybe<EquipmentType>;
  batteryStorageSize?: InputMaybe<Scalars['Int']['input']>;
  roofArea?: InputMaybe<Scalars['Int']['input']>;
  roofEquipmentType?: InputMaybe<EquipmentType>;
  roofType?: InputMaybe<RoofType>;
  batteryStorageBlackoutOption?: InputMaybe<Scalars['Boolean']['input']>;
  ceilingBasementInsulationArea?: InputMaybe<Scalars['Int']['input']>;
  ceilingTopInsulationArea?: InputMaybe<Scalars['Int']['input']>;
  hotWaterNewHeatingSystem?: InputMaybe<Scalars['Boolean']['input']>;
  newHeatingSystem?: InputMaybe<NewHeatingSystem>;
  state?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  sanitaryRenovationArea?: InputMaybe<Scalars['Float']['input']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']['input']>;
  numberOfToilets?: InputMaybe<Scalars['Float']['input']>;
  newFlooringArea?: InputMaybe<Scalars['Float']['input']>;
  numberOfInteriorDoors?: InputMaybe<Scalars['Float']['input']>;
  wallsAndInteriorDoorsArea?: InputMaybe<Scalars['Float']['input']>;
  numberOfCarPorts?: InputMaybe<Scalars['Float']['input']>;
  numberOfGaragePlaces?: InputMaybe<Scalars['Float']['input']>;
  kitchenEquipmentType?: InputMaybe<EquipmentType>;
  airConditionEquipmentType?: InputMaybe<EquipmentType>;
  sanitaryRenovationEquipmentType?: InputMaybe<EquipmentType>;
  newFlooringEquipmentType?: InputMaybe<EquipmentType>;
  wallsAndInteriorDoorsEquipmentType?: InputMaybe<EquipmentType>;
  renewalOfElectricInstallationEquipmentType?: InputMaybe<EquipmentType>;
  alarmSystemEquipmentType?: InputMaybe<EquipmentType>;
  carportEquipmentType?: InputMaybe<EquipmentType>;
  garageEquipmentType?: InputMaybe<EquipmentType>;
  poolEquipmentType?: InputMaybe<EquipmentType>;
  winterGardenEquipmentType?: InputMaybe<EquipmentType>;
  tileStoveEquipmentType?: InputMaybe<EquipmentType>;
  busSystemEquipmentType?: InputMaybe<EquipmentType>;
  otherInvestmentsAmount?: InputMaybe<Scalars['Int']['input']>;
  otherInvestmentsComment?: InputMaybe<Scalars['String']['input']>;
  terraceArea?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  step?: InputMaybe<Scalars['Int']['input']>;
  features?: InputMaybe<Array<ProjectFeature>>;
  zip?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  coordinates?: InputMaybe<LatLngInput>;
  renovationUserPrices?: InputMaybe<RenovationUserPricesInput>;
  adminToken?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateUserAccountInput {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  role: Roles;
}

export type UploadFileRequestResponseItem = {
  __typename?: 'UploadFileRequestResponseItem';
  _id: Scalars['String']['output'];
  signedURL: Scalars['String']['output'];
  webClientFileId: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID']['output'];
  profile: UserProfile;
  createdAt: Scalars['DateTimeISO']['output'];
  isTwoFAEnabled?: Maybe<Scalars['Boolean']['output']>;
  platformType: PlatformType;
  locale?: Maybe<Locale>;
  inviter?: Maybe<BasicUserAttributes>;
  role?: Maybe<Roles>;
  isEmailVerified: Scalars['String']['output'];
  email: Scalars['String']['output'];
  activeRoles: Array<UserRoleClass>;
  tenantId?: Maybe<Scalars['ID']['output']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  appName?: Maybe<Scalars['String']['output']>;
};

export type UserRoleClass = {
  __typename?: 'UserRoleClass';
  lastActivityDate?: Maybe<Scalars['DateTimeISO']['output']>;
  role: Roles;
  tenantId?: Maybe<Scalars['ID']['output']>;
  invitedBy?: Maybe<Scalars['ID']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
};

export interface UsersFilter {
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  accountType: AccountType;
}

export type Webhook = {
  __typename?: 'Webhook';
  _id: Scalars['String']['output'];
  trigger: WebhookTrigger;
  events: Array<WebhookEvent>;
  url: Scalars['String']['output'];
  headers: Array<WebhookHeader>;
};

export enum WebhookEvent {
  transferJSON = 'transferJSON',
  transferPDF = 'transferPDF',
}

export type WebhookHeader = {
  __typename?: 'WebhookHeader';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export interface WebhookHeaderInput {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
}

export enum WebhookTrigger {
  pdfRequestSubmission = 'pdfRequestSubmission',
}
