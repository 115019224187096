import React from 'react';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { Stack, Alert } from 'react-bootstrap';
import useIframes from 'hooks/iframe/useIframes';
import { grabErrorMessage } from 'utils/helpers';
import IframesFilterForm from 'components/iframe/IframesFilter';
import IframeButton, { IframeButtonType } from 'components/iframe/IframeButton';
import IframesTable from 'components/iframe/IframesTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Iframe, Roles } from 'graphql/types.generated';
import useUser from 'hooks/user/useUser';

interface ITenantIframesParams {
  tenantId: string;
}

const TenantIframes: FC<ITenantIframesParams> = ({ tenantId }) => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'iframesPage' });
  const { iframes, loading, total, error } = useIframes({ currentPage, tenantId });

  const { me } = useUser({ fetchPolicy: 'cache-only' });

  return (
    <>
      <Stack direction="horizontal" gap={3}>
        {me?.role === Roles.MEINBAU_ADMIN ? (
          <div className="ms-auto">
            <IframeButton tenantId={tenantId} type={IframeButtonType.create}>
              {t('iframes.createIframeBtn')} <FontAwesomeIcon icon={faPlus} />
            </IframeButton>
          </div>
        ) : null}
      </Stack>
      <IframesFilterForm {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <IframesTable {...{ iframes: iframes as Iframe[], currentPage, changePage, total, loading }} /> : null}
    </>
  );
};

export default TenantIframes;
