import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IframesQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int']['input'];
  searchString: SchemaTypes.Scalars['String']['input'];
  skip: SchemaTypes.Scalars['Int']['input'];
  tenantId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']['input']>;
}>;

export type IframesQuery = {
  __typename?: 'Query';
  iframes: {
    __typename?: 'PaginatedIframeResponse';
    total: number;
    items: Array<{
      __typename?: 'Iframe';
      _id: string;
      name: string;
      tenantId: string;
      projectType: SchemaTypes.ProjectType;
      archived?: boolean | null;
    }>;
  };
};

export const IframesDocument = gql`
  query Iframes($limit: Int!, $searchString: String!, $skip: Int!, $tenantId: ID) {
    iframes(limit: $limit, searchString: $searchString, skip: $skip, tenantId: $tenantId) {
      total
      items {
        _id
        name
        tenantId
        projectType
        archived
      }
    }
  }
`;

/**
 * __useIframesQuery__
 *
 * To run a query within a React component, call `useIframesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIframesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIframesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchString: // value for 'searchString'
 *      skip: // value for 'skip'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useIframesQuery(baseOptions: Apollo.QueryHookOptions<IframesQuery, IframesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IframesQuery, IframesQueryVariables>(IframesDocument, options);
}
export function useIframesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IframesQuery, IframesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IframesQuery, IframesQueryVariables>(IframesDocument, options);
}
export type IframesQueryHookResult = ReturnType<typeof useIframesQuery>;
export type IframesLazyQueryHookResult = ReturnType<typeof useIframesLazyQuery>;
export type IframesQueryResult = Apollo.QueryResult<IframesQuery, IframesQueryVariables>;
export function refetchIframesQuery(variables: IframesQueryVariables) {
  return { query: IframesDocument, variables: variables };
}
