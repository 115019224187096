import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import { TenantsQuery, TenantsQueryVariables, useTenantsQuery } from 'graphql/queries/tenant/generated/Tenants';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { TenantStatusFilter } from 'graphql/types.generated';

interface IGetTenantsProps extends QueryHookOptions<TenantsQuery, TenantsQueryVariables> {
  currentPage: number;
}

export const useGetTenantsFilter = () => {
  const {
    searchParams: { tenantsSearchQuery = '', status = TenantStatusFilter.ALL },
  } = useGetSearchParams(['tenantsSearchQuery', 'status']);

  return { searchQuery: tenantsSearchQuery, status };
};

export default function useTenants({ currentPage, ...baseOptions }: IGetTenantsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const filter = useGetTenantsFilter();

  const { data, loading, error } = useTenantsQuery({
    variables: {
      skip,
      limit: constants.tableRowsCountPerPage,
      searchString: filter.searchQuery,
      status: filter.status as TenantStatusFilter,
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const tenants = data?.tenants?.items || [];
  const total = data?.tenants?.total || 0;

  return {
    tenants,
    total,
    loading,
    error,
  };
}
