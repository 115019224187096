import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MeinBauApiLogsQuery } from '../../graphql/queries/meinBauApiLogs/generated/MeinBauApiLogs';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { Badge } from 'react-bootstrap';
import { formatDate } from 'utils/helpers';
import i18n from 'i18n';
import { routePaths } from 'router/routes';
import { generatePath, useNavigate } from 'react-router-dom';

export type MeinBauApiLogsListItem = First<MeinBauApiLogsQuery['meinBauApiLogs']['items']>;
interface iMeinBauApiLogsTableProps extends IPaginationProps {
  meinBauApiLogs: MeinBauApiLogsListItem[];
  loading: boolean;
}

export type MeinBauApiLogsTableTableRow = MeinBauApiLogsListItem;

const MeinBauApiLogsTable: FC<iMeinBauApiLogsTableProps> = ({
  meinBauApiLogs,
  currentPage,
  changePage,
  total,
  loading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data: MeinBauApiLogsTableTableRow[] = useMemo(() => {
    return meinBauApiLogs.map((meinBauApiLog: MeinBauApiLogsListItem) => {
      const { _id, status, useCase, method, createdAt, ip, appName } = meinBauApiLog;
      return {
        _id,
        status,
        useCase,
        method,
        createdAt,
        ip,
        appName,
      };
    });
  }, [meinBauApiLogs]);

  const columns: ColumnDef<MeinBauApiLogsTableTableRow>[] = useMemo(
    () => [
      {
        header: t('meinBauApiLogs.tableHeaders.appName'),
        accessorKey: 'appName',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('meinBauApiLogs.tableHeaders.useCase'),
        accessorKey: 'useCase',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('meinBauApiLogs.tableHeaders.ip'),
        accessorKey: 'ip',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('meinBauApiLogs.tableHeaders.method'),
        accessorKey: 'method',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('meinBauApiLogs.tableHeaders.createdAt'),
        accessorKey: 'createdAt',
        cell: (info) => formatDate({ date: new Date(info.getValue() as string), locale: i18n.language }),
      },
      {
        header: t('meinBauApiLogs.tableHeaders.status'),
        accessorKey: 'status',
        cell: (info) => <Badge>{info.getValue() as string}</Badge>,
      },
    ],
    [t],
  );

  const onRowClick = useCallback(
    (row: MeinBauApiLogsTableTableRow) => {
      navigate(generatePath(routePaths.meinBauApiLog, { meinBauApiLogId: row._id }));
    },
    [navigate],
  );

  return (
    <>
      <Table
        getRowProps={(row) => ({
          className: 'clickableRow',
          onClick: () => onRowClick(row.original),
        })}
        columns={columns}
        data={data}
        loading={loading}
        emptyText="apiLogs.noLogsFound.description"
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default MeinBauApiLogsTable;
