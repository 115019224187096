import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BasicUserAttributesFragmentDoc } from '../../../fragments/user/generated/BasicUserAttribuesFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveApplicationMutationVariables = SchemaTypes.Exact<{
  applicationId: SchemaTypes.Scalars['ID']['input'];
}>;

export type ArchiveApplicationMutation = {
  __typename?: 'Mutation';
  archiveApplication: {
    __typename?: 'Application';
    _id: string;
    createdAt: any;
    archived?: boolean | null;
    profile: { __typename?: 'ApplicationProfile'; appName: string };
    createdBy: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    };
    archivedBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  };
};

export const ArchiveApplicationDocument = gql`
  mutation ArchiveApplication($applicationId: ID!) {
    archiveApplication(applicationId: $applicationId) {
      _id
      profile {
        appName
      }
      createdBy {
        ...BasicUserAttributes
      }
      createdAt
      archivedBy {
        ...BasicUserAttributes
      }
      archived
    }
  }
  ${BasicUserAttributesFragmentDoc}
`;
export type ArchiveApplicationMutationFn = Apollo.MutationFunction<
  ArchiveApplicationMutation,
  ArchiveApplicationMutationVariables
>;

/**
 * __useArchiveApplicationMutation__
 *
 * To run a mutation, you first call `useArchiveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveApplicationMutation, { data, loading, error }] = useArchiveApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useArchiveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveApplicationMutation, ArchiveApplicationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveApplicationMutation, ArchiveApplicationMutationVariables>(
    ArchiveApplicationDocument,
    options,
  );
}
export type ArchiveApplicationMutationHookResult = ReturnType<typeof useArchiveApplicationMutation>;
export type ArchiveApplicationMutationResult = Apollo.MutationResult<ArchiveApplicationMutation>;
export type ArchiveApplicationMutationOptions = Apollo.BaseMutationOptions<
  ArchiveApplicationMutation,
  ArchiveApplicationMutationVariables
>;
