import React, { useState } from 'react';
import RDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import de from 'date-fns/locale/de';
import cn from 'classnames';
// import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import s from './DatePicker.module.sass';
import { subTimezoneOffset, dateFormat as _dateFormat, dateFormatInput, timeFormatOnly } from '../../../utils/helpers';
import { Locale } from '../../../graphql/types.generated';

registerLocale(Locale.de, de);

export interface iSimpleDatePicker
  extends Pick<
    ReactDatePickerProps,
    | 'minDate'
    | 'maxDate'
    | 'showYearPicker'
    | 'yearItemNumber'
    | 'isClearable'
    | 'startDate'
    | 'endDate'
    | 'showTimeSelect'
    | 'disabled'
    | 'selected'
    | 'name'
    | 'onKeyDown'
    | 'className'
    | 'dateFormat'
  > {
  onChange?: (date?: Date | Date[]) => void;
  selectsRange?: boolean;
}

const SimpleDatePicker: FC<iSimpleDatePicker> = ({
  dateFormat = [_dateFormat, dateFormatInput],
  showYearPicker,
  yearItemNumber,
  isClearable,
  maxDate,
  minDate,
  startDate: _startDate,
  endDate: _endDate,
  selectsRange,
  showTimeSelect,
  disabled,
  onChange,
  selected,
  name,
  onKeyDown,
  className,
}) => {
  const { i18n } = useTranslation();
  const [dateRange, setDateRange] = useState([
    _startDate ? new Date(_startDate) : null,
    _endDate ? new Date(_endDate) : null,
  ]);
  const [startDate, endDate] = dateRange;

  const handleChange = React.useCallback(
    (date: any) => {
      if (selectsRange) {
        let d = date;
        if (Array.isArray(date) && date[0] && date[1]) {
          d = [subTimezoneOffset(date[0]), subTimezoneOffset(date[1])];
        }
        setDateRange(d);
        onChange && onChange(d);
      } else {
        const dateWithOffset = date ? subTimezoneOffset(date) : undefined;
        const d = showTimeSelect ? date : dateWithOffset;
        onChange && onChange(d);
      }
    },
    [selectsRange, onChange, showTimeSelect],
  );

  return (
    <div className={cn(s.inputWrapper, className)}>
      {/* <CalendarIcon className={s.calendarIcon} /> */}
      <RDatePicker
        {...{
          onKeyDown,
          name,
          dateFormat,
          showYearPicker,
          yearItemNumber,
          isClearable,
          startDate,
          endDate,
          selectsRange,
          showTimeSelect,
          disabled,
          maxDate,
          minDate,
        }}
        timeFormat={timeFormatOnly}
        locale={i18n.language}
        autoComplete="off"
        showYearDropdown
        showMonthDropdown
        {...(selectsRange ? {} : { selected: selected ? new Date(selected) : null })}
        onChange={handleChange}
        className="form-control"
        wrapperClassName="w-100"
        calendarClassName={cn(s.calendar, { [s.disabled]: disabled })}
      />
    </div>
  );
};

export default SimpleDatePicker;
