import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectOption } from './Select';
import ReactSelect, { OnChangeValue, StylesConfig } from 'react-select';
import { Form } from 'react-bootstrap';
import { MenuPortalTarget, useMenuPortalTarget } from './useMenuPortalTarget';
import { selectStyles } from './Select.styles';

export interface PlainSelectParams {
  name: string;
  initialValue?: ISelectOption['value'];
  label?: string;
  options: ISelectOption[];
  isClearable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  menuPortalTarget?: MenuPortalTarget;
  onSelectOption?: (option: ISelectOption) => Promise<void>;
}

const PlainSelect: FC<PlainSelectParams> = ({
  name,
  initialValue = null,
  menuPortalTarget: _menuPortalTarget = MenuPortalTarget.body,
  label,
  options,
  isClearable,
  isDisabled,
  isSearchable,
  onSelectOption,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<ISelectOption['value']>(initialValue);
  const localizedOptions = useMemo(
    () => options.map((option) => ({ ...option, label: t(option.label) })),
    [options, t],
  );

  const handleInputChange = useCallback((v: string) => {
    setInputValue(v);
  }, []);

  const optionValue = useMemo(() => {
    const option = localizedOptions?.find((o) => o.value === value);
    return option || null;
  }, [localizedOptions, value]);

  const handleChange = useCallback(
    (option: OnChangeValue<ISelectOption, boolean>) => {
      const selectedOption = option as ISelectOption;
      const selectedValue = selectedOption?.value;
      setValue(selectedValue);
      if (typeof onSelectOption === 'function') {
        onSelectOption(selectedOption);
      }
    },
    [onSelectOption],
  );

  const menuPortalTarget = useMenuPortalTarget(_menuPortalTarget);

  return (
    <Form.Group className="w-100">
      {label ? (
        <Form.Label htmlFor={name} className="text-primary">
          {t(label)}
        </Form.Label>
      ) : null}
      <div onClick={(e) => e.stopPropagation()}>
        <ReactSelect
          inputValue={inputValue}
          isClearable={isClearable}
          onInputChange={handleInputChange}
          inputId={name}
          value={optionValue}
          onChange={handleChange}
          name={name}
          options={localizedOptions}
          styles={selectStyles as StylesConfig<ISelectOption>}
          placeholder=""
          isDisabled={isDisabled}
          components={{ IndicatorSeparator: () => null }}
          isSearchable={isSearchable}
          blurInputOnSelect
          menuPortalTarget={menuPortalTarget}
          noOptionsMessage={() => <div>{t('forms.noOptions')}</div>}
        />
      </div>
    </Form.Group>
  );
};

export default PlainSelect;
