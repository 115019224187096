import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { generatePath, useNavigate } from 'react-router-dom';
import { routePaths } from 'router/routes';

export enum IframeButtonType {
  create = 'create',
  update = 'update',
}

interface IIframeButtonParams {
  tenantId?: string;
  iframeId?: string;
  variant?: ButtonVariant;
  children?: React.ReactNode;
  type: IframeButtonType;
}

const IframeButton: FC<IIframeButtonParams> = ({ tenantId, iframeId, variant, children, type }) => {
  const navigate = useNavigate();

  const onBtnClick = useCallback(() => {
    if (type === IframeButtonType.update) {
      navigate(generatePath(routePaths.updateIframe, { iframeId, tenantId }));
    } else {
      navigate(generatePath(routePaths.createIframe, { tenantId }));
    }
  }, [iframeId, navigate, tenantId, type]);

  return (
    <>
      <Button variant={variant} onClick={() => onBtnClick()}>
        {children}
      </Button>
    </>
  );
};

export default IframeButton;
