import React, { CSSProperties, useMemo } from 'react';
import { Alert, AlertProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { grabErrorMessage } from 'utils/helpers';

interface ErrorAlertParams extends AlertProps {
  children?: React.ReactNode;
  style?: CSSProperties;
  apolloError?: ApolloError;
  message?: string;
  className?: string;
}

const ErrorAlert: FC<ErrorAlertParams> = ({ children, apolloError, message, className, ...rest }) => {
  const { t } = useTranslation();

  const content = useMemo(() => {
    if (apolloError) return grabErrorMessage(apolloError);
    if (message) return t(message);
    if (children) return children;
    return null;
  }, [children, apolloError, message, t]);

  return (
    <Alert variant="danger" className={className} {...rest}>
      {content}
    </Alert>
  );
};

export default ErrorAlert;
