import React from 'react';
import { FastField, Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ScrollToError from 'components/common/ScrollToError';
import { AccountType, Roles } from 'graphql/types.generated';
import useFilteredRoleOptions from 'hooks/user/useFilteredRoleOptions';
import Select from 'components/inputs/Select/Select';
import useAddUsersToTenant, { AddUsersToTenantFormValues } from 'hooks/user/useAddUsersToTenant';
import TenantUsersSelect from 'components/inputs/Select/TenantUsersSelect';
import { MenuPortalTarget } from 'components/inputs/Select/useMenuPortalTarget';
import SubmitButton from 'components/common/Button/SubmitButton';

const validationSchema = yup.object().shape({
  userIds: yup.number().nullable().required('errors.chooseOne').typeError('errors.chooseOne'),
  role: yup.string().required('errors.requiredField'),
});

interface AddUserToTenantFormProps {
  hideModal: () => void;
  accountType: AccountType;
}

const AddUserToTenantForm: FC<AddUserToTenantFormProps> = ({ hideModal, accountType }) => {
  const { t } = useTranslation();
  const { tenantId } = useParams() as { tenantId: string };
  const filteredRoleOptions = useFilteredRoleOptions(accountType);

  const initialValues: AddUsersToTenantFormValues = {
    userIds: [],
    role: '' as Roles,
  };

  const onSubmit = useAddUsersToTenant({ hideModal, tenantId });

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {() => (
        <Form>
          <ScrollToError inModal />
          <TenantUsersSelect
            name="userIds"
            label={t('userManagement.users.addTenantUserModal.clientUsersField')}
            menuPortalTarget={MenuPortalTarget.modal}
          />
          <FastField
            name="role"
            component={Select}
            label="auth.role"
            options={filteredRoleOptions}
            menuPortalTarget={MenuPortalTarget.modal}
          />

          <div className="text-center mt-4">
            <SubmitButton />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddUserToTenantForm;
