import React from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { CountEntitiesPerIframe, CountEntitiesPerTenant } from 'graphql/types.generated';
import { Card } from 'react-bootstrap';

interface ICountPerEntityChartProp {
  data: CountEntitiesPerTenant[] | CountEntitiesPerIframe[];
  noDataText: {
    title: string;
    description: string;
  };
  name?: string;
  onBarClick?: (barObject?: CountEntitiesPerTenant) => void;
}

const CountPerEntityChart: FC<ICountPerEntityChartProp> = ({ data, name, noDataText, onBarClick }) => {
  const isGeneralGraph = !!(data as CountEntitiesPerTenant[])[0]?.companyName;
  return (
    <>
      <div style={{ position: 'relative' }}>
        {data.length === 0 && (
          <div
            style={{
              width: '100%',
              position: 'absolute',
              top: '40%',
              zIndex: 3000,
            }}
          >
            <Card style={{ width: '100%', textAlign: 'center' }}>
              <Card.Body>
                <Card.Title>{noDataText.title}</Card.Title>
                <Card.Text>{noDataText.description}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        )}

        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey={isGeneralGraph ? 'companyName' : 'name'} />
            <YAxis dataKey="count" allowDecimals={false} />
            <Tooltip {...(data.length === 0 ? { cursor: { fill: '#ffffff00' } } : {})} />
            <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
            <ReferenceLine y={0} stroke="#000" />
            {data.length > 1 && <Brush dataKey="count" height={30} stroke="#8884d8" />}
            <Bar dataKey="count" name={name} fill="#8884d8" onClick={onBarClick}>
              {data.map((entry) => (
                <Cell
                  cursor="pointer"
                  key={`cell-${
                    (entry as CountEntitiesPerTenant)?.tenantId || (entry as CountEntitiesPerIframe)?.iframeId
                  }`}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default CountPerEntityChart;
