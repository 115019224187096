import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type BasicUserAttributesFragment = {
  __typename?: 'BasicUserAttributes';
  _id: string;
  email: string;
  profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
};

export type BasicUserAttributesFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BasicUserAttributesFragmentDoc = gql`
  fragment BasicUserAttributes on BasicUserAttributes {
    _id
    email
    profile {
      firstName
      lastName
    }
  }
`;
