import React from 'react';
import { FastField, Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import s from './LoginForm.module.sass';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ScrollToError from 'components/common/ScrollToError';
import TextInput from 'components/inputs/TextInput/TextInput';
import PasswordInput from 'components/inputs/PasswordInput';
import Button from 'components/common/Button/Button';
import { routePaths } from 'router/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faKey, faUser } from '@fortawesome/free-solid-svg-icons';

const validationSchema = yup.object().shape({
  email: yup.string().required('errors.requiredField').email('errors.invalidEmail'),
  password: yup.string().required('errors.requiredField').min(8, 'errors.passwordIsTooShort'),
});

export interface ILoginFormValues {
  email: string;
  password: string;
  code?: string;
}

interface ILoginFormProps {
  initialValues: ILoginFormValues;
  onSubmit: (values: ILoginFormValues, helpers: FormikHelpers<ILoginFormValues>) => void;
}

const LoginForm: FC<ILoginFormProps> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {({ isSubmitting }) => (
        <Form>
          <ScrollToError />
          <FastField
            name="email"
            type="email"
            component={TextInput}
            label="auth.email"
            startIcon={<FontAwesomeIcon icon={faUser} />}
          />
          <FastField
            name="password"
            type="password"
            component={PasswordInput}
            label="auth.password"
            startIcon={<FontAwesomeIcon icon={faKey} />}
          />
          <p className="my-4 text-end" style={{ fontSize: '14px' }}>
            <Link to={routePaths.forgotPassword}>{t('auth.forgotPassword.questionOnLoginForm')}</Link>
          </p>

          <div className={s.submitBtnWrapper}>
            <Button disabled={isSubmitting} loading={isSubmitting} type="submit">
              {t('buttons.submit')} <FontAwesomeIcon icon={faAngleRight} />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
