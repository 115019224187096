import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Co2SavingsReportQueryVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
}>;

export type Co2SavingsReportQuery = {
  __typename?: 'Query';
  co2SavingsReport: {
    __typename?: 'CO2SavingsReport';
    newWindows: { __typename?: 'CO2Category'; co2Savings: number; projectsCount: number };
    ceilingTopInsulation: { __typename?: 'CO2Category'; co2Savings: number; projectsCount: number };
    ceilingBasementInsulation: { __typename?: 'CO2Category'; co2Savings: number; projectsCount: number };
    facadeInsulation: { __typename?: 'CO2Category'; co2Savings: number; projectsCount: number };
    domesticVentilation: { __typename?: 'CO2Category'; co2Savings: number; projectsCount: number };
    solarPowerSystem: { __typename?: 'CO2Category'; co2Savings: number; projectsCount: number };
    heatingSystem: { __typename?: 'CO2Category'; co2Savings: number; projectsCount: number };
  };
};

export const Co2SavingsReportDocument = gql`
  query Co2SavingsReport($iframeId: ID!) {
    co2SavingsReport(iframeId: $iframeId) {
      newWindows {
        co2Savings
        projectsCount
      }
      ceilingTopInsulation {
        co2Savings
        projectsCount
      }
      ceilingBasementInsulation {
        co2Savings
        projectsCount
      }
      facadeInsulation {
        co2Savings
        projectsCount
      }
      domesticVentilation {
        co2Savings
        projectsCount
      }
      solarPowerSystem {
        co2Savings
        projectsCount
      }
      heatingSystem {
        co2Savings
        projectsCount
      }
    }
  }
`;

/**
 * __useCo2SavingsReportQuery__
 *
 * To run a query within a React component, call `useCo2SavingsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCo2SavingsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCo2SavingsReportQuery({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useCo2SavingsReportQuery(
  baseOptions: Apollo.QueryHookOptions<Co2SavingsReportQuery, Co2SavingsReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Co2SavingsReportQuery, Co2SavingsReportQueryVariables>(Co2SavingsReportDocument, options);
}
export function useCo2SavingsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Co2SavingsReportQuery, Co2SavingsReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Co2SavingsReportQuery, Co2SavingsReportQueryVariables>(Co2SavingsReportDocument, options);
}
export type Co2SavingsReportQueryHookResult = ReturnType<typeof useCo2SavingsReportQuery>;
export type Co2SavingsReportLazyQueryHookResult = ReturnType<typeof useCo2SavingsReportLazyQuery>;
export type Co2SavingsReportQueryResult = Apollo.QueryResult<Co2SavingsReportQuery, Co2SavingsReportQueryVariables>;
export function refetchCo2SavingsReportQuery(variables: Co2SavingsReportQueryVariables) {
  return { query: Co2SavingsReportDocument, variables: variables };
}
