import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import { ApiLogsQuery, ApiLogsQueryVariables, useApiLogsQuery } from 'graphql/queries/apiCallLog/generated/ApiLogs';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { ApiNameFilter } from 'graphql/types.generated';

interface IApiCallLogsProps extends QueryHookOptions<ApiLogsQuery, ApiLogsQueryVariables> {
  currentPage: number;
}

export const useGetApiCallLogsFilter = () => {
  const {
    searchParams: { apiName },
  } = useGetSearchParams(['apiName']);

  return { apiName };
};

export default function useApiCallLogs({ currentPage, ...baseOptions }: IApiCallLogsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const { apiName } = useGetApiCallLogsFilter();

  const { data, loading, error } = useApiLogsQuery({
    variables: {
      ...(typeof apiName === 'string' ? { apiName: apiName as ApiNameFilter } : { apiName: ApiNameFilter.all }),
      skip,
      limit: constants.tableRowsCountPerPage,
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const apiLogs = data?.apiLogs?.items || [];
  const total = data?.apiLogs?.total || 0;

  return {
    apiLogs,
    total,
    loading,
    error,
  };
}
