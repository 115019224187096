import LoadingOverlay from 'components/common/Loading/LoadingOverlay';
import useLogout from 'hooks/auth/useLogout';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { routePaths } from 'router/routes';

export default function Logout() {
  const logout = useLogout();
  useEffect(() => {
    logout().catch();
  }, [logout]);

  return (
    <>
      <LoadingOverlay />
      <Navigate to={routePaths.login} />
    </>
  );
}
