/* eslint-disable no-inner-declarations */
import { ApolloError, MutationHookOptions } from '@apollo/client';
import { useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { throttle } from 'lodash';
import { EnhancedFile, enhancedFilesToUploadReady } from '../DroppedFiles.helpers';
import {
  UploadFileRequestsMutation,
  UploadFileRequestsMutationVariables,
  useUploadFileRequestsMutation,
} from 'graphql/mutations/file/generated/UploadFileRequests';
import { PersistFileMutationOptions } from 'graphql/mutations/file/generated/PersistFile';
import usePersistFile, { OnCompletedPersistFile } from './usePersistFile';

interface IUploadToS3Props {
  enhancedFile: EnhancedFile;
  signedURL: string;
  onUploadProgress: (webClientFileId: string, progressEvent: any) => void;
}

interface IUploadFilesFuncProps extends Omit<UploadFileRequestsMutationVariables, 'files'> {
  enhancedFiles: EnhancedFile[];
}

const uploadToS3 = async ({ enhancedFile, signedURL, onUploadProgress }: IUploadToS3Props) => {
  const { file, webClientFileId, controller } = enhancedFile;

  const options = {
    headers: { 'Content-Type': file.type },
    onUploadProgress: throttle((progressEvent: any) => {
      onUploadProgress(webClientFileId, progressEvent);
    }, 1000),
    signal: controller.signal,
  };

  return axios.put(signedURL, file, options);
};

interface IUploadFileRequestProps
  extends MutationHookOptions<UploadFileRequestsMutation, UploadFileRequestsMutationVariables> {
  onCompletedPersistFile?: OnCompletedPersistFile;
  onUploadProgress: IUploadToS3Props['onUploadProgress'];
  persistFileRefetchQueries?: PersistFileMutationOptions['refetchQueries'];
}

const useUploadFileRequest = ({
  onUploadProgress,
  persistFileRefetchQueries,
  onCompletedPersistFile,
  ...baseOptions
}: IUploadFileRequestProps) => {
  const persistFile = usePersistFile({ onCompleted: onCompletedPersistFile });
  const [uploadFileRequestMutation] = useUploadFileRequestsMutation(baseOptions);

  const handleUploadFiles = useCallback(
    async ({ enhancedFiles, ...rest }: IUploadFilesFuncProps) => {
      try {
        const completedFileIds: string[] = [];
        const rejectedFileIds: string[] = [];
        const files = enhancedFilesToUploadReady(enhancedFiles);

        const variables: UploadFileRequestsMutationVariables = { files, ...rest };

        const { data } = await uploadFileRequestMutation({ variables });
        const { uploadFileRequests } = data as UploadFileRequestsMutation;

        const uploadFileIds: string[] = [];
        const promises = uploadFileRequests.map(async ({ _id: requestId, signedURL, webClientFileId }) => {
          const enhancedFile = enhancedFiles.find(({ webClientFileId: id }) => id === webClientFileId);
          if (!enhancedFile) return;

          try {
            const res: AxiosResponse = await uploadToS3({ enhancedFile, signedURL, onUploadProgress });

            if (res.status === 200) {
              const { data = {} } = await persistFile({
                variables: { requestId },
                refetchQueries: persistFileRefetchQueries,
              });
              completedFileIds.push(enhancedFile.webClientFileId);
              if (data && data.persistFile) uploadFileIds.push(data.persistFile._id);
            }
          } catch (error) {
            rejectedFileIds.push(enhancedFile.webClientFileId);
          }
        });

        await Promise.allSettled(promises);
        return { completedFileIds, rejectedFileIds, uploadFileIds };
      } catch (error) {
        const e = error as ApolloError;
        throw e;
      }
    },
    [onUploadProgress, persistFile, persistFileRefetchQueries, uploadFileRequestMutation],
  );
  return handleUploadFiles;
};

export default useUploadFileRequest;
