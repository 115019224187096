import { differenceWith, isEqual } from 'lodash';
import bytes from 'bytes';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { allowedFiles, allowedMimeTypes } from './DroppedFiles.helpers';

export const trimLeadingDot = (extension = '') => {
  return extension.startsWith('.') ? extension.substring(1) : extension;
};

export const mimeType2Extension = (type: string) => {
  const file = allowedFiles.find(({ mimeType }) => mimeType === type);
  return file?.extension || '';
};

function getFileExtension(filename: string): string {
  const parts = filename.split('.');
  if (parts.length > 1) {
    return parts[parts.length - 1];
  }
  return '';
}

export const filterTypeAcceptableFiles = ({
  files = [],
  extensions,
  t,
}: {
  files: File[];
  extensions: string[];
  t: (key: string) => string;
}) => {
  const validFiles = files.filter(({ type, name }: File) => {
    if (type) return allowedMimeTypes.includes(type);
    return extensions.includes(getFileExtension(name));
  });
  if (files.length !== validFiles.length) toastTypeUnacceptedFiles(files, validFiles, t);
  return validFiles;
};

export function toastTypeUnacceptedFiles(
  files: File[],
  acceptableFiles: File[],
  t: (t: string, values: Record<string, string>) => string,
) {
  const typeUnacceptableFiles = differenceWith(files, acceptableFiles, isEqual);
  typeUnacceptableFiles.forEach(({ name }) => {
    toast.error(t('dnd.extensionIsNotAllowed', { fileName: name }), {
      style: { whiteSpace: 'pre-line' },
    });
  });
}

export const getFileSizeInMB = (size: number) => bytes(size, { unit: 'MB', unitSeparator: ' ' }).split(' ')[0];

export function validateFilesSize(
  files: File[] = [],
  maxFileSize: number,
  t: (t: string, values: Record<string, string | number>) => string,
) {
  const validFilesBySize = files.filter(({ size }) => getFileSizeInMB(size) <= maxFileSize);
  const invalidFilesBySize = files.filter(({ size }) => getFileSizeInMB(size) > maxFileSize);
  const invalidFilenames = invalidFilesBySize.map(({ name }) => name).join(', ');

  if (invalidFilesBySize.length) {
    toast.error(t('dnd.maxFileSizeError', { size: maxFileSize, fileNames: invalidFilenames }), {
      style: { whiteSpace: 'pre-line' },
    });
  }
  return validFilesBySize;
}

export const validateFileType = (fileBuffer: Uint8Array, mimeType: string) => {
  const isAcceptableMimeType = allowedMimeTypes.find((type) => type === mimeType);
  return isAcceptableMimeType;
};

const fileToBuffer = (file: File) => {
  return new Promise<Buffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const result = reader.result;
      if (result instanceof ArrayBuffer) {
        const buffer = Buffer.from(result);
        resolve(buffer);
      } else {
        reject(new Error('Error reading the file'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Error reading the file'));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const validateMimeTypes = async (files: File[]) => {
  const res = await Promise.all(
    files.map(async (file) => {
      const buffer = await fileToBuffer(file);
      const mimeType = file.type;
      const isValid = validateFileType(buffer, mimeType);
      return isValid;
    }),
  );
  return res.every(Boolean);
};
