import constants from 'constants/constants';
import {
  DeleteUserFromTenantMutation,
  useDeleteUserFromTenantMutation,
} from 'graphql/mutations/tenant/generated/DeleteUserFromTenant';
import { AllUsersDocument, AllUsersQuery, AllUsersQueryVariables } from 'graphql/queries/user/generated/AllUsers';
import { AccountType } from 'graphql/types.generated';
import { useGetUsersFilter } from 'hooks/user/useGetUsers';
import { useParams } from 'react-router-dom';
import { toastErrorMessage } from 'utils/helpers';

const { tableRowsCountPerPage } = constants;

interface DeleteUserFromTenantParams {
  userId: string;
}

const useDeleteUserFromTenant = ({ userId }: DeleteUserFromTenantParams) => {
  const { tenantId } = useParams();
  const usersFilter = useGetUsersFilter();

  const [deleteUserFromTenant, { loading, client }] = useDeleteUserFromTenantMutation({
    onError: toastErrorMessage,
    refetchQueries: ['TenantUsers'],
    onCompleted: (res: DeleteUserFromTenantMutation) => {
      if (!res || !res?.deleteUserFromTenant) return;
      const variables: AllUsersQueryVariables = {
        tenantId,
        limit: tableRowsCountPerPage,
        skip: 0,
        filter: { ...usersFilter, accountType: AccountType.tenant },
      };
      client.cache.updateQuery({ query: AllUsersDocument, variables }, (data: AllUsersQuery | null) => {
        if (!data) return data;
        const { allUsers } = data;
        const { items } = allUsers;
        const newItems = items.filter(({ _id }) => _id !== userId);
        return { ...data, allUsers: { ...allUsers, items: newItems } };
      });
    },
  });

  return { onDeleteUserFromTenant: deleteUserFromTenant, loading };
};

export default useDeleteUserFromTenant;
