import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import useGetSearchParams from 'hooks/useGetSearchParams';
import {
  AllProjectsQuery,
  AllProjectsQueryVariables,
  useAllProjectsQuery,
} from 'graphql/queries/project/generated/AllProjects';
import { ProjectTypeFilter } from 'graphql/types.generated';

interface IGetProjectsProps extends QueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables> {
  iframeId?: string;
  currentPage: number;
}

export const useGetProjectsFilter = () => {
  const {
    searchParams: { projectsSearchQuery = '', projectType = ProjectTypeFilter.all },
  } = useGetSearchParams(['projectsSearchQuery', 'projectType']);

  return { searchQuery: projectsSearchQuery, projectType };
};

export default function useProjects({ iframeId, currentPage, ...baseOptions }: IGetProjectsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const filter = useGetProjectsFilter();

  const { data, loading, error } = useAllProjectsQuery({
    variables: {
      skip,
      limit: constants.tableRowsCountPerPage,
      searchQuery: filter.searchQuery,
      ...(iframeId ? { iframeId } : {}),
      ...(filter.projectType ? { projectType: filter.projectType as ProjectTypeFilter } : {}),
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const projects = data?.allProjects?.items || [];
  const total = data?.allProjects?.total || 0;

  return {
    projects,
    total,
    loading,
    error,
  };
}
