import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FastField } from 'formik';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from '../../utils/helpers';
import SubmitButton from 'components/common/Button/SubmitButton';
import DatePicker from 'components/inputs/DatePicker';
import { ApolloCache, DefaultContext, MutationFunctionOptions } from '@apollo/client';
import { GenerateReportMutation } from 'graphql/mutations/reports/generated/GenerateReport';
import { Exact, ReportFileInput } from 'graphql/types.generated';

type FormValues = {
  startDate?: Date;
  endDate?: Date;
};

interface IDateRangeExcelFilterProps {
  onClick: (
    options?:
      | MutationFunctionOptions<
          GenerateReportMutation,
          Exact<{
            input: ReportFileInput;
          }>,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<any>;
  iframeId?: string;
}

const DateRangeExcelFilter: FC<IDateRangeExcelFilterProps> = ({ onClick, iframeId }) => {
  const initialValues = useMemo(() => {
    return { startDate: undefined, endDate: undefined };
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        onClick({
          variables: {
            input: {
              rangeEndDate: values.endDate,
              rangeStartDate: values.startDate,
              iframeId: iframeId,
            },
          },
        });
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [iframeId, onClick],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <FastField
              name="startDate"
              type="search"
              label="dashboardInfo.rangePicker.startTimeDateRange"
              component={DatePicker}
            />
            <FastField
              name="endDate"
              type="search"
              label="dashboardInfo.rangePicker.endTimeDateRange"
              component={DatePicker}
            />
          </div>
          <div className="filter-buttons">
            <SubmitButton label="projects.downloadReportOfAllProjects" icon={faFileExcel} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DateRangeExcelFilter;
