import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadFileRequestQueryVariables = SchemaTypes.Exact<{
  fileId: SchemaTypes.Scalars['ID']['input'];
}>;

export type DownloadFileRequestQuery = {
  __typename?: 'Query';
  downloadFileRequest: { __typename?: 'DownloadFileRequestResponse'; signedURL: string };
};

export const DownloadFileRequestDocument = gql`
  query DownloadFileRequest($fileId: ID!) {
    downloadFileRequest(fileId: $fileId) {
      signedURL
    }
  }
`;

/**
 * __useDownloadFileRequestQuery__
 *
 * To run a query within a React component, call `useDownloadFileRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFileRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFileRequestQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDownloadFileRequestQuery(
  baseOptions: Apollo.QueryHookOptions<DownloadFileRequestQuery, DownloadFileRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadFileRequestQuery, DownloadFileRequestQueryVariables>(
    DownloadFileRequestDocument,
    options,
  );
}
export function useDownloadFileRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DownloadFileRequestQuery, DownloadFileRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadFileRequestQuery, DownloadFileRequestQueryVariables>(
    DownloadFileRequestDocument,
    options,
  );
}
export type DownloadFileRequestQueryHookResult = ReturnType<typeof useDownloadFileRequestQuery>;
export type DownloadFileRequestLazyQueryHookResult = ReturnType<typeof useDownloadFileRequestLazyQuery>;
export type DownloadFileRequestQueryResult = Apollo.QueryResult<
  DownloadFileRequestQuery,
  DownloadFileRequestQueryVariables
>;
export function refetchDownloadFileRequestQuery(variables: DownloadFileRequestQueryVariables) {
  return { query: DownloadFileRequestDocument, variables: variables };
}
