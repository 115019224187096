import React from 'react';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { Stack, Alert } from 'react-bootstrap';
import useTenants from 'hooks/tenant/useTenants';
import TenantButton from 'components/tenants/TenantButton';
import TenantsTable from 'components/tenants/TenantsTable';
import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import { grabErrorMessage } from 'utils/helpers';
import TenantsFilterForm from 'components/tenants/TenantsFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const TenantsManagementPage = () => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'tenantsPage' });
  const { tenants, loading, total, error } = useTenants({ currentPage });

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.tenantsManagement') }]} />
      <Stack direction="horizontal" gap={3}>
        <PageTitle>{t('pageTitles.tenantsManagement')}</PageTitle>
        <div className="ms-auto">
          <TenantButton>
            {t('tenants.createTenantBtn')} <FontAwesomeIcon icon={faPlus} />
          </TenantButton>
        </div>
      </Stack>
      <TenantsFilterForm {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <TenantsTable {...{ tenants, currentPage, changePage, total, loading }} /> : null}
    </>
  );
};

export default TenantsManagementPage;
