import constants from '../../constants/constants';
import get from 'lodash/get';
import { QueryHookOptions } from '@apollo/client';
import { AllUsersQuery, AllUsersQueryVariables, useAllUsersQuery } from 'graphql/queries/user/generated/AllUsers';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { AccountType } from 'graphql/types.generated';
import { useParams } from 'react-router-dom';

const { tableRowsCountPerPage } = constants;

interface IGetUsersProps extends QueryHookOptions<AllUsersQuery, StrictUnion<AllUsersQueryVariables>> {
  currentPage: number;
  accountType: AccountType;
}

export const stringToBoolean = (str?: string) => {
  if (typeof str !== 'string') return undefined;
  if (str === 'true') return true;
  if (str === 'false') return false;
  return undefined;
};

export const useGetUsersFilter = () => {
  const {
    searchParams: { usersSearchQuery },
  } = useGetSearchParams(['usersSearchQuery']);

  return { searchQuery: usersSearchQuery };
};

export default function useGetUsers({ currentPage, accountType, ...baseOptions }: IGetUsersProps) {
  const skip = tableRowsCountPerPage * (currentPage - 1);
  const filter = useGetUsersFilter();
  const { tenantId } = useParams();

  const { data, loading, error, startPolling, stopPolling } = useAllUsersQuery({
    variables: { filter: { ...filter, accountType }, tenantId, skip, limit: tableRowsCountPerPage },
    ...baseOptions,
  });

  const users = get(data, 'allUsers.items', []);
  const total = get(data, 'allUsers.total', 0);

  return { users, total, loading, error, startPolling, stopPolling };
}
