import React, { useCallback, useMemo } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { toastErrorMessage } from '../../utils/helpers';
import SubmitButton from 'components/common/Button/SubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import useGetSearchParams from 'hooks/useGetSearchParams';
import constants from 'constants/constants';
import { refetchTenantsQuery } from 'graphql/queries/tenant/generated/Tenants';
import { useGetTenantsFilter } from 'hooks/tenant/useTenants';
import Select from 'components/inputs/Select/Select';
import { useTranslation } from 'react-i18next';
import { TenantStatusFilter } from 'graphql/types.generated';

const { tableRowsCountPerPage } = constants;

interface ITenantsFilterForm {
  changePage: (page: number) => void;
}

type FormValues = {
  tenantsSearchQuery: string;
  status: TenantStatusFilter;
};

const TenantsFilterForm: FC<ITenantsFilterForm> = ({ changePage }) => {
  const { t } = useTranslation();

  const { searchParams, setSearchParams } = useGetSearchParams();
  const filter = useGetTenantsFilter();

  const initialValues = useMemo(() => {
    return { tenantsSearchQuery: filter.searchQuery, status: filter.status as TenantStatusFilter };
  }, [filter.searchQuery, filter.status]);

  const onSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        const { tenantsSearchQuery, status } = values;
        changePage(1);
        setSearchParams({
          ...searchParams,
          ...(typeof tenantsSearchQuery === 'string' ? { tenantsSearchQuery } : {}),
          ...(typeof status === 'string' ? { status } : { status: TenantStatusFilter.ALL }),
        });

        refetchTenantsQuery({
          skip: 0,
          limit: tableRowsCountPerPage,
          searchString: tenantsSearchQuery,
          ...(typeof status === 'string' ? { status } : { status: TenantStatusFilter.ALL }),
        });
      } catch (error: any) {
        toastErrorMessage(error);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [changePage, searchParams, setSearchParams],
  );

  return (
    <Formik {...{ initialValues, onSubmit, enableReinitialize: true }}>
      {() => (
        <Form className="filters-form-container">
          <div className="filters-form">
            <Field name="tenantsSearchQuery" type="search" label="tenants.searchQuery" component={TextInput} />
            <Field
              name="status"
              label="tenants.status.label"
              isClearable
              component={Select}
              options={[
                { label: t('tenants.status.all'), value: TenantStatusFilter.ALL },
                { label: t('tenants.status.archived'), value: TenantStatusFilter.ARCHIVED },
                { label: t('tenants.status.active'), value: TenantStatusFilter.ACTIVE },
              ]}
            />
          </div>
          <div className="filter-buttons">
            <SubmitButton label="buttons.search" icon={faMagnifyingGlass} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TenantsFilterForm;
