import { AccountType, Roles } from 'graphql/types.generated';
import { useTranslation } from 'react-i18next';

export const roleOptions = [
  {
    label: `roles.${Roles.MEINBAU_ADMIN}`,
    value: Roles.MEINBAU_ADMIN,
    isMeinbau: AccountType.meinbau,
  },
  {
    label: `roles.${Roles.TENANT_ADMIN}`,
    value: Roles.TENANT_ADMIN,
    isTenant: AccountType.tenant,
  },
];

const useFilteredRoleOptions = (accountType: AccountType) => {
  const { t } = useTranslation();
  const localizedRoleOptions = roleOptions.map(({ label, ...rest }) => ({ label: t(label), ...rest }));

  const filteredOptions = localizedRoleOptions.filter((option) => {
    if (accountType === AccountType.meinbau) {
      return option.isMeinbau === AccountType.meinbau;
    } else if (accountType === AccountType.tenant) {
      return option.isTenant === AccountType.tenant;
    }
    return false;
  });

  return filteredOptions;
};

export default useFilteredRoleOptions;
