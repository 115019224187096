import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import SubmitButton from 'components/common/Button/SubmitButton';
import Select from 'components/inputs/Select';
import MultiSelect from 'components/inputs/Select/MultiSelect';
import { MenuPortalTarget } from 'components/inputs/Select/useMenuPortalTarget';
import TextInput from 'components/inputs/TextInput';
import { FastField, Form, Formik } from 'formik';
import { useAddWebhookMutation } from 'graphql/mutations/iframe/generated/AddWebhook';
import { useUpdateWebhookMutation } from 'graphql/mutations/iframe/generated/UpdateWebhook';
import { IframeWebhookInput, Webhook, WebhookEvent, WebhookTrigger } from 'graphql/types.generated';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toastErrorMessage } from 'utils/helpers';
import Headers from './Headers';
import * as yup from 'yup';

export enum WebhookFormType {
  create = 'create',
  update = 'update',
}

interface WebhookFormParams {
  iframeId: string;
  webhook?: Webhook;
  formType: WebhookFormType;
  afterSubmitFunc?: () => void;
}

const validationSchema = yup.object().shape({
  url: yup.string().url().required('errors.requiredField'),
  events: yup.array().of(yup.string()).min(1).nullable(),
  trigger: yup.string().required('errors.chooseOne').nullable(),
});

const WebhookForm: FC<WebhookFormParams> = ({ webhook, formType, iframeId, afterSubmitFunc }) => {
  const { t } = useTranslation();
  const [addWebhook] = useAddWebhookMutation({ onError: toastErrorMessage });
  const [updateWebhook] = useUpdateWebhookMutation({ onError: toastErrorMessage });

  const onSubmit = useCallback(
    async (values: IframeWebhookInput) => {
      if (formType === WebhookFormType.create) {
        await addWebhook({ variables: { iframeId, input: values } });
      }
      if (formType === WebhookFormType.update && webhook) {
        await updateWebhook({ variables: { iframeId, webhookId: webhook._id, input: values } });
      }
      if (typeof afterSubmitFunc === 'function') afterSubmitFunc();
    },
    [addWebhook, afterSubmitFunc, formType, iframeId, updateWebhook, webhook],
  );
  const initialValues: IframeWebhookInput = useMemo(() => {
    return {
      trigger: (webhook?.trigger || '') as WebhookTrigger,
      events: webhook?.events || [],
      url: webhook?.url || '',
      headers: webhook?.headers?.map(({ name, value }) => ({ name, value })) || [],
    };
  }, [webhook?.events, webhook?.headers, webhook?.trigger, webhook?.url]);

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema, enableReinitialize: true }}>
      {() => (
        <Form>
          <FastField name="url" label="webhook.form.url" component={TextInput} />
          <FastField
            name="trigger"
            label="webhook.form.trigger"
            component={Select}
            menuPortalTarget={MenuPortalTarget.modal}
            options={[
              {
                label: t(`webhook.form.triggerOptions.${WebhookTrigger.pdfRequestSubmission}`),
                value: WebhookTrigger.pdfRequestSubmission,
              },
            ]}
          />
          <FastField
            name="events"
            label="webhook.form.events"
            component={MultiSelect}
            menuPortalTarget={MenuPortalTarget.modal}
            options={[
              {
                label: t(`webhook.form.eventOptions.${WebhookEvent.transferJSON}`),
                value: WebhookEvent.transferJSON,
              },
              {
                label: t(`webhook.form.eventOptions.${WebhookEvent.transferPDF}`),
                value: WebhookEvent.transferPDF,
              },
            ]}
          />
          <Headers />
          <div className="text-center">
            <SubmitButton label="buttons.submit" icon={faFloppyDisk} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default WebhookForm;
