import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Stack, Badge, ListGroup, Button } from 'react-bootstrap';

import { MeinBauApiLog } from 'graphql/types.generated';
import { copyToClipBoard, formatDate } from 'utils/helpers';
import i18n from 'i18n';

interface IMeinBauApiLogDataParams {
  meinBauApiLog: MeinBauApiLog;
}

const MeinBauApiLogsData: FC<IMeinBauApiLogDataParams> = ({ meinBauApiLog }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meinBauApiLog' });

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Card.Title className="mb-0">{t('titles.baseData')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.appName')}</Card.Text>
              {meinBauApiLog.appName ? meinBauApiLog.appName : t('baseData.appNameNotSpecified')}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.ip')}</Card.Text>
              {meinBauApiLog.ip ? meinBauApiLog.ip : t('baseData.ipNotSpecified')}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.httpMethod')}</Card.Text>
              {meinBauApiLog.method}
            </ListGroup.Item>
            <ListGroup.Item>
              <Card.Text className="fw-bold mb-1">{t('baseData.createdAt')}</Card.Text>
              {formatDate({ date: meinBauApiLog.createdAt, locale: i18n.language })}
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
      <Row>
        <Col lg={6}>
          <Card className="mb-3">
            <Card.Header>
              <Stack direction="horizontal" gap={3}>
                <Card.Title className="mb-0">{t('titles.input')}</Card.Title>
                <Button
                  className="ms-auto"
                  onClick={() => copyToClipBoard(meinBauApiLog.input, t('copiedToClipboard'))}
                >
                  {t('titles.copy')}
                </Button>
              </Stack>
            </Card.Header>
            <Card.Body>{meinBauApiLog.input}</Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="mb-3">
            <Card.Header>
              <Stack direction="horizontal" gap={3}>
                <Card.Title className="mb-0"> {t('titles.result')}</Card.Title>
                <Badge bg={meinBauApiLog.status !== 200 ? 'danger' : 'success'}>{meinBauApiLog.status}</Badge>
                {(meinBauApiLog.output || meinBauApiLog.errorMessage) && (
                  <Button
                    className="ms-auto"
                    onClick={() =>
                      copyToClipBoard(
                        meinBauApiLog.output ? `${meinBauApiLog.output}` : `${meinBauApiLog.errorMessage}`,
                        t('copiedToClipboard'),
                      )
                    }
                  >
                    {t('titles.copy')}
                  </Button>
                )}
              </Stack>
            </Card.Header>
            <Card.Body>{meinBauApiLog.output || meinBauApiLog.errorMessage}</Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MeinBauApiLogsData;
