import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { FileInstanceLinkFragmentDoc } from '../../../fragments/file/generated/FileInstanceLink';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFileInstanceLinkMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateFileInstanceInput;
}>;

export type CreateFileInstanceLinkMutation = {
  __typename?: 'Mutation';
  createFileInstanceLink: {
    __typename?: 'FileInstanceLink';
    _id: string;
    instanceId: string;
    instanceType: SchemaTypes.FileInstanceType;
    file: {
      __typename?: 'File';
      _id: string;
      name: string;
      iframeId: string;
      tenantId: string;
      sizeBytes: number;
      createdAt: any;
    };
  };
};

export const CreateFileInstanceLinkDocument = gql`
  mutation CreateFileInstanceLink($input: CreateFileInstanceInput!) {
    createFileInstanceLink(input: $input) {
      ...FileInstanceLink
    }
  }
  ${FileInstanceLinkFragmentDoc}
`;
export type CreateFileInstanceLinkMutationFn = Apollo.MutationFunction<
  CreateFileInstanceLinkMutation,
  CreateFileInstanceLinkMutationVariables
>;

/**
 * __useCreateFileInstanceLinkMutation__
 *
 * To run a mutation, you first call `useCreateFileInstanceLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileInstanceLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileInstanceLinkMutation, { data, loading, error }] = useCreateFileInstanceLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileInstanceLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFileInstanceLinkMutation, CreateFileInstanceLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFileInstanceLinkMutation, CreateFileInstanceLinkMutationVariables>(
    CreateFileInstanceLinkDocument,
    options,
  );
}
export type CreateFileInstanceLinkMutationHookResult = ReturnType<typeof useCreateFileInstanceLinkMutation>;
export type CreateFileInstanceLinkMutationResult = Apollo.MutationResult<CreateFileInstanceLinkMutation>;
export type CreateFileInstanceLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateFileInstanceLinkMutation,
  CreateFileInstanceLinkMutationVariables
>;
