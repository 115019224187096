/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Locales } from 'i18n';
import { iframeProjectsReportUrl, projectsReportUrl } from './reportUrls';

type ApiRoutes = {
  projectEstimationUrl: (projectId: string, requestId?: string) => string;
  projectReportUrl: (projectId: string) => string;
  iframeProjectsReportUrl: (iframeId: string, startDate?: Date, endDate?: Date) => string;
  projectsReportUrl: (startDate?: Date, endDate?: Date) => string;
};

type AppConfig = {
  defaultLocale: Locales;
  graphqlServerUrl: string;
  xClientType: string;
  termsOfUseUrl: string;
  privacyPolicyUrl: string;
  apiRoutes: ApiRoutes;
  iframeBaseUrl: string;
  adminBaseUrl: string;
  projectResultPageUrl: (params: { projectId: string; iframeId: string }) => string;
};

const config: AppConfig = {
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE ? (process.env.REACT_APP_DEFAULT_LOCALE! as Locales) : Locales.en,
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL || '/graphql',
  xClientType: process.env.REACT_APP_X_CLIENT_TYPE || 'ADMIN',
  termsOfUseUrl: process.env.REACT_APP_TERMS_OF_USE_URL!,
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY!,
  iframeBaseUrl: process.env.REACT_APP_IFRAME_BASE_URL || '',
  adminBaseUrl: process.env.REACT_APP_ADMIN_BASE_URL!,
  projectResultPageUrl: ({ projectId, iframeId }: { projectId: string; iframeId: string }) =>
    `${process.env.REACT_APP_IFRAME_BASE_URL}/result/${iframeId}/${projectId}`,
  apiRoutes: {
    projectEstimationUrl: (projectId, requestId) =>
      `/api/admin/project-estimation/${projectId}${requestId ? `?requestId=${requestId}` : ''}`,
    projectReportUrl: (projectId) => `/api/admin/project-full-details/${projectId}`,
    iframeProjectsReportUrl,
    projectsReportUrl,
  },
};

export default config;
