import React from 'react';
import usePagination from 'hooks/usePagination';
import { Alert } from 'react-bootstrap';
import useProjectPdfRequests from 'hooks/projectPdfRequest/useProjectPdfRequests';
import { grabErrorMessage } from 'utils/helpers';
import ProjectPdfRequestsFilter from 'components/projectPdfRequsts/ProjectPdfRequestsFilter';
import ProjectPdfRequestsTable from 'components/projectPdfRequsts/ProjectPdfRequestsTable';
import useIframe from 'hooks/iframe/useIframe';

interface IIframeProjectPdfRequestsProps {
  iframeId: string;
}

const IframeProjectPdfRequests: FC<IIframeProjectPdfRequestsProps> = ({ iframeId }) => {
  const { currentPage, changePage } = usePagination({ searchParamKey: 'iframeProjectPdfRequestsPage' });
  const { projectPdfRequests, loading, total, error } = useProjectPdfRequests({ currentPage, iframeId });
  const { iframe, loading: iframeLoading, error: iframeError } = useIframe({ iframeId });

  return (
    <>
      <ProjectPdfRequestsFilter {...{ changePage }} />
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!iframeLoading && iframeError ? <Alert variant="danger">{grabErrorMessage(iframeError)}</Alert> : null}
      {!error && iframe ? (
        <ProjectPdfRequestsTable
          {...{
            projectPdfRequests,
            usePhoneNumber: iframe.requestProjectPdfConfig.usePhoneNumber,
            currentPage,
            changePage,
            total,
            loading,
          }}
        />
      ) : null}
    </>
  );
};

export default IframeProjectPdfRequests;
