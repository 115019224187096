import { BaseMutationOptions, MutationHookOptions } from '@apollo/client';
import { useCallback } from 'react';
import {
  PersistFileMutation,
  PersistFileMutationVariables,
  usePersistFileMutation,
} from 'graphql/mutations/file/generated/PersistFile';
import { toastErrorMessage } from 'utils/helpers';

export type OnCompletedPersistFile = (data: PersistFileMutation, clientOptions?: BaseMutationOptions) => void;

const usePersistFile = ({
  onCompleted,
  ...baseOptions
}: MutationHookOptions<PersistFileMutation, PersistFileMutationVariables>) => {
  const handleCompleted = useCallback<OnCompletedPersistFile>(
    async (data, clientOptions) => {
      if (typeof onCompleted === 'function') return onCompleted(data, clientOptions);
    },
    [onCompleted],
  );

  const [persistFileMutation] = usePersistFileMutation({
    onCompleted: handleCompleted,
    onError: toastErrorMessage,
    ...baseOptions,
  });

  return persistFileMutation;
};

export default usePersistFile;
