import BreadcrumbBuilder from 'components/common/BreadcrumbBuilder';
import PageTitle from 'components/common/PageTitle';
import MeinBauApiLogsTable from 'components/meinBauApiLogs/MeinBauApiLogsTable';
import useMeinBauApiLogs from 'hooks/meinBauApiLogs/useMeinBauApiLogs';
import usePagination from 'hooks/usePagination';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { grabErrorMessage } from 'utils/helpers';

const TenantMeinBauApiLogsPage = () => {
  const { t } = useTranslation();
  const { tenantId } = useParams();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'tenantMeinBauApiLogsPage' });
  const { meinBauApiLogs, loading, total, error } = useMeinBauApiLogs({ currentPage, tenantId });

  return (
    <>
      <BreadcrumbBuilder pathLinks={[{ text: t('pageTitles.meinBauApiLogs') }]} />
      <PageTitle>{t('pageTitles.meinBauApiLogs')}</PageTitle>
      {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
      {!error ? <MeinBauApiLogsTable {...{ meinBauApiLogs, currentPage, changePage, total, loading }} /> : null}
    </>
  );
};

export default TenantMeinBauApiLogsPage;
