import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import {
  AllInvitationsQuery,
  AllInvitationsQueryVariables,
  useAllInvitationsQuery,
} from 'graphql/queries/invitation/generated/AllInvitations';
import useGetSearchParams from 'hooks/useGetSearchParams';
import { AccountType } from 'graphql/types.generated';
import { useParams } from 'react-router-dom';

interface IGetInvitationsProps extends QueryHookOptions<AllInvitationsQuery, AllInvitationsQueryVariables> {
  currentPage: number;
  accountType: AccountType;
}

export const stringToBoolean = (str?: string) => {
  if (typeof str !== 'string') return undefined;
  if (str === 'true') return true;
  if (str === 'false') return false;
  return undefined;
};

export const useGetInvitationsFilter = () => {
  const {
    searchParams: { invitationsSearchQuery, isDraft },
  } = useGetSearchParams(['invitationsSearchQuery', 'isDraft']);

  return { searchQuery: invitationsSearchQuery, isDraft: stringToBoolean(isDraft) };
};

export default function useGetInvitations({ currentPage, accountType, ...baseOptions }: IGetInvitationsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const { tenantId } = useParams();
  const filter = useGetInvitationsFilter();

  const { loading, data, error, refetch, startPolling, stopPolling } = useAllInvitationsQuery({
    variables: { filter: { ...filter, accountType }, tenantId, skip, limit: constants.tableRowsCountPerPage },
    ...baseOptions,
  });

  const invitations = data?.allInvitations?.items || [];
  const total = data?.allInvitations?.total || 0;
  const plainTotal = data?.allInvitations?.plainTotal || 0;

  return { invitations, plainTotal, total, loading, error, refetch, startPolling, stopPolling };
}
