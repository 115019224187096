import React, { useCallback, useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import SubmitButton from 'components/common/Button/SubmitButton';
import { faEdit, faRotate } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import UserActionBadge from 'components/common/UserActionBadge';
import { useConfigureIframeFuelMatrixMutation } from 'graphql/mutations/iframe/generated/ConfigureIframeFuelMatrix';
import { toastErrorMessage } from 'utils/helpers';
import { toast } from 'react-toastify';
import {
  FuelMatrixConfiguration,
  FuelMatrixConfigurationInput,
  FuelMatrixConfigurationItem,
  Iframe,
} from 'graphql/types.generated';
import Button from 'components/common/Button';
import IframeFuelMatrixTable from './IframeFuelMatrixTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';

interface IIframeFuelMatrixForm {
  iframe: IframeQuery['iframe'];
}

const validationSchema = yup.object().shape({
  heatingOil: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
    avgPricePerKWh: yup.number().required('errors.requiredField'),
  }),
  naturalGas: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
    avgPricePerKWh: yup.number().required('errors.requiredField'),
  }),
  coal: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
    avgPricePerKWh: yup.number().required('errors.requiredField'),
  }),
  firewood: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
    avgPricePerKWh: yup.number().required('errors.requiredField'),
  }),
  woodChips: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
    avgPricePerKWh: yup.number().required('errors.requiredField'),
  }),
  woodPellets: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
    avgPricePerKWh: yup.number().required('errors.requiredField'),
  }),
  electricityUsed: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
    avgPricePerKWh: yup.number().required('errors.requiredField'),
  }),
  electricityProduced: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
  }),
  districtHeating: yup.object({
    avgPricePerUnit: yup.number().required('errors.requiredField'),
    avgPricePerKWh: yup.number().required('errors.requiredField'),
  }),
});

function isNumeric(str: any) {
  if (typeof str != 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

const fuelTypes = [
  'heatingOil',
  'naturalGas',
  'coal',
  'firewood',
  'woodChips',
  'woodPellets',
  'electricityUsed',
  'electricityProduced',
  'districtHeating',
];

const IframeFuelMatrixForm: React.FunctionComponent<IIframeFuelMatrixForm> = ({ iframe }) => {
  const { t } = useTranslation();

  const [configureIframeFuelMatrix] = useConfigureIframeFuelMatrixMutation({
    onError: toastErrorMessage,
    onCompleted: () => toast.success(t('translations.resultPageConfigurationUpdated')),
  });

  const initialValues: FuelMatrixConfiguration = useMemo(() => {
    const items: any = {};

    if (iframe?.fuelMatrix) {
      for (const key of fuelTypes) {
        items[key] = {
          input: (
            iframe?.fuelMatrix?.configuration[key as keyof FuelMatrixConfiguration] as FuelMatrixConfigurationItem
          ).input,
          unit: (iframe?.fuelMatrix?.configuration[key as keyof FuelMatrixConfiguration] as FuelMatrixConfigurationItem)
            .unit,
          avgPricePerUnit: (
            iframe?.fuelMatrix?.configuration[key as keyof FuelMatrixConfiguration] as FuelMatrixConfigurationItem
          ).avgPricePerUnit,
          conversionFactor: (
            iframe?.fuelMatrix?.configuration[key as keyof FuelMatrixConfiguration] as FuelMatrixConfigurationItem
          ).conversionFactor,
          resultInKWhHeating: (
            iframe?.fuelMatrix?.configuration[key as keyof FuelMatrixConfiguration] as FuelMatrixConfigurationItem
          ).resultInKWhHeating,
          avgPricePerKWh: (
            iframe?.fuelMatrix?.configuration[key as keyof FuelMatrixConfiguration] as FuelMatrixConfigurationItem
          ).avgPricePerKWh,
        };
      }
    }
    return items;
  }, [iframe?.fuelMatrix]);

  const onSubmit = useCallback(
    async (fuelMatrix: FuelMatrixConfigurationInput) => {
      for (const key of Object.keys(fuelMatrix)) {
        if (
          fuelMatrix[key as keyof FuelMatrixConfigurationInput] &&
          isNumeric(fuelMatrix[key as keyof FuelMatrixConfigurationInput].avgPricePerUnit)
        ) {
          fuelMatrix[key as keyof FuelMatrixConfigurationInput].avgPricePerUnit = Number(
            fuelMatrix[key as keyof FuelMatrixConfigurationInput].avgPricePerUnit,
          );
        }
        if (
          fuelMatrix[key as keyof FuelMatrixConfigurationInput] &&
          isNumeric(fuelMatrix[key as keyof FuelMatrixConfigurationInput].avgPricePerKWh)
        ) {
          fuelMatrix[key as keyof FuelMatrixConfigurationInput].avgPricePerKWh = Number(
            fuelMatrix[key as keyof FuelMatrixConfigurationInput].avgPricePerKWh,
          );
        }
      }
      await configureIframeFuelMatrix({
        variables: {
          iframeId: (iframe as Iframe)._id,
          fuelMatrix,
        },
      });
    },
    [configureIframeFuelMatrix, iframe],
  );

  const onReset = useCallback(async () => {
    await configureIframeFuelMatrix({
      variables: {
        iframeId: (iframe as Iframe)._id,
        fuelMatrix: null,
      },
    });
  }, [configureIframeFuelMatrix, iframe]);

  return (
    <>
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        <Form>
          <Stack className="mb-3" direction="horizontal" gap={3}>
            <div className="ms-auto">
              <Button className="mx-1" onClick={onReset}>
                {t('fuelMatrix.setToDefault')} <FontAwesomeIcon icon={faRotate} />
              </Button>
              <SubmitButton label={'fuelMatrix.updateFuelMatrix'} icon={faEdit} disabled={!iframe.fuelMatrix} />
            </div>
          </Stack>
          <IframeFuelMatrixTable disabled={!iframe.fuelMatrix} />
          {iframe?.fuelMatrix?.updatedBy && (
            <>
              {t('fuelMatrix.lastUpdatedBy')}
              <UserActionBadge user={iframe?.fuelMatrix?.updatedBy} date={iframe?.fuelMatrix?.updatedAt} />
            </>
          )}
        </Form>
      </Formik>
    </>
  );
};

export default IframeFuelMatrixForm;
