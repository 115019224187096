import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { IframePlanningItemFragmentDoc } from '../../../fragments/iframe/generated/IframePlanningItem';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IframePlanningItemsQueryVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
}>;

export type IframePlanningItemsQuery = {
  __typename?: 'Query';
  iframe: {
    __typename?: 'Iframe';
    _id: string;
    planningItems?: Array<{
      __typename?: 'IframePlanningItem';
      parentId: string;
      name: SchemaTypes.ProjectFeature;
      parent: SchemaTypes.ProjectFeature;
      pricePerUnit?: number | null;
      equipmentTypes?: { __typename?: 'EquipmentTypes'; standard: number; premium: number; superior: number } | null;
      pricesPerUnit?: {
        __typename?: 'SolePlateOrBasementPricesPerUnit';
        solePlate?: number | null;
        basement?: number | null;
      } | null;
    }> | null;
  };
};

export const IframePlanningItemsDocument = gql`
  query IframePlanningItems($iframeId: ID!) {
    iframe(iframeId: $iframeId) {
      _id
      planningItems {
        ...IframePlanningItem
      }
    }
  }
  ${IframePlanningItemFragmentDoc}
`;

/**
 * __useIframePlanningItemsQuery__
 *
 * To run a query within a React component, call `useIframePlanningItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIframePlanningItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIframePlanningItemsQuery({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useIframePlanningItemsQuery(
  baseOptions: Apollo.QueryHookOptions<IframePlanningItemsQuery, IframePlanningItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IframePlanningItemsQuery, IframePlanningItemsQueryVariables>(
    IframePlanningItemsDocument,
    options,
  );
}
export function useIframePlanningItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IframePlanningItemsQuery, IframePlanningItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IframePlanningItemsQuery, IframePlanningItemsQueryVariables>(
    IframePlanningItemsDocument,
    options,
  );
}
export type IframePlanningItemsQueryHookResult = ReturnType<typeof useIframePlanningItemsQuery>;
export type IframePlanningItemsLazyQueryHookResult = ReturnType<typeof useIframePlanningItemsLazyQuery>;
export type IframePlanningItemsQueryResult = Apollo.QueryResult<
  IframePlanningItemsQuery,
  IframePlanningItemsQueryVariables
>;
export function refetchIframePlanningItemsQuery(variables: IframePlanningItemsQueryVariables) {
  return { query: IframePlanningItemsDocument, variables: variables };
}
