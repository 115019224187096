import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import useGetSearchParams from 'hooks/useGetSearchParams';
import {
  AllApplicationsQuery,
  AllApplicationsQueryVariables,
  useAllApplicationsQuery,
} from 'graphql/queries/applications/generated/AllApplications';

interface IAllApplicationsProps extends QueryHookOptions<AllApplicationsQuery, AllApplicationsQueryVariables> {
  currentPage: number;
}

export const useGetAllApplicationsFilter = () => {
  const {
    searchParams: { applicationsSearchQuery = '' },
  } = useGetSearchParams(['applicationsSearchQuery']);

  return { searchQuery: applicationsSearchQuery };
};

export default function useAllApplications({ currentPage, ...baseOptions }: IAllApplicationsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const filter = useGetAllApplicationsFilter();

  const { data, loading, error, refetch } = useAllApplicationsQuery({
    variables: {
      skip,
      limit: constants.tableRowsCountPerPage,
      filter: {
        searchQuery: filter.searchQuery,
      },
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const allApplications = data?.allApplications?.items || [];
  const total = data?.allApplications?.total || 0;

  return {
    allApplications,
    refetchAllApplications: refetch,
    total,
    loading,
    error,
  };
}
