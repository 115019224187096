import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectPdfRequestsQuery } from '../../graphql/queries/projectPdfRequests/generated/ProjectPdfRequests';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import config from 'config/config';
import { formatDate } from 'utils/helpers';

export type ProjectPdfRequestsListItem = First<ProjectPdfRequestsQuery['projectPdfRequests']['items']>;
interface iProjectPdfRequestsTableProps extends IPaginationProps {
  projectPdfRequests: ProjectPdfRequestsListItem[];
  loading: boolean;
  usePhoneNumber?: boolean | null;
}

export type ProjectPdfRequestsTableTableRow = ProjectPdfRequestsListItem & {
  pdf: { projectId: string; requestId: string };
};

const ProjectPdfRequestsTable: FC<iProjectPdfRequestsTableProps> = ({
  projectPdfRequests,
  usePhoneNumber,
  currentPage,
  changePage,
  total,
  loading,
}) => {
  const { t } = useTranslation();

  const data: ProjectPdfRequestsTableTableRow[] = useMemo(() => {
    return projectPdfRequests.map((projectPdfRequest: ProjectPdfRequestsListItem) => {
      const { _id, project, user, meta, createdAt } = projectPdfRequest;
      return {
        _id,
        project,
        user,
        meta,
        pdf: { projectId: project._id, requestId: _id },
        createdAt,
      };
    });
  }, [projectPdfRequests]);

  const columns: ColumnDef<ProjectPdfRequestsTableTableRow>[] = useMemo(() => {
    const tableColumns: ColumnDef<ProjectPdfRequestsTableTableRow>[] = [
      {
        header: t('projectPdfRequests.tableHeaders.userName'),
        accessorFn: (row) => {
          if (!row.user.firstName && !row.user.lastName)
            return <b className="text-danger">{t('projectPdfRequests.userNameNotProvided')}</b>;
          return `${row.user.firstName || ''} ${row.user.lastName || ''}`;
        },
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('projectPdfRequests.tableHeaders.email'),
        accessorKey: 'user.email',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('projectPdfRequests.tableHeaders.name'),
        accessorKey: 'project.name',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('projectPdfRequests.tableHeaders.clientIp'),
        accessorKey: 'meta.clientIP',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('projectPdfRequests.tableHeaders.createdAt'),
        accessorKey: 'createdAt',
        cell: (info) => formatDate({ date: new Date(info.getValue() as string) }),
      },
      {
        header: t('projectPdfRequests.tableHeaders.pdf'),
        accessorKey: 'pdf',
        cell: (info) => {
          const { projectId, requestId } = info.getValue() as ProjectPdfRequestsTableTableRow['pdf'];
          return (
            <a
              href={config.apiRoutes.projectEstimationUrl(projectId, requestId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="outline-primary">
                <FontAwesomeIcon icon={faFilePdf} />
              </Button>
            </a>
          );
        },
      },
    ];
    if (usePhoneNumber) {
      tableColumns.splice(2, 0, {
        header: t('projectPdfRequests.tableHeaders.phoneNumber'),
        accessorKey: 'user.phoneNumber',
        cell: (info) => info.getValue() as string,
      });
    }
    return tableColumns;
  }, [t, usePhoneNumber]);

  return (
    <>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        emptyText="projectPdfRequests.noProjectPdfRequestsFound.description"
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default ProjectPdfRequestsTable;
