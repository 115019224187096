import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneralAnalyticQueryVariables = SchemaTypes.Exact<{
  rangeEnd?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['DateTimeISO']['input']>;
  rangeStart?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['DateTimeISO']['input']>;
}>;

export type GeneralAnalyticQuery = {
  __typename?: 'Query';
  generalAnalytic: {
    __typename?: 'AnalyticsResponse';
    allPdfRequestsCount: number;
    allProjectsCount: number;
    allTenantsCount: number;
    allUsersCount: number;
    pdfRequestsPerTenants: Array<{
      __typename?: 'CountEntitiesPerTenant';
      tenantId: string;
      companyName: string;
      count: number;
    }>;
    projectsPerTenants: Array<{
      __typename?: 'CountEntitiesPerTenant';
      tenantId: string;
      companyName: string;
      count: number;
    }>;
  };
};

export const GeneralAnalyticDocument = gql`
  query GeneralAnalytic($rangeEnd: DateTimeISO, $rangeStart: DateTimeISO) {
    generalAnalytic(rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
      allPdfRequestsCount
      allProjectsCount
      allTenantsCount
      allUsersCount
      pdfRequestsPerTenants {
        tenantId
        companyName
        count
      }
      projectsPerTenants {
        tenantId
        companyName
        count
      }
    }
  }
`;

/**
 * __useGeneralAnalyticQuery__
 *
 * To run a query within a React component, call `useGeneralAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralAnalyticQuery({
 *   variables: {
 *      rangeEnd: // value for 'rangeEnd'
 *      rangeStart: // value for 'rangeStart'
 *   },
 * });
 */
export function useGeneralAnalyticQuery(
  baseOptions?: Apollo.QueryHookOptions<GeneralAnalyticQuery, GeneralAnalyticQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeneralAnalyticQuery, GeneralAnalyticQueryVariables>(GeneralAnalyticDocument, options);
}
export function useGeneralAnalyticLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GeneralAnalyticQuery, GeneralAnalyticQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeneralAnalyticQuery, GeneralAnalyticQueryVariables>(GeneralAnalyticDocument, options);
}
export type GeneralAnalyticQueryHookResult = ReturnType<typeof useGeneralAnalyticQuery>;
export type GeneralAnalyticLazyQueryHookResult = ReturnType<typeof useGeneralAnalyticLazyQuery>;
export type GeneralAnalyticQueryResult = Apollo.QueryResult<GeneralAnalyticQuery, GeneralAnalyticQueryVariables>;
export function refetchGeneralAnalyticQuery(variables?: GeneralAnalyticQueryVariables) {
  return { query: GeneralAnalyticDocument, variables: variables };
}
