import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BasicUserAttributesFragmentDoc } from '../../../fragments/user/generated/BasicUserAttribuesFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuditLogQueryVariables = SchemaTypes.Exact<{
  auditLogId: SchemaTypes.Scalars['ID']['input'];
}>;

export type AuditLogQuery = {
  __typename?: 'Query';
  auditLog: {
    __typename?: 'AuditLogEventsClass';
    _id: string;
    name: SchemaTypes.AuditLogEventName;
    eventInitiator?: string | null;
    affectedUser?: string | null;
    affectedUserId?: string | null;
    tenantInfo?: string | null;
    payload?: string | null;
    tenantId?: string | null;
    meta: { __typename?: 'Meta'; clientIP: string; domain: string; country?: string | null };
    createdBy?: {
      __typename?: 'BasicUserAttributes';
      _id: string;
      email: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
    } | null;
  };
};

export const AuditLogDocument = gql`
  query AuditLog($auditLogId: ID!) {
    auditLog(auditLogId: $auditLogId) {
      _id
      name
      eventInitiator
      affectedUser
      affectedUserId
      tenantInfo
      payload
      meta {
        clientIP
        domain
        country
      }
      tenantId
      createdBy {
        ...BasicUserAttributes
      }
    }
  }
  ${BasicUserAttributesFragmentDoc}
`;

/**
 * __useAuditLogQuery__
 *
 * To run a query within a React component, call `useAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogQuery({
 *   variables: {
 *      auditLogId: // value for 'auditLogId'
 *   },
 * });
 */
export function useAuditLogQuery(baseOptions: Apollo.QueryHookOptions<AuditLogQuery, AuditLogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditLogQuery, AuditLogQueryVariables>(AuditLogDocument, options);
}
export function useAuditLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuditLogQuery, AuditLogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditLogQuery, AuditLogQueryVariables>(AuditLogDocument, options);
}
export type AuditLogQueryHookResult = ReturnType<typeof useAuditLogQuery>;
export type AuditLogLazyQueryHookResult = ReturnType<typeof useAuditLogLazyQuery>;
export type AuditLogQueryResult = Apollo.QueryResult<AuditLogQuery, AuditLogQueryVariables>;
export function refetchAuditLogQuery(variables: AuditLogQueryVariables) {
  return { query: AuditLogDocument, variables: variables };
}
