import RefreshTokenService from 'services/RefreshTokenService';
import { MeDocument } from 'graphql/queries/user/generated/Me';
import { LoginMutation, useLoginMutation } from 'graphql/mutations/auth/generated/Login';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'router/routes';
import { ctxTenantIdVar } from 'graphql/apollo';

const useLogin = () => {
  const navigate = useNavigate();

  const [authMutation, { client }] = useLoginMutation({
    onCompleted: async ({ login: resultData }) => {
      const { refreshToken, user } = resultData as LoginMutation['login'];
      const data = { me: user, allUserTenants: { total: 0, items: [] } };

      if (refreshToken) {
        RefreshTokenService.setRefreshToken(refreshToken);
      }

      if (user.activeRoles?.length && user.activeRoles?.length > 1) {
        navigate(routePaths.changeTenant);
      } else {
        const userRole = user.activeRoles[0];
        const tenantId = userRole.tenantId as string;

        ctxTenantIdVar(tenantId);

        data.me.tenantId = tenantId;
        data.me.role = userRole.role;
      }

      client.writeQuery({ query: MeDocument, data });
      return resultData;
    },
  });
  return authMutation;
};

export default useLogin;
