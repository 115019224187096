import { faEdit } from '@fortawesome/free-solid-svg-icons';
import TextArea from 'components/inputs/TextArea';
import { Formik, Form, FastField } from 'formik';
import { Iframe, ResultPageConfigurationInput } from 'graphql/types.generated';
import * as yup from 'yup';
import React, { useCallback, useMemo } from 'react';
import { Accordion, Card, Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/inputs/TextInput';
import { useConfigureIframeResultPageMutation } from 'graphql/mutations/iframe/generated/ConfigureIframeResultPage';
import { toastErrorMessage } from 'utils/helpers';
import { toast } from 'react-toastify';
import Checkbox from 'components/inputs/Checkbox';
import s from './IframeTranslations.module.sass';
import SubmitButton from 'components/common/Button/SubmitButton';
import { resultPageConfiguration } from '../initialValues/ResultPageConfiguration';
import { IframeQuery } from 'graphql/queries/iframe/generated/Iframe';

interface IIframeTranslationsProps {
  iframe: IframeQuery['iframe'];
}

const partnerSectionValidationSchema = yup.object().shape({
  header: yup.object().shape({
    en: yup.string(),
    de: yup.string(),
  }),
  content: yup.object().shape({
    en: yup.string(),
    de: yup.string(),
  }),
  pdfButton: yup.object().shape({
    en: yup.string(),
    de: yup.string(),
  }),
  position: yup.number().min(0).max(6),
  isEnabled: yup.boolean(),
});

const positioningValidationSchema = yup.object().shape({
  position: yup.number().min(0).max(6),
  isEnabled: yup.boolean(),
});

const validationSchema = yup.object().shape({
  headerSection: yup.object().shape({
    header: yup.object().shape({
      en: yup.string(),
      de: yup.string(),
    }),
    position: yup.number().min(0).max(6),
    isEnabled: yup.boolean(),
  }),
  planSection: positioningValidationSchema,
  equivalentsSection: positioningValidationSchema,
  totalCostSection: positioningValidationSchema,
  investmentsPayOfSection: positioningValidationSchema,
  firstPartnerSection: partnerSectionValidationSchema,
  secondPartnerSection: partnerSectionValidationSchema,
});

interface ITranslation {
  name: { en: string; de: string };
  label: { en: string; de: string };
  component: any;
}

const TranslationComponent: FC<ITranslation> = ({ name, label, component }) => {
  return (
    <Row>
      <Col md={6}>
        <FastField name={name.de} component={component} label={label.de} />
      </Col>
      <Col md={6}>
        <FastField name={name.en} component={component} label={label.en} />
      </Col>
    </Row>
  );
};

interface IEnableSection {
  name: string;
  label: string;
}

const EnableSectionComponent: FC<IEnableSection> = ({ name, label }) => {
  return (
    <Card className={s.cardWrapper}>
      <Card.Header className={s.cardHeader}>
        <FastField name={name} component={Checkbox} label={label} />
      </Card.Header>
    </Card>
  );
};

const IframeTranslations: FC<IIframeTranslationsProps> = ({ iframe }) => {
  const { t } = useTranslation();
  const [configureIframeResultPage] = useConfigureIframeResultPageMutation({
    onError: toastErrorMessage,
    onCompleted: () => toast.success(t('translations.resultPageConfigurationUpdated')),
  });

  const initialValues: ResultPageConfigurationInput = useMemo(
    (): ResultPageConfigurationInput => resultPageConfiguration(iframe),
    [iframe],
  );

  const onSubmit = useCallback(
    async (resultPageConfiguration: ResultPageConfigurationInput) => {
      await configureIframeResultPage({
        variables: {
          iframeId: iframe._id,
          resultPageConfiguration,
        },
      });
    },
    [configureIframeResultPage, iframe._id],
  );

  return (
    <>
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
        }}
      >
        <Form>
          <Stack className="mb-3" direction="horizontal" gap={3}>
            <div className="ms-auto">
              <SubmitButton label={'translations.updateTranslations'} icon={faEdit} />
            </div>
          </Stack>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FastField
                  name="headerSection.isEnabled"
                  component={Checkbox}
                  label="translations.headerSection.isEnabled"
                />
              </Accordion.Header>
              <Accordion.Body>
                <TranslationComponent
                  component={TextInput}
                  name={{ en: 'headerSection.header.en', de: 'headerSection.header.de' }}
                  label={{ en: 'translations.headerSection.header.en', de: 'translations.headerSection.header.de' }}
                />
              </Accordion.Body>
            </Accordion.Item>
            <EnableSectionComponent name="planSection.isEnabled" label="translations.planSection.isEnabled" />
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <FastField
                  name="firstPartnerSection.isEnabled"
                  component={Checkbox}
                  label="translations.firstPartnerSection.isEnabled"
                />
              </Accordion.Header>
              <Accordion.Body>
                <TranslationComponent
                  component={TextInput}
                  name={{ en: 'firstPartnerSection.header.en', de: 'firstPartnerSection.header.de' }}
                  label={{
                    en: 'translations.firstPartnerSection.header.en',
                    de: 'translations.firstPartnerSection.header.de',
                  }}
                />
                <TranslationComponent
                  component={TextArea}
                  name={{ en: 'firstPartnerSection.content.en', de: 'firstPartnerSection.content.de' }}
                  label={{
                    en: 'translations.firstPartnerSection.content.en',
                    de: 'translations.firstPartnerSection.content.de',
                  }}
                />
                <TranslationComponent
                  component={TextInput}
                  name={{ en: 'firstPartnerSection.pdfButton.en', de: 'firstPartnerSection.pdfButton.de' }}
                  label={{
                    en: 'translations.firstPartnerSection.pdfButton.en',
                    de: 'translations.firstPartnerSection.pdfButton.de',
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
            <EnableSectionComponent
              name="equivalentsSection.isEnabled"
              label="translations.equivalentsSection.isEnabled"
            />
            <EnableSectionComponent name="totalCostSection.isEnabled" label="translations.totalCostSection.isEnabled" />
            <EnableSectionComponent
              name="investmentsPayOfSection.isEnabled"
              label="translations.investmentsPayOfSection.isEnabled"
            />
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <FastField
                  name="secondPartnerSection.isEnabled"
                  component={Checkbox}
                  label="translations.secondPartnerSection.isEnabled"
                />
              </Accordion.Header>
              <Accordion.Body>
                <TranslationComponent
                  component={TextInput}
                  name={{ en: 'secondPartnerSection.header.en', de: 'secondPartnerSection.header.de' }}
                  label={{
                    en: 'translations.secondPartnerSection.header.en',
                    de: 'translations.secondPartnerSection.header.de',
                  }}
                />
                <TranslationComponent
                  component={TextArea}
                  name={{ en: 'secondPartnerSection.content.en', de: 'secondPartnerSection.content.de' }}
                  label={{
                    en: 'translations.secondPartnerSection.content.en',
                    de: 'translations.secondPartnerSection.content.de',
                  }}
                />
                <TranslationComponent
                  component={TextInput}
                  name={{ en: 'secondPartnerSection.pdfButton.en', de: 'secondPartnerSection.pdfButton.de' }}
                  label={{
                    en: 'translations.secondPartnerSection.pdfButton.en',
                    de: 'translations.secondPartnerSection.pdfButton.de',
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Form>
      </Formik>
    </>
  );
};

export default IframeTranslations;
