import React from 'react';
import s from './Footer.module.sass';

interface iFooterProps {
  hideSidebar?: boolean;
}

const Footer: FC<iFooterProps> = () => {
  return (
    <footer className={s.footer}>
      <div className="text-muted">Copyright © {new Date().getFullYear()} MeinBau</div>
    </footer>
  );
};

export default Footer;
