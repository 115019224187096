import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AuditLogsQuery } from '../../graphql/queries/auditLogs/generated/AuditLogs';
import { IPaginationProps, Pagination } from 'components/common/Pagination';
import { ColumnDef } from '@tanstack/react-table';
import Table from 'components/common/Table/Table';
import { routePaths } from 'router/routes';
import { generatePath, useNavigate } from 'react-router-dom';
import { BasicUserAttributes } from 'graphql/types.generated';
import UserActionBadge from 'components/common/UserActionBadge';
import { formatDate } from 'utils/helpers';

export type AuditLogsListItem = First<AuditLogsQuery['auditLogs']['items']>;
interface IAuditLogsTableProps extends IPaginationProps {
  auditLogs: AuditLogsListItem[];
  loading: boolean;
}

export type AuditLogsTableTableRow = AuditLogsListItem;

const AuditLogsTable: FC<IAuditLogsTableProps> = ({ auditLogs, currentPage, changePage, total, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data: AuditLogsTableTableRow[] = useMemo(() => {
    return auditLogs.map((auditLog: AuditLogsListItem) => {
      const { name, createdBy, _id, createdAt } = auditLog;
      return { _id, name, createdBy, createdAt };
    });
  }, [auditLogs]);

  const columns: ColumnDef<AuditLogsTableTableRow>[] = useMemo(
    () => [
      {
        header: t('auditLogs.tableHeaders.name'),
        accessorKey: 'name',
        cell: (info) => info.getValue() as string,
      },
      {
        header: t('auditLogs.tableHeaders.createdAt'),
        accessorKey: 'createdAt',
        cell: (info) => formatDate({ date: new Date(info.getValue() as string) }),
      },
      {
        header: t('auditLogs.tableHeaders.createdBy'),
        accessorKey: 'createdBy',
        cell: (info) => (
          <>
            <UserActionBadge user={info.getValue() as BasicUserAttributes} />
          </>
        ),
      },
    ],
    [t],
  );

  const onRowClick = useCallback(
    (row: AuditLogsTableTableRow) => {
      navigate(generatePath(routePaths.auditLog, { auditLogId: row._id }));
    },
    [navigate],
  );

  return (
    <>
      <Table
        getRowProps={(row) => ({
          className: 'clickableRow',
          onClick: () => onRowClick(row.original),
        })}
        columns={columns}
        data={data}
        loading={loading}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default AuditLogsTable;
