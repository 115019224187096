import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { MeinBauApiLogFragmentDoc } from '../../../fragments/meinBauApiLog/generated/MeinBauApiLogFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeinBauApiLogQueryVariables = SchemaTypes.Exact<{
  meinBauApiLogId: SchemaTypes.Scalars['ID']['input'];
}>;

export type MeinBauApiLogQuery = {
  __typename?: 'Query';
  meinBauApiLog: {
    __typename?: 'MeinBauApiLog';
    _id: string;
    createdAt: any;
    status?: number | null;
    ip?: string | null;
    appName?: string | null;
    useCase: string;
    method: SchemaTypes.MethodType;
    input: string;
    output?: string | null;
    errorMessage?: string | null;
  };
};

export const MeinBauApiLogDocument = gql`
  query MeinBauApiLog($meinBauApiLogId: ID!) {
    meinBauApiLog(meinBauApiLogId: $meinBauApiLogId) {
      ...MeinBauApiLog
    }
  }
  ${MeinBauApiLogFragmentDoc}
`;

/**
 * __useMeinBauApiLogQuery__
 *
 * To run a query within a React component, call `useMeinBauApiLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeinBauApiLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeinBauApiLogQuery({
 *   variables: {
 *      meinBauApiLogId: // value for 'meinBauApiLogId'
 *   },
 * });
 */
export function useMeinBauApiLogQuery(
  baseOptions: Apollo.QueryHookOptions<MeinBauApiLogQuery, MeinBauApiLogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeinBauApiLogQuery, MeinBauApiLogQueryVariables>(MeinBauApiLogDocument, options);
}
export function useMeinBauApiLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeinBauApiLogQuery, MeinBauApiLogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeinBauApiLogQuery, MeinBauApiLogQueryVariables>(MeinBauApiLogDocument, options);
}
export type MeinBauApiLogQueryHookResult = ReturnType<typeof useMeinBauApiLogQuery>;
export type MeinBauApiLogLazyQueryHookResult = ReturnType<typeof useMeinBauApiLogLazyQuery>;
export type MeinBauApiLogQueryResult = Apollo.QueryResult<MeinBauApiLogQuery, MeinBauApiLogQueryVariables>;
export function refetchMeinBauApiLogQuery(variables: MeinBauApiLogQueryVariables) {
  return { query: MeinBauApiLogDocument, variables: variables };
}
