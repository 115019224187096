import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantAnalyticQueryVariables = SchemaTypes.Exact<{
  tenantId: SchemaTypes.Scalars['ID']['input'];
  rangeEnd?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['DateTimeISO']['input']>;
  rangeStart?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['DateTimeISO']['input']>;
}>;

export type TenantAnalyticQuery = {
  __typename?: 'Query';
  tenantAnalytic: {
    __typename?: 'TenantAnalyticsResponse';
    pdfRequestsPerIframes: Array<{
      __typename?: 'CountEntitiesPerIframe';
      iframeId: string;
      name: string;
      count: number;
    }>;
    projectsPerIframes: Array<{ __typename?: 'CountEntitiesPerIframe'; iframeId: string; name: string; count: number }>;
  };
};

export const TenantAnalyticDocument = gql`
  query TenantAnalytic($tenantId: ID!, $rangeEnd: DateTimeISO, $rangeStart: DateTimeISO) {
    tenantAnalytic(tenantId: $tenantId, rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
      pdfRequestsPerIframes {
        iframeId
        name
        count
      }
      projectsPerIframes {
        iframeId
        name
        count
      }
    }
  }
`;

/**
 * __useTenantAnalyticQuery__
 *
 * To run a query within a React component, call `useTenantAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantAnalyticQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      rangeEnd: // value for 'rangeEnd'
 *      rangeStart: // value for 'rangeStart'
 *   },
 * });
 */
export function useTenantAnalyticQuery(
  baseOptions: Apollo.QueryHookOptions<TenantAnalyticQuery, TenantAnalyticQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantAnalyticQuery, TenantAnalyticQueryVariables>(TenantAnalyticDocument, options);
}
export function useTenantAnalyticLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantAnalyticQuery, TenantAnalyticQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantAnalyticQuery, TenantAnalyticQueryVariables>(TenantAnalyticDocument, options);
}
export type TenantAnalyticQueryHookResult = ReturnType<typeof useTenantAnalyticQuery>;
export type TenantAnalyticLazyQueryHookResult = ReturnType<typeof useTenantAnalyticLazyQuery>;
export type TenantAnalyticQueryResult = Apollo.QueryResult<TenantAnalyticQuery, TenantAnalyticQueryVariables>;
export function refetchTenantAnalyticQuery(variables: TenantAnalyticQueryVariables) {
  return { query: TenantAnalyticDocument, variables: variables };
}
