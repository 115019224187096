import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Stack, ListGroup } from 'react-bootstrap';

import { AuditLogEventsClass } from 'graphql/types.generated';
import { copyToClipBoard } from 'utils/helpers';
import UserActionBadge from 'components/common/UserActionBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/common/Button';
import lodash from 'lodash';
import AuditLogPayloadTable, { IAuditLogPayloadItem } from './AuditLogPayloadTable';
import config from 'config/config';

interface IAuditLogDataParams {
  auditLog: AuditLogEventsClass;
}

const AuditLogData: FC<IAuditLogDataParams> = ({ auditLog }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'auditLog' });

  const payloadMapped = useMemo(() => {
    let auditPayLoads: IAuditLogPayloadItem[] = [];
    if (auditLog.payload) {
      auditPayLoads =
        lodash.map(JSON.parse(auditLog.payload), (auditPayloadValue, auditPayloadKey) => {
          return {
            label: auditPayloadKey,
            value: JSON.stringify(auditPayloadValue),
          };
        }) || [];
    }
    return auditPayLoads;
  }, [auditLog.payload]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Card.Title className="mb-0">{t('titles.baseData')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('baseData.name')}</Card.Text>
                  {auditLog.name}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('baseData.tenantId')}</Card.Text>
                  {auditLog.tenantId ? (
                    <>
                      {auditLog.tenantId}
                      <Button
                        size="sm"
                        className="mx-1 h-75"
                        onClick={() =>
                          copyToClipBoard(`${config.adminBaseUrl}/tenant/${auditLog.tenantId}`, t('copiedToClipboard'))
                        }
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </>
                  ) : (
                    t('baseData.tenantIdIsNotSpecified')
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('baseData.createdBy')}</Card.Text>
                  <UserActionBadge user={auditLog.createdBy} />
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col lg={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('baseData.meta.clientIP')}</Card.Text>
                  {auditLog.meta.clientIP}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('baseData.meta.country')}</Card.Text>
                  {auditLog.meta.country ? auditLog.meta.country : t('baseData.meta.countryIsNotSpecified')}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Card.Text className="fw-bold mb-1">{t('baseData.meta.domain')}</Card.Text>
                  {auditLog.meta.domain}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col lg={12}>
          {auditLog.payload ? (
            <Card>
              <Card.Header>
                <Stack direction="horizontal" gap={3}>
                  <Card.Title className="mb-0">{t('titles.payload')}</Card.Title>
                  <Button
                    className="ms-auto"
                    onClick={() => copyToClipBoard(auditLog.payload as string, t('copiedToClipboard'))}
                  >
                    {t('copy')}
                  </Button>
                </Stack>
              </Card.Header>
              <Card.Body>
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '3px' }} />
                {t('helper')}
              </Card.Body>
              <AuditLogPayloadTable auditPayload={payloadMapped} />
            </Card>
          ) : (
            <Card>
              <Card.Body>{t('payloadIsNotProvided')}</Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AuditLogData;
