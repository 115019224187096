import { QueryHookOptions } from '@apollo/client';
import { IframeQuery, IframeQueryVariables, useIframeQuery } from 'graphql/queries/iframe/generated/Iframe';

interface IGetIframeProps extends QueryHookOptions<IframeQuery, IframeQueryVariables> {
  iframeId: string;
}

export default function useIframe({ iframeId }: IGetIframeProps) {
  const { data, loading, error } = useIframeQuery({ variables: { iframeId }, skip: !iframeId });
  return { iframe: (data?.iframe || null) as IframeQuery['iframe'] | null, loading, error };
}
