import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import useGetSearchParams from 'hooks/useGetSearchParams';
import {
  ProjectPdfRequestsQuery,
  ProjectPdfRequestsQueryVariables,
  useProjectPdfRequestsQuery,
} from 'graphql/queries/projectPdfRequests/generated/ProjectPdfRequests';

interface IGetProjectPdfRequestsProps
  extends QueryHookOptions<ProjectPdfRequestsQuery, ProjectPdfRequestsQueryVariables> {
  iframeId?: string;
  currentPage: number;
}

export const useGetProjectPdfRequestsFilter = () => {
  const {
    searchParams: { projectPdfRequestsSearchQuery = '' },
  } = useGetSearchParams(['projectPdfRequestsSearchQuery']);

  return { searchQuery: projectPdfRequestsSearchQuery };
};

export default function useProjectPdfRequests({ iframeId, currentPage, ...baseOptions }: IGetProjectPdfRequestsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const filter = useGetProjectPdfRequestsFilter();

  const { data, loading, error } = useProjectPdfRequestsQuery({
    variables: {
      skip,
      limit: constants.tableRowsCountPerPage,
      searchQuery: filter.searchQuery,
      ...(iframeId ? { iframeId } : {}),
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const projectPdfRequests = data?.projectPdfRequests?.items || [];
  const total = data?.projectPdfRequests?.total || 0;

  return {
    projectPdfRequests,
    total,
    loading,
    error,
  };
}
