import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import usePagination from 'hooks/usePagination';
import useGetInvitations from 'hooks/invitation/useGetInvitations';
import { grabErrorMessage } from 'utils/helpers';
import InvitationsTable from './InvitationsTable';
import useTableObserver from 'hooks/useTableObserver';
import usePolling from 'hooks/usePolling';
import InvitationsFilterForm from './InvitationsFilter';
import { AccountType } from 'graphql/types.generated';

interface IInvitationsSection {
  accountType: AccountType;
}

const InvitationsSection: FC<IInvitationsSection> = ({ accountType }) => {
  const { t } = useTranslation();
  const { currentPage, changePage } = usePagination({ searchParamKey: 'invitations' });

  const { total, invitations, loading, error, startPolling, stopPolling } = useGetInvitations({
    currentPage,
    accountType,
  });
  useTableObserver({ currentPage, loading, isError: !!error, total, changePage });
  usePolling({ startPolling, stopPolling });

  return (
    <>
      <Row>
        <Col xs={12}>
          <h4>{t('userManagement.invitations.title')}</h4>
          <InvitationsFilterForm {...{ changePage, accountType }} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
          {!error ? <InvitationsTable {...{ invitations, total, currentPage, changePage, loading }} /> : null}
        </Col>
      </Row>
    </>
  );
};

export default InvitationsSection;
