import { QueryHookOptions } from '@apollo/client';
import constants from '../../constants/constants';
import {
  CheckStatusOfReportsQuery,
  CheckStatusOfReportsQueryVariables,
  useCheckStatusOfReportsQuery,
} from 'graphql/queries/reports/generated/CheckStatusOfReports';

interface IGetStatusOfReportsProps
  extends QueryHookOptions<CheckStatusOfReportsQuery, CheckStatusOfReportsQueryVariables> {
  iframeId?: string;
  currentPage: number;
}

export default function useReports({ iframeId, currentPage, ...baseOptions }: IGetStatusOfReportsProps) {
  const skip = constants.tableRowsCountPerPage * (currentPage - 1);
  const { data, loading, error, startPolling, stopPolling } = useCheckStatusOfReportsQuery({
    variables: {
      skip,
      limit: constants.tableRowsCountPerPage,
      ...(iframeId ? { iframeId } : {}),
    },
    fetchPolicy: 'cache-and-network',
    ...baseOptions,
  });

  const reports = data?.checkStatusOfReports.items || [];

  return {
    reports,
    total: data?.checkStatusOfReports.total || 0,
    loading,
    error,
    stopPolling,
    startPolling,
  };
}
