import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import s from './ApplicationManagement.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button/Button';
import { Modal, useModal } from 'components/common/Modal';
import CreateApplicationAccountForm from './CreateApplicationAccountForm';
import useAllApplications from 'hooks/application/useAllApplications';
import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { grabErrorMessage, toastErrorMessage } from 'utils/helpers';
import usePagination from 'hooks/usePagination';
import ApplicationsFilterForm from './ApplicationsFilterForm';
import ApplicationsTable from './ApplicationsTable';
import useUser from 'hooks/user/useUser';
import { Roles } from 'graphql/types.generated';

const ApplicationManagement = () => {
  const { t } = useTranslation();
  const { showModal, isVisible, hideModal } = useModal();

  const { currentPage, changePage } = usePagination({ searchParamKey: 'applicationsPage' });
  const { allApplications, refetchAllApplications, loading, error, total } = useAllApplications({
    currentPage,
  });
  const { me } = useUser({ fetchPolicy: 'cache-only' });

  const onBtnClick = useCallback(() => {
    showModal();
  }, [showModal]);

  const onCreateRefetch = useCallback(
    async (searchQuery: string) => {
      try {
        refetchAllApplications({
          filter: { searchQuery },
        });
      } catch (error: any) {
        toastErrorMessage(error);
      }
    },
    [refetchAllApplications],
  );

  return (
    <>
      <div className={s.header}>
        {me?.role === Roles.MEINBAU_ADMIN && (
          <div className={s.buttons}>
            <Button onClick={() => onBtnClick()}>
              {t('applicationManagement.createApplicationAccount')} <FontAwesomeIcon icon={faUserPlus} />
            </Button>
          </div>
        )}
      </div>
      <Row className="mb-2">
        <Col xs={12}>
          <h4>{t('applicationManagement.name')}</h4>
          <ApplicationsFilterForm {...{ changePage }} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!loading && error ? <Alert variant="danger">{grabErrorMessage(error)}</Alert> : null}
          {!error ? (
            <ApplicationsTable {...{ applications: allApplications, total, currentPage, changePage, loading }} />
          ) : null}
        </Col>
      </Row>
      <Modal
        isVisible={isVisible}
        onHide={hideModal}
        headerCloseButton
        headerText={t('applicationManagement.modal.header')}
        size="lg"
      >
        <CreateApplicationAccountForm hideModal={hideModal} onCreateRefetch={onCreateRefetch} />
      </Modal>
    </>
  );
};

export default ApplicationManagement;
